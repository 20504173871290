import React from 'react'

import { Link } from 'react-router-dom'

import { makeAppStyles, useAppTheme } from '@percept/mui'


const { APP } = process.env

// Switchable top-level route based on parent application
const HOME_ROUTE = APP === 'vodafone' ? '/wizard/home' : '/'


const useStyles = makeAppStyles( theme => ({
  logoAnchor: {
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
}) )


export const HomeButton = (): JSX.Element => {

  const appTheme = useAppTheme()

  const { LogoComponent } = appTheme.branding

  const classes = useStyles()

  return (
    <Link to={HOME_ROUTE} className={classes.logoAnchor}>
      <LogoComponent
        size={16}
        units='px'
        color='white'
        opacity={0.9} />
    </Link>
  )
}
