import React, { Fragment, useReducer, useEffect, useCallback } from 'react'

import {
  Box,
  Card,
  CardContent,
  makeAppStyles,
  BackdropLoader,
  Fade,
  Percept,
  TPAAuditLogo,
  BrandtechLogo,
  VodafoneLogo,
  BoxProps,
} from '@percept/mui'

import { useSetInitialPassword, useSignIn, useSingleSignOnCodeExchange } from '@percept/hooks'

import SignIn from './SignIn'
import ConfirmSignIn from './ConfirmSignIn'
import ForgotPassword from './ForgotPassword'
import RequireNewPassword from './RequireNewPassword'
import Onboard from './Onboard'

import authReducer, { initialState } from './authReducer'

import { MultiFactorAuthentication } from '../User/MultiFactorAuthentication'

import qs from 'query-string'

import { noop, some } from 'lodash-es'

import { AppBranding } from '@percept/types'

import { LogoComponentProps } from '@percept/mui/components/Logos/typings'

import { AuthInputProps } from './Inputs'

import { shouldAttemptLoad } from '@percept/utils'


const { BRANDING, MFA_REQUIRED } = process.env


export type AuthenticatorProps = {
  isLoading?: boolean
  enableSingleSignOn?: boolean
  hideSignInHeader?: boolean
  logoContentOverride?: JSX.Element | null
  singleSignOnLoadingContent?: JSX.Element | null
  BoxProps?: BoxProps
  AuthInputProps?: Partial<AuthInputProps>
  onSignUp?: (() => void) | null
}


const useStyles = makeAppStyles( theme => ({
  card: {
    ...theme.appComponents.authenticator.root,
    position: 'relative',
  },
  cardContent: {
    paddingTop: '6rem',
  },
  logoHeader: {
    background: theme.palette.primary.main,
    color: BRANDING === 'PERCEPT' ? theme.palette.primary.dark : '#FFF',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}) )


const useAuthComponentStyles = makeAppStyles<{}, 'heading'>( theme => ({
  heading: {
    ...theme.appComponents.authenticator.heading,
  },
}))


const logoSize = BRANDING === 'TPA' ? 3.5 : 2.5

const logoComponentMapping: Record<AppBranding, React.FC<LogoComponentProps>> = {
  PERCEPT: Percept,
  BRANDTECH: BrandtechLogo,
  TPA: TPAAuditLogo,
  VODAFONE: VodafoneLogo,
}

const LogoComponent = logoComponentMapping[BRANDING] || Percept


export const Authenticator = ({
  isLoading = false,
  enableSingleSignOn = false,
  singleSignOnLoadingContent = null,
  logoContentOverride = null,
  hideSignInHeader = false,
  BoxProps = {},
  AuthInputProps = {},
  onSignUp
}: AuthenticatorProps): JSX.Element => {

  const [state, dispatch] = useReducer(authReducer, initialState)

  const [signInResponse, signIn] = useSignIn({ username: null, password: null })

  const [initialPasswordResponse, setInitialPasswordHandler] = useSetInitialPassword()

  const {
    status,
    ...params
  } = state

  // Mount hook
  useEffect(() => {
    const { username, token } = qs.parse(window.location.search) as (
      Record<'username' | 'token', string>
    )
    const password = token
    if( username && password ){
      dispatch({ type: 'STORE_ONBOARD_PARAMS', payload: { username, token } })
      signIn({ username, password })
      history.replaceState('', document.title, window.location.pathname)
    }
  }, [signIn])

  const [exchangeStatus, exchangeSingleSignOnCode] = useSingleSignOnCodeExchange()

  const isSingleSignOnRedirect = enableSingleSignOn && window.location.href.includes('auth-redirect/sso')

  // Single sign-on handler
  useEffect(() => {
    const { code } = qs.parse(window.location.search) as (
      Record<'code', string>
    )
    if(
      code
      && isSingleSignOnRedirect
      && shouldAttemptLoad(exchangeStatus)
    ){
      history.replaceState('', document.title, window.location.pathname)
      exchangeSingleSignOnCode({ code })
    }
  }, [isSingleSignOnRedirect, exchangeSingleSignOnCode, exchangeStatus])

  const attemptSignIn = useCallback((): void => {
    const { username, password } = state.inputs
    if( username && password ){
      signIn({ username, password })
    }
  }, [signIn, state.inputs])

  useEffect(() => {
    if( signInResponse.data && MFA_REQUIRED ){
      dispatch({ type: 'REQUIRE_MFA' })
    }
  }, [dispatch, signInResponse.data])

  useEffect(() => {
    if( initialPasswordResponse.data ){
      attemptSignIn()
    }
  }, [initialPasswordResponse.data, attemptSignIn])

  const loading = isLoading || some(
    [signInResponse, initialPasswordResponse],
    r => r.loading
  )

  const isInitialLoading = (
    isLoading
    || status === 'loading'
    || isSingleSignOnRedirect
  )

  useEffect(() => {
    const { error } = signInResponse
    if( error && error.detail ){
      const payload = error.detail
      // Reset code in case this is following a password reset - we don't want to prefill
      // the MFA code input with a stale TOTP
      dispatch({
        type: 'UPDATE_INPUTS',
        payload: {
          code: '',
        },
      })
      if( payload.status === 'SOFTWARE_TOKEN_MFA' ){
        dispatch({ type: 'CONFIRM_SIGN_IN_REQUIRED', payload })
      }
      if( payload.status === 'FORCE_CHANGE_PASSWORD' ){
        dispatch({ type: 'USER_ONBOARDING' })
      }
    }
  }, [dispatch, signInResponse])

  const updateInputs = useCallback( payload => {
    dispatch({ type: 'UPDATE_INPUTS', payload })
  }, [dispatch])

  const setInitialPassword = useCallback((): void => {
    const { username, password } = state.inputs
    if( username && password && state.initialToken ){
      setInitialPasswordHandler({
        username,
        token: state.initialToken,
        new_password: password,
      })
    }
  }, [setInitialPasswordHandler, state.inputs, state.initialToken])

  // const appTheme = useAppTheme()

  // const { LogoComponent } = appTheme.branding

  const classes = useStyles()

  const authComponentClasses = useAuthComponentStyles()

  let Component

  switch(status){
    case 'forgotPassword':
      Component = ForgotPassword
      break
    case 'requireNewPassword':
      Component = RequireNewPassword
      break
    case 'confirmSignIn':
      Component = ConfirmSignIn
      break
    case 'onboard':
      Component = Onboard
      break
    case 'signIn':
    default:
      Component = SignIn
      break
  }

  return (
    <Fragment>

      <Box {...BoxProps}>

        <Fade in={!isInitialLoading}>

          <Box
            maxWidth='34em'
            m='auto'
            pt={12}>

            { status === 'requireMFA' ? (
              <MultiFactorAuthentication
                mode='SETUP'
                onClose={noop} />
            ) : (
              <Card className={classes.card}>

                { logoContentOverride || (
                  <div className={classes.logoHeader}>
                    <LogoComponent
                      centered
                      size={logoSize} />
                  </div>
                )}

                <CardContent className={classes.cardContent}>

                  <Box
                    px={2}
                    py={1}>
                    { status === 'loading' ? (
                      <Box minHeight='10rem' />
                    ) : (
                      <Component
                        loading={loading}
                        dispatch={dispatch}
                        updateInputs={updateInputs}
                        attemptSignIn={attemptSignIn}
                        setInitialPassword={setInitialPassword}
                        enableSingleSignOn={enableSingleSignOn}
                        AuthInputProps={AuthInputProps}
                        classes={authComponentClasses}
                        hideSignInHeader={hideSignInHeader}
                        {...params}
                        onSignUp={onSignUp} />
                    )}
                  </Box>

                </CardContent>
              </Card>
            )}

          </Box>

        </Fade>

      </Box>

      <BackdropLoader
        BackdropProps={{ open: loading || status === 'loading' || isSingleSignOnRedirect }}>
        { isSingleSignOnRedirect && singleSignOnLoadingContent }
      </BackdropLoader>

    </Fragment>
  )

}
