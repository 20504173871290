import React from 'react'

import {
  Box,
  BoxProps,
  Chip,
  ChipProps,
  CreateCSSProperties,
  makeAppStyles,
  ProviderLogo,
  Tabs,
  Tab,
  TabProps,
} from '@percept/mui'

import { useNavigation } from '@percept/hooks'

import { useOrgNavigation } from 'components/Organisation'

import { find, get, some } from 'lodash-es'

import { coerceReportProvider, isPlatformUnitContainer } from '@percept/utils'

import { providerURLMap } from '@percept/constants'

import { ReportProvider, ReportSeries } from '@percept/types'


const { PREVIEW, NODE_ENV } = process.env


type PlatformUnitTabValue = (
  ReportProvider | 'overview' | 'performance' | 'insights' | 'adwords_insights_report_video' |
  'health-vs-performance'
)


const useTabClasses = makeAppStyles( theme => ({
  root: {
    fontSize: 13,
    textTransform: 'none',
    fontWeight: 700,
    minHeight: theme.spacing(6),
    color: 'white',
  },
  indicator: (
    theme.appComponents.appBarTabIndicator ?
      theme.appComponents.appBarTabIndicator.colorSecondary :
      {
        backgroundColor: theme.palette.secondary.main,
      }
  ),
}))


const useNetworkChipClasses = makeAppStyles<{ active: boolean }>( theme => ({
  root: ({ active }): CreateCSSProperties => ({
    position: 'relative',
    top: -1,
    height: 18,
    fontSize: 11,
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    cursor: 'inherit',
    backgroundColor: (
      active ?
        theme.palette.secondary.contrastText :
        theme.palette.secondary.dark
    ),
    marginLeft: theme.spacing(0.75),
  }),
  label: ({ active }): CreateCSSProperties => ({
    padding: theme.spacing(0.75),
    color: (
      active ?
        theme.palette.secondary.dark :
        theme.palette.secondary.contrastText
    ),
  }),
}))


const NetworkChip = ({ active, ...props }: ChipProps & { active: boolean }): JSX.Element => (
  <Chip
    classes={useNetworkChipClasses({ active })}
    {...props} />
)


const ProviderNetwork = ({
  provider,
  network,
  active,
}: {
  provider: ReportProvider
  network: string
  active: boolean
}): JSX.Element => (
  <Box display='flex' alignItems='center'>
    <ProviderLogo
      size={1.5}
      units='em'
      provider={provider} />
    <NetworkChip
      label={network}
      active={active} />
  </Box>
)


const hasGoogleAdsVideo = (reportSeries: ReportSeries): boolean => Boolean(
  reportSeries.provider.slug === 'GOOGLE_ADS'
  && get(reportSeries.output_config.insights_reports, 'VIDEO')
)


const getProviderTabs = ({
  hasVideoInsights,
  providers,
  tabClasses,
}: {
  hasVideoInsights: boolean
  providers: ReportProvider[]
  tabClasses: TabProps['classes']
}): JSX.Element[] => {
  return providers.reduce( (acc, provider) => {
    acc.push(
      <Tab
        key={provider}
        value={provider}
        classes={tabClasses}
        label={
          <ProviderLogo
            size={1.5}
            units='em'
            provider={provider} />
        } />
    )
    // Add Video tab if applicable
    if( provider === 'adwords' && hasVideoInsights ){
      acc.push(
        <Tab
          key='youtube'
          value='adwords_insights_report_video'
          classes={tabClasses}
          label={
            <ProviderLogo
              size={1.5}
              units='em'
              provider='youtube' />
          } />
      )
    }
    return acc
  }, [] as JSX.Element[])
}


export const PlatformUnitTabs = (props: Partial<Omit<BoxProps, 'children'>>): JSX.Element => {

  const {
    activePlatformUnit,
    activeSeries,
    activeProvider,
    providers,
    hasInsights,
    isInsightsView,
    isInsightsReportView,
    hasStructuralReporting,
    hasPerformanceReporting,
    hasOverview,
    isPerformanceView,
    isPerformanceReportView,
    isHealthVsPerformanceView,
    isChannelView,
    ready,
  } = useOrgNavigation()

  const isOverview = !some([
    activeSeries,
    isPerformanceView,
    isPerformanceReportView,
    // Single provider channel views constitute an 'overview' in relative terms
    isChannelView && providers.length > 1,
    isInsightsView,
    isInsightsReportView,
    isHealthVsPerformanceView,
  ])

  const containsMultipleOrgs = isPlatformUnitContainer(activePlatformUnit)

  const activeOrgId = activePlatformUnit && activePlatformUnit.id

  const reportSeries = activePlatformUnit && activePlatformUnit.report_series || null
  const hasGoogleAdsVideoTab = !!find(reportSeries, hasGoogleAdsVideo)

  const navigate = useNavigation()

  const tabClasses = useTabClasses()

  const activeTab: PlatformUnitTabValue | null | undefined = (
    isOverview ?
      'overview' :
      (isPerformanceReportView || isPerformanceView) ?
        'performance' :
        isInsightsReportView ?
          'adwords_insights_report_video' :
          isInsightsView ?
            'insights' :
            isHealthVsPerformanceView ?
              'health-vs-performance' :
              (activeSeries || isChannelView) ?
                activeProvider :
                undefined
  )

  return (
    <Box
      {...props}>
      { ready && (
        <Tabs
          /**
          * NOTE - We need to explicitly set a React component key
          * to avoid stale markup on certain transitions between
          * organisation views, e.g child to parent overview.
          */
          key={activeOrgId || 'tabs-loading'}
          classes={tabClasses}
          value={activeTab}
          indicatorColor='secondary'
          onChange={(e, value: PlatformUnitTabValue): void => {
            if( activeOrgId ){
              const baseUrl = `/dashboards/${activeOrgId}`
              switch(value){
                case 'overview':
                  navigate(baseUrl)
                  break

                case 'performance': {
                  if( providers.length > 1 ){
                    navigate(`${baseUrl}/performance`)
                    break
                  }
                  let nextProvider: ReportProvider | null = null
                  if( activeProvider ){
                    nextProvider = activeProvider
                  }else{
                    nextProvider = providers[0]
                  }
                  if( nextProvider ){
                    navigate(`${baseUrl}/${providerURLMap[nextProvider]}`)
                  }
                  break
                }

                case 'insights':
                  navigate(`${baseUrl}/insights`)
                  break

                case 'adwords_insights_report_video': {
                  const matchedSeries = find(
                    reportSeries,
                    hasGoogleAdsVideo,
                  )
                  if( matchedSeries ){
                    navigate(`/series/${matchedSeries.id}/insights-reports/video`)
                  }
                  break
                }

                case 'health-vs-performance':
                  navigate(`${baseUrl}/health-vs-performance`)
                  break

                case 'adform':
                case 'adwords':
                case 'facebook':
                case 'dv360': {
                  const matchedSeries = find(
                    reportSeries,
                    s => coerceReportProvider(s.provider.slug) === value
                  )
                  if( matchedSeries ){
                    navigate(`/series/${matchedSeries.id}`)
                  }else{
                    navigate(`${baseUrl}/${providerURLMap[value]}`)
                  }
                  break
                }

                default:
                  break
              }
            }
          }}>

          { hasOverview && (
            <Tab value='overview' classes={tabClasses} label='Overview' />
          )}

          { hasPerformanceReporting && (containsMultipleOrgs || providers.length > 1) && (
            <Tab value='performance' classes={tabClasses} label='Performance' />
          )}

          { containsMultipleOrgs && (PREVIEW || NODE_ENV === 'development') && (
            <Tab value='health-vs-performance' classes={tabClasses} label='Health Vs Performance' />
          )}

          { hasInsights && (
            <Tab value='insights' classes={tabClasses} label='Insights' />
          )}

          { (providers.length > 1 || hasStructuralReporting) && (
            getProviderTabs({
              providers,
              tabClasses,
              hasVideoInsights: hasGoogleAdsVideoTab,
            })
          )}

        </Tabs>
      )}
    </Box>
  )
}
