
import React, { Fragment, useMemo } from 'react'

import { Typography, TypographyProps } from '@material-ui/core'

import { makeAppStyles } from '../../themes'

import { ChevronRight } from '../../icons'

import { formatAuditDates } from '@percept/utils'

import { DateType } from '@percept/types'


const useStyles = makeAppStyles( theme => ({
  typography: {
    display: 'flex',
    alignItems: 'center',
  },
  separator: {
    fontSize: '1.2em',
    color: theme.palette.action.disabled,
  },
}) )


export type ReportRangeProps = {
  start: DateType
  end: DateType
  separator?: React.ReactElement
  format?: string
  TypographyProps?: Partial<TypographyProps>
  disableTypography?: boolean
}


export const ReportRange = ({
  start,
  end,
  separator,
  format = 'dd/MM/yy',  /* https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md */
  TypographyProps = {},
  disableTypography,
}: ReportRangeProps): JSX.Element | null => {

  const [formattedStart, formattedEnd] = useMemo(() => (
    formatAuditDates({ start, end, format })
  ), [start, end, format])

  const classes = useStyles()

  if( !formattedStart && !formattedEnd ){
    return null
  }

  let content: JSX.Element | null = null

  if( start === end ){
    content = (
      <Fragment>
        { formattedStart }
      </Fragment>
    )
  }else{
    content = (
      <Fragment>
        { formattedStart }
        { separator || <ChevronRight className={classes.separator} /> }
        { formattedEnd }
      </Fragment>
    )
  }

  if( disableTypography ){
    return content
  }

  return (
    <Typography
      className={classes.typography}
      {...TypographyProps}>
      { content }
    </Typography>
  )
}
