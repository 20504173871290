import { Selector } from 'react-redux'

import { FixtureKeyState, StoreState } from 'types'

import { AnyPerformanceDimension, InsightsReportViewType, PerformanceComparisonRange, TipSettings } from '@percept/types'

import { AppTheme } from '@percept/mui'

import { AppState } from './reducers/appStateReducer'


export type DashboardSelector<V, P = undefined> = Selector<StoreState, V, P>


export const getTips: DashboardSelector<TipSettings> = state => (
  state.settings.tips
)

export const getFixtureKey: DashboardSelector<FixtureKeyState> = state => (
  state.fixtureKey
)

export const getTargetCurrency: DashboardSelector<string> = state => (
  state.appState.targetCurrency
)

export const getPerformanceReportingDimension: DashboardSelector<AnyPerformanceDimension> = state => (
  state.appState.performanceReportingDimension
)

export const getPerformanceComparisonRange: DashboardSelector<PerformanceComparisonRange> = state => (
  state.appState.performanceComparisonRange
)

export const getInsightsReportViewType: DashboardSelector<InsightsReportViewType> = state => (
  state.appState.insightsReportViewType
)

export const getReferenceDate: DashboardSelector<Date | null> = state => (
  state.appState.referenceDate
)

export const getReferenceDateBehaviour: DashboardSelector<AppState['referenceDateBehaviour']> = state => (
  state.appState.referenceDateBehaviour
)

export const getLatestAvailableReferenceDate: DashboardSelector<Date | null> = state => (
  state.appState.latestAvailableReferenceDate
)

export const getLatestAvailableDoubleVerifyReferenceDate: DashboardSelector<Date | null> = state => (
  state.appState.latestAvailableDoubleVerifyReferenceDate
)

export const getAppThemeState: DashboardSelector<AppState['theme']> = state => (
  state.appState.theme
)

export const getActiveAppTheme: DashboardSelector<AppTheme> = state => (
  state.appState.theme.active
)

export const getAppThemeOptions: DashboardSelector<AppTheme[]> = state => (
  state.appState.theme.options
)
