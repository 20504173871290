
import React from 'react'

import { AppThemeProvider, AppThemeProviderProps } from './AppThemeProvider'

import { AppTheme } from './themeAugmentation'

import { ChannelKey } from '@percept/types'

import { memoize } from 'lodash-es'


export type ChannelThemeProviderProps = (
  Pick<
    AppThemeProviderProps,
    'children'
  > & {
    channel: ChannelKey | null | undefined
  }
)


const deriveChannelTheme = memoize(
  (channel: ChannelKey | null | undefined) => (
    (theme: AppTheme): AppTheme => {

      if( !channel ){
        return theme
      }

      return {
        ...theme,
        palette: {
          ...(theme.palette),
          secondary: theme.palette.channel[channel],
        },
      }

    }
  )
)


/**
 * Nested channel theme provider, requires `AppTheme` to be provided to an ancestor component
 */
export function ChannelThemeProvider({ channel, children }: ChannelThemeProviderProps): JSX.Element {

  const channelTheme = deriveChannelTheme(channel)

  return (
    <AppThemeProvider theme={channelTheme}>
      { children }
    </AppThemeProvider>
  )

}
