
import { combineReducers } from 'redux'

import { createResetReducer } from '@percept/redux/reducers'

import { connectRouter } from 'connected-react-router'

import { REHYDRATE } from 'redux/actions'

import {
  getBundle,
  // clientReports as clientReportsBundle,
  // clientSeries as clientSeriesBundle,
  performanceReporting as performanceReportingBundle,
  platformUnits as platformUnitsBundle,
  metricMetadata as metricMetadataBundle,
  layouts as layoutsBundle,
  structuralReporting as structuralReportingBundle,
  token as tokenBundle,
  user as userBundle,
} from '@percept/redux/bundles'


import appState from './appStateReducer'
import settings from './settingsReducer'
import filters from '../ReportingDashboard/reducers/filtersReducer'

import { migrate } from '../migrations'

import { mapValues } from 'lodash-es'

import { ReduxAction } from '@percept/types'
import { FixtureKeyState, StoreState } from 'types'
import { Reducer } from 'redux'
import { History } from 'history'


const resetTypes = [userBundle.actions.USER_LOGOUT]


type RootReducer<S extends object> = {
  [K in keyof S]: Reducer<S[K]> 
}


const notificationsBundle = getBundle('notifications', { allErrors: process.env.NODE_ENV === 'development' })


// NOTE - Temporary fixture key reducer
const fixtureKey: Reducer<FixtureKeyState> = (state = 'GLOBAL_ORG', action) => {
  if( action.type === '@@BETA//SET_FIXTURE_KEY' ){
    return action.payload
  }
  return state
}


const reducers = mapValues(
  {
    appState,
    fixtureKey,
    layouts: layoutsBundle.reducer,
    metricMetadata: metricMetadataBundle.reducer,
    notifications: notificationsBundle.reducer,
    performanceReporting: performanceReportingBundle.reducer,
    platformUnits: platformUnitsBundle.reducer,
    // reports: clientReportsBundle.reducer,
    // series: clientSeriesBundle.reducer,
    settings,
    structuralReporting: structuralReportingBundle.reducer,
    token: tokenBundle.reducer,
    user: userBundle.reducer,
    filters
  },
  <S>(r: Reducer<S>) => createResetReducer<S>(resetTypes, r),
) as unknown as RootReducer<StoreState>


const rehydrator = (state: Partial<StoreState> | undefined = {}, action: ReduxAction): StoreState => {
  // Apply any migrations
  const rehydrated = migrate(action.payload.state)
  
  return {
    ...state,
    appState: reducers.appState(rehydrated.appState || undefined, action),
    settings: reducers.settings(rehydrated.settings, action),
    reports: reducers.reports({
      entitySort: rehydrated.auditEntitySort || undefined,
      settings: rehydrated.auditSettings || undefined,
      activeMetric: null,
      activeSegment: null,
      byId: {},
      entities: {},
      filters: {},
      metrics: {},
      payloads: {},
      search: rehydrated.search || { saved: '' },
    }, action),
    series: reducers.series({
      settings: rehydrated.seriesSettings || undefined,
      activeProvider: {},
      byId: {},
      timeSeries: { active: null, byId: {} }
    }, action)
  } as StoreState
}


export const createRootReducer = (history: History): Reducer<StoreState> => {

  const rootReducer = combineReducers({
    ...reducers,
    router: connectRouter(history),
  })


  return (state: Partial<StoreState> = {}, action): StoreState => {
    
    if( action.type === REHYDRATE ){

      return rehydrator(state, action as ReduxAction)
    }

    return rootReducer(
      state as StoreState,
      action,
    )
  
  }

}
