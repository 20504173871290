
import React from 'react'

import { Loader } from '../Loader'

import { MetricVisualisationWrap, MetricCard } from '../Cards'

import { TextMetricVisualisation, NumberValueProps } from './TextMetricVisualisation'

import { ErrorMetric, NotApplicable } from './ErrorMetrics'

import MetricDebug from './MetricDebug'

import { useCommonMetricStyles } from './styles'

import {
  displayMappings, metricContainsSignal,
} from './lib'

import {
  parseDimension,
} from '@percept/utils'

import { every, get, intersection } from 'lodash-es'

import {
  MetricProps, VisualisationContainer,
} from './typings'

import {
  MetricFormat,
  ProportionMetric,
  RatioMetric,
  ValueMetric as ValueMetricType,
} from '@percept/types'
import { Box } from '@material-ui/core'
import { PlainTextButton } from '../Buttons'
import { ZoomIn } from '@material-ui/icons'


const { APP, PERCEPT_ENV } = process.env

const DEBUG = APP === 'admin' || PERCEPT_ENV !== 'prod'

const defaultDimensionOptions = ['count']


export type ValueMetricProps = (
  Omit<
    MetricProps,
    'metric'
  > & {
    metric: ProportionMetric | ValueMetricType | RatioMetric | null
  }
)

export const ValueMetric = ({
  id,
  metric,
  previousHealth,
  loadingPrevious,
  title,
  description,
  annotations,
  dimensionOptions = defaultDimensionOptions,
  loading,
  debug = false,
  displayType = 'DETAIL',
  onAnnotateMetric,
  setActiveMetric,
  containerRef,
  tipSettings,
  onToggleTip,
  tipPriority,
  format,
  display_options,
  WrapComponent,
  LoaderProps = {},
  MetricCardProps = {},
  metric_type,
  ...props
}: ValueMetricProps): JSX.Element => {

  const displayOptions = displayMappings[displayType],
        { showDescriptions } = displayOptions

  const classes = useCommonMetricStyles()

  const availableDimensions = (
    metric ?
      intersection(
        dimensionOptions,
        Object.keys(metric.dimensions)
      ) :
      defaultDimensionOptions
  )

  const visualisationFormat: MetricFormat = format || display_options.chart_type as MetricFormat || 'number'
  
  const coerceNullToZero = display_options.coerce_null === true

  const visualisations: VisualisationContainer[] = availableDimensions.map( dimension => {

    const isNotApplicable = (
      !metric
      || !metric.dimensions[dimension]
      || (
        !metricContainsSignal(metric, dimension)
        && !coerceNullToZero
      ) 
    )

    const parsedDimension = parseDimension(dimension)

    const rawValue = (
      !metric ?
        null :
        metric.metric_type === 'value' ?
          metric.dimensions[dimension].value :
          metric.dimensions[dimension].enumerator.value
    )

    const value = coerceNullToZero && rawValue === null ? 0 : rawValue
    
    const total = (
      // Percentage format can be applied to value or attribute metrics, which are inherently single
      // value - in this instance we derive a total of 100.
      format === 'percentage' && (!metric || !['proportion', 'ratio'].includes(metric.metric_type)) ?
        100 :
        !metric ?
          0 : metric.metric_type === 'value' ?
            value || 0 :
            metric.dimensions[dimension].denominator.value || 0
    )

    return {
      key: dimension,
      label: parsedDimension.label,
      dimension,
      isNotApplicable,
      content: (
        loading ? (
          <Loader
            preset='fullsize'
            {...LoaderProps} />
        ) : !metric ? (
          <ErrorMetric
            className={classes.muted} />
        ) : isNotApplicable ? (
          <NotApplicable
            className={classes.muted} />
        ) : (
          <TextMetricVisualisation<NumberValueProps>
            format={visualisationFormat as NumberValueProps['format']}
            displayType={displayType}
            {...props}
            metric_type={(
              metric && metric.metric_type
              || metric_type
              || 'value'
            ) as 'value' | 'proportion' | 'ratio'}
            value={value}
            total={total}
            dimension={parsedDimension.dimension} />
        )
      ),
    }
  })

  if( WrapComponent ){
    return (
      <WrapComponent
        containerRef={containerRef}>
        { visualisations.map(({ content }) => content ) }
      </WrapComponent>
    )
  }

  const health = get(metric, ['dimensions', 'count', 'health'], null)

  return (
    <MetricCard
      title={title}
      description={showDescriptions && description || undefined}
      health={health}
      showHeader={true}
      actionPopoverDisabled={
        every(visualisations, v => v.isNotApplicable )
      }
      actionPopoverContent={
        <Box p={2}>
          <PlainTextButton
            className={classes.actionButton}
            variant='contained'
            size='small'
            startIcon={<ZoomIn />}
            onClick={(): void => {
              setActiveMetric && setActiveMetric({
                metric_id: id,
                dimension: 'count',
                title
              })
            }}>
            View Detail
          </PlainTextButton>
        </Box>
      }
      debugContent={
        DEBUG && (
          <MetricDebug id={id} />
        ) || null
      }
      {...MetricCardProps}
      containerRef={containerRef}>

      { visualisations.map( ({ label, isNotApplicable, dimension, content }, i) => {

        return (
          <MetricVisualisationWrap
            key={i}
            label={label}
            disabled={isNotApplicable}
            onClick={
              (!isNotApplicable && setActiveMetric) ? (
                (): void => {
                  setActiveMetric({
                    metric_id: id,
                    dimension,
                    title,
                  })
                }
              ) : undefined
            }>
            
            { content }

          </MetricVisualisationWrap>
        )
      })}

    </MetricCard>
  )
}
