import React from 'react'

import { Link } from 'react-router-dom'

import { Box, MassiveRoundedPlainTextButton, Typography } from '@percept/mui'

import { LockOpen } from '@percept/mui/icons'

import {
  mainBodyTextBoxProps,
  underlineStyles,
} from './lib'


export const TPAWelcomeContent = ({
  signInRoute
}: {
  signInRoute: string
}): JSX.Element => {
  return (
    <Box maxWidth='75em' margin='auto'>
      <Box mb={5}>
        <Typography variant='h1'>
          Welcome to TPA Audit
        </Typography>
      </Box>

      <Box mb={3} {...mainBodyTextBoxProps}>
        TPA Audit provides digital advertisers with <span style={underlineStyles}> insights that matter.</span>
      </Box>

      <Box mb={3} {...mainBodyTextBoxProps}>
        Insights which are born from impartial expertise and delivered at speed.
      </Box>

      <Box mb={3} {...mainBodyTextBoxProps}>
        No single digital advertiser is the same so by utilising TPA Audit you are getting access to customised frameworks and scores for your needs and that set you up for success.
      </Box>

      <Box mb={3} {...mainBodyTextBoxProps}>
        Our technology platform provides specific recommendations and relevant benchmarks which will shift the needle on your digital advertising. <strong>What are you waiting for?</strong>
      </Box>

      <Box my={7} display='flex' flexBasis='100%' justifyContent='center'>
        <Link to={signInRoute}>
          <MassiveRoundedPlainTextButton
            variant='contained'
            color='secondary'
            startIcon={
              <LockOpen />
            }>
            Sign In
          </MassiveRoundedPlainTextButton>
        </Link>
      </Box>
    </Box>
  )
}