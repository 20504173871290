
import React from 'react'

import { Loader } from '../Loader'

import { MetricVisualisationWrap, MetricCard } from '../Cards'

import { TextMetricVisualisation } from './TextMetricVisualisation'

import { ErrorMetric, NotApplicable } from './ErrorMetrics'

import MetricDebug from './MetricDebug'

import {
  displayMappings,
} from './lib'

import { useCommonMetricStyles } from './styles'

import {
  MetricProps,
} from './typings'

import {
  MetricFormat,
  AttributeMetric as AttributeMetricType,
} from '@percept/types'


const { APP, PERCEPT_ENV } = process.env

const DEBUG = APP === 'admin' || PERCEPT_ENV !== 'prod'

const defaultDimensionOptions = ['count']


export type AttributeMetricProps = (
  Omit<
    MetricProps,
    'metric' | 'metric_type'
  > & {
    metric: AttributeMetricType | null
  }
)

export const AttributeMetric = ({
  id,
  metric,
  previousHealth,
  loadingPrevious,
  title,
  description,
  annotations,
  dimensionOptions = defaultDimensionOptions,
  loading,
  debug = false,
  displayType = 'DETAIL',
  onAnnotateMetric,
  setActiveMetric,
  containerRef,
  tipSettings,
  onToggleTip,
  tipPriority,
  format,
  display_options,
  WrapComponent,
  LoaderProps = {},
  MetricCardProps = {},
  ...props
}: AttributeMetricProps): JSX.Element => {

  const displayOptions = displayMappings[displayType],
        { showDescriptions } = displayOptions

  const classes = useCommonMetricStyles()

  const visualisationFormat: MetricFormat = format || display_options.chart_type as MetricFormat || 'text'
  
  const coerceNullToZero = display_options.coerce_null === true

  const isNotApplicable = !metric || metric.value === null && coerceNullToZero

  const prettyPrint = !!display_options.pretty_print

  const content = (
    loading ? (
      <Loader
        preset='fullsize'
        {...LoaderProps} />
    ) : !metric ? (
      <ErrorMetric
        className={classes.muted} />
    ) : isNotApplicable ? (
      <NotApplicable
        className={classes.muted} />
    ) : (
      <TextMetricVisualisation
        format={visualisationFormat}
        displayType={displayType}
        prettyPrint={prettyPrint}
        metric_type='attribute'
        {...props}
        value={metric.value}
        total={0} />
    )
  )

  if( WrapComponent ){
    return (
      <WrapComponent
        containerRef={containerRef}>
        { content }
      </WrapComponent>
    )
  }

  return (
    <MetricCard
      title={title}
      description={showDescriptions && description || undefined}
      previousHealth={previousHealth}
      loadingPrevious={loadingPrevious}
      showHeader={true}
      debugContent={
        DEBUG && (
          <MetricDebug id={id} />
        ) || null
      }
      {...MetricCardProps}
      containerRef={containerRef}>

      <MetricVisualisationWrap>
        { content }
      </MetricVisualisationWrap>

    </MetricCard>
  )
}
