import { useCallback } from 'react'

import { makeSynchronousHook, makeSelectorHook } from '@percept/hooks/libv2'

import {
  getTargetCurrency,
  getFixtureKey,
  getTips,
  getAppThemeState,
  getPerformanceReportingDimension,
  getReferenceDate,
  getReferenceDateBehaviour,
  getLatestAvailableReferenceDate,
  getLatestAvailableDoubleVerifyReferenceDate,
  getPerformanceComparisonRange,
  getInsightsReportViewType,
} from 'redux/selectors'

import {
  toggleTip,
  setTargetCurrency,
  setPerformanceReportingDimension,
  setReferenceDate,
  setReferenceDateBehaviour,
  setAppTheme,
  setPerformanceComparisonRange,
  setInsightsReportViewType
} from 'redux/actions'

import { FixtureKeyState } from 'types'


export const useSignOut = (): (() => void) => {
  return useCallback(() => {
    throw new Error('Deprecated hook - use @percept/hooks user hooks')
  }, [])
}


export const useTargetCurrency = makeSynchronousHook(
  getTargetCurrency,
  setTargetCurrency,
)


export const useAppThemeState = makeSynchronousHook(
  getAppThemeState,
  setAppTheme,
)

export const usePerformanceReportingDimension = makeSynchronousHook(
  getPerformanceReportingDimension,
  setPerformanceReportingDimension,
)

export const useReferenceDate = makeSynchronousHook(
  getReferenceDate,
  setReferenceDate,
)

export const useReferenceDateBehaviour = makeSynchronousHook(
  getReferenceDateBehaviour,
  setReferenceDateBehaviour,
)

export const useLatestAvailableReferenceDate = makeSelectorHook(
  getLatestAvailableReferenceDate
)

export const useLatestAvailableDoubleVerifyReferenceDate = makeSelectorHook(
  getLatestAvailableDoubleVerifyReferenceDate
)

export const usePerformanceComparisonRange = makeSynchronousHook(
  getPerformanceComparisonRange,
  setPerformanceComparisonRange,
)

export const useInsightsReportViewType = makeSynchronousHook(
  getInsightsReportViewType,
  setInsightsReportViewType,
)


export const useTipSettings = makeSynchronousHook(
  getTips,
  toggleTip,
)


export const useFixtureKey = makeSynchronousHook(
  getFixtureKey,
  (payload: FixtureKeyState) => ({
    type: '@@BETA//SET_FIXTURE_KEY',
    payload,
  })
)