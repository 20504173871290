import React, { Fragment, useCallback, useState } from 'react'

import {
  Box,
  MetricSearchConfig,
} from '@percept/mui'

import {
  ReportDashboardApp,
  ReportDashboardAppProps,
  ReportDashboardDriverNavigationProps,
  UserPopover,
} from '@percept/app-components'

import { HomeButton } from 'components/HomeButton'

import { useActiveOrgEnvironment } from 'components/Organisation'

import {
  useLocationParams,
  useNavigation,
} from '@percept/hooks'

import { useTipSettings } from 'hooks'

import { ReportSeries, StructuralReport, TipLabel } from '@percept/types'

import { PlatformUnitCrumbs } from 'components/PlatformUnitCrumbs'


const { PERCEPT_ENV, BRANDING } = process.env

const debug = PERCEPT_ENV === 'dev' || PERCEPT_ENV === 'staging'

const SITENAME = BRANDING === 'TPA' ? 'TPA Audit' : 'Percept'


const initialSearchConfig: MetricSearchConfig = {
  view: 'LAYOUT',
  query: '',
  searchDescriptions: false,
  searchIds: debug,
  allowPayloadSearch: debug,
  sortBy: 'A_TO_Z',
  dimension: 'count',
}

const tipPriority: TipLabel[] = [
  'reportSelector',
  'accountFilter',
  'metricDetail'
]

// const detailTipPriority: TipLabel[] = [
//   'reportSelector',
//   'accountFilter',
//   'metricDimension',
//   'metricExamples'
// ]


export const Report = (): JSX.Element => {

  const { series_id, report_id } = useLocationParams()

  const [searchConfig, updateSearchConfigValue] = useState(initialSearchConfig)

  const setSearchConfig = useCallback((update: Partial<MetricSearchConfig>): void => {
    updateSearchConfigValue( prev => ({ ...prev, ...update }) )
  }, [updateSearchConfigValue])

  const [tipSettings, onToggleTip] = useTipSettings()

  const featureHandlers: Partial<ReportDashboardAppProps> = {
    searchConfig,
    setSearchConfig,
    tipPriority,
    tipSettings,
    onToggleTip,
  }

  const navigate = useNavigation()

  const navigationHandlers: ReportDashboardDriverNavigationProps = {
    onReportChange: useCallback((e: React.MouseEvent, report: StructuralReport): void => {
      navigate(`/series/${series_id}/reports/${report.id}`)
    }, [navigate, series_id]),
    onSeriesChange: useCallback((e: React.MouseEvent, series: ReportSeries): void => {
      navigate(`/series/${series.id}`)
    }, [navigate])
  }

  const { hierarchy, ...activeOrgEnvironment } = useActiveOrgEnvironment()

  const basePageConfig = {
    site: SITENAME,
    paths: hierarchy.map( ({ name }) => name ),
  }

  return (
    <ReportDashboardApp
      series_id={series_id && String(series_id)}
      report_id={report_id}
      appBarStartContent={
        <Fragment>
          <HomeButton />
          <Box ml={0.5} mr={1.5} display='flex' alignItems='center'>
            {/* <PlatformUnitTreePopover /> */}
            <PlatformUnitCrumbs
              hierarchy={hierarchy}
              {...activeOrgEnvironment}
              ButtonProps={{
                size: 'small',
              }}
              showActiveOnly />
          </Box>
        </Fragment>
      }
      appBarEndContent={
        <UserPopover />
      }
      basePageConfig={basePageConfig}
      {...navigationHandlers}
      {...featureHandlers} />
  )

}

export default Report
