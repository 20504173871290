
import React, { useCallback, useMemo, useState, useEffect, Fragment, useRef } from 'react'

import {
  Alert,
  Box,
  BackdropLoader,
  PlainTextButtonMenu,
  Button,
  ButtonProps,
  ControlAppBarInsert,
  DashboardChrome,
  EntityListPopover,
  ReportDashboard,
  SortConfig,
  makeAppStyles,
  Health,
  useChannelBackgroundStyles,
  ReportDashboardProps,
  ReportSelect,
  useMediaQuery,
  useAppTheme,
  ProviderLogo,
  RoundedPlainTextButton,
} from '@percept/mui'

import { MetricDetail } from './MetricDetail'

import { ArrowBack, Timelapse } from '@percept/mui/icons'

import {
  useLocation,
  useLocationParams,
  useNavigation,
} from '@percept/hooks'

import {
  usePerformanceTailConfiguration,
  makeUseDerivedMetricSeries,
  getCleanLocationParams,
} from './lib'

import { find, get, some } from 'lodash-es'

import { getHealthFrom, isReportImpactWeighted, parseDimension } from '@percept/utils'

import { providerLabelMap } from '@percept/constants'

import {
  EntityType,
  ReportEntityFilter,
} from '@percept/types'

import { MetricDetailProps, ReportDashboardDriverProps } from './typings'


const useStyles = makeAppStyles( theme => ({
  spacedRight: {
    marginRight: theme.spacing(1),
    '&:last-child': {
      marginRight: 0,
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.up('xl')]: {
      marginRight: theme.spacing(2.5),
    },
  },
  controlButton: {
    color: '#FFF',
    // Add chip-like appeareance to control buttons on smaller screens
    [theme.breakpoints.down('md')]: {
      borderRadius: '1.2rem',
      boxShadow: theme.shadows['3'],
    },
  },
  reportHealth: {
    marginRight: theme.spacing(0.5),
    fontSize: 18,
    [theme.breakpoints.up('lg')]: {
      fontSize: 22,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 26,
    },
    textShadow: '0 0 3px rgba(0,0,0,0.15), 0 0 6px rgba(0,0,0,0.2)'
  },
  primaryAppBarInsert: {
    display: 'flex',
    width: '100%',
    padding: theme.spacing(1, 2),
    minHeight: theme.spacing(6.5),
  },
  primaryAppBarInsertWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flex: '1 1 auto',
    transition: theme.transitions.create('backgroundColor'),
  },
  controlAppBarInsert: {
    backgroundColor: 'rgba(0,0,0,0.28)',
    padding: theme.spacing(0, 2),
  },
  selectedAppBarItem: {
    fontWeight: 700,
  },
  minimumWidthMenu: {
    minWidth: theme.spacing(8),
  },
  fixedControlFragment: {
    position: 'fixed',
    top: theme.spacing(13.5),
    right: theme.spacing(1.5),
    zIndex: theme.zIndex.appBar - 1,
  },
  fixedControlFragmentDetail: {
    top: theme.spacing(8),
  },
}) )

const useAppBarButtonStyles = makeAppStyles({
  text: {
    color: 'inherit',
  }
})

const defaultSortConfig: SortConfig = {
  key: 'name',
  order: 'ASC'
}


export const ReportDashboardDriver = ({
  channel,
  provider,
  series,
  reportListing,
  report_id,
  result_id,
  series_id,
  report,
  payload,
  metadata,
  layout,
  useEntity,
  useMetric,
  useSeriesMetric,
  debug = false,
  searchConfig,
  setSearchConfig,
  onReportChange,
  onSeriesChange,
  appBarStartContent,
  appBarEndContent,
  usePageTitle,
  basePageConfig,
  reportFilter,
  ...props
}: ReportDashboardDriverProps): JSX.Element => {

  const navigate = useNavigation()

  const location = useLocation()

  const locationParams = useLocationParams()

  const entity_id = (
    locationParams.entity_id
    || get(payload.data, 'root_entity_id', null)
  )
  const entity_type = (
    locationParams.entity_type
    || get(payload.data, 'root_entity_type', null)
  ) as EntityType

  const metric_id = locationParams.metric_id || null

  const dimension = locationParams.dimension || 'count'

  const entityFilter = useMemo(() => {
    return {
      id: entity_id || '',
      type: entity_type,
    }
  }, [entity_id, entity_type])

  const [entity] = useEntity({
    series_id,
    report_id,
    entity_id,
    entity_type,
    ...(result_id && {
      result_id,
    }),
  })

  const setEntityFilter = useCallback((filter: ReportEntityFilter) => {
    navigate(location.pathname, {
      search: {
        ...getCleanLocationParams({ params: locationParams }),
        ...(metric_id && { metric_id } || {}),
        entity_id: filter.id,
        entity_type: filter.type,
      },
    })
  }, [navigate, location, locationParams, metric_id])


  const [entitySortConfig, setEntitySortConfig] = useState(defaultSortConfig)

  const useDerivedMetricSeries = makeUseDerivedMetricSeries(useSeriesMetric, series.data)

  /* Performance Tails */

  const parsedDimension = parseDimension(dimension)

  const performanceTailOverride = parsedDimension.tail

  const [storedPerformanceTail, setActivePerformanceTail] = useState<string | null>(null)

  const {
    dimensions,
    activePerformanceTail: derivedActivePerformanceTail,
    performanceTailOptions,
    reportPeriodTail,
  } = usePerformanceTailConfiguration({
    performanceTail: storedPerformanceTail,
    report: report.data,
    entity: entity.data,
  })

  const defaultPerformanceTail = find(performanceTailOptions, o => o.isDefault )

  const hasPerformanceTails: boolean | null = (
    !(entity.data && layout.data) ?
      null :
      performanceTailOptions.length > 1
      && get(layout.data, ['features', 'performance_tails']) !== false
  )

  const activePerformanceTail = (
    hasPerformanceTails ?
      derivedActivePerformanceTail :
      ''
  )

  const onPerformanceTailChange = (e: React.MouseEvent, tail: string): void => {
    if( dimension !== 'count' ){
      const parsedDimension = parseDimension(dimension)
      navigate(location.pathname, {
        search: {
          ...getCleanLocationParams({ params: locationParams }),
          dimension: tail ? `${parsedDimension.dimension}_${tail}` : parsedDimension.dimension,
          ...(metric_id && { metric_id } || {}),
        },
        replace: true,
      })
    }
    setActivePerformanceTail(tail)
  }

  const setActiveMetric: ReportDashboardProps['setActiveMetric'] = (metric): void => {
    navigate(location.pathname, {
      search: {
        ...getCleanLocationParams({ params: locationParams }),
        dimension: metric.dimension || dimension,
        metric_id: metric.metric_id,
        ...(metric.segment && {
          segment: metric.segment.label,
        } || {}),
      },
    })
  }

  const onActivateLayoutItemPath = (layoutItemPath: string): void => {
    const [tabIndex] = layoutItemPath.split('-')

    const tab = get(layout.data, ['members', Number(tabIndex), 'key'])

    navigate(location.pathname, {
      search: {
        ...getCleanLocationParams({ params: locationParams }),
        tab,
        ...(layoutItemPath && {
          path: layoutItemPath,
        } || {}),
      },
      replace: true,
    })
  }

  useEffect(() => {
    if( hasPerformanceTails ){
      if( performanceTailOverride && (activePerformanceTail !== performanceTailOverride) ){
        setActivePerformanceTail(performanceTailOverride)
      }else if( defaultPerformanceTail && !activePerformanceTail ){
        setActivePerformanceTail(defaultPerformanceTail.value)
      }
    }
  }, [
    activePerformanceTail, hasPerformanceTails, defaultPerformanceTail,
    performanceTailOverride, setActivePerformanceTail,
  ])

  /* Tab behaviour */

  const activeTab = (
    locationParams.tab || (
      get(layout.data, ['payload', 'members', 0, 'key'])
    )
  ) as string | undefined

  const onTabChange = (e: React.ChangeEvent<{}>, tab: string): void => {
    navigate(location.pathname, {
      replace: true,
      search: {
        ...getCleanLocationParams({ params: locationParams }),
        tab,
      },
    })
  }

  const onDimensionChange: MetricDetailProps['onDimensionChange'] = (e, dimension): void => {
    navigate(location.pathname, {
      replace: true,
      search: {
        ...getCleanLocationParams({ params: locationParams }),
        ...(metric_id && { metric_id } || {}),
        dimension,
      },
    })
  }

  const onSegmentChange: MetricDetailProps['onSegmentChange'] = (segment): void => {
    navigate(location.pathname, {
      replace: true,
      search: {
        ...getCleanLocationParams({ params: locationParams }),
        ...(metric_id && { metric_id } || {}),
        segment,
      },
    })
  }

  useEffect(() => {
    if(
      !location.search.includes('path')
    ){
      window.scroll(0, 0)
    }
  }, [location])


  usePageTitle({
    ...basePageConfig,
    paths: [
      ...(basePageConfig.paths || []),
      ...(provider && [
        providerLabelMap[provider]
      ] || []),
      ...(entity.data && [
        entity.data.name
      ] || []),
      ...(metric_id && [
        get(metadata.data, [metric_id, 'title']),
        ...(parsedDimension.label && [
          parsedDimension.label
        ] || []),
      ] || []),
      ...(locationParams.segment && [
        String(locationParams.segment)
      ] || []),
      ...(activePerformanceTail && [
        activePerformanceTail
      ] || []),
    ]
  })

  const tabChangeHandler = useRef<(tab: string) => void>()

  tabChangeHandler.current = (tab: string): void => {
    navigate(location.pathname, {
      replace: true,
      search: {
        ...getCleanLocationParams({ params: locationParams }),
        tab,
      }
    })
  }

  useEffect(() => {
    if( activeTab && layout.data ){
      const availableTabs = layout.data.payload.members.filter( m => m.type === 'tab' ).map( m => (
        m.key || m.name
      ))
      if( !find(availableTabs, t => t === activeTab) ){
        console.log('Missing tab', activeTab, availableTabs)
        tabChangeHandler.current && tabChangeHandler.current(availableTabs[0])
      }
    }
  }, [activeTab, layout.data])

  const [metricReturnRoute, setMetricReturnRoute] = useState<'REPORT' | 'SERIES'>('REPORT')

  // Catch referrer and set back button behaviour
  useEffect(() => {
    if( locationParams.percept_referrer ){
      if( locationParams.percept_referrer === 'series' ){
        setMetricReturnRoute('SERIES')
      }
      navigate(location.pathname, {
        replace: true,
        search: {
          ...getCleanLocationParams({ params: locationParams, exclude: ['percept_referrer'] }),
          ...(metric_id && { metric_id }),
        }
      })
    }
  }, [locationParams, navigate, location, metric_id])

  const isLarge = useMediaQuery(useAppTheme().breakpoints.up('lg'))

  const classes = useStyles()

  const channelBackgroundClasses = useChannelBackgroundStyles()

  const channelBackgroundClass = channel && channelBackgroundClasses[channel] || ''

  const appBarButtonProps: Partial<ButtonProps> = {
    classes: useAppBarButtonStyles(),
    className: classes.spacedRight,
    size: isLarge ? 'medium' : 'small',
  }

  const controlButtonProps: Partial<ButtonProps> = {
    ...appBarButtonProps,
    className: (
      `${classes.controlButton} ${classes.spacedRight}`// ${isLarge ? '' : channelBackgroundClass}`
    ),
    color: isLarge ? 'default' : 'secondary',
    variant: isLarge ? 'text' : 'contained',
  }

  const error = (report.error || entity.error || payload.error)

  const isLoading = some(
    [report, payload, entity, layout, metadata],
    r => !r.data
  )

  const isImpactWeighted = !!(report.data && isReportImpactWeighted(report.data))

  const controlFragment = (
    payload.data && entityFilter && (hasPerformanceTails !== null) && (
      <Fragment>

        <EntityListPopover
          ButtonProps={controlButtonProps}
          entities={payload.data.entities}
          activeEntity={entityFilter}
          onEntityClick={setEntityFilter}
          filterNoData={!debug}
          sortBy={entitySortConfig}
          sortEntities={(config): void => {
            setEntitySortConfig({ ...entitySortConfig, ...config })
          }} />

        { hasPerformanceTails && (
          <PlainTextButtonMenu
            TriggerProps={{
              ...controlButtonProps,
              startIcon: <Timelapse />,
            }}
            MenuProps={{
              classes: {
                list: classes.minimumWidthMenu,
              },
            }}
            isEqual={(a, b): boolean => (a === b)}
            value={activePerformanceTail || ''}
            label={!activePerformanceTail ? reportPeriodTail : activePerformanceTail}
            options={performanceTailOptions}
            selectedClassName={
              `${channelBackgroundClass} ${classes.selectedAppBarItem}`
            }
            onChange={(e, tail: string): void => onPerformanceTailChange(e, tail)} />
        )}

      </Fragment>
    )
  )

  return (
    <DashboardChrome
      AppBarProps={{
        className: channelBackgroundClass || undefined,
        color: channel ? 'secondary' : 'primary',
      }}
      appBarInserts={[
        /* Primary report navigation app bar insert */
        <div
          key='primary-insert'
          className={classes.primaryAppBarInsertWrapper}>

          <span className={classes.primaryAppBarInsert}>

            { appBarStartContent }

            { provider && series.data && (
              <Button
                {...appBarButtonProps}
                onClick={(e): void => {
                  onSeriesChange(e, series.data)
                }}>
                <ProviderLogo
                  size={1.5}
                  units='em'
                  provider={provider} />
              </Button>
            )}

            { (series.data && reportListing.data && report.data) && (
              <ReportSelect
                TriggerProps={appBarButtonProps}
                value={report.data}
                reports={reportListing.data}
                reportFilter={reportFilter}
                onChange={onReportChange} />
            )}

            { entity.data && (
              <Health
                className={classes.reportHealth}
                value={getHealthFrom(entity.data)} />
            )}

            { !!(metric_id && series.data) && (
              <Box
                ml={3}
                display='flex'
                alignItems='center'>
                <RoundedPlainTextButton
                  {...appBarButtonProps}
                  variant='contained'
                  size='small'
                  startIcon={
                    <ArrowBack />
                  }
                  onClick={(e): void => {
                    if( metricReturnRoute === 'SERIES' ){
                      onSeriesChange(e, series.data)
                    }else{
                      navigate(location.pathname, {
                        search: getCleanLocationParams({
                          params: locationParams,
                          exclude: ['metric_id', 'segment', 'dimension']
                        })
                      })
                    }
                  }}>
                  { metricReturnRoute === 'SERIES' ? 'Back To Series' : 'Back To Report' }
                </RoundedPlainTextButton>
              </Box>
            )}

            <Box
              display='flex'
              marginLeft='auto'>

              { isLarge && controlFragment }

              { appBarEndContent }

            </Box>

          </span>

        </div>,

        !metric_id && (
          (entity.data && metadata.data && report.data && layout.data) ? (
            <ControlAppBarInsert
              BoxProps={{
                className: classes.controlAppBarInsert
              }}
              activeTab={activeTab}
              onTabChange={onTabChange}
              activePerformanceTail={activePerformanceTail}
              onActivateLayoutItemPath={onActivateLayoutItemPath}
              setActiveMetric={setActiveMetric}
              searchConfig={searchConfig}
              setSearchConfig={setSearchConfig}
              dimensionOptions={dimensions}
              healthDimension='count'
              entity={entity.data}
              metrics={entity.data.metrics}
              layout={layout.data.payload}
              metadata={metadata.data}
              impactWeighted={isImpactWeighted} />
          ) : (
            <span />
          )
        )

      ].filter( (e): e is JSX.Element => !!e )}>

      <Fragment>

        { !isLarge && controlFragment && (
          <div
            className={
              `${classes.fixedControlFragment} ${metric_id ? classes.fixedControlFragmentDetail : ''}`
            }>
            { controlFragment }
          </div>
        )}

        { error ? (

          <Alert variant='error' {...error} />

        ) : (metric_id && entity.data && provider) ? (

          <MetricDetail
            activePerformanceTail={activePerformanceTail}
            onDimensionChange={onDimensionChange}
            reportMetric={entity.data.metrics[metric_id]}
            currency={entity.data.currency_code}
            segment={locationParams.segment ? String(locationParams.segment) : undefined}
            onSegmentChange={onSegmentChange}
            provider={provider}
            metric_id={metric_id}
            series_id={series_id}
            report_id={report_id}
            result_id={result_id}
            entity_type={entity_type}
            entity_id={entity_id}
            dimension={dimension}
            metadata={metadata}
            useMetric={useMetric}
            useSeriesMetric={useDerivedMetricSeries} />

        ) : (!metric_id && report.data && provider && layout.data && payload.data && entity.data && metadata.data && entityFilter) ? (

          <ReportDashboard
            healthDimension='count'
            debug={debug}
            provider={provider}
            reportStart={report.data.start}
            reportEnd={report.data.end}
            entity={entity.data}
            metrics={entity.data.metrics}
            attributes={get(entity.data, ['derived', 'attributes'], null)}
            performanceAttributes={get(entity.data, ['derived', 'performance'], null)}
            currency={entity.data.currency_code}
            impactWeighted={isImpactWeighted}
            metadata={metadata.data}
            layout={layout.data.payload}
            dimensionOptions={dimensions}
            activePerformanceTail={activePerformanceTail}
            activeTab={activeTab}
            setActiveMetric={setActiveMetric}
            activeLayoutItemPath={
              locationParams.path ?
                String(locationParams.path) :
                undefined
            }
            onActivateLayoutItemPath={onActivateLayoutItemPath}
            {...props} />

        ) : (
          null
        )}

        <BackdropLoader BackdropProps={{open: isLoading}} />

      </Fragment>

    </DashboardChrome>
  )

}
