import { Filters } from 'enums/Filters'
import { FormColumns } from 'enums/FormColumns'
import { WebFormTypes } from 'enums/WebFormTypes'
import { range } from 'lodash-es'
import { InvestmentReportScope, InvestmentReportType } from 'mediaInvestmentTypes'

export const ALL_REPORTING_FEATURES_ENABLED =
  process.env.NODE_ENV === 'development' ||
  (process.env.PERCEPT_ENV !== 'prod' && process.env.APP !== 'vodafone')


type InvestmentView = {
  title: string
  subTitle: string
  reportTypes: Partial<Record<InvestmentReportScope, InvestmentReportType>>
  selectOptions: {
    label: string
    value: InvestmentReportScope
  }[]
}

export type InvestmentRoute = {
  path: string
  title: string
  props: InvestmentView[]
}


export const CALENDAR_YEAR_EPOCH = 2015

const calendarYearOptions = (
  range(CALENDAR_YEAR_EPOCH, new Date().getUTCFullYear() + 1).map( value => ({
    name: String(value),
    value: String(value),
  }))
)


export const MEDIA_INVESTMENT_ROUTES = [
  {
    path: '/campaign-pillar',
    title: 'Campaign Pillar',
    props: [
      {
        title: 'Campaign Pillar',
        subTitle:
          'Top-line campaign investment per Brand, Brand Product, Performance',
        selectOptions: [
          { label: 'Period', value: 'period' },
          { label: 'Market', value: 'market' },
        ],
        chart: 'stacked-bar-chart',
      },
      {
        title: 'Campaign Sub Pillar',
        subTitle:
          'Media investment broken down into Purpose-led, Pro-bono, Product, Performance, Business',
      },
      {
        title: 'Brand vs Performance',
        subTitle: 'Investment split of Brand & Brand product vs. Performance',
        selectOptions: [
          { label: 'Period', value: 'period' },
          { label: 'Market', value: 'market' },
        ],
      },
      {
        title: 'Consumer vs Business',
        subTitle:
          'Campaign investment by target: Consumer invest vs. Business invest',
      },
    ],
  },
  {
    path: '/media-invest-by-channel',
    title: 'Media Mix',
    props: [
      {
        title: 'Media Invest by Channel',
        subTitle:
          'Investment by media type: Digital, TV, Print, OOH, Radio, Cinema, Direct Mail',
        selectOptions: [          
          { label: 'Period', value: 'period' },
          { label: 'Market', value: 'market' },
          { label: 'Campaign Pillar', value: 'campaign-pillar' },
          { label: 'Campaign Sub Pillar', value: 'campaign-sub-pillar' },
        ],
      },
      ...(
        ALL_REPORTING_FEATURES_ENABLED
          ? [
            {
              subTitle: 'Investment year on year change per media type',
              title: 'Media Mix YOY in %',
              chart: 'multiline-chart',
              onlyPercentageValues: true,
              onlyYear: true,
            }
          ] : []
      ),
      {
        title: 'Media Mix TV vs Digital',
        subTitle: 'Investment detail: TV vs. Digital',
        onlyYear: true,
      },
    ],
  },
  {
    path: '/second-brand',
    title: 'Second Brand',
    props: [
      {
        title: 'Second Brand',
        subTitle: 'Investment by 2nd brand',
        selectOptions: [
          { label: 'Period', value: 'period', chart: 'bar-group-chart' },
          { label: 'Campaign Pillar', value: 'campaign-pillar' },
          ...(
            ALL_REPORTING_FEATURES_ENABLED ? [
              { label: 'Media Mix', value: 'media-mix' }
            ] : []
          ),
          { label: 'Digital Media Mix', value: 'digital-media-mix' },
        ],
        chart: 'stacked-bar-chart'
      },
    ],
  },
  {
    path: '/brand-messaging',
    title: 'Brand Messaging',
    props: [
      {
        title: 'Brand Messaging',
        subTitle:
          'Investment by message type: Green, Inclusion, Digital Society, Other',
        selectOptions: [
          { label: 'Market', value: 'market' },
          { label: 'Campaign Pillar', value: 'campaign-pillar' },
          ...(
            ALL_REPORTING_FEATURES_ENABLED
              ? [
                { label: 'Media Mix', value: 'media-mix' }
              ] : []
          ),
        ],
      },
    ],
  },
  {
    path: '/product',
    title: 'Product',
    props: [
      {
        title: 'Product',
        subTitle:
          'Investment by product: Mobile, Fixed, Converged, TV, Purpose, Business, etc',
        selectOptions: [
          { label: 'Market', value: 'market' },
          { label: 'Campaign Pillar', value: 'campaign-pillar' },
          ...(
            ALL_REPORTING_FEATURES_ENABLED
              ? [
                { label: 'Media Mix', value: 'media-mix' }
              ] : []
          ),
        ],
      },
    ],
  },
  {
    path: '/funding-source',
    title: 'Funding Source',
    props: [
      {
        title: 'Funding Source',
        subTitle: 'Investment by source of funds: A&R and Media (OPEX)',
        selectOptions: [
          { label: 'Market', value: 'market' },
          { label: 'Campaign Pillar', value: 'campaign-pillar' },
          ...(
            ALL_REPORTING_FEATURES_ENABLED
              ? [
                { label: 'Campaign Sub Pillar', value: 'campaign-sub-pillar' },
                { label: 'Media Mix', value: 'media-mix' },
                { label: 'Digital Media Mix', value: 'digital-media-mix' },
                { label: 'Brand vs Performance', value: 'brand-vs-performance' },
              ] : []
          ),
        ],
      },
    ],
  },
]


export const COMPETITIVE_INVESTMENT_ROUTES = [
  {
    path: '/sos-overview',
    props: [
      {
        title: 'SOS - Overview',
        subTitle:
          'Share of media investment for Vodafone competitors per market',
        label: 'Table view only',
      },
    ],
  },
  {
    path: '/sos-largest-competitor',
    props: [
      {
        title: 'SOS - Largest Competitor',
        subTitle: 'Share of Spend of Vodafone largest competitors',
        label: 'Table view only',
      },
    ],
  },
  {
    path: '/sos-gap-to-largest-competitors',
    props: [
      {
        title: 'SOS - Gap to Largest Competitor',
        subTitle:
          'Value of investment: VF compared to largest competitor in pp',
        chart: 'bar-group-chart',
        possibleNegativeValues: true,
      },
    ],
  },
  {
    path: '/sos-trend-vs-largest-competitor',
    props: [
      {
        title: 'SOS - Trend vs Largest Competitor',
        subTitle: 'Investment difference change compared to largest competitor',
        selectOptions: [
          { value: 'Germany', label: 'Germany' },
          { value: 'Big 6 Total', label: 'Big 6 Total' },
        ],
        selectType: 'selectScopeOfData',
        chart: 'multiline-chart',
      },
    ],
  },
  {
    path: '/sos-by-market',
    props: [
      {
        title: 'SOS - by Market',
        subTitle: 'Share of Spend broken down by market',
        selectType: 'selectScopeOfData',
        marketSort: true,
      },
    ],
  },
  {
    path: '/tv-sov-overview',
    props: [
      {
        title: 'TV SOV - Overview',
        subTitle:
          'TV share of voice investment for Vodafone competitors per market',
        label: 'Table view only',
      },
    ],
  },
  {
    path: '/tv-sov-gap-to-largest-competitor',
    props: [
      {
        title: 'TV SOV - Gap to Largest Competitor',
        subTitle: 'VF compared to largest competitor in pp',
        chart: 'bar-group-chart',
        possibleNegativeValues: true,
      },
    ],
  },
  {
    path: '/tv-sov-by-market',
    props: [
      {
        title: 'TV SOV - by Market',
        subTitle: 'TV share of voice investment broken down by market',
        selectType: 'selectScopeOfData',
        marketSort: true,
      },
    ],
  },
]

export const SIDEBAR_TABS_MEDIA_INVESTMENT = MEDIA_INVESTMENT_ROUTES.map(
  (el) => ({
    title: el.title,
    value: el.path,
  })
)

export const SIDEBAR_TABS_COMPETITIVE_INVESTMENT =
  COMPETITIVE_INVESTMENT_ROUTES.map((el) => ({
    title: el.props[0].title,
    value: el.path,
  }))

export const POSSIBLE_FILTERS_MEDIA_INVESTMENT = [
  {
    filter_name: 'Market',
    filter_value: 'market',
    groups: [
      {
        group_name: 'Markets',
        group_value: Filters.Markets,
        options: [],
      },
    ],
  },
  {
    filter_name: 'Period',
    filter_value: 'period',
    groups: [
      {
        group_name: 'Financial Year',
        group_value: Filters.FinancialYear,
        options: [],
      },
      ...(ALL_REPORTING_FEATURES_ENABLED && [
        {
          group_name: 'Calendar Year',
          group_value: Filters.CalendarYears,
          options: calendarYearOptions,
        },
      ] || []),
    ],
  },
  {
    filter_name: 'Second Brand',
    filter_value: 'second-brand',
    groups: [
      {
        group_name: 'Second Brand',
        group_value: Filters.SecondBrand,
        options: [],
      },
    ],
  },
  {
    filter_name: 'Media Channel',
    filter_value: 'media-channel',
    groups: [
      {
        group_name: 'Media Channel',
        group_value: Filters.MediaChannel,
        options: [],
      },
    ],
  },
  {
    filter_name: 'Media Sub Channel',
    filter_value: 'media-sub-channel',
    groups: [
      {
        group_name: 'Media Sub Channel',
        group_value: Filters.MediaSubChannel,
        options: [],
      },
    ],
  },
  {
    filter_name: 'Funding Source',
    filter_value: 'funding_source',
    groups: [
      {
        group_name: 'Funding Source',
        group_value: Filters.FundingSource,
        options: [],
      },
    ],
  },
  {
    filter_name: 'Campaign Pillar',
    filter_value: 'campaign-pillar',
    groups: [
      {
        group_name: 'Campaign Pillar',
        group_value: Filters.Campaign,
        options: [],
      },
    ],
  },
  {
    filter_name: 'Sub-Campaign Pillar',
    filter_value: Filters.SubCampaign,
    groups: [
      {
        group_name: 'Sub-Campaign Pillar',
        group_value: Filters.SubCampaign,
        options: [],
      },
    ],
  },
  {
    filter_name: 'Buy Type',
    filter_value: Filters.BuyType,
    groups: [
      {
        group_name: 'Buy Type',
        group_value: Filters.BuyType,
        options: [],
      },
    ],
  },
  {
    filter_name: 'JBP',
    filter_value: Filters.JBP,
    groups: [
      {
        group_name: 'JBP',
        group_value: Filters.JBP,
        options: [],
      },
    ],
  },
]

export const POSSIBLE_FILTERS_COMPETITIVE_INVESTMENT = [
  {
    filter_name: 'Market',
    filter_value: 'market',
    groups: [
      {
        group_name: 'Markets',
        group_value: Filters.Markets,
        options: [],
      },
    ],
  },
  {
    filter_name: 'Period',
    filter_value: 'period',
    groups: [
      {
        group_name: 'Financial Year',
        group_value: Filters.FinancialYear,
        options: [],
      },
      ...(ALL_REPORTING_FEATURES_ENABLED && [
        {
          group_name: 'Calendar Year',
          group_value: Filters.CalendarYears,
          options: calendarYearOptions,
        },
      ] || []),
    ],
  },
  {
    filter_name: 'Partner',
    filter_value: 'partner',
    groups: [
      {
        group_name: 'Partner',
        group_value: Filters.Partner,
        options: [
          { name: 'India', value: 'india' },
          { name: 'Qatar', value: 'qatar' },
          { name: 'Netherlands', value: 'netherlands' },
          { name: 'New Zealand', value: 'new-zealand' },
        ],
      },
    ],
  },
  {
    filter_name: 'Media Channel',
    filter_value: 'media-channel',
    groups: [
      {
        group_name: 'Media Channel',
        group_value: Filters.MediaChannel,
        options: [],
      },
    ],
  },
  {
    filter_name: 'Competitors',
    filter_value: 'competitors',
    groups: [
      {
        group_name: 'Competitors',
        group_value: Filters.Competitors,
        options: [
          { name: 'Vodafone', value: 'vodafone' },
          { name: 'Simon', value: 'simon' },
          { name: 'Congstar', value: 'congstar' },
        ],
      },
    ],
  },
]

export const MEDIA_INVESTMENT_FORM_COLUMNS = [
  {
    name: FormColumns.secondBrand,
    label: 'BRAND',
    description: 'Investment by 2nd brand',
  },
  {
    name: FormColumns.campaignPillar,
    label: 'CAMPAIGN PILLAR',
    description:
      'Top-line campaign investment per Brand, Brand Product, Performance',
  },
  {
    name: FormColumns.campaignSubPillar,
    description:
      'Media investment broken down into Purpose-led, Pro-bono, Product, Performance, Business',
    label: 'SUB-CAMPAIGN PILLAR',
  },
  {
    name: FormColumns.buyType,
    description:
      'Investment per: Programmatic, Direct Buy, Credits, Apple Fund',
    label: 'BUY TYPE',
  },
  {
    name: FormColumns.mediaChannel,
    description:
      'Investment by media type: Digital, TV, Print, OOH, Radio, Cinema, Direct Mail',
    label: 'MEDIA CHANNEL',
  },
  {
    name: FormColumns.mediaSubChannel,
    description:
      'Investment broken down into details per Media Channel: Paid Social, Video, Audio, Native, Display, etc',
    label: 'MEDIA-SUB CHANNEL',
  },
  {
    name: FormColumns.fundingSource,
    description: 'Investment by source of funds: A&R and Media (OPEX)',
    label: 'FUNDING SOURCE',
  },
  {
    name: FormColumns.message,
    description:
      'Investment by message type: Green, Inclusion, Digital Society, Other',
    label: 'MESSAGE',
  },
  {
    name: FormColumns.product,
    description:
      'Investment by product: Mobile, Fixed, Converged, TV, Purpose, Business, etc',
    label: 'PRODUCT',
  },
  {
    name: FormColumns.jbp,
    description:
      'Joint Business Partnerships with Vendors such as Google, Meta, TikTok, etc.',
    label: 'JBP/VENDOR',
  },
]

export const COMPETITIVE_INVESTMENT_FORM_COLUMNS = [
  {
    name: FormColumns.agency,
    label: 'AGENCY',
    description:
      'Any 3rd party supporting Vodafone in running Media Report activities',
  },
  {
    name: FormColumns.mediaChannel,
    description:
      'Investment by media type: Digital, TV, Print, OOH, Radio, Cinema, Direct Mail',
    label: 'MEDIA CHANNEL',
  },
  {
    name: FormColumns.dataSource,
    description: 'Provenience of the data',
    label: 'DATA SOURCE',
  },
  {
    name: FormColumns.competitor,
    description: 'Direct competitors to Vodafone and Vodafone sub-brands',
    label: 'COMPETITORS',
  },
  {
    name: FormColumns.competitveSecondBrand,
    description: 'Investment by 2nd brand',
    label: 'BRAND',
  },
  {
    name: FormColumns.timeLags,
    description: '',
    label: 'TIME LAG',
  },
  {
    name: FormColumns.grossNet,
    description:
      'Net investment is Gross minus costs (e.g. taxes, headcount, facilities, etc)',
    label: 'GROSS/NET',
  },
  {
    name: FormColumns.agencyDiscount,
    description: 'Any 3rd party related discount applied to the investment',
    label: 'AGENCY DISCOUNT',
  },
]

export const COMPETITIVE_TV_SHARE_OF_VOICE_FORM_COLUMNS = [
  {
    name: FormColumns.agency,
    description:
      'Any 3rd party supporting Vodafone in running Media Report activities',
    label: 'AGENCY',
  },
  {
    name: FormColumns.tradedAudience,
    description: 'Audience segment of focus',
    label: 'AUDIENCE',
  },
  {
    name: FormColumns.dataSource,
    description: 'Provenience of the data',
    label: 'DATA SOURCE',
  },
  {
    name: FormColumns.competitor,
    description: 'Direct competitors to Vodafone and Vodafone sub-brands',
    label: 'COMPETITORS',
  },
  {
    name: FormColumns.competitveSecondBrand,
    description: 'Investment by 2nd brand',
    label: 'BRAND',
  },
  {
    name: FormColumns.timeLags,
    description: '',
    label: 'TIME LAG',
  },
  {
    name: FormColumns.equivSpotLengths,
    description: '',
    label: 'CONFIRM 30" EQUIV SPOT LENGTHS',
  },
  {
    name: FormColumns.spotLengths,
    description: '',
    label: 'IF "NO" SELECT SPOT LENGTHS',
  },
]
export const FORM_ROUTES = [
  {
    path: '/media-investment',
    props: {
      title: 'Media Investment',
      tableColumnsWithOptions: MEDIA_INVESTMENT_FORM_COLUMNS,
      value: WebFormTypes.MediaInvestment,
    },
  },
  {
    path: '/competitive-investment',
    props: {
      title: 'Competitive Investment',
      tableColumnsWithOptions: COMPETITIVE_INVESTMENT_FORM_COLUMNS,
      value: WebFormTypes.CompetitiveInvestment,
    },
  },
  {
    path: '/competitve-tv-share-of-voice',
    props: {
      title: 'Competitive TV Share of Voice',
      tableColumnsWithOptions: COMPETITIVE_TV_SHARE_OF_VOICE_FORM_COLUMNS,
      value: WebFormTypes.CompetitiveTVSOV,
    },
  },
]

export const ACTIVE_FORM_ROUTES = ALL_REPORTING_FEATURES_ENABLED
  ? FORM_ROUTES
  : FORM_ROUTES.slice(0, 1)
