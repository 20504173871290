import React from 'react'

import { Link } from 'react-router-dom'

import { Box, MassiveRoundedPlainTextButton, Typography } from '@percept/mui'

import { LockOpen } from '@percept/mui/icons'

import {
  mainBodyTextBoxProps,
  secondaryBodyTextBoxProps,
  linkStyles,
} from './lib'


export const PerceptWelcomeContent = ({
  signInRoute
}: {
  signInRoute: string
}): JSX.Element => {
  return (
    <Box maxWidth='75em' margin='auto'>
      <Box mb={5}>
        <Typography variant='h1'>
          Always On Ad Insights
        </Typography>
      </Box>

      <Box mb={3} {...mainBodyTextBoxProps}>
        Are you getting the most for your digital media spend?
      </Box>

      <Box mb={3} {...mainBodyTextBoxProps}>
        How do you find out if your digital media budget is  being spent well?
      </Box>

      <Box mb={3} {...mainBodyTextBoxProps}>
        Performance data doesn’t tell the whole story - it doesn’t tell you how you could get more for your money.
      </Box>

      <Box mb={3} {...mainBodyTextBoxProps}>
        Our technology platform and reports provide specific actions to get more from media spend - find out how well your online advertising setup matches up to industry best practices, get actionable insights to help you increase your digital media performance.
      </Box>

      <Box my={7} display='flex' flexBasis='100%' justifyContent='center'>
        <Link to={signInRoute}>
          <MassiveRoundedPlainTextButton
            variant='contained'
            color='secondary'
            startIcon={
              <LockOpen />
            }>
            Sign In
          </MassiveRoundedPlainTextButton>
        </Link>
      </Box>

      <Box mb={2}>
        <Typography variant='h3'>
          How it works
        </Typography>
      </Box>

      <Box {...secondaryBodyTextBoxProps}>
        The platform uses account configuration snapshots in combination with performance reports to create a set of metrics against which industry best practices are applied as benchmarks, providing a health score for media accounts, along with insights and specific actions for media account managers to implement.
      </Box>

      <Box mt={6} mb={2}>
        <Typography variant='h3'>
          Sign up
        </Typography>
      </Box>

      <Box {...secondaryBodyTextBoxProps}>
        If you’re not currently using Percept to drive increased performance in your digital media activity,{' '}
        <a href='mailto:support@perceptaudit.com' style={linkStyles}>get in touch</a>.
      </Box>

      <Box mt={6} mb={2}>
        <Typography variant='h3'>
          How we use your data
        </Typography>
      </Box>

      <Box mb={3} {...secondaryBodyTextBoxProps}>
        Our tools periodically download reporting data and account configuration for the ad accounts you authorise us to access. The data is analysed to provide reports on how well your account is configured and performing.
      </Box>

      <Box {...secondaryBodyTextBoxProps}>
        For full details of how we use and protect your data, please refer to our{' '}
        <a
          href='https://perceptaudit.com/terms-of-use'
          target='_blank'
          rel='noopener noreferrer'
          style={linkStyles}>
          Terms of Use
        </a>
        {' '}and{' '}
        <a
          href='https://perceptaudit.com/privacy-policy'
          target='_blank'
          rel='noopener noreferrer'
          style={linkStyles}>
          Privacy Policy
        </a>.
      </Box>
    </Box>
  )
}