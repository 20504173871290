

import React, { useState, Fragment, CSSProperties } from 'react'

import {
  Button,
  SvgIconProps,
  Box,
  BoxProps,
} from '@material-ui/core'

import { makeAppStyles } from '../../themes'

import { Check, Folder, FolderOpen, Remove } from '@material-ui/icons'

import { isArray, any, getPath } from '@percept/utils'


const useStyles = makeAppStyles( theme => ({
  tree: {
    backgroundColor: theme.palette.background.paper,
  },
  node: {
    flex: '1 1 auto',
    position: 'relative',
  },
  rootNode: {
    flex: '1 1 auto',
    position: 'relative',
    width: '100%',
    '&:first-child': {
      margin: 0
    }
  },
  parentNode: {
    flex: '1 1 auto',
    position: 'relative',
  },
  nodeMarker: {
    position: 'absolute',
    top: '2.75rem',
    left: '1rem',
    width: '1px',
    height: 'calc(100% - 3rem)',
    backgroundColor: theme.palette.action.disabledBackground,
  }
}) )


type TreeClasses = Record<'tree' | 'node' | 'rootNode' | 'parentNode' | 'nodeMarker', string>


type NodeType<T extends object = {}> = {
  id: string
  name: string
  path?: string[]
  members?: NodeType<T>[]
} & T


const isActive = (partialNode: Pick<NodeType, 'id'>, activeIds: string[] = []): boolean => {
  return activeIds.indexOf(String(partialNode.id)) !== -1
}

const hasActive = (partialNode: Pick<NodeType, 'id' | 'members'>, activeIds: string[] = []): boolean => {
  if( !activeIds.length || !getPath(partialNode.members, 'length') ){
    return false
  }

  const findActive = (n: Pick<NodeType, 'id' | 'members'>): boolean => {
    return (
      isActive(n, activeIds) || !!(
        n.members && any(n.members, findActive)
      )
    )
  }
  
  return any(partialNode.members as NodeType[], findActive)
} 

type RenderNodeProps<T extends NodeType = NodeType> = T & {
  classes: TreeClasses
  path?: string[]
  active?: boolean
  expanded?: boolean | null
  onClick?: (node: NodeType<T> & { path?: string[] }) => void
  onExpand?: () => void
}

// type NodeEventHandler<T extends NodeType = NodeType> = (node: NodeType<T & { path: string[] }> & { path: string[] }) => void 

type NodeEventHandler<T extends NodeType = NodeType> = (node: NodeType<T>) => void 

type NodeComponentProps<T extends NodeType = NodeType> = RenderNodeProps<T> & {
  style?: CSSProperties
  className?: string
  classes: TreeClasses
}

// Consumers of TreeView providing their own NodeComponent as renederer
// will not need to supply the typed classes that the default implementation uses
export type OverridingNodeComponentProps<N> = Omit<NodeComponentProps, 'classes'> & N & {
  onClick?: (node: N) => void
}


const RenderNode = ({ active, expanded, members, name, onExpand, style, className }: NodeComponentProps): JSX.Element => {

  const iconProps: SvgIconProps = {
    color: active ? 'primary' : 'disabled'
  }

  return (
    <div
      className={className}
      style={style}>
      <Button
        fullWidth
        color={members && members.length ? undefined : active ? 'primary' : 'secondary'}
        startIcon={
          members ?
            (expanded ? <FolderOpen {...iconProps} /> : <Folder {...iconProps} />) :
            active ?
              <Check {...iconProps} /> :
              <Remove {...iconProps} />
        }
        onClick={
          typeof onExpand === 'function' ?
            ((): void => onExpand()) :
            undefined
        }>
        { name }
      </Button>

    </div>
  )
}


type NodeProps<T extends NodeType = NodeType> = RenderNodeProps<T> & {
  defaultDepth: number
  margin: string | number
  activeIds?: string[]
  // onClick?: (node: NodeType<T>) => void
  onClick?: NodeEventHandler<T>
  NodeComponent?: (props: NodeComponentProps) => JSX.Element
  nodeClassName?: string
  parentClassName?: string
  gutter?: boolean
}


function Node<T extends NodeType = NodeType>({
  classes,
  id,
  name,
  members,
  path = [],
  activeIds = [],
  onClick,
  expanded = true,
  onExpand,
  NodeComponent = RenderNode,
  nodeClassName = '',
  margin,
  ...nodeProps 
}: NodeProps<T>): JSX.Element {
  return (
    <NodeComponent
      classes={classes}
      className={[
        (
          path.length === 0 ?
            classes.rootNode :
            classes.node
        ),
        nodeClassName
      ].join(' ')}
      style={{
        paddingLeft: margin
      }}
      active={isActive({ id }, activeIds)}
      expanded={expanded}
      id={id}
      name={name}
      members={members}
      path={path}
      onClick={(): void => {
        if( typeof onClick === 'function' ){
          onClick({ id, name, members, path, ...nodeProps } as NodeType<T>)
        }else if( typeof onExpand === 'function' ){
          onExpand()
        }
      }}
      onExpand={onExpand}
      {...nodeProps} />
  )
}



function ParentNode<T extends NodeType = NodeType>({
  id,
  path = [],
  members,
  NodeComponent = RenderNode,
  nodeClassName = '',
  parentClassName = '',
  activeIds = [],
  onClick,
  defaultDepth,
  gutter = false,
  margin,
  classes,
  ...nodeProps }: NodeProps<T>): JSX.Element {

  const [expanded, setExpanded] = useState<boolean>(
    defaultDepth === -1
    || path.length < defaultDepth
    || hasActive({ id, members }, activeIds)
  )

  const toggleExpanded = (): void => setExpanded( prev => !prev )

  const isRoot = path.length === 0

  return (

    <div
      className={
        isRoot ?
          `${classes.rootNode} ${parentClassName}` :
          `${classes.parentNode} ${parentClassName}`
      }>

      <NodeComponent
        classes={classes}
        id={id}
        active={isActive({ id }, activeIds)}
        activeIds={activeIds}
        expanded={expanded}
        path={path}
        members={members}
        onExpand={toggleExpanded}
        onClick={(): void => {
          if( typeof onClick === 'function' ){
            onClick({ id, members, path, ...nodeProps } as NodeType<T>)
          } else {
            toggleExpanded()
          }
        }}
        {...nodeProps} />

      { !!(expanded && members && members.length) && (
        <Fragment>

          { gutter && (
            <span
              className={classes.nodeMarker}
              style={{
                left: margin
              }} />
          )}

          <div
            style={{
              paddingLeft: margin
            }}
          >
            { members.map( (m, i) => (
              <NodeWrapper
                classes={classes}
                defaultDepth={defaultDepth}
                gutter={gutter}
                margin={margin}
                key={m.id}
                path={[...(path || []), 'children', String(i)]}
                {...m as NodeType<T>}
                NodeComponent={NodeComponent}
                nodeClassName={nodeClassName}
                parentClassName={parentClassName}
                activeIds={activeIds}
                active={isActive(m, activeIds)}
                onClick={onClick} />
            ))}
          </div>

        </Fragment>
      )}

    </div>
  )
}

function NodeWrapper<T extends NodeType = NodeType>(props: NodeProps<T>): JSX.Element {
  const Component = props.members ? ParentNode : Node
  return (
    <Component
      {...props} />
  )
}


type TreeViewProps<T extends NodeType = NodeType> = BoxProps & {
  hierarchy: NodeType<T> | NodeType<T>[]
  activeIds?: string[]
  // onNodeClick?: (node: NodeType<T>) => void
  onNodeClick?: NodeEventHandler<T>
  NodeComponent?: (props: NodeComponentProps) => JSX.Element
  className?: string
  nodeClassName?: string
  parentClassName?: string
  defaultDepth?: number
  gutter?: boolean,
  nodeMargin?: string | number
}

export function TreeView<T extends NodeType = NodeType>({
  hierarchy,
  activeIds,
  NodeComponent = RenderNode,
  className = '',
  nodeClassName = '',
  parentClassName = '',
  defaultDepth = 0,
  gutter = false,
  nodeMargin = '2rem',
  onNodeClick,
  ...props
}: TreeViewProps<T>): JSX.Element {

  const classes = useStyles()

  return (
    <Box
      className={`${classes.tree} ${className}`}
      margin='0.5rem 0 0.5rem'
      padding='0.5rem'
      // backgroundColor='black'
      display='inline-flex'
      flex='1 1 auto'
      flexDirection='column'
      justifyContent='center'
      alignItems='flex-start'
      {...props}>
      { isArray(hierarchy) ? (
        hierarchy.map( h => (
          <NodeWrapper
            key={h.id}
            {...h}
            classes={classes}
            defaultDepth={defaultDepth}
            gutter={gutter}
            margin={nodeMargin}
            path={[] as string[]}
            activeIds={activeIds}
            NodeComponent={NodeComponent}
            nodeClassName={nodeClassName}
            parentClassName={parentClassName}
            onClick={onNodeClick} />
        ))
      ) : (
        <NodeWrapper
          {...hierarchy}
          classes={classes}
          defaultDepth={defaultDepth}
          gutter={gutter}
          margin={nodeMargin}
          path={[] as string[]}
          activeIds={activeIds}
          NodeComponent={NodeComponent}
          nodeClassName={nodeClassName}
          parentClassName={parentClassName}
          onClick={onNodeClick} />
      )}
      
    </Box>
  )
}
