import 'react-hot-loader'

import React from 'react'
import { render } from 'react-dom'

import { Provider } from 'react-redux'
import createStore from 'redux/createStore'

import { createBrowserHistory } from 'history'

import App from 'components/App'

/* Polyfills */

import 'whatwg-fetch'

/* Manual polyfill imports
 * The babel config will only inject polyfills based on browser targets determined
 * by code it sees. This is impossible with some pre-built libraries which are either
 * deps or deps of deps, and so to ensure coverage we need to specify some manual polyfills.
 */
import 'core-js/features/number/is-finite'
import 'core-js/features/array/find'
import 'core-js/features/object/entries'
import 'core-js/features/string/starts-with'
import 'core-js/features/object/get-prototype-of'

const { PERCEPT_ENV, NODE_ENV } = process.env

/* Store and middleware dependencies */

const history = createBrowserHistory()

import api from '@percept/api'

import persistence from 'persistence'
import { QueryClient, QueryClientProvider } from 'react-query'

/* Update detection */

const checkForUpdates = NODE_ENV !== 'development'

const updateInterval = NODE_ENV !== 'development' ? 1000 * 60 * 2 : null

/* Debug flag */

const debug = PERCEPT_ENV !== 'prod'

/* Build */

const store = createStore({ history, api, persistence })
export const queryClient = new QueryClient()

render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <App
        debug={debug}
        history={history}
        checkForUpdates={checkForUpdates}
        updateInterval={updateInterval}
      />
    </Provider>
  </QueryClientProvider>,

  document.getElementById('app')
)

/* Chat client */

// const chatIsEnabled = PERCEPT_ENV === 'prod'

// if( chatIsEnabled ){
//   const script = document.createElement('script')
//   script.src = '//www.socialintents.com/api/socialintents.1.3.js#2c9fa5636d080634016d262aa3270dfa'
//   script.async = true
//   document.body.appendChild(script)
// }
