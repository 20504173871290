import {
  Alert,
  makeAppStyles,
  PlainTextButton,
  Box,
  CircularProgress,
  darken,
  Snackbar,
  SnackbarCloseReason,
  Typography,
} from '@percept/mui'
import { Clear, FilterList } from '@percept/mui/icons'
import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { applyFilters } from 'redux/ReportingDashboard/actions'
import {
  filtersReduxState,
  FiltersList,
} from 'redux/ReportingDashboard/reducers/filtersReducer'
import { checkOption, checkFilter } from './checkUtils'
import { AggregationButton } from './components/AgregationButton'
import { Banner } from './components/Banner'
import { PopoverWithCheckbox } from './components/PopoverWithCheckbox'
import { useInvestmentFilters } from './useInvestmentFilters'
import { flatten, sortBy } from 'lodash-es'
import { StoreState } from '@percept/types'
import { FilterItem, OptionType } from './typings'


const useFiltersPanelClasses = makeAppStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2.5),
    marginBottom: theme.spacing(6),
  },
  filtersContainer: {
    ...theme.appComponents.contrastPanel.root,
    width: '75%',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    flexWrap: 'wrap',
    maxWidth: '75vw',
  },
  addFiltersContainer: {
    padding: theme.spacing(3),
  },
  textButton: {
    textTransform: 'none',
    color: theme.appComponents.contrastPanel.root.color,
    fontSize: theme.typography.body2.fontSize,
  },
  buttonActive: {
    backgroundColor: darken(theme.palette.primary.main, 0.275),
    '&:hover': {
      backgroundColor: darken(theme.palette.primary.main, 0.35),
    },
  },
}))


interface Props {
  possibleFilters: FilterItem[]
  dashboardType: keyof filtersReduxState
}

const popoverWithFilterIndex = 0

const serializeFilters = (filters: FiltersList): string => {
  const populatedFilters = filters.filter( f => !!f.options.options.length )
  const sortedArrays = sortBy(
    populatedFilters,
    'filterName'
  ).map( f => [
    f.filterValue,
    ...f.options.options.map( o => o.value ).sort()
  ])
  return flatten(sortedArrays).join('|')
}


export const FiltersPanel = ({
  possibleFilters,
  dashboardType,
}: Props): JSX.Element => {

  const storedFilters = useSelector((state: StoreState) => state.filters[dashboardType])

  const [filtersList, setFiltersList] = useState(storedFilters)

  const [bannerOpen, setBannerOpen] = useState(false)

  const matchesStoredFilters = useMemo(() => {
    return serializeFilters(storedFilters) == serializeFilters(filtersList)
  }, [storedFilters, filtersList])

  const { result, loading } = useInvestmentFilters(possibleFilters)

  const filtersPanelClasses = useFiltersPanelClasses()
  const dispatch = useDispatch()

  const [hasEmptyOptions, setHasEmptyOptions] = useState(false)
  const handleCloseEmptyOption = (reason: SnackbarCloseReason): void => {
    if (reason === 'clickaway') {
      return
    }
    setHasEmptyOptions(false)
  }
  const [submitBanner, setSubmitBanner] = useState(false)
  const handleCloseSubmitBanner = (reason: SnackbarCloseReason): void => {
    if (reason === 'clickaway') {
      return
    }
    setSubmitBanner(false)
  }

  const changeHandlerFiltersOptions = (
    value: OptionType | OptionType[],
    groupValue: string,
    filterName: string
  ): void => {
    const result = checkOption(filtersList, filterName, value, groupValue)
    setFiltersList(result)
  }

  const changeHandlerAddFilters = (value: OptionType | OptionType[]): void => {
    if (!Array.isArray(value))
      setFiltersList(checkFilter(filtersList, value.value))
  }

  const deleteHandlerAggregationButtons = (
    filterValue: string,
    optionValue: { value: string; name: string },
    groupValue: string
  ): void => {
    const validList = checkOption(filtersList, filterValue, optionValue, groupValue)
    const withoutEmpty = validList.filter( f => !!f.options.options.length )
    setFiltersList(withoutEmpty)
    if( !matchesStoredFilters ){
      setBannerOpen(true)
    }
  }

  const [anchorEl, setAnchorEl] = useState<{
    element: null | HTMLElement
    id: number | undefined
  }>({ element: null, id: undefined })

  const handleClickPopover = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ): void => {
    setAnchorEl({ element: event.currentTarget, id: index })
  }

  const handleClosePopoverFilterList = (): void => {
    setAnchorEl({ element: null, id: undefined })
  }

  const handleClosePopover = (): void => {
    setAnchorEl({ element: null, id: undefined })
    const withoutEmpty = filtersList.filter( f => !!f.options.options.length )
    setFiltersList(withoutEmpty)
    if( !matchesStoredFilters ){
      setBannerOpen(true)
    }
  }

  const submitHandler = (): void => {
    setHasEmptyOptions(false)
    if (filtersList.some((e) => !e.options.options.length)) {
      return setHasEmptyOptions(true)
    } else {
      setBannerOpen(false)
      setSubmitBanner(true)
      dispatch(
        applyFilters({ list: filtersList, dashboardType: dashboardType })
      )
    }
  }

  const closeHandlerBanner = (reason: SnackbarCloseReason): void => {
    if (reason === 'clickaway') {
      return
    }
    setBannerOpen(false)
  }

  return (
    <Box className={filtersPanelClasses.root}>
      <Snackbar
        open={hasEmptyOptions}
        autoHideDuration={5000}
        onClose={(_, reason): void => handleCloseEmptyOption(reason)}>
        <Alert variant='error' py={1} px={2}>
          You need to select at least one option for each filter
        </Alert>
      </Snackbar>
      <Snackbar
        open={submitBanner}
        autoHideDuration={5000}
        onClose={(_, reason): void => handleCloseSubmitBanner(reason)}>
        <Alert variant='success' py={1} px={2}>
          All filters have been applied
        </Alert>
      </Snackbar>
      <Box py={1} px={2} className={filtersPanelClasses.filtersContainer}>
        <Box display='flex' flexWrap='wrap'>
          {loading ? (
            <Typography variant='body2'>
              <CircularProgress size='1em' color='inherit' style={{marginRight: 6}} />
              Loading available filters...
            </Typography>
          ) : filtersList.length === 0 ? (
            <Typography variant='body2'>
              No filters are selected. Displaying all unfiltered data.
            </Typography>
          ) : (
            possibleFilters &&
            result
              .filter((filter) =>
                filtersList
                  .map((el) => el.filterValue)
                  .includes(filter.filter_value)
              )
              .map((filter, index) => {
                const selectedFilter = filtersList.filter(
                  (el) => el.filterValue === filter.filter_value
                )[0].options
                const listOfChoices = selectedFilter.options

                return (
                  <Box key={index}>
                    <AggregationButton
                      filter_name={filter.filter_name}
                      options={
                        listOfChoices.find((el) => el.value === 'all')
                          ? listOfChoices.filter((el) => el.value === 'all')
                          : listOfChoices
                      }
                      deleteHandler={(value): void =>
                        deleteHandlerAggregationButtons(
                          filter.filter_value,
                          value,
                          selectedFilter.group_value
                        )
                      }
                      clickHandler={(e): void =>
                        handleClickPopover(e, index + 1)
                      }
                    />
                    <PopoverWithCheckbox
                      anchorEl={
                        anchorEl.id === index + 1 ? anchorEl.element : null
                      }
                      closeHandler={handleClosePopover}
                      optionsList={filter.groups}
                      checkedOptions={{
                        list: listOfChoices,
                        group: selectedFilter.group_value,
                      }}
                      changeHandler={(value, group_value): void =>
                        changeHandlerFiltersOptions(
                          value,
                          group_value,
                          filter.filter_value
                        )
                      }
                    />
                  </Box>
                )
              })
          )}
        </Box>
        <Box display='flex' alignItems='center' marginLeft='auto'>
          <Box marginRight={2}>
            <PlainTextButton
              variant='contained'
              size='small'
              startIcon={<FilterList />}
              onClick={(e): void => handleClickPopover(e, popoverWithFilterIndex)}>
              Manage Filters
            </PlainTextButton>
          </Box>
          <PlainTextButton
            variant='contained'
            size='small'
            startIcon={<Clear />}
            disabled={!filtersList.length}
            onClick={(): void => {
              setFiltersList([])
              if( storedFilters.length ){
                setBannerOpen(true)
              }
            }}>
            Clear Filters
          </PlainTextButton>
        </Box>
        <PopoverWithCheckbox
          anchorEl={
            anchorEl.id === popoverWithFilterIndex ? anchorEl.element : null
          }
          closeHandler={handleClosePopoverFilterList}
          optionsList={[
            {
              group_name: 'Filters',
              group_value: 'filters',
              options: result.map((el) => ({
                name: el.filter_name,
                value: el.filter_value,
              })),
            },
          ]}
          checkedOptions={{
            list: filtersList.map((el) => el.filterValue),
            group: 'filters',
          }}
          changeHandler={changeHandlerAddFilters}
        />
      </Box>
      <PlainTextButton
        className={!matchesStoredFilters ? filtersPanelClasses.buttonActive : undefined}
        color={matchesStoredFilters ? 'default' : 'secondary'}
        variant='contained'
        disabled={matchesStoredFilters}
        onClick={submitHandler}>
        { matchesStoredFilters ? 'Filters Applied' : 'Apply Filters'}
      </PlainTextButton>
      <Banner
        closeHandlerBox={closeHandlerBanner}
        closeHandler={(): void => setBannerOpen(false)}
        open={bannerOpen && !matchesStoredFilters}
      />
    </Box>
  )
}
