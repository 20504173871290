import { Box, Button, makeAppStyles } from '@percept/mui'
import React from 'react'

const useToggleClasses = makeAppStyles((theme) => ({
  buttonGroup: {
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.background.paper,
    width: 'fit-content',
    height: 'fit-content',
    display: 'flex',
    flexWrap: 'nowrap',
    borderRadius: theme.shape.borderRadius,
  },
  activeButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  inactiveButton: {
    color: theme.palette.text.secondary,
    textTransform: 'none',
  },
  disabledButton: {
    color: (
      theme.palette.type === 'dark' ?
        'rgba(255, 255, 255, 0.2)' :
        'rgba(0, 0, 0, 0.2)'
    ),
    textTransform: 'none',
  },
}))

export type ToggleButtonProps = {
  label: string
  id: string
}

type ToggleProps = {
  buttons: ToggleButtonProps[]
  activeButtonId: string
  onChange?: (id: ToggleButtonProps['id']) => void
}

export const ToggleButtons = ({
  buttons,
  onChange,
  activeButtonId,
}: ToggleProps): JSX.Element => {
  const toggleClasses = useToggleClasses()

  return (
    <Box className={toggleClasses.buttonGroup}>
      {buttons.map((button) => (
        <Button
          size='small'
          key={button.id}
          id={button.id}
          className={
            activeButtonId === button.id
              ? toggleClasses.activeButton
              : onChange
                ? toggleClasses.inactiveButton
                : toggleClasses.disabledButton
          }
          onClick={(): void => onChange?.(button.id)}>
          {button.label}
        </Button>
      ))}
    </Box>
  )
}
