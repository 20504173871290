import React from 'react'

import {
  Box,
  CellRenderers,
  Money,
  PercentageDonut,
  ProviderLogo,
  Typography,
  useAppTheme,
} from '@percept/mui'

import { DeltaRenderer } from 'components/Tables'

import { get, isNumber, round, some, sortBy, sum } from 'lodash-es'

import { subDays } from 'date-fns'

import { PerformanceReportingDatum } from 'types'

import {
  ChannelKey,
  PerformanceRangeKey,
  PerformanceTimeseriesDatum,
  PerformanceValue,
  ReportProvider,
  ReportProviderV2,
} from '@percept/types'
import { weightedScore } from '@percept/utils'


export type ProviderIdent = {
  provider: ReportProvider
  channel: ChannelKey
}


export type EfficiencyReportingDatum = PerformanceReportingDatum & {
  potential_efficiency_ratio: number | null
}

export type EfficiencyTimeseriesDatum = PerformanceTimeseriesDatum & {
  potential_efficiency_ratio: PerformanceValue
}

export type EfficiencyComparisonDimension = 'cost' | 'potential_efficiency_ratio'

export type RangedValue = {
  weeks: number
  datum: EfficiencyTimeseriesDatum | null
}


export const potentialEfficencyEnabledProviders: ReportProviderV2[] = ['FACEBOOK', 'GOOGLE_ADS']


export const rangedWeekValues = [
  1, 4, 12, 26, 52,
]

export const rangedWeekKeyMap = rangedWeekValues.reduce( (acc, value) => {
  acc[value] = `${value}w`
  return acc
}, {} as Record<number, string>)

export const getRangedWeekLabel = (weeks: number): string => (
  weeks == 26 ? '6 Months' :
    weeks === 52 ? '1 Year' :
      `${weeks} Week${weeks > 1 ? 's' : ''}`
)


export const getOverallRatio = (weightedRatios: { ratio: number, weight: number }[]): number | null => {
  return weightedScore(
    weightedRatios.map( r => r.ratio ),
    weightedRatios.map( r => r.weight ),
  )
}



export const PotentialEfficiencyRenderer = ({
  ratio,
  channel,
}: {
  ratio: number | null
  channel: ChannelKey
}): JSX.Element => {
  const theme = useAppTheme()
  return (
    <PercentageDonut
      key={channel}
      width={38}
      height={38}
      donutThickness={0.2}
      fontSize={12}
      trackColor={theme.palette.channel[channel].main}
      color='rgba(0,0,0,0.15)'
      value={round(Number(ratio) * 100)} />
  )
}


export const EfficiencyValueCellRenderer = ({
  value,
  currency,
  potential_efficiency_ratio,
  channel,
}: {
    value: PerformanceValue
    potential_efficiency_ratio: number | null
    channel: ChannelKey
    currency?: string
  }
): JSX.Element => {

  if( value === null || isNaN(value) ){
    return (
      <Typography variant='body2' color='textSecondary'>––</Typography>
    )
  }

  value = Number(value)

  return (
    <Box display='flex' alignItems='center' justifyContent='flex-end'>
      <Box mr={potential_efficiency_ratio === null ? 0 : 6.5}>
        <Money
          amount={value}
          abbreviate={true}
          precision={
            !value ?
              0 :
              value < 0.01 ?
                4 :
                2
          }
          currency={currency} />
      </Box>
      { potential_efficiency_ratio !== null && (
        <span style={{position: 'absolute'}}>
          <PotentialEfficiencyRenderer
            ratio={potential_efficiency_ratio}
            channel={channel} />
        </span>
      )}
    </Box>
  )
}

export const NowCellRenderer = ({
  now,
  potential_efficiency_ratio,
  currency,
  channel,
}: EfficiencyReportingDatum & {
    channel: ChannelKey
    currency?: string
  }
): JSX.Element => {
  return (
    <EfficiencyValueCellRenderer
      value={now}
      potential_efficiency_ratio={potential_efficiency_ratio}
      channel={channel}
      currency={currency} />
  )
}

export type RangedPotentialEffiencyRow = {
  value: PerformanceValue
  potential_efficiency_ratio: PerformanceValue
  channel: ChannelKey
  provider: ReportProvider
  currency: string | undefined
} & Record<string, {
  value: PerformanceValue
  potential_efficiency_ratio: PerformanceValue
  currency: string | undefined
}>

export type PotentialEfficiencyRow = EfficiencyReportingDatum & {
  channel: ChannelKey
  currency?: string
  provider?: ReportProvider
}


const sentimentCheck = (): boolean => {
  return true
}

/* eslint-disable react/display-name, react/prop-types */
const makeDeltaRenderer = (deltaKey: keyof PotentialEfficiencyRow) => (
  (props: PotentialEfficiencyRow): JSX.Element => (
    <DeltaRenderer deltaKey={deltaKey} invertSentimentCheck={sentimentCheck} {...props} />
  )
)
/* eslint-enable react/display-name, react/prop-types */


export const cellRenderers: CellRenderers<
  EfficiencyReportingDatum & {
    channel: ChannelKey
    currency?: string
    provider?: ReportProvider
  }
> = {
  // eslint-disable-next-line react/display-name, react/prop-types
  label: ({ label, provider }) => (
    provider ? (
      <ProviderLogo size={1.5} provider={provider} />
    ) : <span>{label}</span>
  ),
  now: NowCellRenderer,
  week: makeDeltaRenderer('week'),
  month: makeDeltaRenderer('month'),
  quarter: makeDeltaRenderer('quarter'),
  year: makeDeltaRenderer('year'),
}


/* eslint-disable react/display-name, react/prop-types */
const makeRangedValueRenderer = (key: string) => (
  (props: RangedPotentialEffiencyRow): JSX.Element => {
    // const key = rangedWeekKeyMap[rangedValue]
    let value: number | null = null
    let potential_effiency_ratio: number | null = null
    const datum = props[key]
    if( datum ){
      value = datum.value
      potential_effiency_ratio = datum.potential_efficiency_ratio
    }
    return (
      <EfficiencyValueCellRenderer
        {...props}
        value={value}
        potential_efficiency_ratio={potential_effiency_ratio} />
    )
  }
)
/* eslint-enable react/display-name, react/prop-types */


export const rangedValueCellRenderers: CellRenderers<RangedPotentialEffiencyRow> = {
  // eslint-disable-next-line react/display-name, react/prop-types
  provider: ({ provider }) => <ProviderLogo size={1.5} provider={provider} />,
  fyToDate: makeRangedValueRenderer('fyToDate'),
  ...(rangedWeekValues.reduce( (acc, value) => {
    acc[rangedWeekKeyMap[value]] = makeRangedValueRenderer(rangedWeekKeyMap[value])
    return acc
  }, {} as Record<string, (props: RangedPotentialEffiencyRow) => JSX.Element>)),
} 


const relativeDelta = (a: PerformanceValue, b: PerformanceValue): PerformanceValue => {
  if( a === null || b === null ){
    return null
  }
  // Calculate percentage of recent against historic for relative percentage
  const deltaPercentage = (
    ((a - b) / b) * 100
  )
  if( isNumber(deltaPercentage) && isFinite(deltaPercentage) ){
    return deltaPercentage
  }
  return null
}

const filterTimeseries = <T extends PerformanceTimeseriesDatum>(
  dataset: T[],
  start: Date,
  end?: Date | null
): T[] => {
  if( !dataset.length ){
    return []
  }
  const sortedTimeseries = sortBy(dataset, 'start')
  const earliest = sortedTimeseries[0]
  const latest = sortedTimeseries[sortedTimeseries.length - 1]
  const startTime = start.getTime()
  const endTime = end ? end.getTime() : latest.end
  const incompleteSignals = [
    startTime < earliest.start,
    endTime > latest.end,
  ]
  if( some(incompleteSignals) ){
    return []
  }
  const matches = sortedTimeseries.filter( (d) => {
    // We allow for timeseries periods to encompass the start and end,
    // rather than require them to fall within those bounds, as these
    // figures aren't necessarily daily.
    return (
      (d.start >= startTime && d.end <= endTime)
      || (d.start < startTime && d.end > startTime)
      || (d.end > endTime && d.start < endTime)
    )
  })
  return matches
}

const sumTimeseries = (
  dataset: PerformanceTimeseriesDatum[],
  start: Date,
  end?: Date | null
): number | null => {
  const filteredTimeseries = filterTimeseries(dataset, start, end).filter( d => d.value !== null )
  if( !filteredTimeseries.length ){
    return null
  }
  const total = sum(filteredTimeseries.map( d => d.value))
  return total
}


const makeGetComparisonRanges = (days: number) => (end: Date): [[Date, Date], [Date, Date]] => {
  const start = subDays(end, days - 1)
  const prevEnd = subDays(start, 1)
  const prevStart = subDays(prevEnd, days - 1)
  return [[prevStart, prevEnd], [start, end]]
}


const weekValues: Record<PerformanceRangeKey, number> = {
  now: 7/1,
  week: 1,
  month: 4,
  quarter: 12,
  half_year: 26,
  year: 52,
}


const comparisonRangeGenerators: Record<
  PerformanceRangeKey, (end: Date) => [[Date, Date], [Date, Date]]
> = {
  now: makeGetComparisonRanges(1),
  week: makeGetComparisonRanges(7),
  month: makeGetComparisonRanges(7 * 4),
  quarter: makeGetComparisonRanges(7 * 12),
  half_year: makeGetComparisonRanges(7 * 26),
  year: makeGetComparisonRanges(7 * 52),
}

const getTimeseriesWeightedAverageRatio = (
  dataset: EfficiencyTimeseriesDatum[], start: Date, end: Date
): number | null => (
  getOverallRatio(
    filterTimeseries(dataset, start, end).map( d => ({
      ratio: Number(d.potential_efficiency_ratio),
      weight: Number(d.value),
    }))
  )
)


const getTimeseriesRelativeDelta = (
  dataset: EfficiencyTimeseriesDatum[],
  period: PerformanceRangeKey,
  dimension: EfficiencyComparisonDimension,
): number | null => {
  const sortedTimeseries = sortBy(dataset, 'start')
  const latest = sortedTimeseries[sortedTimeseries.length - 1]
  const [comparisonRange, benchmarkRange] = comparisonRangeGenerators[period](new Date(latest.end))
  const generateComparator = dimension === 'cost' ? sumTimeseries : getTimeseriesWeightedAverageRatio
  return relativeDelta(
    generateComparator(dataset, ...benchmarkRange),
    generateComparator(dataset, ...comparisonRange),
  )
}


export const generateRangedValues = (
  dataset: EfficiencyTimeseriesDatum[]
): RangedValue[] => {
  const endDatum = dataset[dataset.length - 1]
  const endDate = new Date(endDatum.end)
  const periods: PerformanceRangeKey[] = ['week', 'month', 'quarter', 'half_year', 'year']
  const rangedValues: RangedValue[] = periods.map( period => {
    const [, benchmark] = comparisonRangeGenerators[period](endDate)
    const filteredTimeseries = filterTimeseries(dataset, benchmark[0], benchmark[1])
    const startDatum = filteredTimeseries[0]
    let datum: EfficiencyTimeseriesDatum | null = null
    if( startDatum && endDatum ){
      datum = {
        ...startDatum,
        end: endDatum.end,
        value: sum(filteredTimeseries.map( d => Number(d.value))),
        potential_efficiency_ratio: getTimeseriesWeightedAverageRatio(dataset, benchmark[0], benchmark[1]),
      }
    }
    return { datum, weeks: weekValues[period] }
  })
  // Calculate financial year to date
  const now = new Date()
  const YYYY = now.getUTCFullYear()
  const financialYYYY = (
    now.getUTCMonth() < 3 ?
      YYYY - 1 :
      YYYY
  )
  const financialYearStart = new Date(
    financialYYYY,
    3,
    1
  )
  rangedValues.push({
    weeks: 0,
    datum: {
      ...endDatum,
      value: sumTimeseries(dataset, financialYearStart, endDate),
      potential_efficiency_ratio: getTimeseriesWeightedAverageRatio(
        dataset, financialYearStart, endDate
      ),
    }
  })
  return rangedValues
}


export const generateComparisons = (
  dataset: EfficiencyTimeseriesDatum[],
  comparisonDimension: EfficiencyComparisonDimension = 'cost',
): Record<PerformanceRangeKey | 'potential_efficiency_ratio', PerformanceValue> => {
  const { length } = dataset
  return {
    now: get(dataset, [length - 1, 'value'], null),
    potential_efficiency_ratio: get(dataset, [length - 1, 'potential_efficiency_ratio'], null), 
    week: getTimeseriesRelativeDelta(dataset, 'week', comparisonDimension),
    month: getTimeseriesRelativeDelta(dataset, 'month', comparisonDimension),
    quarter: getTimeseriesRelativeDelta(dataset, 'quarter', comparisonDimension),
    half_year: getTimeseriesRelativeDelta(dataset, 'half_year', comparisonDimension),
    year: getTimeseriesRelativeDelta(dataset, 'year', comparisonDimension),
  }
}
