import React, { Fragment, useMemo } from 'react'

import {
  Box,
  Card,
  CardContent,
  CardStrip,
  Loader,
  SimpleTable,
  Typography,
} from '@percept/mui'

import { DimensionalSummary } from 'components/DimensionalSummary'

import { PerformanceTableProps } from 'components/Tables'

import { DownloadTrigger } from './DownloadTrigger'

import { usePlatformUnitPotentialEfficiencies } from '@percept/hooks'

import { useTargetCurrency } from 'hooks'

import { flatten, maxBy, uniq } from 'lodash-es'

import { format} from 'date-fns'

import {
  cellRenderers,
  rangedValueCellRenderers,
} from './lib'

import { usePotentialEfficiencyDatasets, useRangedPotentialEfficiencyTableData } from './datasets'

import { PerformanceReportingDatum } from 'types'

import {
  PlatformUnitParams,
  PotentialEfficiencyDatum,
} from '@percept/types'



export const PotentialEfficiencies = ({
  org_unit_id,
  suppressLoader = false,
}: PlatformUnitParams & {
  suppressLoader?: boolean
}): JSX.Element => {

  const [target_currency] = useTargetCurrency()

  const [{ data: groupings }] = usePlatformUnitPotentialEfficiencies({
    org_unit_id,
    target_currency,
  })

  const datasets = usePotentialEfficiencyDatasets({
    potentialEfficiencyScores: groupings,
    currency: target_currency,
  })

  const { rows, columns } = useRangedPotentialEfficiencyTableData({ datasets })

  const {
    latestReport,
    referenceDate,
  } = useMemo(() => {
    let referenceDate: Date | null = null
    let latestReport: PotentialEfficiencyDatum | null = null
    if( groupings ){
      const allData = flatten(
        groupings.map( g => g.potential_efficiency_scores)
      )
      latestReport = maxBy(allData, d => d.end) || null
      if( latestReport ){
        referenceDate = new Date(latestReport.end)
        latestReport = {
          ...latestReport,
          start: new Date(latestReport.start).getTime(),
          end: new Date(latestReport.end).getTime(),
        }
      }
    }
    return {
      latestReport,
      referenceDate,
    }
  }, [groupings])

  if( !datasets || !groupings ){
    if( suppressLoader ){
      return <Box height='12rem' />
    }else{
      return <Loader height='12rem' preset='centered' />
    }
  }

  // console.group('FY WASTAGE')
  // for( const dataset of datasets ){
  //   console.group(dataset.provider)
  //   for( const quarter of dataset.quarterlyDataset ){
  //     console.log('RANGE', new Date(quarter.start), new Date(quarter.end))
  //     console.log('TOTAL', round(quarter.value || 0, 2))
  //     console.log('%', Math.round((quarter.potential_efficiency_ratio || 0) * 100))
  //   }
  //   console.groupEnd()
  // }
  // console.groupEnd()

  const nowLabel = !latestReport ? undefined : (
    uniq([latestReport.start, latestReport.end]).map( d => format(d, 'dd/MM/yy')).join(' - ')
  )

  return (
    <Fragment>
      <DimensionalSummary
        dimension='cost'
        labelHistory={
          <Fragment>
            History
            <Box display='inline-flex' ml={2}>
              <DownloadTrigger
                org_unit_id={org_unit_id}
                currency={target_currency}
                datasets={datasets}
                color='secondary' />
            </Box>
          </Fragment>
        }
        labelChange='Comparison'
        labelSplit='Breakdown'
        referenceDate={referenceDate}
        datasets={datasets}
        currency={target_currency}
        color='neutral'
        PerformanceTableProps={{
          nowLabel,
          showNowColumn: false,
          renderers: cellRenderers as PerformanceTableProps<PerformanceReportingDatum>['renderers'],
          showQuarterlyFigure: true,
        }} />

      <Box my={3} />

      <Box mb={3}>
        <Typography variant='h5'>
          Totals
        </Typography>
      </Box>

      <Card>
        <CardStrip color='neutral' />
        <CardContent>
          <SimpleTable
            columns={columns}
            rows={rows}
            renderers={rangedValueCellRenderers}
            sortable />
        </CardContent>
      </Card>
    </Fragment>
  )
}
