import {
  AdNetworkParams,
  AsyncReduxAction,
  ChannelParams,
  CurrencyParams,
  DateRangeParams,
  DoubleVerifyProvider,
  DoubleVerifyProviderConstant,
  PerformanceReportingAggregationParams,
  PerformanceTimeseriesParams,
  PlatformUnitParams,
  PlatformUnitPerformanceTimeseriesByProviderParams,
  PlatformUnitProviderPerformanceTimeseriesParams,
  PlatformUnitProviderPerformanceTotalsParams,
  ProviderParams,
  ProviderReferenceDates,
  ReduxAction,
  ReferenceDateParams,
  ReportProvider,
  ReportProviderParams,
  ReportProviderV2,
  URLDoubleVerifyProvider,
} from '@percept/types'

import { providerStringEnumMap } from '@percept/constants'

import { format } from 'date-fns'

import qs from 'query-string'


const { PERFORMANCE_API_ROOT } = process.env


const doubleVerifyProviderEnumMap: Record<ReportProvider, URLDoubleVerifyProvider> = {
  facebook: 'facebook',
  adwords: 'youtube',
  adform: 'standard',
}


const createAsyncPerformanceAction = (action: AsyncReduxAction): AsyncReduxAction => ({
  ...action,
  options: {
    baseURL: `${PERFORMANCE_API_ROOT}/v1`,
    paramsSerializer: (params): string => {
      return (
        qs.stringify(params, {
          arrayFormat: 'none',
        })
      )
    },
    ...action.options || {},
  }
})


export const LOAD_PROVIDER_PERFORMANCE_TOTALS = 'LOAD_PROVIDER_PERFORMANCE_TOTALS'

export const loadProviderPerformanceTotals = ({
  org_unit_id,
  provider,
  start_date,
  end_date,
  target_currency,
}: PlatformUnitProviderPerformanceTotalsParams): AsyncReduxAction => createAsyncPerformanceAction({
  type: LOAD_PROVIDER_PERFORMANCE_TOTALS,
  resource: `/performance/unit/${org_unit_id}/total/provider/${providerStringEnumMap[provider]}`,
  options: {
    params: {
      start_date,
      end_date,
      ...(target_currency && {
        target_currency,
      })
    }
  },
  meta: {
    org_unit_id,
    provider,
    start_date,
    end_date,
    ...(target_currency && {
      target_currency,
    })
  }  
})

/*
/v1/performance/unit/{unit_id}/total/provider/{provider}/network
*/

export const LOAD_PROVIDER_PERFORMANCE_TOTALS_BY_NETWORK = 'LOAD_PROVIDER_PERFORMANCE_TOTALS_BY_NETWORK'

export const loadProviderPerformanceTotalsByNetwork = ({
  org_unit_id,
  provider,
  start_date,
  end_date,
  target_currency,
}: PlatformUnitProviderPerformanceTotalsParams): AsyncReduxAction => createAsyncPerformanceAction({
  type: LOAD_PROVIDER_PERFORMANCE_TOTALS_BY_NETWORK,
  resource: `/performance/unit/${org_unit_id}/total/provider/${providerStringEnumMap[provider]}/network`,
  options: {
    params: {
      start_date,
      end_date,
      ...(target_currency && {
        target_currency,
      })
    }
  },
  meta: {
    org_unit_id,
    provider,
    start_date,
    end_date,
    ...(target_currency && {
      target_currency,
    })
  }  
})


export const LOAD_PROVIDER_PERFORMANCE_TOTALS_BY_SUB_NETWORK = 'LOAD_PROVIDER_PERFORMANCE_TOTALS_BY_SUB_NETWORK'

export const loadProviderPerformanceTotalsBySubNetwork = ({
  org_unit_id,
  provider,
  start_date,
  end_date,
  target_currency,
}: PlatformUnitProviderPerformanceTotalsParams): AsyncReduxAction => createAsyncPerformanceAction({
  type: LOAD_PROVIDER_PERFORMANCE_TOTALS_BY_SUB_NETWORK,
  resource: `/performance/unit/${org_unit_id}/total/provider/${providerStringEnumMap[provider]}/sub-network`,
  options: {
    params: {
      start_date,
      end_date,
      ...(target_currency && {
        target_currency,
      })
    }
  },
  meta: {
    org_unit_id,
    provider,
    start_date,
    end_date,
    ...(target_currency && {
      target_currency,
    })
  }  
})


export const LOAD_PROVIDER_PERFORMANCE_TOTALS_BY_CAMPAIGN_OBJECTIVE = 'LOAD_PROVIDER_PERFORMANCE_TOTALS_BY_CAMPAIGN_OBJECTIVE'

export const loadProviderPerformanceTotalsByCampaignObjective = ({
  org_unit_id,
  provider,
  start_date,
  end_date,
  target_currency,
}: PlatformUnitProviderPerformanceTotalsParams): AsyncReduxAction => createAsyncPerformanceAction({
  type: LOAD_PROVIDER_PERFORMANCE_TOTALS_BY_CAMPAIGN_OBJECTIVE,
  resource: `/performance/unit/${org_unit_id}/total/provider/${providerStringEnumMap[provider]}/extra-network-attr-1`,
  options: {
    params: {
      start_date,
      end_date,
      ...(target_currency && {
        target_currency,
      })
    }
  },
  meta: {
    org_unit_id,
    provider,
    start_date,
    end_date,
    ...(target_currency && {
      target_currency,
    })
  }  
})


export const LOAD_PROVIDER_PERFORMANCE_COMPARISONS = 'LOAD_PROVIDER_PERFORMANCE_COMPARISONS'

export const loadProviderPerformanceComparisons = ({
  org_unit_id,
  provider,
  reference_date,
  target_currency,
}: (
  PlatformUnitParams &
  ProviderParams &
  Partial<ReferenceDateParams> &
  Partial<CurrencyParams>
)): AsyncReduxAction => createAsyncPerformanceAction({
  type: LOAD_PROVIDER_PERFORMANCE_COMPARISONS,
  resource: `/performance/unit/${org_unit_id}/comparisons/provider/${providerStringEnumMap[provider]}`,
  options: {
    params: {
      ...(reference_date && {
        reference_date
      }),
      ...(target_currency && {
        target_currency,
      })
    }
  },
  meta: {
    org_unit_id,
    provider,
    ...(reference_date && {
      reference_date
    }),
    ...(target_currency && {
      target_currency,
    })
  }  
})


export const LOAD_DOUBLE_VERIFY_PROVIDER_PERFORMANCE_COMPARISONS = 'LOAD_DOUBLE_VERIFY_PROVIDER_PERFORMANCE_COMPARISONS'

export const loadDoubleVerifyProviderPerformanceComparisons = ({
  org_unit_id,
  provider,
  reference_date,
}: (
  PlatformUnitParams &
  ProviderParams &
  Partial<ReferenceDateParams>
)): AsyncReduxAction => createAsyncPerformanceAction({
  type: LOAD_DOUBLE_VERIFY_PROVIDER_PERFORMANCE_COMPARISONS,
  resource: `/doubleverify/unit/${org_unit_id}/comparisons/provider/${doubleVerifyProviderEnumMap[provider]}`,
  options: {
    params: {
      ...(reference_date && {
        reference_date
      }),
    }
  },
  meta: {
    org_unit_id,
    provider,
    ...(reference_date && {
      reference_date
    }),
  }  
})


export const LOAD_PERFORMANCE_TIMESERIES = 'LOAD_PERFORMANCE_TIMESERIES'

export const loadPerformanceTimeseries = (
  params: PerformanceTimeseriesParams
): AsyncReduxAction => createAsyncPerformanceAction({
  type: LOAD_PERFORMANCE_TIMESERIES,
  resource: '/performance/timeseries/',
  options: {
    params,
  },
  meta: params,
})



export const LOAD_PERFORMANCE_TIMESERIES_BY_PROVIDER = 'LOAD_PERFORMANCE_TIMESERIES_BY_PROVIDER'

export const loadPerformanceTimeseriesByProvider = ({
  org_unit_id,
  period,
  chunking,
  target_currency,
  reference_date,
}: PlatformUnitPerformanceTimeseriesByProviderParams
): AsyncReduxAction => createAsyncPerformanceAction({
  type: LOAD_PERFORMANCE_TIMESERIES_BY_PROVIDER,
  resource: `/performance/unit/${org_unit_id}/timeseries/by-provider`,
  options: {
    params: {
      period,
      chunking,
      target_currency,
      ...(reference_date && {
        reference_date
      }),
    },
  },
  meta: {
    org_unit_id,
    period,
    chunking,
    ...(target_currency && {
      target_currency
    }),
    ...(reference_date && {
      reference_date,
    })
  },
})


export const LOAD_PROVIDER_PERFORMANCE_TIMESERIES = 'LOAD_PROVIDER_PERFORMANCE_TIMESERIES'

export const loadProviderPerformanceTimeseries = ({
  org_unit_id,
  provider,
  period,
  chunking,
  target_currency,
  reference_date,
}: PlatformUnitProviderPerformanceTimeseriesParams
): AsyncReduxAction => createAsyncPerformanceAction({
  type: LOAD_PROVIDER_PERFORMANCE_TIMESERIES,
  resource: `/performance/unit/${org_unit_id}/timeseries/provider/${providerStringEnumMap[provider]}`,
  options: {
    params: {
      period,
      chunking,
      target_currency,
      ...(reference_date && {
        reference_date
      }),
    },
  },
  meta: {
    org_unit_id,
    provider,
    period,
    chunking,
    ...(target_currency && {
      target_currency
    }),
    ...(reference_date && {
      reference_date
    }),
  },
})


// DoubleVerify

export const LOAD_DOUBLE_VERIFY_PROVIDER_PERFORMANCE_TIMESERIES = 'LOAD_DOUBLE_VERIFY_PROVIDER_PERFORMANCE_TIMESERIES'

export const loadDoubleVerifyProviderPerformanceTimeseries = ({
  org_unit_id,
  provider,
  period,
  chunking,
  reference_date,
}: (
    PlatformUnitParams &
    ReportProviderParams &
    PerformanceReportingAggregationParams &
    Partial<ReferenceDateParams>
  )
): AsyncReduxAction => createAsyncPerformanceAction({
  type: LOAD_DOUBLE_VERIFY_PROVIDER_PERFORMANCE_TIMESERIES,
  resource: `/doubleverify/unit/${org_unit_id}/timeseries/provider/${doubleVerifyProviderEnumMap[provider]}`,
  options: {
    params: {
      period,
      chunking,
      ...(reference_date && {
        reference_date
      }),
    },
  },
  meta: {
    org_unit_id,
    provider,
    period,
    chunking,
    ...(reference_date && {
      reference_date
    }),
  },
})


export const LOAD_CHANNEL_PERFORMANCE_TIMESERIES = 'LOAD_CHANNEL_PERFORMANCE_TIMESERIES'

export const loadChannelPerformanceTimeseries = ({
  org_unit_id,
  channel,
  period,
  chunking,
  target_currency,
  reference_date,
}: (
    PlatformUnitParams &
    ChannelParams &
    PerformanceReportingAggregationParams &
    Partial<CurrencyParams> &
    Partial<ReferenceDateParams>
  )
): AsyncReduxAction => createAsyncPerformanceAction({
  type: LOAD_CHANNEL_PERFORMANCE_TIMESERIES,
  resource: `/performance/unit/${org_unit_id}/timeseries/channel/${channel}`,
  options: {
    params: {
      period,
      chunking,
      target_currency,
      ...(reference_date && {
        reference_date
      }),
    },
  },
  meta: {
    org_unit_id,
    channel,
    period,
    chunking,
    ...(target_currency && {
      target_currency
    }),
    ...(reference_date && {
      reference_date
    }),
  },
})


export const LOAD_AD_NETWORK_PERFORMANCE_TIMESERIES = 'LOAD_AD_NETWORK_PERFORMANCE_TIMESERIES'

export const loadAdNetworkPerformanceTimeseries = ({
  org_unit_id,
  network,
  period,
  chunking,
  target_currency,
  reference_date,
}: (
    PlatformUnitParams &
    AdNetworkParams &
    PerformanceReportingAggregationParams &
    Partial<CurrencyParams> &
    Partial<ReferenceDateParams>
  )
): AsyncReduxAction => createAsyncPerformanceAction({
  type: LOAD_AD_NETWORK_PERFORMANCE_TIMESERIES,
  resource: `/performance/unit/${org_unit_id}/timeseries/network/${network}`,
  options: {
    params: {
      period,
      chunking,
      target_currency,...(reference_date && {
        reference_date
      }),
    },
  },
  meta: {
    org_unit_id,
    network,
    period,
    chunking,
    ...(target_currency && {
      target_currency
    }),...(reference_date && {
      reference_date
    }),
  },
})


/* Admin reference dates */

export const LOAD_PROVIDER_DATA_AVAILABILITY_TIMESERIES = 'LOAD_PROVIDER_DATA_AVAILABILITY_TIMESERIES'

export const loadProviderDataAvailabilityTimeseries = ({
  provider,
  level,
  account_id,
}: {
  provider: ReportProvider | DoubleVerifyProvider
  level: 'ACCOUNT' | 'CAMPAIGN'
  account_id?: string
}): AsyncReduxAction => createAsyncPerformanceAction({
  type: LOAD_PROVIDER_DATA_AVAILABILITY_TIMESERIES,
  resource: `/admin/available-data/timeseries/${providerStringEnumMap[provider]}`,
  options: {
    params: {
      ...(level && {
        aggregation_level: level === 'ACCOUNT' ? 'ACCOUNT_DAY' : 'CAMPAIGN_DAY',
      }),
      ...(account_id && {
        account_id
      }),
    },
  },
  meta: {
    provider,
    level,
    account_id,
  }
})



export const LOAD_PROVIDER_REFERENCE_DATES = 'LOAD_PROVIDER_REFERENCE_DATES'

export const loadProviderReferenceDates = (): AsyncReduxAction => createAsyncPerformanceAction({
  type: LOAD_PROVIDER_REFERENCE_DATES,
  resource: '/admin/reference-dates',
})


export const SET_PROVIDER_REFERENCE_DATES = 'SET_PROVIDER_REFERENCE_DATES'

export const setProviderReferenceDates = (
  providerReferenceDates: Partial<ProviderReferenceDates>
): AsyncReduxAction => {

  const matchingProviders = Object.keys(providerReferenceDates) as (ReportProvider | DoubleVerifyProvider)[]

  const data = matchingProviders.reduce( (acc, p) => {
    const provider = providerStringEnumMap[p]
    const dateInfo = providerReferenceDates[p]
    if( dateInfo && dateInfo.reference_date ){
      acc.push({
        provider,
        reference_date: format(dateInfo.reference_date, 'yyyy-MM-dd')
      })
    }
    return acc
  }, [] as {
    provider: ReportProviderV2 | DoubleVerifyProviderConstant
    reference_date: string
  }[])
  
  return createAsyncPerformanceAction({
    type: SET_PROVIDER_REFERENCE_DATES,
    resource: '/admin/reference-dates',
    options: {
      method: 'PUT',
      data,
    },
    successActions: [
      loadProviderReferenceDates(),
    ],
  })
}

export const CLEAR_SET_PROVIDER_REFERENCE_DATES = 'CLEAR_SET_PROVIDER_REFERENCE_DATES'

export const clearSetProviderReferenceDates = (): ReduxAction => ({
  type: CLEAR_SET_PROVIDER_REFERENCE_DATES,
  payload: null,
})
