import {
  useMyUser,
  useMyUserDetail,
  usePlatformUnit,
  usePlatformUnitProviderInfo,
  usePlatformUnitPotentialEfficiencies,
  useChannelPillarScores,
  useMyUserPrivileges,
} from '@percept/hooks'

import { useTargetCurrency } from 'hooks'


export const UserEnvironmentLoader = (): null => {
  const [target_currency] = useTargetCurrency()
  const [privileges] = useMyUserPrivileges()
  const [user] = useMyUser()
  const [userDetail] = useMyUserDetail()
  const org_unit_id = (
    user.data && user.data.default_org_unit_id
    || userDetail.data && userDetail.data.default_org_unit_id
    || privileges.data && privileges.data.user && privileges.data.user.default_org_unit_id
  )
  // Eagerly load required resources for default dashboard view
  usePlatformUnitPotentialEfficiencies({ org_unit_id, target_currency })
  useChannelPillarScores({ org_unit_id })
  usePlatformUnit({ org_unit_id })
  usePlatformUnitProviderInfo({ org_unit_id })
  // We want to ensure we kick off the loaders, nothing more
  return null
}
