
import React, { Fragment } from 'react'

import { makeAppStyles } from '../../themes'

import { Group } from '@visx/group'

import { animated, useSprings } from 'react-spring'

import { getColor } from '../lib'

import { DatumEventHandler, SVGDatumType } from '../typings'

import { HistogramBarDatum, HistogramProps } from './typings'

import { AnyScaleBand } from '@visx/shape/lib/types'

import { NumberLike } from '@visx/scale'

import { AxisScale } from '@visx/axis'


const useStyles = makeAppStyles( theme => ({
  barBackground: {
    transition: theme.transitions.create('opacity'),
    fill: 'black',
    opacity: 0,
  },
  barGroup: {
    '&:hover > $barBackground': {
      opacity: 0.15,
    },
  },
}) )


export type AnimatedBarsProps<T extends SVGDatumType = SVGDatumType> = {
  bars: HistogramBarDatum<T>[]
  xScale: AnyScaleBand
  yScale: AxisScale<NumberLike>
  maxHeight: number
  onBarMouseMove?: DatumEventHandler<T>
} & Pick<
  HistogramProps<T>,
  'animate' | 'animateInitial' |
  'onSegmentMouseOver' | 'onSegmentClick' | 'barPaddingRatio' | 'svgBarProps' |
  'fillOverride'
>


export function AnimatedBars<T extends SVGDatumType>({
  animate = true,
  animateInitial = false,
  bars,
  xScale,
  yScale,
  maxHeight,
  onSegmentClick,
  onSegmentMouseOver,
  barPaddingRatio = 0.3,
  svgBarProps = {
    fillOpacity: 1,
    strokeOpacity: 1,
  },
  fillOverride,
}: AnimatedBarsProps<T>): JSX.Element {

  const springs = useSprings(
    bars.length,
    bars.map(({
      y,
      height,
      data,
      fillOpacity = svgBarProps.fillOpacity,
      strokeOpacity = svgBarProps.strokeOpacity,
    }) => {
      const fill = fillOverride || getColor(data) || undefined
      return {
        // Alias to 'top' here to keep TS happy as that's a CSS property
        top: y,
        height,
        fill,
        fillOpacity,
        strokeOpacity,
        immediate: !animate,
        from: {
          top: animateInitial ? yScale(0) : y,
          height: animateInitial ? 0 : height,
          fill,
          fillOpacity: animateInitial ? 0 : fillOpacity,
          strokeOpacity: animateInitial ? 0 : strokeOpacity
        },
      }
    })
  )

  const zeroPoint = Number(yScale(0))

  const [yMin, yMax] = yScale.domain()

  const bandWidth = xScale && xScale.bandwidth() || 0

  const padding = bandWidth * barPaddingRatio

  const classes = useStyles()

  return (
    <Fragment>

      { springs.map( (props, i) => {
        const item = bars[i]
        const isNegative = Number(item.data.value) < 0

        const bgTop = isNegative ? zeroPoint : 0
        const bgHeight = Math.abs(
          zeroPoint - Number(yScale(isNegative ? yMin : yMax))
        )
        const bgLeft = item.x - (padding / 1.5)
        const bgWidth = item.width + (padding * 1.5)

        return (
          <Group
            className={classes.barGroup}
            key={item.label}
            onClick={
              onSegmentClick ?
                ((): void => onSegmentClick(item.data)) :
                undefined
            }
            onMouseMove={
              onSegmentMouseOver ?
                ((e): void => onSegmentMouseOver(e, item.data, bars.map( ({ data }) => data ))) :
                undefined
            }>

            <rect
              x={bgLeft}
              y={0}
              height={maxHeight}
              width={bgWidth}
              fill='transparent'
              stroke='none' />

            <rect
              className={classes.barBackground}
              x={bgLeft}
              y={bgTop}
              height={bgHeight}
              width={bgWidth}
              stroke='none' />

            <animated.rect
              key={item.label}
              strokeWidth={0}
              stroke={props.fill}
              {...svgBarProps}
              strokeOpacity={props.strokeOpacity}
              fillOpacity={props.fillOpacity}
              cursor={onSegmentClick ? 'pointer' : 'default'}
              x={item.x}
              y={props.top}
              width={item.width}
              height={props.height}
              fill={props.fill} />

          </Group>
        )
      }) }


    </Fragment>
  )
}

