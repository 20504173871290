import React, { useMemo } from 'react'

import { Grid, GridSize } from '@percept/mui'

import { StructuralSummary } from './StructuralSummary'

import { useChannelPillarScores } from '@percept/hooks'

import { useLayoutGridSpacing } from 'hooks'

import { intersection, isNumber } from 'lodash-es'

import { channelSlugKeyMap } from '@percept/constants'

import { ChartData } from '@percept/mui/charts'

import {
  ChannelKey,
  ChannelSlug,
  MetricPillar,
  PlatformUnit,
  PlatformUnitMetricPillarChannelScores,
  PlatformUnitMetricPillarScores,
} from '@percept/types'


const pillarsToChartData = (
  pillarScores: PlatformUnitMetricPillarScores | PlatformUnitMetricPillarChannelScores
): Record<MetricPillar, ChartData> => {
  const pillars: MetricPillar[] = [
    'structure', 'creative', 'audience', 'brand_safety'
  ]
  return pillars.reduce( (acc, pillar) => {
    acc[pillar] = pillarScores.scores.map( score => ({
      label: score.date,
      value: isNumber(score[pillar]) ? score[pillar] : null
    })) as ChartData
    return acc
  }, {} as Record<MetricPillar, ChartData>)
}


function getGridSizing<T>(items: T[]): GridSize {
  switch(items.length){
    case 3: return 4
    case 2: return 6
    default: return 12
  }
}


export type SingleOrgStructuralSummaryProps = {
  platformUnit: PlatformUnit
}


export const SingleOrgStructuralSummary = ({
  platformUnit,
}: SingleOrgStructuralSummaryProps): JSX.Element => {

  const [{ data: channelPillarScores }] = useChannelPillarScores({
    org_unit_id: platformUnit.id,
  })

  const channelPillars: {
    channel: ChannelKey
    pillars: Record<MetricPillar, ChartData>
    overall: ChartData
  }[] = useMemo(() => {
    if( !channelPillarScores ){
      return []
    }
    const allChannels: ChannelSlug[] = [
      'SEARCH', 'SOCIAL', 'PROGRAMMATIC'
    ]
    const availableChannels = intersection(
      intersection(allChannels),
      Object.keys(channelPillarScores) as ChannelSlug[]
    )

    return availableChannels.map( channel => {
      return {
        channel: channelSlugKeyMap[channel],
        pillars: pillarsToChartData(channelPillarScores[channel]),
        overall: channelPillarScores[channel].scores.map( s => ({
          label: s.date,
          value: s.overall,
        })),
      }
    })
  }, [channelPillarScores])

  const gridSize = getGridSizing(channelPillars)

  const gridSpacing = useLayoutGridSpacing()

  return (
    <Grid container spacing={gridSpacing}>
      { channelPillars.map( ({ channel, pillars, overall }, i) => {
        return (
          <Grid
            key={i}
            item
            xs={12}
            md={gridSize}>
            <StructuralSummary
              channel={channel}
              provider={null}
              pillars={pillars}
              overall={overall} />
          </Grid>
        )
      })}
    </Grid>
  )
}
