import { Box, Typography, Tab, Tabs, IconButton, Loader, makeAppStyles, PerceptAppBar } from '@percept/mui'
import Api from 'api/services/Api'
import React, { useState }  from 'react'
import { SubmissionTrackerTable } from './SubmissionTrackerTable'
import { transformData } from './transformData'
import { SpendingGroups } from '@percept/types'
import { subMonths } from 'date-fns'
import { range } from 'lodash-es'
import { Close } from '@percept/mui/icons'
import { useNavigation } from '@percept/hooks'
import { useLocation } from 'react-router'

// Show results for how many previous months
const NumberOfMonths = 6

// Create list of last N months for which we want to see reports 
const startOfThisMonth = new Date().setDate(1)
const validMonths = range(1, NumberOfMonths + 1).map( i => (
  subMonths(startOfThisMonth, i)
)).reverse()

const useClasses = makeAppStyles((theme) => ({
  tableContainer: {
    marginTop: '90px',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
  },
  tabs:{
    marginTop: '10px',
    marginBottom: '-60px',
    backgroundColor: 'white',
  },
  field:{
    backgroundColor: 'red',
  },
  appBarInsert: {
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
  },
  exit:{
    marginLeft: 'auto',
  },
  header:{
    fontSize: 18,
  }
}))

const useTabClasses = makeAppStyles( theme => ({
  root: {
    fontSize: 13,
    textTransform: 'none',
    fontWeight: 700,
    minHeight: theme.spacing(6),
    color: 'white',
  },
  tab: {
    fontSize: 13,
    textTransform: 'none',
    fontWeight: 700,
    minHeight: theme.spacing(6),
    color: 'white',
  },
  indicator: (
    theme.appComponents.appBarTabIndicator ?
      theme.appComponents.appBarTabIndicator.colorSecondary :
      {
        backgroundColor: theme.palette.secondary.main,
      }
  ),
}))

export const SubmissionTracker = (): JSX.Element => {
  const classes = useClasses()
  const tabClasses = useTabClasses()
  const navigate = useNavigation()
  const { pathname } = useLocation()

  const [activeGroup, setActiveGroup] = useState<keyof SpendingGroups>('media_investment')    

  const { data: submissionTracker, isLoading }  = Api.useSubmissionTracker(activeGroup)
  
  const rowData = transformData(submissionTracker, validMonths)

  return (
    <Box>
      <PerceptAppBar
        color='primary'
        appBarInserts={[
          <div key='form' className={classes.appBarInsert}>
            <Tabs
              key='form'
              classes={tabClasses}
              value={activeGroup}
              indicatorColor='secondary'
              onChange={(e, value: keyof SpendingGroups): void => {
                setActiveGroup(value)
              }}>
              <Tab value='media_investment' label='Media Investment' className={tabClasses.tab}/>
              {/* Other two tabs are commented, since they are not implemented yet */}
              {/* <Tab value='competitive_investment' label='Competitve Investment' className={tabClasses.tab}/>
              <Tab value='competitve_tv_share_of_voice' label='Competitve TV Share of Voice' className={tabClasses.tab}/> */}
            </Tabs>
          </div>
        ]}>
        <Typography className={classes.header} variant='h4'>Submission Tracker</Typography>
        <IconButton
          className={classes.exit}
          color='inherit'
          onClick={(): void => {
            navigate(pathname.replace('submission-tracker', 'media-investment'))
          }}>
          <Close />
        </IconButton>
      </PerceptAppBar>
      { isLoading ? (
        <Loader preset='fullscreen' />
      ) : rowData ? (
        <Box className={classes.tableContainer}>
          <SubmissionTrackerTable rows={rowData} validMonths={validMonths}/>
        </Box>
      ) : (
        <Box
          height='30vh'
          display='flex'
          justifyContent='center'
          alignItems='center'>
          <Typography variant='h6'>No data</Typography>
        </Box>
      )}
    </Box>
  )
}
