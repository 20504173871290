
import * as actions from './actions'
import reducer from './reducer'
import * as selectors from './selectors'

import { ReduxBundle } from '../lib'

import { UserState } from './typings'


type UserActions = typeof actions
type UserSelectors = typeof selectors


export type UserBundle = ReduxBundle<UserState, UserActions, UserSelectors>


export { actions, reducer, selectors }


export const makeBundle = (): UserBundle => ({
  actions,
  reducer,
  selectors
})

export default { actions, reducer, selectors } as UserBundle
