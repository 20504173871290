import { perceptTheme } from './brandedAppThemes'

import { brandtechAppTheme } from './brandtechAppTheme'
import { tpaTheme } from './tpaTheme'

import { AppBranding } from '@percept/types'

import { AppTheme } from './themeAugmentation'


const { BRANDING } = process.env


const defaultThemeMapping: Partial<Record<AppBranding, AppTheme>> = {
  BRANDTECH: brandtechAppTheme,
  PERCEPT: perceptTheme,
  TPA: tpaTheme,
}


export const defaultAppTheme = defaultThemeMapping[BRANDING] || perceptTheme
