

import localforage from 'localforage'


const emptyDbResponse = () => new Promise( resolve => resolve(null) )


class Persistence {

  constructor(){
    this.db = null
  }

  getActiveDb(){
    return this.db
  }

  hasActiveDb(){
    return this.db !== null
  }

  drop(){
    return this.db && this.db.dropInstance() || emptyDbResponse()
  }

  selectDb(name){
    this.db = localforage.createInstance({ name })
  }

  get(key){
    return this.db && this.db.getItem(key) || emptyDbResponse()
  }

  set(key, value){
    return this.db && this.db.setItem(key, value) || emptyDbResponse()
  }

  delete(key){
    return this.db && this.db.removeItem(key) || emptyDbResponse()
  }

  clear(){
    return this.db && this.db.clear() || emptyDbResponse()
  }

}


export default new Persistence()
