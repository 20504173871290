import { Box, Tab, Tabs } from '@material-ui/core'
import { useNavigation } from '@percept/hooks'
import { makeAppStyles, RoundedPlainTextButton } from '@percept/mui'
import { useActiveOrgEnvironment } from 'components/Organisation'
import { ALL_REPORTING_FEATURES_ENABLED } from 'constans'
import React from 'react'
import { useLocation } from 'react-router'
import { Reporting } from 'ReportingRoutes'
import { DataUpload } from '../DataUpload'

const useTabClasses = makeAppStyles((theme) => ({
  root: {
    fontSize: 13,
    textTransform: 'none',
    fontWeight: 700,
    minHeight: theme.spacing(6),
    color: 'white',
  },
  buttonsContainer: {
    display: 'flex',
    gap: theme.spacing(3),
  },
  indicator: (
    theme.appComponents.appBarTabIndicator ?
      theme.appComponents.appBarTabIndicator.colorSecondary :
      {
        backgroundColor: theme.palette.secondary.main,
      }
  ),
}))

export const SubnavbarTabs = (): JSX.Element => {
  const tabClasses = useTabClasses()
  const navigate = useNavigation()
  const { pathname } = useLocation()

  const { activePlatformUnit } = useActiveOrgEnvironment()

  // URGH - temporary frontend-based flag to tell us who can manage data and who can't
  const canManageData = !!(
    activePlatformUnit && activePlatformUnit.name !== 'Vodafone Global'
  )

  const activeTab = pathname.includes(Reporting.MediaInvestment)
    ? Reporting.MediaInvestment
    : pathname.includes(Reporting.CompetitiveInvestment)
    ? Reporting.CompetitiveInvestment
    : Reporting.SubmissionTracker

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      pr={2}>
      <Tabs
        key='reporting'
        classes={tabClasses}
        value={activeTab}
        indicatorColor='secondary'
        onChange={(e, value: Reporting): void => {
          navigate(pathname.split(activeTab)[0] + value)
        }}>
        <Tab
          value={Reporting.MediaInvestment}
          classes={tabClasses}
          label='Media Investment'
        />
        { ALL_REPORTING_FEATURES_ENABLED && (
          <Tab
            value={Reporting.CompetitiveInvestment}
            classes={tabClasses}
            label='Competitive Investment'
          />
        )}
      </Tabs>
      <Box className={tabClasses.buttonsContainer}>
        <RoundedPlainTextButton
          variant='contained'
          size='small'
          onClick={(): void => {
            navigate(pathname.split(activeTab)[0] + Reporting.SubmissionTracker)
          }}>
          Submission Tracker
        </RoundedPlainTextButton>
        { canManageData && (
          <RoundedPlainTextButton
            variant='contained'
            size='small'
            onClick={(): void => {
              navigate(pathname.split(activeTab)[0] + Reporting.Form)
            }}>
            Manage Data
          </RoundedPlainTextButton>
        )}
        { canManageData && activePlatformUnit && (
          <DataUpload
            org_unit_id={activePlatformUnit.id} />
        )}
      </Box>
    </Box>
  )
}
