import { GridSpacing, useAppTheme, useMediaQuery } from '@percept/mui'

import { DocumentTitleHookProps, useDocumentTitle } from '@percept/hooks'

import { AppBranding } from '@percept/types'


const { BRANDING } = process.env


export const useLayoutGridSpacing = (): GridSpacing => {

  const appTheme = useAppTheme()

  // Derive spacings for sm, md, lg, xl screns with minimum hooks required.

  const isSmall = useMediaQuery(appTheme.breakpoints.down('sm'))

  const isMedium = useMediaQuery(appTheme.breakpoints.down('md'))

  const isLarge = useMediaQuery(appTheme.breakpoints.down('lg'))

  if( isSmall ) return 4

  if( isMedium ) return 2

  if( isLarge ) return 3

  return 5

}


const brandingTitleMap: Record<AppBranding, string> = {
  PERCEPT: 'Percept',
  TPA: 'TPA Audit',
  BRANDTECH: 'Brandtech Media Group',
  VODAFONE: 'Vodafone Media Wizard',
}

export const useDashboardPageTitle = (props: DocumentTitleHookProps): void => {
  useDocumentTitle({
    site: brandingTitleMap[BRANDING || 'PERCEPT'],
    ...props,
  })
}
