import React, { useContext } from 'react'

import { Box, Typography, makeAppStyles } from '@percept/mui'
import { UserPrivilegeContext } from 'contexts'


const useStyles = makeAppStyles( theme => ({
  link: {
    textDecoration: 'underline solid',
    margin: '0 0.25em',
    textUnderlineOffset: '6px',
  }
}))


export const VodafoneRedirect = (): JSX.Element => {
  const privileges = useContext(UserPrivilegeContext)
  const classes = useStyles()
  return (
    <Box p={10} display='flex' flexDirection='column' alignItems='center'>
      <Typography variant='h1'>We&apos;ve moved!</Typography>
      <Box mt={10} fontSize={26} fontWeight={700}>
        Your Media Quality Dashboard is now part of the
        <a
          className={classes.link}
          href='https://mwizard.vodafone.com'>
          Vodafone Media Wizard
        </a>
      </Box>

      { privileges.user && privileges.user.default_org_unit_id && (
        <Box mt={8} fontSize={26} fontWeight={700}>
          <a
            className={classes.link}
            href={
              `https://mwizard.vodafone.com/dashboards/${privileges.user.default_org_unit_id}`
            }>
            Click here
          </a>
          to go straight to your Media Quality Dashboard
        </Box>
      )}
    </Box>
  )
}
