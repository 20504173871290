import React from 'react'

import { Box } from '@percept/mui'

import { PublicLayout } from './PublicLayout'

import { BrandtechWelcomeContent, PerceptWelcomeContent, TPAWelcomeContent } from 'components/Welcome'

import { useDocumentTitle, useLocation } from '@percept/hooks'

import { getSignInRoute } from 'DashboardRoutes'

import { AppBranding } from '@percept/types'


const { BRANDING } = process.env


const documentTitleProps: Partial<Record<AppBranding, { site: string, paths: string[] }>> = {
  PERCEPT: {
    site: 'Percept',
    paths: ['Welcome to Percept'],
  },
  TPA: {
    site: 'TPA Audit',
    paths: ['Welcome'],
  },
  BRANDTECH: {
    site: 'Brandtech Media Group',
    paths: ['Welcome'],
  },
}

const componentMapping: Partial<Record<AppBranding, React.FC<{ signInRoute: string }>>> = {
  PERCEPT: PerceptWelcomeContent,
  BRANDTECH: BrandtechWelcomeContent,
  TPA: TPAWelcomeContent,
}


export const Welcome = (): JSX.Element => {

  useDocumentTitle(documentTitleProps[BRANDING || 'PERCEPT'])

  const location = useLocation()

  const signInRoute = getSignInRoute(location)

  const WelcomeComponent = componentMapping[BRANDING] || PerceptWelcomeContent

  return (
    <PublicLayout>
      <Box mt={3} px={4} pb={6}>
        <WelcomeComponent signInRoute={signInRoute} />
      </Box>
    </PublicLayout>
  )
}
