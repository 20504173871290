export enum Filters  {
  Agency = 'agency',
  Department = 'department',
  MarketsGroup ='groups',
  Markets = 'markets',
  FundingSource = 'funding_source',
  Campaign = 'campaign-pillar',
  SubCampaign = 'sub-campaign-pillar',
  SecondBrand = 'second-brand',
  MediaChannel = 'media-channel',
  MediaSubChannel = 'media-sub-channel',
  Message = 'message',
  Product = 'product',
  CalendarYears = 'calendar-year',
  FinancialYear = 'financial-year',
  BuyType = 'buy-type',
  Partner = 'partner',
  Competitors = 'competitors',
  JBP = 'jbp'
}