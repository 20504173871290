import { ReduxAction, StoreState } from '@percept/types'
import { apiClient } from 'api/clients'
import { Filters } from 'enums/Filters'
import { getItem, setItem } from 'lib/localStorage'
import { Dispatch, Reducer } from 'redux'
import { applyFilters, APPLY_FILTERS } from '../actions'

export type FiltersList = Array<{
  filterValue: string
  options: { group_value: string; options: { value: string; name: string }[] }
}>

export type filtersReduxState = {
  filtersMedia: FiltersList
  filtersCompetitve: FiltersList
}

const initialState: filtersReduxState = {
  filtersMedia: getItem('filtersMedia') || [],
  filtersCompetitve: getItem('filtersCompetitive') || [],
}

const filtersReducer: Reducer<filtersReduxState, ReduxAction> = (
  state = initialState,
  action
) => {
  if (action.type === APPLY_FILTERS) {
    setItem(action.payload.dashboardType, action.payload.list)
    return {
      ...state,
      [action.payload.dashboardType as keyof filtersReduxState]:
        action.payload.list,
    }
  }

  return state
}

export function fetchFinancialYears(dashboardType: keyof filtersReduxState) {
  return async function fetchFinancialYearsThunk(
    dispatch: Dispatch,
    getState: () => StoreState
  ) {
    if (getState().filters[dashboardType].length === 0) {
      const response = await (
        await apiClient.get<{ results: { id: string; name: string }[] }>(
          '/financial_years/'
        )
      ).data.results
        .filter((el) => el.name === 'FY2223' || el.name === 'FY2122')
        .map((el) => {
          return { value: el.id, name: el.name }
        })

      dispatch(
        applyFilters({
          list: [
            {
              filterValue: 'period',
              options: {
                group_value: Filters.FinancialYear,
                options: response,
              },
            },
          ],
          dashboardType: dashboardType,
        })
      )
    }
  }
}

export default filtersReducer
