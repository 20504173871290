import React from 'react'

import { ChannelLayout } from './ChannelLayout'

import { ChannelViewManager } from '../components/Channel'

import { useLocationParams } from '@percept/hooks'

import { providerChannelMap } from '@percept/constants'

import { ReportProvider } from '@percept/types'


export const ProviderScreen = (): JSX.Element => {

  const { provider } = useLocationParams()

  const mappedProvider: ReportProvider | null = (
    provider === 'google-ads' ? 'adwords' : provider
  )

  const channel = mappedProvider && providerChannelMap[mappedProvider]

  return (
    <ChannelLayout channel={channel}>
      <ChannelViewManager
        channel={channel}
        provider={mappedProvider} />
    </ChannelLayout>
  )
}

export default ProviderScreen
