import React from 'react'

import { useParams } from 'react-router'

import { ChannelLayout } from './ChannelLayout'

import { PageHeader } from 'components/PageHeader'

import { InsightsReport } from 'components/InsightsReport'

import { useReportSeries } from '@percept/hooks'

import { coerceReportProvider } from '@percept/utils'

import { providerChannelMap } from '@percept/constants'

import { InsightsReportTypeParams, SeriesParams } from '@percept/types'


export const InsightsReportScreen = (): JSX.Element => {

  const { series_id, insights_report_type } = useParams<SeriesParams & InsightsReportTypeParams>()

  const [series] = useReportSeries({ series_id })

  const provider = series.data && coerceReportProvider(series.data.provider.slug)

  const channel = provider && providerChannelMap[provider]

  return (
    <ChannelLayout
      channel={channel}>
      <PageHeader />
      <InsightsReport
        series_id={series_id}
        insights_report_type={insights_report_type} />
    </ChannelLayout>
  )
}

export default InsightsReportScreen
