import { useMutation } from 'react-query'

import { apiClient } from 'api/clients'

import qs from 'query-string'

import { Filters } from 'enums/Filters'

import {
  ExportPeriod,
  MediaSpendExportFilterParams,
  MediaSpendExportRequestParams,
} from './typings'

import { saveAs } from 'file-saver'


const exportFilterMap: Partial<Record<Filters, keyof MediaSpendExportFilterParams>> = {
  [Filters.BuyType]: 'buy_types',
  [Filters.CalendarYears]: 'years',
  [Filters.Campaign]: 'campaigns',
  [Filters.FinancialYear]: 'financial_years',
  [Filters.FundingSource]: 'funding_sources',
  [Filters.JBP]: 'jbps',
  [Filters.Markets]: 'markets',
  [Filters.MediaChannel]: 'media_channels',
  [Filters.MediaSubChannel]: 'media_sub_channels',
  [Filters.SecondBrand]: 'second_brands',
  [Filters.SubCampaign]: 'sub_campaigns',
}


type CSVExportProps = {
  exportParams: Pick<MediaSpendExportRequestParams, 'export_type' | 'export_sub_type'>
  period: 'month' | 'quarter' | 'year'
  filters: {
    [key in Filters]?: string[]
  }
}

const getExportRequestParams = ({
  exportParams,
  period,
  filters,
}: CSVExportProps): MediaSpendExportRequestParams => {
  const filterParams = Object.entries(filters).reduce( (acc, [key, value]) => {
    const filterKey = exportFilterMap[key as Filters]
    if( filterKey ){
      acc[filterKey] = value
    }
    return acc
  }, {} as Partial<MediaSpendExportFilterParams>)
  return {
    group: `${period}ly` as ExportPeriod,
    ...exportParams,
    ...filterParams,
  }
}


export const useCSVExport = () => {
  const { mutate, ...hookValue } = useMutation({
    mutationFn: async (props: CSVExportProps) => {
      const { export_type, ...params } = getExportRequestParams(props)

      const response = await apiClient.get<Blob>(
        `/export/media_spend/${export_type}/`,
        {
          params,
          paramsSerializer(params) {
            return qs.stringify(params)
          },
          responseType: 'blob',
          headers: {
            'Content-Type': 'text/csv',
          }
        }
      )

      // Get filename from content disposition header if available
      const contentDisposition: string = response.headers && response.headers['content-disposition']
      let filename = ''
      if( contentDisposition ){
        const headerValues = contentDisposition.split(';').map( s => s.trim())
        const filenameValue = headerValues.find( v => v.startsWith('filename='))
        if( filenameValue ){
          filename = filenameValue.replace('filename=', '')
        }
      }
      // Set default filename if not found in response headers
      filename = filename || [
        export_type,
        params.export_sub_type,
      ].filter(Boolean).join('-') + '.csv'

      saveAs(response.data, filename)
    }
  })

  return {
    download: mutate,
    ...hookValue,
  }
}
