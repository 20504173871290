/* eslint-disable no-console */
import { REHYDRATE, PERSIST } from 'redux/actions'

import { USER_LOGIN, USER_LOGOUT } from '@percept/redux/bundles/user/actions'

import { getUserAttributes, getUserLoggedIn } from '@percept/redux/bundles/user/selectors'

import { pick } from 'lodash-es'

import { StoreState } from 'types'

import { Dictionary } from '@percept/types'

import { Middleware } from 'redux'


type PartialStoreState = Partial<{
  [K in keyof StoreState]: StoreState[K] extends object ? Partial<StoreState[K]> : StoreState[K] 
}>

const defaultSerialize = (state: StoreState): PartialStoreState => {
  return {
    appState: pick(
      state.appState,
      [
        'referenceDateBehaviour',
        'performanceComparisonRange',
        'insightsReportViewType',
        'targetCurrency'
      ] as (keyof StoreState['appState'])[]
    ),
    reports: pick(
      state.reports,
      ['entitySort', 'settings']
    ),
    series: pick(
      state.series,
      ['settings']
    ),
  }
}
  

const STATE_KEY = 'state'

let timeoutRef: NodeJS.Timeout | null = null

interface Persistence {
  selectDb(dbName: string): void
  get(key: string): Promise<any>
  set(key: string, value: any): Promise<any>
  drop(): Promise<any>
}

export type PersistenceMiddlewareConfig = {
  persistence: Persistence
  serialize?: (state: StoreState) => Dictionary
}


export const persistenceMiddlewareCreator = ({
  persistence,
  serialize = defaultSerialize
}: PersistenceMiddlewareConfig): Middleware<{}, StoreState> => {

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return store => next => action => {

    if( action.type === PERSIST ){
      persistence.set(action.payload.key, action.payload).then( () =>
        console.log(`${action.payload.key} was cached`)
      ).catch( e =>
        console.error(e)
      )

      return
    }

    if( action.type === USER_LOGIN ){

      const result = next(action)

      const state = store.getState()

      const loggedIn = getUserLoggedIn(state)
      const attributes = getUserAttributes(state)

      if( loggedIn && attributes ){

        persistence.selectDb(`percept-dashboard-${attributes.user_id}`)

        persistence.get(STATE_KEY).then( state => {
          if( state !== null ){
            store.dispatch({ type: REHYDRATE, payload: { state } })
          }
        }).catch( e => console.error(e) )

      }

      return result
    
    }else if( action.type === USER_LOGOUT ){
      
      persistence.drop().then( () => {
        console.info('Cache cleaned')
      })
    
    }else{
     
      timeoutRef && clearTimeout(timeoutRef)
      timeoutRef = setTimeout( () => {
        try{
          const dump = serialize(store.getState())
          persistence.set(STATE_KEY, dump)
        }catch(e){ console.error(e) }
      }, 1000)
    
    }

    return next(action)

  }

}
