import React, { Fragment } from 'react'

import { Divider, Typography } from '@material-ui/core'

import { makeAppStyles } from '../../themes'

import { percentageFormatter } from '../formatters'

import { getPercentage } from '@percept/utils'

import { DatumFormatters, SVGDatumType } from '../typings'

// import { Histogram } from '../Histogram/Histogram'


const useStyles = makeAppStyles( theme => ({
  root: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    zIndex: 9999,
    boxShadow: theme.shadows[10],
    pointerEvents: 'none',
  },
  label: {
    fontSize: 14,
    padding: theme.spacing(1, 1.5),
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
  datum: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  indicator: {
    height: 10,
    width: 10,
    borderRadius: '100%',
    marginRight: theme.spacing(1.5),
  },
  datumLabel: {
    marginRight: theme.spacing(2.25),
  },
  value: {
    marginLeft: 'auto',
    padding: theme.spacing(0.5, 0.75),
    fontSize: 12,
    fontWeight: 700,
    color: theme.chart.tooltip.valueColor,
    background: theme.chart.tooltip.valueBackgroundColor,
    borderRadius: theme.shape.borderRadius,
  },
  percentage: {
    marginLeft: theme.spacing(1),
    color: theme.chart.tooltip.secondaryContentColor || theme.palette.text.hint,
  },
  chartContainer: {
    margin: theme.spacing(2, 2, 2, 0),
  },
}) )


type MappedTooltipDatum<T extends SVGDatumType = SVGDatumType> = (
  Omit<T, 'label'> & {
    label: React.ReactNode
    total?: number
  }
)


export type TooltipData<T extends SVGDatumType = SVGDatumType> = {
  label?: React.ReactNode
  datum?: MappedTooltipDatum<T>
  data?: MappedTooltipDatum<T>[]
}


export type TooltipProps<T extends SVGDatumType = SVGDatumType> = (
  {
    tooltipData?: TooltipData<T>
    renderPercentageOfTotal?: boolean
    color?: string
  } &
  Pick<
    DatumFormatters, 'valueFormatter' | 'datumLabelFormatter'
  >
)


export function Tooltip<T extends SVGDatumType = SVGDatumType>({
  tooltipData,
  color,
  renderPercentageOfTotal = false,
  valueFormatter,
  datumLabelFormatter,
}: TooltipProps<T>): JSX.Element | null {

  const classes = useStyles()

  if( !tooltipData ) return null

  return (
    <div className={classes.root}>

      { tooltipData.datum && (
        <div
          className={classes.datum}>

          <div className={classes.indicator} style={{backgroundColor: color || tooltipData.datum.color}} />

          <Typography
            className={classes.datumLabel}
            variant='subtitle1'>
            { datumLabelFormatter ? datumLabelFormatter(tooltipData.datum as SVGDatumType) : tooltipData.datum.label }
          </Typography>

          <span className={classes.value}>
            { valueFormatter(tooltipData.datum.value) }
            { renderPercentageOfTotal && (
              <span className={classes.percentage}>
                { percentageFormatter(getPercentage(Number(tooltipData.datum.value), Number(tooltipData.datum.total)))}
              </span>
            )}
          </span>

        </div>
      )}

      { !!(tooltipData.data && tooltipData.data.length) && (
        <Fragment>

          { tooltipData.label && (
            <Fragment>

              <Typography
                className={classes.label}
                variant='h5'>
                { tooltipData.label }
              </Typography>

              <Divider className={classes.divider} />

            </Fragment>
          )}

          {/* <div className={classes.chartContainer}>
            <Histogram
              height={100}
              width={200}
              data={tooltipData.data}
              grid='rows'
              animate={false}
              numYTicks={5}
              axisLine
              axisText />
          </div> */}

          { tooltipData.data.map( (d, i) => (
            d && (
              <div
                key={`${d.label}-${i}`}
                className={classes.datum}>

                <div className={classes.indicator} style={{backgroundColor: color || d.color}} />

                <Typography
                  className={classes.datumLabel}
                  variant='subtitle1'>
                  { d.label }
                </Typography>

                <span className={classes.value}>
                  { valueFormatter(d.value) }
                  { renderPercentageOfTotal && (
                    <span className={classes.percentage}>
                      { percentageFormatter(getPercentage(Number(d.value), Number(d.total)))}
                    </span>
                  )}
                </span>

              </div>
            )
          ))}
        </Fragment>
      )}

    </div>
  )
}
