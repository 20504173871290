
import React from 'react'

import { Tooltip, TooltipData } from './Tooltip'

import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip'

import ResizeObserverPolyfill from 'resize-observer-polyfill'

import { localPoint } from '@visx/event'

import { getLabelFormatter, getValueFormatter } from './formatters'

import { getDatasetTotal } from './lib'

import { DatumType } from '@percept/types'

import { ChartEvent, DatumEventHandler, BaseChartProps, SVGDatumType } from './typings'


export type ChartWithTooltipProps<
  T extends SVGDatumType,
  P extends object = {}
> = BaseChartProps<T> & P


const tooltipStyles: React.CSSProperties = {
  ...defaultStyles,
  zIndex: 9999,
  background: 'transparent',
  color: 'unset',
  margin: 0,
  padding: 0,
}


const divStyle: React.CSSProperties = {
  position: 'relative',
}


type WithTooltipProps<P extends object> = (
  P & {
    yTickFormatter?: (value: DatumType['value']) => string
    xTickFormatter?: (label: DatumType['label']) => string
    tooltipLabelFormatter?: (label: DatumType['label']) => string
  }
)


export function makeChartWithTooltip<T extends SVGDatumType, P extends BaseChartProps<T>>(
  Component: React.FC<P>
): React.FC<WithTooltipProps<P>> {

  const ComponentWithTooltip: React.FC<WithTooltipProps<P>> = (props): JSX.Element => {
    const {
      tooltipOpen,
      tooltipData,
      tooltipLeft,
      tooltipTop,
      showTooltip,
      hideTooltip,
    } = useTooltip<TooltipData<T>>()

    const { containerRef, TooltipInPortal } = useTooltipInPortal({
      detectBounds: true,
      scroll: true,
      polyfill: ResizeObserverPolyfill,
    })

    const labelFormatter = getLabelFormatter(props)

    const valueFormatter = getValueFormatter(props)

    const handleMouseOver: DatumEventHandler<T, ChartEvent> = (event, datum, data) => {
      const coords = localPoint(event.currentTarget.parentNode as Element, event)
      coords && showTooltip({
        tooltipLeft: coords.x + 5,
        tooltipTop: coords.y + 5,
        tooltipData: {
          datum: {
            ...datum,
            label: labelFormatter(datum.label),
            total: getDatasetTotal(data),
          },
        }
      })
    }

    return (
      <div style={divStyle}>

        <Component
          {...props}
          containerRef={containerRef}
          onSegmentMouseOver={handleMouseOver}
          onSegmentMouseOut={hideTooltip} />

        { tooltipOpen && (
          <TooltipInPortal
            key={Math.random()}
            top={tooltipTop}
            left={tooltipLeft}
            style={tooltipStyles}>
            <Tooltip
              tooltipData={tooltipData}
              renderPercentageOfTotal={!!props.tooltipPercentage}
              valueFormatter={valueFormatter} />
          </TooltipInPortal>
        )}

      </div>
    )
  }

  ComponentWithTooltip.displayName = `${Component.displayName}WithTooltip`

  return ComponentWithTooltip
}
