
import React from 'react'

import { makeAppStyles, useAppTheme } from '../../themes'

import { useSpring, animated } from 'react-spring'

import { isNumber } from '@percept/utils'

import { HealthType } from '@percept/types'

import { AnimatedPercentage } from '../Gauge'

import { OpaqueInterpolation } from 'react-spring'


const useStyles = makeAppStyles( theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 0.35rem',
    // Minimum width sufficient to cover '100%'
    minWidth: '2.89em',
    fontWeight: 700,
    borderRadius: theme.shape.borderRadius,
    userSelect: 'none',
  },
}) )


export type HealthProps = {
  value: HealthType
  className?: string
  fontSize?: string
  animate?: boolean
  showBackground?: boolean
}

export const Health = ({
  value,
  fontSize,
  className,
  animate = true,
  showBackground,
}: HealthProps): JSX.Element | null => {

  const hasValue = isNumber(value)

  const appTheme = useAppTheme()

  const color = appTheme.chart.healthColourScale(value || 0)

  const percentageValue = (value || 0) * 100

  const backgroundColor = (
    showBackground
    && appTheme.appComponents.reportDashboard
    && appTheme.appComponents.reportDashboard.secondaryHealth.backgroundColor
  ) || undefined

  const animatedStyle = useSpring({
    value: percentageValue,
    color,
    immediate: !animate,
    to: {
      color,
      value: percentageValue,
    },
  })

  const classes = useStyles()

  return (
    hasValue && (
      <animated.div
        className={`${classes.root} ${className}`}
        style={{fontSize, backgroundColor, ...animatedStyle}}>

        <AnimatedPercentage
          value={animatedStyle.value as OpaqueInterpolation<number>}
          color={animatedStyle.color} />

      </animated.div>
    ) || null
    
  )
}
