import React, { useMemo } from 'react'

import {
  Box,
  Grid,
  makeAppStyles,
  SentimentDelta,
  Typography,
  useAppTheme,
} from '@percept/mui'

import {
  ChartAnnotation,
  ChartData,
  dmyFormatter,
  ResponsiveLine,
} from '@percept/mui/charts'

import {
  channelPillars,
  getRoundedValue,
  HealthDefs,
  healthDomain,
  healthFormatter,
  PercentageVisualisation,
  withHealthColor,
} from '../QualityAnalysis'

import { every, get } from 'lodash-es'

import { pillarLabelMap, providerChannelMap } from '@percept/constants'

import { MetricPillar, ReportProviderV2, ReportSeriesPillarScores } from '@percept/types'


export type PillarScoresProps = {
  provider: ReportProviderV2
  scores: ReportSeriesPillarScores
}


const useStyles = makeAppStyles( theme => ({
  sentimentDelta: {
    marginRight: theme.spacing(1),
    fontSize: 13,
  },
  chartContainer: {
    height: 48,
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-end',
  },
  deltaTypography: {
    fontSize: 13,
  },
  labelTypography: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexBasis: '100%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))


const getLastDelta = (data: ChartData): number => {
  const len = data.length
  if( len < 2 ){
    return 0
  }
  const last = get(data[len - 1], 'value', null)
  const penultimate = get(data[len - 2], 'value', null)
  if( !(last !== null && penultimate !== null ) ){
    return 0
  }
  return last - penultimate
}


export const PillarScores = ({
  provider,
  scores,
}: PillarScoresProps): JSX.Element => {

  const appTheme = useAppTheme()

  const classes = useStyles()

  const channel = providerChannelMap[provider]

  const datasets = useMemo(() => {
    const pillars: (MetricPillar | 'overall')[] = [
      'overall', ...channelPillars[channel],
    ]
    return pillars.map( pillar => {
      const data = withHealthColor(
        scores.map( score => ({
          label: score.date,
          value: score[pillar],
        })),
        appTheme,
      )
      const backgroundColor = appTheme.palette.background.paper
      return {
        pillar,
        label: pillar === 'overall' ? 'Overall' : pillarLabelMap[pillar],
        isNull: data.length === 0 || every(data, d => d.value === null ),
        data,
        backgroundColor,
        annotations: [],
      } as {
        pillar: MetricPillar | 'overall'
        isNull: boolean
        data: ChartData
        label: string
        backgroundColor: string
        annotations: ChartAnnotation[] | undefined
      }
    })
  }, [channel, scores, appTheme])

  const percentageWidth = `${100 / datasets.length}%`

  return (
    <Grid
      container
      wrap='nowrap'
      justify='space-between'
      spacing={4}>

      { datasets.map( ({ pillar, data, backgroundColor, label, annotations, isNull }) => {
        return (
          <Grid
            item
            className={classes.gridItem}
            style={{width: percentageWidth}}
            key={pillar}>
            { (!isNull && data.length > 1) && (
              <Typography
                variant='h6'
                align='center'
                className={classes.deltaTypography}>
                <SentimentDelta
                  value={getRoundedValue(getLastDelta(data) * 100)} />
              </Typography>
            )}
            { isNull ? (
              <Box
                color='text.disabled'
                display='flex'
                alignItems='center'
                justifyContent='center'
                height={60}
                fontSize={16}
                fontWeight={700}>
                N / A
              </Box>
            ) : data.length === 1 ? (
              <Box>
                <PercentageVisualisation
                  value={getRoundedValue((data[0].value || 0) * 100)}
                  height={56}
                  width={56}
                  fontSize={12} />
              </Box>
            ): (
              <div
                className={classes.chartContainer}>
                <ResponsiveLine
                  height={48}
                  defs={
                    <HealthDefs
                      id='mainGradient'
                      appTheme={appTheme} />
                  }
                  annotations={annotations}
                  backgroundElement={
                    <rect
                      x='0%'
                      y='0%'
                      height='100%'
                      width='100%'
                      fill={backgroundColor} />
                  }
                  fillOverride='url(#mainGradient)'
                  strokeOverride='url(#mainGradient)'
                  roundXDomain={false}
                  roundYDomain={false}
                  verticalMargin={0}
                  domain={healthDomain}
                  yTickFormatter={healthFormatter}
                  xTickFormatter={dmyFormatter}
                  axisLine={false}
                  fillOpacity={0.85}
                  strokeOpacity={1}
                  xScaleType='time'
                  data={data} />
              </div>
            )}
            <Typography
              className={classes.labelTypography}
              variant='subtitle1'>
              { label }
            </Typography>
          </Grid>
        )
      })}

    </Grid>
  )

}
