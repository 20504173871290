import { apiClient } from 'api/clients'
import { Query, useQuery, UseQueryResult } from 'react-query'
import { ListItem } from '../../components/ReportingDashboard/types'
import qs from 'query-string'
import { AxiosResponse } from 'axios'

export interface DataByPeriodProps {
  period: string
  funding_sources?: string[]
  markets?: string[]
  media_sub_channels?: string[]
  financial_years?: string[]
  years?: string[]
  media_channels?: string[]
  campaigns?: string[]
  sub_campaigns?: string[]
  second_brands?: string[]
  buy_types?: string[]
  jbps?: string[]
}

export type DataProps = Omit<DataByPeriodProps, 'period'>

export const mediaInvestmentReportQueryKey = 'mediaInvestmentReport'

export const isMediaInvestmentReportQuery = (query: Query): boolean => (
  query.queryKey[0] === mediaInvestmentReportQueryKey
)


const makeInvestmentReportQuery = (
  queryKey: string,
  route: string,
  options: {
    includePeriod?: true
    adapter?: (response: AxiosResponse<{ results: ListItem[] }>) => ListItem[]
  } = {},
): ((props: DataByPeriodProps) => UseQueryResult<ListItem[], Error>) => {
  return ({
    period,
    funding_sources = [],
    markets = [],
    media_sub_channels = [],
    financial_years = [],
    years = [],
    media_channels = [],
    campaigns = [],
    sub_campaigns = [],
    second_brands = [],
    buy_types = [],
    jbps = [],
  }) => {
    return useQuery<ListItem[], Error>(
      [
        mediaInvestmentReportQueryKey,
        queryKey,
        ...(options.includePeriod && [period] || []),
        funding_sources,
        markets,
        media_sub_channels,
        financial_years,
        years,
        media_channels,
        campaigns,
        sub_campaigns,
        second_brands,
        buy_types,
        jbps,
      ],
      async () => {
        const res = await apiClient.get<{
          results: ListItem[]
        }>(route, {
          params: {
            ...(options.includePeriod ? {
              group: period
            } : {}),
            funding_sources,
            markets,
            media_sub_channels,
            financial_years,
            years,
            media_channels,
            campaigns,
            sub_campaigns,
            second_brands,
            buy_types,
            jbps,
          },
          paramsSerializer(params) {
            return qs.stringify(params)
          },
        })

        return options.adapter ? options.adapter(res) : res.data.results
      },
      { staleTime: 1000 * 60 * 5 }
    )
  }
}


export default {
  useCampaignPillar: makeInvestmentReportQuery(
    'campaignByPillar',
    '/media_spend/campaign_pillar',
    { includePeriod: true },
  ),
  useCampaignPillarByMarket: makeInvestmentReportQuery(
    'campaignPillarByMarket',
    '/media_spend/campaign_pillar/by_market',
  ),
  useBrandPerformance: makeInvestmentReportQuery(
    'brandVsPerformance',
    '/media_spend/brand_vs_performance',
    { includePeriod: true },
  ),
  useBrandPerformanceByMarket: makeInvestmentReportQuery(
    'brandVsPerformanceByMarket',
    '/media_spend/brand_vs_performance/by_market',
  ),
  useCampaignSubPillar: makeInvestmentReportQuery(
    'campaignSubPillar',
    '/media_spend/campaign_sub_pillar/by_period',
    { includePeriod: true },
  ),
  useConsumerVsBusiness: makeInvestmentReportQuery(
    'consumerVsBusiness',
    '/media_spend/consumer_vs_business',
    { includePeriod: true },
  ),
  useMediaMix: makeInvestmentReportQuery(
    'mediaMix',
    '/media_spend/media_mix/by_period',
    { includePeriod: true }
  ),
  useMediaMixByMarket: makeInvestmentReportQuery(
    'mediaMixByMarket',
    '/media_spend/media_mix/by_market',
  ),
  useMediaMixByCampaign: makeInvestmentReportQuery(
    'mediaMixByCampaign',
    '/media_spend/media_mix/by_campaign',
  ),
  useMediaMixBySubCampaign: makeInvestmentReportQuery(
    'mediaMixBySubCampaign',
    '/media_spend/media_mix/by_sub_campaign',
  ),
  useMediaMixYOY: makeInvestmentReportQuery(
    'mediaMixYOY',
    '/media_spend/media_mix/by_year_over_year',
    {
      adapter: res => res.data.results.filter(
        (r) => r.row_group !== 'Total percents yoy'
      )
    }
  ),
  useMediaMixTVVsDigital: makeInvestmentReportQuery(
    'mediaMixTVVsDigital',
    '/media_spend/media_mix/tv_vs_digital/by_year_over_year',
  ),
  useSecondBrandByPeriod: makeInvestmentReportQuery(
    'secondBrandByPeriod',
    '/media_spend/second_brand/by_period',
    { includePeriod: true },
  ),
  useSecondBrandByCampaignPillar: makeInvestmentReportQuery(
    'secondBrandByCampaignPillar',
    '/media_spend/second_brand/by_campaign_pillar',
  ),
  useSecondBrandByMediaMix: makeInvestmentReportQuery(
    'secondBrandByMediaMix',
    '/media_spend/second_brand/by_media_mix',
  ),
  useSecondBrandByDigitalMediaMix: makeInvestmentReportQuery(
    'secondBrandByDigitalMediaMix',
    '/media_spend/second_brand/by_digital_media_mix',
  ),
  useBrandMessagingByMarket: makeInvestmentReportQuery(
    'brandMessagingByMarket',
    '/media_spend/message/by_market',
  ),
  useBrandMessagingByCampaignPillar: makeInvestmentReportQuery(
    'brandMessagingByCampaignPillar',
    '/media_spend/message/by_campaign',
  ),
  useBrandMessagingByMediaMix: makeInvestmentReportQuery(
    'brandMessagingByMediaMix',
    '/media_spend/message/by_media_mix',
  ),
  useFundingSourceByMarket: makeInvestmentReportQuery(
    'fundingSourceByMarket',
    '/media_spend/funding_source/by_market',
  ),
  useFundingSourceByCampaign: makeInvestmentReportQuery(
    'fundingSourceByCampaign',
    '/media_spend/funding_source/by_campaign',
  ),
  useFundingSourceBySubCampaign: makeInvestmentReportQuery(
    'fundingSourceBySubCampaign',
    '/media_spend/funding_source/by_sub_campaign',
  ),
  useFundingSourceByMediaMix: makeInvestmentReportQuery(
    'fundingSourceByMediaMix',
    '/media_spend/funding_source/by_media_mix',
  ),
  useFundingSourceByDigitalMediaMix: makeInvestmentReportQuery(
    'fundingSourceByDigitalMediaMix',
    '/media_spend/funding_source/by_digital_media_mix',
  ),
  useFundingSourceByBrandVsPerformance: makeInvestmentReportQuery(
    'fundingSourceByBrandVsPerformance',
    '/media_spend/funding_source/by_brand_vs_performance',
  ),
  useProductByMarket: makeInvestmentReportQuery(
    'productByMarket',
    '/media_spend/product/by_market',
  ),
  useProductByCampaign: makeInvestmentReportQuery(
    'productByCampaign',
    '/media_spend/product/by_campaign',
  ),
  useProductByMediaMix: makeInvestmentReportQuery(
    'productByMediaMix',
    '/media_spend/product/by_media_mix',
  ),
}
