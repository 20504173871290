
import React from 'react'

import { Box, IconButton, TooltipProps } from '@material-ui/core'

import { Health } from '../Health'

import { Money } from '../Money'

import { PlainTextButton } from '../Buttons'

import { OverridingNodeComponentProps } from '../TreeView'

import { EntityTooltip } from './EntityTooltip'

import { ArrowDropDown, ArrowDropUp } from '../../icons'

import { isNumber, displayNumber } from '@percept/utils'

import { dimensions } from './lib'

import { TreeEntityListItem } from './typings'


const EntityPerformanceSummary = (
  { performanceValues, currency, health }: (
    Pick<
      TreeEntityListItem,
      'currency' | 'performanceValues' | 'health'
    >
  )
): JSX.Element => {
  return (
    <Box
      display='flex'
      flexWrap='nowrap'
      marginLeft='auto'
      alignItems='center'>
      <Box
        display='flex'
        justifyContent='flex-end'
        width='6rem'>
        { isNumber(health) && (
          <Health
            showBackground
            fontSize='1.2em'
            value={health} />
        )}
      </Box>
      { dimensions.map( dimension => (
        <Box
          key={dimension}
          fontWeight={700}
          fontSize={13}
          textAlign='right'
          width='6rem'>
          {
            !performanceValues ?
              null :
              currency && dimension === 'cost' ? (
                <Money
                  amount={performanceValues.cost}
                  currency={currency} />
              ) : !isNumber(performanceValues[dimension]) ? (
                null
              ) :  (
                // TS requires a cast, despite handling this with `isNumber` above
                displayNumber(performanceValues[dimension] as number)
              )
          }
        </Box>
      ))}
    </Box>
  )
}


export type EntityListItemProps = (
  OverridingNodeComponentProps<
    TreeEntityListItem
  >
)


const entityTooltipProps: Partial<TooltipProps> = {
  PopperProps: {
    popperOptions: {
      positionFixed: true,
    },
  },
}


export const EntityListItem = (props: EntityListItemProps): JSX.Element => {

  const {
    id,
    className,
    name,
    type,
    health,
    active,
    expanded,
    onExpand,
    onClick,
    members,
    currency,
    performanceValues,
  } = props

  const clickable = type && type !== 'Campaign' && !active

  const isGroupType = type && type.toUpperCase().indexOf('GROUP') !== -1

  const trigger = (
    <PlainTextButton
      size='small'
      color='inherit'
      onClick={
        clickable && onClick ?
          ((): void => onClick(props)) :
          undefined
      }>
      { name }
    </PlainTextButton>
  )
  
  return (
    <Box
      className={className}
      display='flex'>

      { type === 'AccountGroup' && typeof onExpand === 'function' &&
        <IconButton
          size='small'
          color='inherit'
          disabled={!members || members.length === 0}
          onClick={onExpand}>
          { expanded ?
            <ArrowDropUp /> :
            <ArrowDropDown /> }
        </IconButton> }

      { isGroupType ? (
        trigger
      ) : (
        <EntityTooltip
          placement='right'
          title={`${type} ID - ${id}`}
          {...entityTooltipProps}>
          { trigger }
        </EntityTooltip>
      )}

      <EntityPerformanceSummary
        health={health}
        currency={currency}
        performanceValues={performanceValues} />

    </Box>
  )
}

export default EntityListItem
