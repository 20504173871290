import { find, intersection, noop, some } from 'lodash-es'

import {
  DoubleVerifyProvider,
  PlatformUnit,
  PlatformUnitChildType,
  ReportProvider,
  ReportProviderObject,
  ReportSeries,
} from '@percept/types'

import { isPlatformUnitContainer, coerceReportProvider, isVodafonePlatformUnit } from '@percept/utils'

import { providerDoubleVerifyMap, reportProviders } from '@percept/constants'

import { OrgNavigation } from './typings'


export const inventoryToProviders = (inventory: { provider: ReportProviderObject }[]): ReportProvider[] => {
  return inventory.map( i => coerceReportProvider(i.provider.slug))
}


export const discoverProviders = (platformUnit: PlatformUnit | null): ReportProvider[] => {
  if( !platformUnit ){
    return []
  }

  const providers: Set<ReportProvider> = new Set()

  const walk = (o: PlatformUnit): void => {
    if( isPlatformUnitContainer(o) ){
      if( o.performance_reporting && o.performance_reporting.enabled && o.performance_reporting.providers ){
        inventoryToProviders(o.performance_reporting.providers).forEach( provider => providers.add(provider) )
      }
      o.children && o.children.forEach(walk)
    }else{
      if( o.report_series ){
        inventoryToProviders((o.report_series || [])).forEach( provider => providers.add(provider) )
      }
    }
  }

  walk(platformUnit)

  return intersection(reportProviders, Array.from(providers))

}


export const discoverDoubleVerifyProviders = (platformUnit: PlatformUnit | null): DoubleVerifyProvider[] => {
  if( !platformUnit ){
    return []
  }

  const providers: Set<DoubleVerifyProvider> = new Set()

  const walk = (o: PlatformUnit): void => {
    if( o.performance_reporting && o.performance_reporting.enabled && o.performance_reporting.providers ){
      o.performance_reporting.providers.forEach( entry => {
        if( entry.augmentations && entry.augmentations.doubleverify ){
          providers.add(providerDoubleVerifyMap[entry.provider.slug])
        }
      })
    }
    o.children && o.children.forEach(walk)
  }

  walk(platformUnit)

  return intersection([
    'doubleverify_adwords',
    'doubleverify_facebook',
    'doubleverify_adform',
  ], Array.from(providers))

}


type PlatformUnitWithPaths = PlatformUnit<{
  path: number[]
}>


function visitPlatformUnit(platformUnit: PlatformUnit, path: number[]): PlatformUnitWithPaths {
  return {
    path,
    ...platformUnit,
    ...(isPlatformUnitContainer(platformUnit) && {
      children: platformUnit.children.map( (n, i) => visitPlatformUnit(n, [...path, i]) )
    } || {}),
  } as PlatformUnitWithPaths
}


const getPlatformUnitWithPaths = (platformUnit: PlatformUnit, initialPath: number[] = []): PlatformUnitWithPaths => (
  visitPlatformUnit(platformUnit, initialPath)
)


export const getPlatformUnitHierarchy = (
  rootPlatformUnits: PlatformUnit[] | null,
  activeId: string  | null
): OrgNavigation['hierarchy'] => {

  if( !rootPlatformUnits || !rootPlatformUnits.length ){
    return []
  }

  if( rootPlatformUnits.length == 1 && !isPlatformUnitContainer(rootPlatformUnits[0]) ){
    return rootPlatformUnits
  }

  if( !activeId ){
    return [rootPlatformUnits[0]]
  }

  let path: number[] = []

  const walk = (org: PlatformUnitWithPaths): void => {
    if( org.id === activeId ){
      path = org.path
      throw new Error('Not an error!')
    }else if( isPlatformUnitContainer(org) ){
      org.children.forEach(walk)
    }
  }

  try{
    rootPlatformUnits.map( (unit, i) => getPlatformUnitWithPaths(unit, [i]) ).forEach(walk)
    console.warn('No active id matched', activeId, rootPlatformUnits)
  }catch(e){
    noop()
  }

  const hierarchy: OrgNavigation['hierarchy'] = []

  const queue = path
  let activeCollection = rootPlatformUnits

  while( queue && queue.length ){
    /* `Array.shift()` returns `undefined` when called on empty array, but the `while` condition
     *  precludes this so we're safe to override the inferred type here */
    const org = activeCollection[queue.shift() as number]
    hierarchy.push({
      ...org,
      options: activeCollection.map( value => ({
        label: value.name,
        value,
      })),
    })
    if( isPlatformUnitContainer(org) ){
      activeCollection = org.children
    }
  }

  return hierarchy

}


export const matchToSeriesId = (
  report_series: PlatformUnit['report_series'],
  series_id: string | null
): ReportSeries | null => (
  series_id &&
    find(report_series, s => (
      String(s.id) === String(series_id)
    )) ||
    null
)


export const getOrgBySeriesId = (
  platformUnits: PlatformUnit[] | null,
  seriesId: string
): PlatformUnit | null => {

  if( !platformUnits ) return null

  let matchedOrg: PlatformUnit | null = null
  
  const walk = (org: PlatformUnit): void => {
    
    if( isPlatformUnitContainer(org) ){
      org.children.forEach(walk)
    }else if( matchToSeriesId(org.report_series, seriesId) ){
      matchedOrg = org
      throw new Error('Not an error!')
    }
  }

  try{
    platformUnits.forEach(walk)
  }catch(e){
    noop()
  }

  return matchedOrg
}


export const orgLabelDisplayMap: Record<
  PlatformUnitChildType, string
> = {
  BRAND: 'Brand',
  CLIENT: 'Client',
  MARKET: 'Market',
}


export const platformUnitHasInsights = (platformUnit: PlatformUnit | null): boolean => {
  return Boolean(
    platformUnit
    && !platformUnit.name.includes('Voda')
    && platformUnit.report_series
    && some(platformUnit.report_series, s => s.output_config.recommendations_report )
  )
}

export const platformUnitHasInsightReports = (platformUnit: PlatformUnit | null): boolean => {
  return Boolean(
    platformUnit
    && platformUnit.report_series
    && some(platformUnit.report_series, s => (
      some(Object.values(s.output_config.insights_reports || {}))
    ))
  )
}


export const getPotentialEfficiencyLabel = (platformUnit: PlatformUnit): string => {
  /* Currently, we need to change the UI label as per client request based
    on the active platform unit, as we are using the same application instance.
    Once we transfer Vodafone to using the new dedicated app instance, this
    can and should change to a build-time conditional based on the application name. */
  if( isVodafonePlatformUnit(platformUnit) ){
    return 'Wastage'
  }
  return 'Potential Efficiencies'
}
