/* eslint-disable indent */
import { Box, CircularProgress, Typography } from '@material-ui/core'
import { ParentSize } from '@visx/responsive'
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router'
import { AggregationToggles, SelectType } from '../AggregationToggles'
import { BarGroupChart, CHART_HEIGHT, MultiLineChart, StackedBarChart } from '../Charts'
import { ReportTable } from '../ReportTable'
import { TitleComponent } from '../TitleComponent'
import { useInvestmentReportData } from './useInvestmentReportData'
import { useCSVExport } from './useCSVExport'
import { useSelector } from 'react-redux'
import { StoreState } from '@percept/types'
import { Filters } from 'enums/Filters'
import { Loader, PlainTextButton } from '@percept/mui'
import { find } from 'lodash-es'
import { CloudDownload } from '@material-ui/icons'

export type Report = {
  title: string
  subTitle?: string
  selectOptions?: { value: string; label: string; chart?: string }[]
  chart?: string
  label?: string
  selectType?: SelectType
  marketSort?: boolean
  possibleNegativeValues?: boolean
  onlyPercentageValues?: boolean
  onlyYear?: boolean
}

export type PossibleFiltersOptions = {
  [key in Filters]?: string[]
}

export enum Summary {
  AllTotal = 'All total',
}

export const InvestmentReport = ({
  title,
  subTitle,
  selectOptions,
  chart = 'stacked-bar-chart',
  label,
  selectType,
  marketSort,
  possibleNegativeValues,
  onlyPercentageValues,
  onlyYear
}: Report): JSX.Element => {
  const [view, setView] = useState('table')
  const [dataFormat, setDataFormat] = useState(onlyPercentageValues ? 'percentage' : 'currency')
  const [periodFormat, setPeriodFormat] = useState('year')
  const initialScope = selectOptions && selectOptions[0].value
  const [scopeOfData, setScopeOfData] = useState<string | undefined>(initialScope)

  const { pathname } = useLocation()
  const lastPartUrl = pathname.split('/').pop()
  const difference = lastPartUrl === 'sos-trend-vs-largest-competitor'

  const list = useSelector((state: StoreState) => state.filters.filtersMedia)

  const filterOptions: PossibleFiltersOptions = list.reduce((result, el) => {
    return {
      ...result,
      [el.options.group_value]: el.options.options
        .map((option) => option.value)
        .filter((el) => el !== 'all'),
    }
  }, {})

  const noResultMessage = 'No results found. Maybe you should add some filters.'

  const { data, isLoading, isRefetching, exportParams } = useInvestmentReportData({
    url: lastPartUrl,
    title,
    scope: scopeOfData,
    periodFormat,
    filterOptions,
  })

  const {
    download,
    isLoading: isDownloading,
  } = useCSVExport()

  const marketList = useMemo(() => {
    return (
      data &&
      marketSort &&
      view === 'chart' &&
      data
        .map((el) => {
          return { value: el.row_group, label: el.row_group }
        })
        .filter((el) => el.value !== Summary.AllTotal)
    )
  }, [data, marketSort, view])

  const canonicalSelectOptions = marketList ? marketList : selectOptions
  const defaultScope = canonicalSelectOptions && canonicalSelectOptions[0].value

  useEffect(() => {
    if( canonicalSelectOptions ){
      const match = find(canonicalSelectOptions, o => o.value === scopeOfData )
      if( !match && scopeOfData !== defaultScope ){
        setScopeOfData(defaultScope)
      }
    }
  }, [canonicalSelectOptions, defaultScope, scopeOfData])

  const chartType = selectOptions?.find(el => el.value === scopeOfData)?.chart || chart

  const downloadButton = exportParams && (
    <PlainTextButton
      size='small'
      variant='contained'
      disabled={isDownloading}
      startIcon={
        isDownloading ?
          <CircularProgress size='1em' color='inherit' /> :
          <CloudDownload />
      }
      onClick={(): void => {
        exportParams && download({
          exportParams,
          filters: filterOptions,
          period: periodFormat as 'month' | 'quarter' | 'year',
        })
      }}>
      Download CSV
    </PlainTextButton>
  )

  return (
    <Box mt={5}>
      <TitleComponent
        titleText={title}
        subtitleText={subTitle}
        labelText={label}
        downloadButton={downloadButton}
      />
      <AggregationToggles
        view={view}
        selectOptions={canonicalSelectOptions}
        selectedValueSelect={scopeOfData}
        changeHandlerSelect={(e): void =>
          setScopeOfData(e.target.value as string)
        }
        setView={label ? undefined : setView}
        dataFormat={difference ? undefined : dataFormat}
        setDataFormat={onlyPercentageValues ? undefined : setDataFormat}
        periodFormat={periodFormat}
        setPeriodFormat={onlyYear ? undefined: setPeriodFormat}
        selectType={selectType}
      />
      <br />
      {view === 'table' ? (
        data && data.length > 0 && data[0].costs.length > 0 ? (
          <ReportTable
            data={data}
            dataFormat={dataFormat}
            showFetchIndicator={isLoading || isRefetching}
            differenceVariant={difference}
          />
        ) : isLoading ? (
          <Loader preset='centered' />
        ) : (
          <Typography>
           {noResultMessage}
          </Typography>
        )
      ) : (
        {
          'stacked-bar-chart':
            data && data.length > 0 ? (
              <ParentSize>
                {({ width }): JSX.Element => (
                  <StackedBarChart
                    dataFormat={dataFormat}
                    dataObject={
                      marketList
                        ? data.filter((el) => el.row_group === scopeOfData)
                            .length === 0
                          ? data.filter(
                              (el) =>
                                marketList &&
                                el.row_group === marketList[0].value
                            )
                          : data.filter((el) => el.row_group === scopeOfData)
                        : data
                    }
                    width={width}
                  />
                )}
              </ParentSize>
            ) : isLoading ? (
              <Loader preset='centered' height={CHART_HEIGHT} />
            ) : (
              <Typography>
                {noResultMessage}
              </Typography>
            ),
          'multiline-chart':
            data && data.length > 0 ? (
              <MultiLineChart
                dataFormat={dataFormat}
                data={data.filter((el) => el.row_group !== 'Difference')}
              />
            ) : isLoading ? (
              <Loader preset='centered' height={CHART_HEIGHT} />
            ) : (
              <Typography>
                {noResultMessage}
              </Typography>
            ),
          'bar-group-chart':
            data && data.length > 0 ? (
              <ParentSize>
                {({ width }): JSX.Element => (
                  <BarGroupChart
                    dataFormat={dataFormat}
                    dataObject={data}
                    width={width}
                    mirrorYDomain={possibleNegativeValues}
                  />
                )}
              </ParentSize>
            ) : isLoading ? (
              <Loader preset='centered' height={CHART_HEIGHT} />
            ) : (
              <Typography>
                {noResultMessage}
              </Typography>
            ),
        }[chartType]
      )}
    </Box>
  )
}
