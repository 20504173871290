import React from 'react'
import { BarGroup as BarGroupComponent } from '@visx/shape'
import { Group } from '@visx/group'
import { Grid } from '@visx/grid'
import { scaleBand, scaleLinear } from '@visx/scale'
import { useTooltip, useTooltipInPortal } from '@visx/tooltip'
import { Box, useAppTheme } from '@percept/mui'
import { LegendOrdinal } from '@visx/legend'
import { BarGroupBar } from '@visx/shape/lib/types'
import { joinGroupDataToOneArray, transformPlainData } from './transformData'
import { Tooltip } from './components/Tooltip'
import { Axis } from './components/Axis'
import { BarChartProps } from './type'
import { Summary } from '../DashboardLayout'
import { reorderColumns } from '../reorderColumns'
import { getMoneyFormatter, percentageFormatter } from '@percept/mui/charts'
import { AXIS_MARGIN_BOTTOM, CHART_HEIGHT } from './constants'
import { useChartClasses, visxLegendStyles } from './styles'

const defaultMargin = { top: 40, right: 20, bottom: 0, left: 70 }

let tooltipTimeout: number

export const BarGroupChart = ({
  dataObject,
  dataFormat,
  width,
  margin = defaultMargin,
  mirrorYDomain = false,
  valueFormatter,
}: BarChartProps & { mirrorYDomain?: boolean }): JSX.Element | null => {

  const height = CHART_HEIGHT
  
  const totalObj = dataObject.find((e) => e.row_group === Summary.AllTotal) ||
    (dataObject.length === 1 && dataObject[0]) || {
    row_group: '',
    data: [],
    costs: [],
    total: '',
    total_percentage: '',
    total_currency: '',
  }
  
  const data = transformPlainData(joinGroupDataToOneArray([totalObj]), dataFormat)
  const currency = dataObject[0].total_currency
  const formatter = valueFormatter || (
    dataFormat === 'currency' ?
      getMoneyFormatter(currency) :
      percentageFormatter
  )
  const groupsName = reorderColumns(dataObject.reduce((arr: string[], el) => {
    const newArr = el.costs.filter((item: { type_value: string }) => !arr.find(el => el===item.type_value)).map(el => el.type_value)
    return arr.concat(newArr)
  }, []))

  const getData = (el: Record<string, string>): string => el.column

  const chartClasses = useChartClasses()
  const appTheme = useAppTheme()
  const axisColor = appTheme.palette.text.primary

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<BarGroupBar<string>>()

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  })

  const xMax = width - margin.left - margin.right
  const yMax = height - margin.top - AXIS_MARGIN_BOTTOM

  const yMaxScale = data.reduce((max, el) => {
    groupsName.map((key) => {
      const target = Number(el[key])
      const number = target < 0 ? -1 * target : target
      return number > max ? (max = number) : max
    })
    return max
  }, 0)

  const xScale = scaleBand<string>({
    domain: data.map((object) => String(getData(object))),
    padding: 0.2,
    range: [0, xMax],
  })
  const groupScale = scaleBand<string>({
    domain: groupsName,
    padding: 0.1,
    range: [0, xScale.bandwidth()],
  })
  const yScale = scaleLinear<number>({
    domain: mirrorYDomain ? [-1 * yMaxScale, yMaxScale] : [0, yMaxScale],
    range: [yMax, 0],
    nice: true,
  })
  const colorScale = appTheme.chart.getOrdinalColourScale(groupsName)

  const zeroPoint = yScale(0)

  return width < 10 ? null : (
    <Box className={chartClasses.root}>
      <svg ref={containerRef} width={width} height={height}>
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          fill='transparent'
        />
        <Grid
          top={margin.top}
          left={margin.left}
          xScale={xScale}
          yScale={yScale}
          width={xMax}
          height={yMax}
          stroke={appTheme.chart.grid.stroke}
          strokeOpacity={0.75}
          strokeWidth={2}
        />
        <Group top={margin.top} left={margin.left}>
          <BarGroupComponent<Record<string, string>, string>
            data={data}
            keys={groupsName}
            x0={getData}
            x0Scale={xScale}
            x1Scale={groupScale}
            yScale={yScale}
            height={yMax}
            color={colorScale}>
            {(barGroups): JSX.Element[] =>
              barGroups.map((barGroup) => (
                <Group
                  key={`bar-group-${barGroup.index}-${barGroup.x0}`}
                  left={barGroup.x0}>
                  {barGroup.bars.map((bar) => !bar.value ? null : (
                    <rect
                      key={`bar-stack-${barGroup.index}-${bar.index}`}
                      x={bar.x}
                      y={
                        mirrorYDomain
                          ? bar.value <= 0
                            ? zeroPoint
                            : zeroPoint - bar.height + zeroPoint
                          : bar.y
                      }
                      height={
                        mirrorYDomain
                          ? bar.value <= 0
                            ? yScale(bar.value) - zeroPoint
                            : bar.height - zeroPoint
                          : bar.height
                      }
                      width={bar.width}
                      fill={bar.color}
                      opacity={0.9}
                      onMouseLeave={(): void => {
                        tooltipTimeout = window.setTimeout(() => {
                          hideTooltip()
                        }, 300)
                      }}
                      onMouseMove={(): void => {
                        if (tooltipTimeout) clearTimeout(tooltipTimeout)
                        const left = bar.x + bar.width * 2 + barGroup.x0
                        const top = bar.y + bar.height / 4
                        showTooltip({
                          tooltipData: bar,
                          tooltipTop: top,
                          tooltipLeft: left,
                        })
                      }}
                    />
                  ))}
                </Group>
              ))
            }
          </BarGroupComponent>
        </Group>
        <Axis
          margin={margin}
          yMax={yMax}
          xScale={xScale}
          yScale={yScale}
          color={axisColor}
          tickFormatter={formatter}
          maxWidth={(width-margin.left-margin.right)/data.length}
        />
      </svg>
      <Box className={chartClasses.legend}>
        <LegendOrdinal
          style={visxLegendStyles}
          scale={colorScale}
          direction='row'
          labelMargin='0 30px 0 0'
        />
      </Box>
      {tooltipOpen && tooltipData && (
        <Tooltip
          TooltipInPortal={TooltipInPortal}
          colorScale={colorScale}
          top={tooltipTop}
          left={tooltipLeft}
          value={Number(tooltipData.value)}
          label={tooltipData.key}
          valueFormatter={formatter}
        />
      )}
    </Box>
  )
}
