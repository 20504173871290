import React, { Fragment } from 'react'

import { ButtonPopover, Health, makeAppStyles, RoundedPlainTextButton } from '@percept/mui'

import { InsightsReportTree } from './InsightsReportTree'

import { ArrowDropDown } from '@percept/mui/icons'

import { InsightsReportTreeHierarchy } from './lib'

import { InsightsReportOutputUnitType } from '@percept/types'


const useStyles = makeAppStyles( theme => ({
  trigger: {
    transition: theme.transitions.create('color'),
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.dark,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  health: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(-1),
    height: '1em',
    position: 'relative',
    top: 0,
  }
}) )


export type InsightsReportTreePopoverProps = {
  insightsReportTree: InsightsReportTreeHierarchy
  activeOutputUnit: InsightsReportOutputUnitType & { id: string }
  setActiveOutputUnit: (unit: (InsightsReportOutputUnitType & { id: string }) | null) => void
  showSectionHealth?: boolean
}

export const InsightsReportTreePopover = ({
  insightsReportTree,
  activeOutputUnit,
  setActiveOutputUnit,
  showSectionHealth = false,
}: InsightsReportTreePopoverProps): JSX.Element => {

  const classes = useStyles()

  return (
    <ButtonPopover
      className={classes.trigger}
      variant='contained'
      color='secondary'
      size='small'
      endIcon={<ArrowDropDown />}
      buttonContent={activeOutputUnit && (
        <Fragment>
          { activeOutputUnit.text }
          { activeOutputUnit.health !== null && (
            <Health
              showBackground
              animate={false}
              className={classes.health}
              value={activeOutputUnit.health}
              fontSize='1.1em' />
          )}
        </Fragment>
      ) || 'Loading'}
      disabled={!activeOutputUnit}
      ButtonComponent={RoundedPlainTextButton}>
      { ({ onClose }): JSX.Element => {
        return (
          <InsightsReportTree
            insightsReportTree={insightsReportTree}
            activeIds={[activeOutputUnit.id]}
            showSectionHealth={showSectionHealth}
            onNodeClick={(node): void => {
              const { type, id } = node
              if( !id ){
                console.warn('Invalid node', node)
              }
              if( type === 'OUTPUT_UNIT' ){
                setActiveOutputUnit(node as unknown as InsightsReportOutputUnitType & { id: string })
              }else{
                setActiveOutputUnit(null)
              }
              onClose()
            }} />
        )
      }}
    </ButtonPopover>
  )
}
