import React, { Fragment } from 'react'

import {
  Box,
  ButtonPopover,
  ButtonPopoverProps,
  FormControlLabel,
  makeAppStyles,
  Radio,
  ReportRange,
  RoundedPlainTextButton,
  Typography,
} from '@percept/mui'

import { ArrowDropDown, CompareArrows } from '@percept/mui/icons'

import { subDays } from 'date-fns'

import { PerformanceComparisonRange } from '@percept/types'


export type PerformanceComparisonRangeSelectProps = {
  referenceDate: Date | null
  value: PerformanceComparisonRange
  onChange: (range: PerformanceComparisonRange) => void
} & Partial<Omit<ButtonPopoverProps, 'value' | 'onChange'>>


const useStyles = makeAppStyles( theme => ({
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  formControlLabel: {
    marginBottom: theme.spacing(1),
  },
  caption: {
    display: 'block',
    fontSize: 11,
    color: theme.palette.text.hint
  }
}))


const performanceComparisonRangeLabelMap: Record<PerformanceComparisonRange, string> = {
  d1: 'Compare Yesterday',
  d7: 'Compare Week on Week',
  d28: 'Compare Month on Month',
  d365: 'Compare Year on Year',
}


const performanceComparisonRangeOptions: PerformanceComparisonRange[] = [
  'd7', 'd28', 'd365',
]


export const derivePerformanceComparisonLabel = ({
  referenceDate,
  performanceComparisonRange,
  flexDirection = 'row'
}: {
  referenceDate: Date | number | string
  performanceComparisonRange: PerformanceComparisonRange
  flexDirection?: 'row' | 'column'
}): React.ReactNode => {

  const dayDelta = Number(performanceComparisonRange.replace('d', ''))

  const referenceEnd = new Date(referenceDate)
  const referenceStart = subDays(referenceEnd, dayDelta - 1)

  const benchmarkEnd = subDays(referenceStart, 1)
  const benchmarkStart = subDays(benchmarkEnd, dayDelta - 1)

  return (
    <Box display='flex' flexDirection={flexDirection} alignItems='center'>
      <ReportRange
        start={referenceStart}
        end={referenceEnd} />
      <Box my={0.25} mx={1}>
        vs.
      </Box>
      <ReportRange
        start={benchmarkStart}
        end={benchmarkEnd} />
    </Box>
  )

}


export const PerformanceComparisonRangeSelect = ({
  referenceDate,
  value,
  onChange,
  ...props
}: PerformanceComparisonRangeSelectProps): JSX.Element => {

  const classes = useStyles()

  return (
    <ButtonPopover
      ButtonComponent={RoundedPlainTextButton}
      buttonContent={performanceComparisonRangeLabelMap[value]}
      variant='contained'
      color='secondary'
      startIcon={<CompareArrows />}
      endIcon={<ArrowDropDown />}
      PopoverProps={{
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        elevation: 12,
      }}
      {...props}>

      { ({ onClose }): JSX.Element => (
        <Box p={2} pb={0}>

          <Typography
            className={classes.subtitle}
            variant='subtitle1'>
            Comparison Range
          </Typography>

          <Box
            display='flex'
            flexDirection='column'>

            { performanceComparisonRangeOptions.map( c => (
              <FormControlLabel
                key={c}
                className={classes.formControlLabel}
                label={
                  <Fragment>
                    { performanceComparisonRangeLabelMap[c] }
                    { referenceDate && (
                      <Typography
                        className={classes.caption}
                        variant='caption'>
                        { derivePerformanceComparisonLabel({
                          referenceDate,
                          performanceComparisonRange: c,
                        })}
                      </Typography>
                    )}
                  </Fragment>
                }
                checked={value === c}
                onChange={(e, checked): void => {
                  if( checked ){
                    onChange(c)
                  }
                }}
                control={<Radio />} />
            ))}
          </Box>

        </Box>
      )}

    </ButtonPopover>
  )
}
