import React, { useMemo } from 'react'

import {
  BackdropLoader,
  ProviderLogo,
} from '@percept/mui'

import Series from './Series'

import { PillarScores } from './PillarScores'

import { InsightsReport } from 'components/InsightsReport'

import {
  useReportSeries,
  useReportSeriesPillarScores,
  useReportSeriesReports,
} from '@percept/hooks'

import { some, sortBy } from 'lodash-es'

import { ReportSeries, SeriesParams, StructuralReport } from '@percept/types'


const SeriesWithInsights = ({ id, provider }: ReportSeries): JSX.Element => {

  const series_id = id

  const [pillarScores] = useReportSeriesPillarScores({ series_id })

  return (
    <InsightsReport
      key={series_id}
      series_id={id}
      title={
        <ProviderLogo
          size={1.75}
          units='rem'
          provider={provider.slug} />
      }
      insights_report_type='primary'
      showReportLink={true}
      gridInserts={
        pillarScores.data ? [
          <PillarScores
            key={series_id}
            provider={provider.slug}
            scores={pillarScores.data} />
        ] : undefined
      } />
  )
}


const hasPrimaryInsightReports = (
  series: ReportSeries,
  reports: StructuralReport[]
): boolean => {
  const latestReport = sortBy(reports.filter( r => !!r.results.length), 'end').reverse()[0]
  return Boolean(
    series.output_config.insights_reports
    && series.output_config.insights_reports.PRIMARY
    && latestReport
    && some(latestReport.results, res => res.result_type === 'INSIGHTS_REPORT' && res.result_subtype === 'PRIMARY' )
  )
}


export const SeriesViewManager = ({ series_id }: SeriesParams): JSX.Element => {

  const [series] = useReportSeries({ series_id })

  const [reports] = useReportSeriesReports({ series_id })

  const hasInsights = useMemo(() => {
    if( !(series.data && reports.data) ){
      return null
    }
    return hasPrimaryInsightReports(series.data, reports.data)
  }, [series.data, reports.data])

  if( !(series.data && reports.data) ){
    return <BackdropLoader />
  }
  if( hasInsights ){
    return <SeriesWithInsights {...series.data} />
  }
  return (
    <Series series_id={series_id} />
  )
}
