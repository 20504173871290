import React, { Fragment, useMemo } from 'react'

import {
  AppTheme,
  Box,
  Divider,
  GridProps,
  Health,
  makeAppStyles,
  MetricCard,
  MetricVisualisationWrap,
  SecondaryTooltip,
  SentimentDelta,
  Typography,
  useAppTheme,
} from '@percept/mui'

import {
  ChartAnnotation,
  ChartData,
  Domain,
  HealthGradient,
  longMonthYearFormatter,
  ResponsiveLine,
} from '@percept/mui/charts'

import { PercentageVisualisation } from './PercentageVisualisations'

import { every, get, intersection, last } from 'lodash-es'

import {
  channelPillars,
  getAveragePillarScoreAnnotations,
  getRoundedValue,
  pillarDescriptionMap,
} from './lib'

import { channelDisplayMap, pillarLabelMap } from '@percept/constants'

import { ChannelKey, MetricPillar, ReportProvider } from '@percept/types'


export type StructuralSummaryProps = {
  channel: ChannelKey
  provider: ReportProvider | null
  pillars: Record<MetricPillar, ChartData>
  overall: ChartData
}


const useStyles = makeAppStyles( theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  titleContent: {
    display: 'inline-flex',
    alignItems: 'center',
    flexBasis: '100%',
    width: 'calc(100% - 22px)',
  },
  health: {
    marginLeft: theme.spacing(1),
    height: '1em',
    fontSize: '1.25em',
    padding: 0,
    [theme.breakpoints.only('md')]: {
      fontSize: '1.1em',
    },
    minWidth: 'unset',
  },
  overallHealthContainer: {
    height: 28,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'right',
    marginLeft: 'auto',
  },
  overallHealthChart: {
    width: 116,
    [theme.breakpoints.up('sm')]: {
      width: 200,
    },
    [theme.breakpoints.up('md')]: {
      width: 52,
    },
    [theme.breakpoints.up('lg')]: {
      width: 136,
    },
    [theme.breakpoints.up('xl')]: {
      width: 200,
    },
  },
  sentimentDelta: {
    marginRight: theme.spacing(1),
    fontSize: 13,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
    justifyContent: 'space-between',
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  grid: {
    marginTop: theme.spacing(0.5),
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-end',
    marginTop: theme.spacing(-2),
  },
  headerTypography: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0, 0),
  },
  deltaTypography: {
    fontSize: 13,
  },
  labelTypography: {
    marginTop: theme.spacing(-1),
    display: 'flex',
    flexBasis: '100%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 11,
  },
}))


export const healthDomain: Domain = [0, 1]


export const healthFormatter = (value: number | null): string => (
  `${getRoundedValue((value || 0) * 100)}%`
)

const getLastDelta = (data: ChartData): number => {
  const len = data.length
  if( len < 2 ){
    return 0
  }
  const last = get(data[len - 1], 'value', null)
  const penultimate = get(data[len - 2], 'value', null)
  if( !(last !== null && penultimate !== null ) ){
    return 0
  }
  return last - penultimate
}


export const HealthDefs = ({
  appTheme,
  id = 'healthGradient',
}: {
  appTheme: AppTheme
  id?: string
}): JSX.Element => (
  <Fragment>

    <HealthGradient
      id={id}
      colourScale={appTheme.chart.healthColourScale}
      gradientTransform='rotate(0)' />

    <pattern id='stripePattern' patternUnits='userSpaceOnUse' width='8' height='8'>
      <rect
        height='100%'
        width='100%'
        fill={appTheme.palette.info.main} />
      <line
        stroke={appTheme.palette.info.dark}
        x1='0'
        y1='8'
        x2='8'
        y2='0' />
    </pattern>

  </Fragment>
)


export const withHealthColor = (data: ChartData, theme: AppTheme): ChartData => (
  data.map( d => ({
    ...d,
    color: theme.chart.healthColourScale(d.value || 0),
  }))
)


export const StructuralSummary = ({
  channel,
  provider,
  pillars,
  overall,
}: StructuralSummaryProps): JSX.Element => {

  const appTheme = useAppTheme()

  const fillOpacity = appTheme.palette.type === 'dark' ? 0.3 : 0.6

  const classes = useStyles()

  const mappablePillars = useMemo(() => {
    return intersection(channelPillars[channel], Object.keys(pillars) as MetricPillar[]).map( pillar => {

      const annotations = getAveragePillarScoreAnnotations({
        provider,
        pillar,
        appTheme,
        fill: 'url(#stripePattern)',
      })

      return {
        pillar,
        isNull: pillars[pillar].length === 0 || every(pillars[pillar], d => d.value === null ),
        data: withHealthColor(pillars[pillar], appTheme),
        annotations,
      } as {
        pillar: MetricPillar,
        isNull: boolean,
        data: ChartData,
        annotations: ChartAnnotation[] | undefined
      }
    })
  }, [channel, provider, pillars, appTheme])

  const overallData = useMemo(() => {
    if( !overall || !overall.length ){
      return null
    }
    return withHealthColor(overall, appTheme)
  }, [overall, appTheme])

  const gridItemProps: GridProps = {
    item: true,
    xs: channel === 'search' ? 4 : 3,
    alignItems: 'stretch',
    className: classes.gridItem,
  }

  const channelLabel = channelDisplayMap[channel].label

  const titleContent = (
    <Fragment>
      { channelLabel }
      { overallData && (
        <Fragment>

          { overallData.length > 1 && (
            <Health
              className={classes.health}
              value={get(last(overallData), 'value', null)} />
          )}

          <div
            className={classes.overallHealthContainer}>

            <SentimentDelta
              className={classes.sentimentDelta}
              value={getRoundedValue(getLastDelta(overallData) * 100)} />

            <div className={classes.overallHealthChart}>
              <ResponsiveLine
                height={28}
                defs={
                  <HealthDefs
                    id='titleGradient'
                    appTheme={appTheme} />
                }
                fillOverride='url(#titleGradient)'
                strokeOverride='url(#titleGradient)'
                roundXDomain={false}
                roundYDomain={false}
                verticalMargin={0}
                domain={healthDomain}
                yTickFormatter={healthFormatter}
                xTickFormatter={longMonthYearFormatter}
                axisLine={true}
                fillOpacity={fillOpacity}
                xScaleType='time'
                data={overallData} />
            </div>
           
          </div>

        </Fragment>
      )}
    </Fragment>
  )

  return (
    <MetricCard
      color={channel}
      title={
        <span className={classes.titleContent}>
          {titleContent}
        </span>
      }
      description={
        <Fragment>
          Average monthly health scores from all {channelLabel} reports split by category, including up to the last 12 months where available.
          <br />
          <br />
          Percentage change is indicated across the 2 most recent health scores for each category where available.
          <br />
          <br />
          The shaded area shows the boundary of the 25th and 75th average score percentiles.
        </Fragment>
      }>
      
      <Box mb={2} mx={1} width='100%'>
        <Divider orientation='horizontal' />
      </Box>

      { mappablePillars.map( ({ pillar, data, annotations, isNull }) => {
        return (
          <MetricVisualisationWrap
            key={pillar}
            label={
              <Typography
                variant='subtitle1'
                className={classes.labelTypography}>
                <SecondaryTooltip
                  placement='bottom-start'
                  title={pillarDescriptionMap[pillar]}>
                  <span>
                    { pillarLabelMap[pillar] }
                  </span>
                </SecondaryTooltip>
              </Typography>
            }
            button={false}
            GridProps={gridItemProps}>
            { (!isNull && data.length > 1) && (
              <Typography
                variant='h6'
                align='center'
                className={classes.deltaTypography}>
                <SentimentDelta
                  value={getRoundedValue(getLastDelta(data) * 100)} />
              </Typography>
            )}
            { isNull ? (
              <Box
                color='text.disabled'
                display='flex'
                alignItems='center'
                justifyContent='center'
                height={66}
                fontSize={16}
                fontWeight={700}>
                N / A
              </Box>
            ) : data.length === 1 ? (
              <Box my={0.5}>
                <PercentageVisualisation
                  value={getRoundedValue((data[0].value || 0) * 100)}
                  height={56}
                  width={56}
                  fontSize={12} />
              </Box>
            ): (
              <ResponsiveLine
                height={48}
                defs={
                  <HealthDefs
                    id='mainGradient'
                    appTheme={appTheme} />
                }
                annotations={annotations}
                fillOverride='url(#mainGradient)'
                strokeOverride='url(#mainGradient)'
                roundXDomain={false}
                roundYDomain={false}
                verticalMargin={0}
                domain={healthDomain}
                yTickFormatter={healthFormatter}
                xTickFormatter={longMonthYearFormatter}
                axisLine={true}
                fillOpacity={fillOpacity}
                xScaleType='time'
                data={data} />
            )}
          </MetricVisualisationWrap>
        )
      })}
    </MetricCard>
  )

}
