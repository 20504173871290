import React from 'react'

import { Alert, Box, Button, Typography } from '@percept/mui'

import { Sync } from '@percept/mui/icons'

import { DefaultLayout } from './DefaultLayout'


export const ErrorScreen = (): JSX.Element => {
  return (
    <DefaultLayout>

      <Box p={6} display='flex' justifyContent='center'>

        <Alert
          variant='error'
          header={
            <Typography variant='h4'>
              {'Sorry, Something\'s Broken'}
            </Typography>
          }
          maxWidth='40rem'
          p={3}>

          <Box mt={4}>

            <Typography variant='h5'>
              Please try reloading the app.
            </Typography>
          </Box>

          <Box mt={3} fontSize={14}>
            {'We\'ve made a note of what\'s happened and we\'re looking into it. If this keeps happening, please get in touch.'}
          </Box>

          <Box mt={5} display='flex' justifyContent='center' flexGrow={1}>
            <Button
              size='large'
              variant='contained'
              startIcon={
                <Sync />
              }
              onClick={(): void => {
                window.location.reload()
              }}>
              Reload App
            </Button>
          </Box>

        </Alert>

      </Box>

    </DefaultLayout>
  )
}

export default ErrorScreen
