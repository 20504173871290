import React from 'react'

import {
  Card,
  CardProps,
  Grid,
  GridSpacing,
  useAppTheme,
  CardContent,
  CardStrip,
  Chip,
  Box,
  ReportRange,
} from '@percept/mui'

import {
  LineProps,
  Domain,
  HealthGradient,
  ResponsiveLine,
  longDayMonthYearFormatter,
} from '@percept/mui/charts'

import { useSeriesHealth } from './useSeriesHealth'

import { useSeriesDatasets } from './useSeriesDatasets'

import { StructuralReport } from '@percept/types'

import { TimelineDatum } from 'types'


export type SeriesHistoryProps = {
  reports: StructuralReport[]
  gridSpacing?: GridSpacing
} & CardProps


const lineProps: Omit<LineProps<TimelineDatum>, 'data' | 'width'> = {
  height: 85,
  grid: 'rows',
  axisLine: true,
  axisText: true,
  /**
   * NOTE - `verticalMargin` is set to 0 despite including axis text
   * Currently this is required as the HealthGradient is scaled to
   * the parent svg
   */
  verticalMargin: 0,
  xScaleType: 'time',
  numYTicks: 5,
  fillOpacity: 0.25,
  arbitraryTimeline: true,
  xTickFormatter: longDayMonthYearFormatter,
  // eslint-disable-next-line react/display-name
  tooltipLabelDatumFormatter: (d) => (
    <ReportRange
      start={d.start}
      end={d.end}
      format='dd/MM/yy' />
  )
}


const healthDomain: Domain = [0, 1]

const healthFormatter = (value: number | null): string => (
  `${Math.round((value || 0) * 100)}%`
)


export const SeriesHistory = ({ reports, ...props }: SeriesHistoryProps): JSX.Element => {

  const appTheme = useAppTheme()

  const seriesDatasets = useSeriesDatasets({
    reports,
    appTheme,
  })

  const healthDataset = useSeriesHealth({
    reports,
    appTheme,
  })

  return (
    <Card
      {...props}>

      <CardStrip color='secondary' />

      <CardContent>

        <Grid container spacing={5}>

          <Grid item xs={12}>
            <Box mb={2}>
              <Chip
                size='small'
                color='secondary'
                label='Health' />
            </Box>

            <Box
              ml={0.5}
              mr={1.5}>
              <ResponsiveLine
                {...lineProps}
                defs={
                  <HealthGradient
                    id='healthGradient'
                    colourScale={appTheme.chart.healthColourScale}
                    gradientTransform='rotate(0)' />
                }
                fillOpacity={0.35}
                fillOverride='url(#healthGradient)'
                strokeOverride='url(#healthGradient)'
                domain={healthDomain}
                yTickFormatter={healthFormatter}
                data={healthDataset} />
            </Box>
          </Grid>

          { seriesDatasets.map( ({ dimension, data, color, label, formatter }) => {
            return (
              <Grid key={dimension} item xs={12}>

                <Box
                  mb={2}>
                  <Chip
                    size='small'
                    color='secondary'
                    label={label} />
                </Box>

                <Box
                  ml={0.5}
                  mr={1.5}>
                  <ResponsiveLine
                    key={dimension}
                    {...lineProps}
                    yTickFormatter={formatter}
                    fill={color}
                    data={data} />
                </Box>

              </Grid>
            )
          })}

        </Grid>
      </CardContent>

    </Card>
  )
}
