import React, { Fragment } from 'react'

import { Box, BoxProps, PerceptAppBar, PerceptAppBarProps } from '@percept/mui'

import { HomeButton } from 'components/HomeButton'

import { UserPopover } from '@percept/app-components'


export type DefaultLayoutProps = React.PropsWithChildren<{
  AppBarProps?: Partial<PerceptAppBarProps>
}>

const flexProps: BoxProps = {
  display: 'flex',
  alignItems: 'center',
}

export const PlainLayout = ({
  children,
  AppBarProps = {},
}: DefaultLayoutProps): JSX.Element => {
  return (
    <Fragment>
      <PerceptAppBar
        position='fixed'
        startIcon={
          <Box {...flexProps}>
            <HomeButton />
          </Box>
        }
        {...AppBarProps}>
        {AppBarProps.children}
        <Box {...flexProps} marginLeft='auto'>
          <UserPopover />
        </Box>
      </PerceptAppBar>
      <Box pt={11}>{children}</Box>
    </Fragment>
  )
}
