

import React from 'react'

import { useSpring, OpaqueInterpolation } from 'react-spring'

import { Donut, DonutProps } from '../../charts/Donut'

import { AnimatedPercentage } from './AnimatedPercentage'

import { makeAppStyles, useAppTheme } from '../../themes'

import { getPercentageDataset, PercentageGaugeTypeProps } from './lib'

import { DatumType } from '@percept/types'


const useStyles = makeAppStyles({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: 1,
  },
  valueWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
})


export type PercentageDonutProps<T extends DatumType = DatumType> = (
  PercentageGaugeTypeProps &
  Omit<
    DonutProps<T>,
    'data'
  > &
  Pick<
    React.CSSProperties,
    'fontSize'
  > & {
    textColor?: 'gauge' | 'inherit'
  }
)


export function PercentageDonut<T extends DatumType = DatumType>({
  value,
  color = '',
  textColor = 'inherit',
  fontSize = '1em',
  trackColor = 'rgba(0,0,0,0)',
  formatter,
  isHealthPercentage = false,
  invert = false,
  ...props
}: PercentageDonutProps<T>): JSX.Element {

  const originalValue = value

  const numberValue: number = value || 0

  const appTheme = useAppTheme()

  const dataset = getPercentageDataset<T>({
    value: numberValue,
    invert,
    color,
    trackColor,
    isHealthPercentage,
    appTheme,
  })

  const animatedProps = useSpring({
    value: numberValue,
    color: dataset.color,
    to: { value: numberValue, color: dataset.color }
  })

  const classes = useStyles()

  return (
    <div
      className={classes.root}>

      <Donut
        data={dataset.data}
        padAngle={0}
        {...props} />

      <div className={classes.valueWrapper}>

        { originalValue !== null && (
          <AnimatedPercentage
            fontSize={fontSize}
            value={animatedProps.value as OpaqueInterpolation<number>}
            color={textColor === 'gauge' ? animatedProps.color : textColor}
            formatter={formatter} />
        )}

      </div>

    </div>
  )
}
