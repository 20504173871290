import React from 'react'

import { CircularProgress, PlainTextButton } from '@percept/mui'

import { LockOpen, ErrorOutline } from '@percept/mui/icons'

import { useSingleSignOnMetadata } from '@percept/hooks'

import { getSingleSignOnLink } from './lib'


export const SingleSignOn = (): JSX.Element => {

  const [{data, error}] = useSingleSignOnMetadata()

  const singleSignOnUrl = data && getSingleSignOnLink(data)

  return (
    <PlainTextButton
      variant='contained'
      color='primary'
      size='large'
      disabled={!data}
      startIcon={
        error ?
          <ErrorOutline /> :
          (!data) ?
            <CircularProgress size='1em' color='inherit' /> :
            <LockOpen />
      }
      onClick={(): void => {
        if( singleSignOnUrl ){
          window.location.href = singleSignOnUrl
        }
      }}>
      { error ? 'Single Sign On Unavailable' : 'Log in with Vodafone'}
    </PlainTextButton>
  )
}
