
import React from 'react'

import { Alert, Box, Typography } from '@percept/mui'

import * as Buttons from './Buttons'
import * as Inputs from './Inputs'

import { useCodeChallengeResponse } from '@percept/hooks'

import { isValidCode } from './utils'

import { AuthComponentProps } from './lib'


const ConfirmSignIn = ({
  mfaChallenge,
  inputs,
  updateInputs,
  dispatch,
  classes,
  AuthInputProps = {},
}: AuthComponentProps): JSX.Element => {

  const [codeResponse, respondToChallenge] = useCodeChallengeResponse()

  const { error } = codeResponse

  const { code } = inputs

  return (
    <form
      onSubmit={(e): void => {
        e.preventDefault()
      }}>
      
      <Typography
        className={classes.heading}
        variant='h3'>
        Confirm Code
      </Typography>

      { !!mfaChallenge && !error && (
        <Alert
          my={3}
          variant='info'
          message={`Please enter the verification code sent to your device '${mfaChallenge.device_name}'`} />
      )}

      { !!error && (
        <Alert
          my={3}
          variant='error'
          {...error} />
      )}

      <Inputs.Code
        BoxProps={{
          mb: 5,
        }}
        {...AuthInputProps}
        autoFocus
        value={code || ''}
        error={!!error}
        onChange={(e): void => {
          const updatedCode = e.target.value
          // Detect autofill from e.g browser extensions and schedule autologin
          if( mfaChallenge && (!code || code.length === 0) && updatedCode.length === 6 ){
            setTimeout(() => {
              respondToChallenge({
                challenge_type: 'software_token_mfa',
                ...mfaChallenge,
                mfa_code: updatedCode,
              })
            }, 250)
          }
          updateInputs({ code: updatedCode })
        }} />

      <Buttons.Confirm
        type='submit'
        disabled={!isValidCode(code) || codeResponse.loading}
        onClick={(): void => {
          if( mfaChallenge && isValidCode(code) ){
            respondToChallenge({
              challenge_type: 'software_token_mfa',
              ...mfaChallenge,
              mfa_code: code,
            })
          }
        }} />

      <Box mt={3}>
        <Buttons.BackToSignIn
          onClick={(): void => dispatch({ type: 'SIGN_IN' })} />
      </Box>

    </form>
  )

}

export default ConfirmSignIn