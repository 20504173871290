import React from 'react'

import { Link } from 'react-router-dom'

import { Box, MassiveRoundedPlainTextButton, Typography } from '@percept/mui'

import { LockOpen } from '@percept/mui/icons'

import {
  mainBodyTextBoxProps,
} from './lib'


export const BrandtechWelcomeContent = ({
  signInRoute
}: {
  signInRoute: string
}): JSX.Element => {
  return (
    <Box maxWidth='75em' margin='auto'>
      <Box mb={5}>
        <Typography variant='h1'>
          Always On Ad Insights
        </Typography>
      </Box>

      <Box mb={3} {...mainBodyTextBoxProps}>
        Are you getting the most for your digital media spend?
      </Box>

      <Box mb={3} {...mainBodyTextBoxProps}>
        How do you find out if your digital media budget is being spent well?
      </Box>

      <Box mb={3} {...mainBodyTextBoxProps}>
        Performance data doesn’t tell the whole story - it doesn’t tell you how you could get more for your money.
      </Box>

      <Box mb={3} {...mainBodyTextBoxProps}>
        Our technology platform and reports provide specific actions to get more from media spend - find out how well your online advertising setup matches up to industry best practices, get actionable insights to help you increase your digital media performance.
      </Box>

      <Box my={7} display='flex' flexBasis='100%' justifyContent='center'>
        <Link to={signInRoute}>
          <MassiveRoundedPlainTextButton
            variant='contained'
            color='secondary'
            startIcon={
              <LockOpen />
            }>
            Sign In
          </MassiveRoundedPlainTextButton>
        </Link>
      </Box>
    </Box>
  )
}