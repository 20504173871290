
import adminUsers, { makeBundle as makeAdminUsersBundle } from './adminUsers'
import annotations, { makeBundle as makeAnnotationsBundle } from './annotations'
import audits, { makeBundle as makeAuditsBundle } from './audits'
import clientReports, { makeBundle as makeClientReportsBundle } from './clientReports'
import clientSeries, { makeBundle as makeClientSeriesBundle } from './clientSeries'
import jsonSchemas, { makeBundle as makeJsonSchemasBundle } from './jsonSchemas'
import layouts, { makeBundle as makeLayoutsBundle } from './layouts'
import metricMetadata, { makeBundle as makeMetricMetadataBundle } from './metricMetadata'
import notifications, { makeBundle as makeNotificationsBundle, NotificationBundle, notificationsWithFetchErrors } from './notifications'
import performanceReporting, { makeBundle as makePerformanceReportingBundle } from './performanceReporting'
import platformUnits, { makeBundle as makePlatformUnitsBundle } from './platformUnits'
import structuralReporting, { makeBundle as makeStructuralReportingBundle } from './structuralReporting'
import seriesGroups, { makeBundle as makeSeriesGroupsBundle } from './seriesGroups'
import series, { makeBundle as makeSeriesBundle } from './series'
import token, { makeBundle as makeTokenBundle } from './token'
import user, { makeBundle as makeUserBundle } from './user'


export {
  adminUsers,
  annotations,
  audits,
  clientReports,
  clientSeries,
  jsonSchemas,
  layouts,
  metricMetadata,
  notifications,
  notificationsWithFetchErrors,
  performanceReporting,
  platformUnits,
  series,
  seriesGroups,
  structuralReporting,
  token,
  user,
}

type BundleKey = (
  'adminUsers' | 'annotations' | 'audits' | 'clientReports' | 'clientSeries' | 'jsonSchemas' | 'layouts' | 'metricMetadata' |
  'notifications' | 'notificationsWithFetchErrors' | 'performanceReporting' | 'platformUnits' |
  'series' | 'seriesGroups' | 'token' | 'user'
)

const bundles = {
  adminUsers,
  annotations,
  audits,
  clientReports,
  clientSeries,
  jsonSchemas,
  layouts,
  metricMetadata,
  notifications,
  notificationsWithFetchErrors,
  performanceReporting,
  platformUnits,
  series,
  seriesGroups,
  structuralReporting,
  token,
  user,
}

type Bundles = typeof bundles

const bundlers = {
  adminUsers: makeAdminUsersBundle,
  annotations: makeAnnotationsBundle,
  audits: makeAuditsBundle,
  clientReports: makeClientReportsBundle,
  clientSeries: makeClientSeriesBundle,
  jsonSchemas: makeJsonSchemasBundle,
  layouts: makeLayoutsBundle,
  metricMetadata: makeMetricMetadataBundle,
  notifications: makeNotificationsBundle,
  notificationsWithFetchErrors: (): NotificationBundle => notificationsWithFetchErrors,
  performanceReporting: makePerformanceReportingBundle,
  platformUnits: makePlatformUnitsBundle,
  series: makeSeriesBundle,
  seriesGroups: makeSeriesGroupsBundle,
  structuralReporting: makeStructuralReportingBundle,
  token: makeTokenBundle,
  user: makeUserBundle,
}

type Bundlers = typeof bundlers


export const getBundle = <T extends BundleKey>(bundle: T, options = {}): Bundles[T] => {
  if( typeof bundlers[bundle] !== 'function' ){
    throw new Error(`Unrecognised bundle "${bundle}" requested`)
  }
  return bundlers[bundle](options) as Bundles[T]
}


export default bundles
