import { OptionType } from './typings'
import { FiltersList } from 'redux/ReportingDashboard/reducers/filtersReducer'

export const checkOption = (
  list: FiltersList,
  filterName: string,
  option: OptionType[] | OptionType,
  groupValue: string
): FiltersList => {

  const optionList = Array.isArray(option) ? option : [option]
  let result: FiltersList = list

  for (let i = 0; i < optionList.length; i++){
    result = result.map((filter) => {
      return filter.filterValue === filterName
        ? filter.options.group_value === groupValue
          ? filter.options.options.find((el) => el.value === optionList[i].value)
            ? optionList[i].value === 'all'
              ? {
                ...filter,
                options: {
                  ...filter.options,
                  options: [],
                },
              }
              : {
                ...filter,
                options: {
                  ...filter.options,
                  options: filter.options.options.filter(
                    (el) => el.value !== optionList[i].value && el.value !== 'all'
                  ),
                },
              }
            : {
              ...filter,
              options: {
                ...filter.options,
                options: [...filter.options.options, optionList[i]],
              },
            }
          : {
            ...filter,
            options: {
              group_value: groupValue,
              options: [optionList[i]],
            },
          }
        : filter
    }) 
  }

  return result
}

export const checkFilter = (
  list: FiltersList,
  checkFilter: string
): FiltersList => {
  return list.some((e) => e.filterValue === checkFilter)
    ? list.filter((filter) => filter.filterValue !== checkFilter)
    : list.concat({
      filterValue: checkFilter,
      options: { group_value: '', options: [] },
    })
}
