import React, { Fragment } from 'react'

import { Link } from 'react-router-dom'

import {
  Box,
  BoxProps,
  Divider,
  RoundedPlainTextButtonMenu,
  Typography,
  useChannelBackgroundStyles,
  // useChannelColorStyles,
  makeAppStyles,
  ButtonProps,
  SeriesSelect,
  ProviderLogo,
  MenuOption,
  RoundedPlainTextButton,
  Tabs,
  Tab,
} from '@percept/mui'

import { ArrowDropDown, ArrowForward, Flare, Speed, TableChart } from '@percept/mui/icons'

import { useNavigation } from '@percept/hooks'

import { useDashboardPageTitle, useDerivedSeriesListing } from 'hooks'

import { useOrgNavigation } from '../Organisation'

import { capitalize, find, some } from 'lodash-es'

import { providerChannelMap, providerLabelMap, providerURLMap } from '@percept/constants'

import { PlatformUnit, PlatformUnitLeaf, ReportProvider } from '@percept/types'
import { PlatformUnitCrumbs } from 'components/PlatformUnitCrumbs'
import { PlatformUnitTabs } from 'components/PlatformUnitTabs'


const { PERCEPT_ENV } = process.env

const isDev = PERCEPT_ENV === 'dev'


const areOrgsEqual = (
  a: Pick<PlatformUnit, 'id'> | null,
  b: Pick<PlatformUnit, 'id'> | null
): boolean => {
  return Boolean(
    a && b &&
    a.id === b.id
  )
}


const useStyles = makeAppStyles( theme => ({
  header: {
    transition: theme.transitions.create('color'),
    display: 'flex',
    alignItems: 'center',
  },
  menu: {
    minWidth: theme.spacing(15),
    marginRight: theme.spacing(2),
    justifyContent: 'space-between',
    '&:last-child': {
      marginRight: 0,
    },
    transition: theme.transitions.create('color'),
  },
  toggleLink: {
    marginRight: theme.spacing(3),
  },
  divider: {
    marginRight: theme.spacing(3),
  },
  orgLink: {
    color: theme.palette.primary.main,
  },
  orgLinkSeparator: {
    color: theme.palette.action.disabled,
    margin: theme.spacing(0, 1.5),
  }
}) )


const useTabClasses = makeAppStyles( theme => ({
  root: {
    fontSize: 13,
    textTransform: 'none',
    fontWeight: 700,
    minHeight: 42,
  },
}))


export type PageHeaderProps = {
  header?: React.ReactNode
  secondaryContent?: React.ReactNode
} & BoxProps


export const PageHeader = ({ header, secondaryContent, ...props }: PageHeaderProps): JSX.Element | null => {

  const {
    rootPlatformUnit,
    activePlatformUnit,
    activeSeries,
    activeProvider,
    providers,
    hierarchy,
    platformUnitOptions,
    siblingPlatformUnitOptions,
    childPlatformUnitLabel,
    activeChannel,
    hasInsightReports,
    isInsightsView,
    isInsightsReportView,
    hasPerformanceReporting,
    isPerformanceView,
    isPerformanceReportView,
    isChannelView,
    loading,
    ready,
  }= useOrgNavigation()

  useDashboardPageTitle({
    paths: [
      ...hierarchy.map( ({ name }) => name ),
      ...(isPerformanceView && [
        'Performance'
      ] || []),
      ...(isPerformanceReportView && [
        'Custom Performance Report'
      ] || []),
      ...(activeChannel && [
        capitalize(activeChannel),
      ] || []),
      ...(activeProvider && [
        providerLabelMap[activeProvider],
      ] || []),
      ...(isInsightsReportView && [
        'Video'
      ] || [])
    ],
  })

  const isOverview = !some([
    activeSeries,
    isPerformanceReportView,
    // Single provider channel views constitute an 'overview' in relative terms
    isChannelView && providers.length > 1,
    isInsightsView,
    isInsightsReportView,
  ])

  const hasOverview = (
    hasPerformanceReporting ||
    platformUnitOptions.length > 0
  )

  const hasStructuralReporting = some(
    activePlatformUnit && activePlatformUnit.report_series,
    s => s.enabled
  )

  const hasTabBar = (
    !activeSeries && (
      isPerformanceReportView ||
      isChannelView ||
      isInsightsView
    )
  )

  const seriesListing = (
    activePlatformUnit && (
      ((activePlatformUnit as PlatformUnitLeaf).report_series || [])
    ) || []
  )

  const navigate = useNavigation()

  const classes = useStyles()

  // const channelClasses = useChannelColorStyles()

  const channelBackgroundClasses = useChannelBackgroundStyles()

  const tabClasses = useTabClasses()

  const triggerProps: Partial<ButtonProps> = {
    disabled: loading,
    variant: 'contained',
    className: classes.menu,
    endIcon: <ArrowDropDown />,
    size: 'medium',
  }

  const activeOrgId = activePlatformUnit && activePlatformUnit.id
  const rootOrgId = rootPlatformUnit && rootPlatformUnit.id

  const isOrgSibling = rootOrgId && activeOrgId && rootOrgId !== activeOrgId

  return null

  // return (
  //   <Fragment>

  //     <PlatformUnitTabs 
  //       mb={4}
  //       mt={6}
  //       minHeight={32}/>

  //     <Box
  //       display='none'
  //       alignItems='center'
  //       mb={4}
  //       minHeight={40}
  //       {...props}>

  //       {/* <Typography
  //         className={classes.header}
  //         variant='h3'>

  //         <PlatformUnitCrumbs
  //           activePlatformUnit={activePlatformUnit}
  //           loading={loading}
  //           platformUnitOptions={platformUnitOptions}
  //           childPlatformUnitLabel={childPlatformUnitLabel}
  //           hierarchy={hierarchy}
  //           ButtonProps={triggerProps} />

  //       </Typography> */}

  //       { secondaryContent && (
  //         <Box ml={2}>
  //           { secondaryContent }
  //         </Box>
  //       ) }

  //       { ready && (
  //         <Box
  //           ml='auto'
  //           display='flex'
  //           alignItems='center'>

  //           {/* { hasInsightReports && activePlatformUnit && !isDev && (
  //             <Fragment>
  //               <Link
  //                 className={classes.toggleLink}
  //                 to={`/dashboards/${activePlatformUnit.id}${isInsightsView ? '' : '/insights'}`}>
  //                 <RoundedPlainTextButton
  //                   variant='contained'
  //                   color='primary'
  //                   startIcon={
  //                     isInsightsView ?
  //                       <Speed /> :
  //                       <Flare />
  //                   }>
  //                   Go To {isInsightsView ? 'Performance' : 'Insights'}
  //                 </RoundedPlainTextButton>
  //               </Link>
  //               <Divider
  //                 className={classes.divider}
  //                 orientation='vertical'
  //                 flexItem />
  //             </Fragment>
  //           )} */}

  //           {/* { !activeSeries && (
  //             <Fragment>

  //               { activePlatformUnit && isDev && (
  //                 <RoundedPlainTextButtonMenu
  //                   // label={orgMenuLabel}
  //                   value={
  //                     isInsightsView ?
  //                       'Insights' :
  //                       isPerformanceReportView ?
  //                         'Performance Report' :
  //                         'Overview'
  //                   }
  //                   TriggerProps={{
  //                     ...triggerProps,
  //                     color: 'primary',
  //                     endIcon: <ArrowDropDown />,
  //                     startIcon: (
  //                       isInsightsView ?
  //                         <Flare /> :
  //                         isPerformanceReportView ?
  //                           <TableChart /> :
  //                           <Speed />
  //                     ),
  //                   }}
  //                   options={[
  //                     { value: 'Overview' },
  //                     { value: 'Performance Report' },
  //                     ...(hasInsightReports && [
  //                       { value: 'Insights' } 
  //                     ] || []),
  //                   ]}
  //                   onChange={(e, value: string): void => {
  //                     switch(value){
  //                       case 'Overview':
  //                         navigate(`/dashboards/${activePlatformUnit.id}`)
  //                         break
  //                       case 'Performance Report':
  //                         navigate(`/dashboards/${activePlatformUnit.id}/performance-report`)
  //                         break
  //                       case 'Insights':
  //                         navigate(`/dashboards/${activePlatformUnit.id}/insights`)
  //                         break
  //                       default:
  //                         break
  //                     }
  //                   }} />
  //               )}

  //             </Fragment>            
  //           )} */}

  //           {/* { (siblingPlatformUnitOptions.length > 0) && (
  //             <RoundedPlainTextButtonMenu
  //               label={activePlatformUnit && activePlatformUnit.name}
  //               value={isOrgSibling ? activePlatformUnit : null}
  //               TriggerProps={{
  //                 ...triggerProps,
  //                 color: activePlatformUnit && isOrgSibling ? 'secondary' : 'default',
  //               }}
  //               isEqual={areOrgsEqual}
  //               options={siblingPlatformUnitOptions}
  //               onChange={(e, platformUnit): void => {
  //                 const typedPlatformUnit = platformUnit as PlatformUnit | null
  //                 if( typedPlatformUnit ){
  //                   navigate(`/dashboards/${typedPlatformUnit.id}`)
  //                 }
  //               }} />
  //           )}

  //           { (platformUnitOptions.length > 0) && (
  //             <RoundedPlainTextButtonMenu
  //               label={`Select ${childPlatformUnitLabel || 'Activity'}`}
  //               value={null}
  //               TriggerProps={{
  //                 ...triggerProps,
  //                 color: 'default',
  //               }}
  //               isEqual={areOrgsEqual}
  //               options={platformUnitOptions}
  //               onChange={(e, platformUnit): void => {
  //                 const typedPlatformUnit = platformUnit as PlatformUnit | null
  //                 if( typedPlatformUnit ){
  //                   navigate(`/dashboards/${typedPlatformUnit.id}`)
  //                 }
  //               }} />
  //           )} */}

  //           {/* Show top-level provider selector for performance reporting enabled units */}
  //           {/* { (hasPerformanceReporting && providers.length > 1) && (
  //             <RoundedPlainTextButtonMenu
  //               label={
  //                 activeProvider ? (
  //                   <ProviderLogo size={1.75} provider={activeProvider} />
  //                 ) : (
  //                   'Select Provider'
  //                 )
  //               }
  //               value={activeProvider}
  //               TriggerProps={{
  //                 ...triggerProps,
  //                 color: activeProvider ? 'inherit' : 'default',
  //                 className: [
  //                   classes.menu,
  //                   activeProvider && channelBackgroundClasses[providerChannelMap[activeProvider]],
  //                 ].filter(Boolean).join(' '),
  //               }}
  //               options={
  //                 providers.map( provider => {
  //                   return {
  //                     value: provider,
  //                     label: (
  //                       <ProviderLogo size={1.75} provider={provider} />
  //                     ),
  //                   }
  //                 }) as MenuOption<ReportProvider>[]
  //               }
  //               onChange={(e, provider: ReportProvider | null): void => {
  //                 if( provider && activeOrgId ){
  //                   navigate(`/dashboards/${activeOrgId}/${providerURLMap[provider]}`)
  //                 }
  //               }} />
  //           )} */}

  //           {/* Show series selector for nested channel series selection, or units without performance reporting */}
  //           {/* { !!((isChannelView || !hasPerformanceReporting) && seriesListing.data && seriesListing.data.length) && (
  //             <SeriesSelect
  //               value={activeSeries || undefined}
  //               placeholder='View Reports'
  //               channelFilter={hasPerformanceReporting && activeChannel || undefined}
  //               MenuComponent={RoundedPlainTextButtonMenu}
  //               TriggerProps={{
  //                 ...triggerProps,
  //                 color: activeSeries ? 'secondary' : 'default',
  //               }}
  //               series={seriesListing.data}
  //               onChange={(e, series): void => {
  //                 navigate(`/series/${series.series_id}`)
  //               }} />
  //           )} */}

  //         </Box>
  //       )}

  //     </Box>

  //     { ready && (
  //       <Box display='none' mb={4} mt={-2.5}>
  //         <Tabs
  //           classes={tabClasses}
  //           value={
  //             (isOverview || isPerformanceReportView) ?
  //               'overview' :
  //               isInsightsView ? 
  //                 'insights' :
  //                 (isChannelView || activeSeries) ? // (activeSeries && providers.length > 1)) ?
  //                   activeProvider :
  //                   // 'performance' :
  //                   activeSeries ?
  //                     // activeSeries.provider :
  //                     'reports' :
  //                     undefined
  //           }
  //           indicatorColor='secondary'
  //           onChange={(e, value: string): void => {
  //             if( activeOrgId ){
  //               const baseUrl = `/dashboards/${activeOrgId}`
  //               switch(value){
  //                 case 'overview':
  //                   navigate(baseUrl)
  //                   break

  //                 case 'performance': {
  //                   let nextProvider: ReportProvider | null = null
  //                   if( activeProvider ){
  //                     nextProvider = activeProvider
  //                   }else{
  //                     nextProvider = providers[0]
  //                   }
  //                   if( nextProvider ){
  //                     navigate(`${baseUrl}/${providerURLMap[nextProvider]}`)
  //                   }
  //                   break
  //                 }

  //                 case 'insights':
  //                   navigate(`${baseUrl}/insights`)
  //                   break

  //                 case 'reports': {
  //                   let nextProvider: ReportProvider | null = null
  //                   if( activeProvider ){
  //                     nextProvider = activeProvider
  //                   }else{
  //                     nextProvider = providers[0]
  //                   }
  //                   if( seriesListing.data && seriesListing.data.length ){
  //                     for( const series of seriesListing.data ){
  //                       if( series.provider === nextProvider ){
  //                         navigate(`/series/${series.series_id}`)
  //                         break
  //                       }
  //                     }
  //                   }
  //                   break
  //                 }

  //                 case 'adform':
  //                 case 'adwords':
  //                 case 'facebook': {
  //                   const matchedSeries = find(
  //                     activePlatformUnit.report_series,
  //                     s => s.enabled && s.provider_type === value
  //                   )
  //                   if( matchedSeries ){
  //                     navigate(`/series/${matchedSeries.historic_id}`)
  //                   }else{
  //                     navigate(`${baseUrl}/${providerURLMap[value]}`)
  //                   }
  //                   break
  //                 }

  //                 default:
  //                   break
  //               }
  //             }
  //           }}>
  //           { hasOverview && (
  //             <Tab value='overview' classes={tabClasses} label='Overview' />
  //           )}
  //           {/* { hasPerformanceReporting && (
  //             <Tab value='performance' label='Performance' />
  //           )} */}
  //           { hasInsightReports && (
  //             <Tab value='insights' classes={tabClasses} label='Insights' />
  //           )}
  //           {/* { !!(seriesListing.data && seriesListing.data.length) && (
  //             <Tab value='reports'classes={tabClasses} label='Reports' />
  //           )} */}
  //           { (providers.length > 1 || hasStructuralReporting) && providers.map( provider => (
  //             <Tab
  //               key={provider}
  //               value={provider}
  //               classes={tabClasses}
  //               label={
  //                 <ProviderLogo
  //                   size={1.5}
  //                   units='em'
  //                   provider={provider} />
  //               } />
  //           ))}
  //         </Tabs>
  //       </Box>
  //     )}

  //   </Fragment>
  // )

}