import axios from 'axios'

const { MEDIA_INVESTMENT_API_ROOT } = process.env

export const apiClient = axios.create({
  baseURL: `${MEDIA_INVESTMENT_API_ROOT}/v1`,
})

apiClient.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${sessionStorage.getItem('PERCEPT_TOKEN')}`
  return config
},
(err) => Promise.reject(err)
)
