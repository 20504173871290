import { useMemo } from 'react'

import {
  ChannelKey,
  MetricPillar,
  NestedPlatformUnitMetricPillarScores,
  PlatformUnit,
  PlatformUnitMetricPillarScores,
  ReportProvider,
} from '@percept/types'

import { useActivePlatformUnit } from 'components/Organisation'

import { find, get, isNumber, isFinite, last, mapValues, sortBy, round } from 'lodash-es'

import { ChartAnnotation, ChartData } from '@percept/mui/charts'
import { AppTheme } from '@percept/mui'


export const PILLAR_SCORE_AVERAGES_BY_PROVIDER: Record<
  ReportProvider, Record<
    MetricPillar, Record<
      'lower' | 'upper', number
    > | null
  >
> = {
  adform: {
    creative: {
      lower: 0.3859,
      upper: 0.5331,
    },
    structure: {
      lower: 0.4872,
      upper: 0.5766,
    },
    audience: {
      lower: 0.4039,
      upper: 0.5694,
    },
    brand_safety: {
      lower: 0.5085,
      upper: 0.7403,
    },
  },
  adwords: {
    creative: {
      lower: 0.5490,
      upper: 0.6257,
    },
    structure: {
      lower: 0.6445,
      upper: 0.8084,
    },
    audience: {
      lower: 0.1756,
      upper: 0.5922,
    },
    brand_safety: null,
  },
  facebook: {
    creative: {
      lower: 0.2518,
      upper: 0.3386,
    },
    structure: {
      lower: 0.6282,
      upper: 0.7745,
    },
    audience: {
      lower: 0.4861,
      upper: 0.5819,
    },
    brand_safety: {
      lower: 0.3034,
      upper: 0.8499,
    },
  },
  dv360: {
    creative: null,
    structure: null,
    audience: null,
    brand_safety: null,
  },
}


export const getRoundedValue = (value: number): number => {
  const absValue = Math.abs(value)
  const precision = (
    (absValue >= 1 || absValue === 0) ?
      0 :
      absValue >= 0.1 ?
        1 :
        2
  )
  return round(value, precision)
}


export const scaleValue = ({
  value,
  domain: [min, max] = [0, 1],
  range: [lower, upper] = [0, 1],
}: {
  value: number | null | undefined
  range?: [number, number]
  domain?: [number, number]
}): number => {
  const numberValue = isNumber(value) && isFinite(value) ? value : 0
  const scaled = (
    ( (upper - lower) * (numberValue - min) ) / (max - min)
  ) + lower

  if( !isFinite(scaled) ){
    return 0
  }

  return scaled
}


export type QualityAnalysisDatum = (
  Pick<
    PlatformUnit, 'id' | 'name'
  > & {
    value: number | null
  }
)

type PillarScoreContainer = (
  Pick<PlatformUnit, 'id' | 'name'> & {
    pillarScores: Record<MetricPillar, ChartData>
  }
)

type QualityAnalysis = {
  best: Record<MetricPillar, QualityAnalysisDatum | null> | null
  worst: Record<MetricPillar, QualityAnalysisDatum | null> | null
  improving: Record<MetricPillar, QualityAnalysisDatum | null> | null
}

export const pillars: MetricPillar[] = [
  'structure', 'creative', 'audience', 'brand_safety'
]


export const channelPillars: Record<ChannelKey, MetricPillar[]> = {
  search: [
    'structure', 'creative', 'audience',
  ],
  social: [
    'structure', 'creative', 'audience', 'brand_safety',
  ],
  programmatic: [
    'structure', 'creative', 'audience', 'brand_safety',
  ],
}


export const pillarDescriptionMap: Record<MetricPillar, string> = {
  creative: 'Adoption of creative best practices',
  audience: 'Ability to maximise audience data and targeting',
  brand_safety: 'Adherence to Brand Safety guidelines',
  structure: 'Adoption of configuration best practices for optimum performance',
}


const pillarsToChartData = (
  pillarScores: PlatformUnitMetricPillarScores
): Record<MetricPillar, ChartData> => { 
  return pillars.reduce( (acc, pillar) => {
    acc[pillar] = pillarScores.scores.map( score => ({
      label: score.date,
      value: isNumber(score[pillar]) ? (Number(score[pillar]) * 100) : null
    })) as ChartData
    return acc
  }, {} as Record<MetricPillar, ChartData>)
}



export const useQualityAnalysis = ({
  provider,
  pillarScores
}: {
  provider: ReportProvider | null
  pillarScores: NestedPlatformUnitMetricPillarScores | null
}): QualityAnalysis => {

  const activePlatformUnit = useActivePlatformUnit()

  return useMemo(() => {

    if( provider && pillarScores && activePlatformUnit && activePlatformUnit.children ){
      const targetUnits = activePlatformUnit.children

      const collated = pillarScores.reduce( (acc, { org_unit_id, byProvider }) => {
        const targetUnit = find(targetUnits, u => String(u.id) === String(org_unit_id) )
        if( targetUnit ){
          const scoreData = byProvider[provider]
          acc.push({
            id: targetUnit.id,
            name: targetUnit.name,
            pillarScores: pillarsToChartData(scoreData),
          })
        }
        return acc
      }, [] as PillarScoreContainer[])

      const pivoted = collated.reduce( (acc, { id, name, pillarScores }) => {
        pillars.forEach( pillar => {
          acc[pillar] = acc[pillar] || []
          acc[pillar].push({
            id,
            name,
            scores: pillarScores[pillar]
          })
        })
        return acc
      }, {} as Record<MetricPillar, (Pick<PlatformUnit, 'id' | 'name'> & { scores: ChartData })[]>)

      const withLatestValue = mapValues(pivoted, (scoreContainers) => {
        return scoreContainers.map( ({ id, name, scores }) => ({
          id,
          name,
          value: get(last(scores), 'value', null)
        }))
      })

      const best = mapValues(withLatestValue, data => {
        return sortBy(
          data.filter( d => d.value !== null ),
          'value'
        ).reverse()[0] || null
      })

      const worst = mapValues(withLatestValue, data => {
        return sortBy(
          data.filter( d => d.value !== null ),
          'value'
        )[0] || null
      })

      const improving = mapValues(pivoted, scoreContainers => {
        const withDelta = scoreContainers.map( ({ id, name, scores }) => {
          let value = null
          const len = scores.length
          if( len > 1 ){
            const [comparator, benchmark] = [scores[len - 1].value, scores[len - 2].value]
            if( comparator !== null && benchmark !== null ){
              /**
               * NOTE - we use absolute percentage increases here, rather than relative
               */
              value = getRoundedValue(comparator - benchmark)
            }
          }
          return { id, name, value }
        })
        return sortBy(
          withDelta.filter( d => d.value !== null ),
          'value'
        ).reverse()[0] || null
      })

      return { best, worst, improving }

    }

    return { best: null, worst: null, improving: null }

  }, [provider, pillarScores, activePlatformUnit])

}


type MultiOrgQualityAnalysis = Record<MetricPillar, QualityAnalysisDatum[]>


export const useMultiOrgQualityAnalysis = ({
  provider,
  pillarScores
}: {
  provider: ReportProvider | null
  pillarScores: NestedPlatformUnitMetricPillarScores | null
}): MultiOrgQualityAnalysis => {

  const activePlatformUnit = useActivePlatformUnit()

  return useMemo(() => {

    if( provider && pillarScores && activePlatformUnit && activePlatformUnit.children ){
      const targetUnits = activePlatformUnit.children

      const collated = pillarScores.reduce( (acc, { org_unit_id, byProvider }) => {
        const targetUnit = find(targetUnits, u => String(u.id) === String(org_unit_id) )
        if( targetUnit ){
          const scoreData = byProvider[provider]
          acc.push({
            id: targetUnit.id,
            name: targetUnit.name,
            pillarScores: pillarsToChartData(scoreData),
          })
        }
        return acc
      }, [] as PillarScoreContainer[])

      const pivoted = collated.reduce( (acc, { id, name, pillarScores }) => {
        pillars.forEach( pillar => {
          acc[pillar] = acc[pillar] || []
          acc[pillar].push({
            id,
            name,
            scores: pillarScores[pillar]
          })
        })
        return acc
      }, {} as Record<MetricPillar, (Pick<PlatformUnit, 'id' | 'name'> & { scores: ChartData })[]>)

      const multiOrgQualityAnalysis: MultiOrgQualityAnalysis = mapValues(pivoted, (scoreContainers) => {
        const containersWithLatestValue = scoreContainers.map( ({ id, name, scores }) => ({
          id,
          name,
          value: get(last(scores), 'value', null)
        }))

        return sortBy(
          containersWithLatestValue,
          'name'
        )
      })

      return multiOrgQualityAnalysis
    }

    return {
      structure: [],
      creative: [],
      audience: [],
      brand_safety: [],
    }
  }, [activePlatformUnit, provider, pillarScores])

}


export const getAveragePillarScoreAnnotations = ({
  provider,
  pillar,
  appTheme,
  fill,
}: {
  provider: ReportProvider | null
  pillar: MetricPillar
  appTheme: AppTheme
  fill: string
}): ChartAnnotation[] | undefined => {

  if( !provider ){
    return undefined
  }

  const averageBounds = PILLAR_SCORE_AVERAGES_BY_PROVIDER[provider][pillar]

  const annotations: ChartAnnotation[] | undefined = (
    averageBounds ? [
      {
        type: 'box',
        fill,
        fillOpacity: 0.2,
        values: {
          x: '0%',
          y: `${(1 - averageBounds.upper) * 100}%`,
          height: (
            `${(averageBounds.upper - averageBounds.lower) * 100}%`
          ),
        }
      },
      {
        type: 'line',
        stroke: appTheme.palette.info.main,
        strokeWidth: 1,
        strokeOpacity: 0.5,
        values: {
          x: '0%',
          y: `${(1 - averageBounds.upper) * 100}%`,
        }
      },
      {
        type: 'line',
        stroke: appTheme.palette.info.main,
        strokeWidth: 1,
        strokeOpacity: 0.5,
        values: {
          x: '0%',
          y: `${(1 - averageBounds.lower) * 100}%`,
        }
      },
    ] : undefined
  )

  return annotations

}