import React, { Fragment, useState } from 'react'

import {
  Box,
  BoxProps,
  Divider,
  IconButtonPopover,
  IconButtonPopoverProps,
  Loader,
  Typography,
  makeAppStyles,
  RoundedPlainTextButton,
} from '@percept/mui'

import {
  Email,
  ExitToApp,
  EnhancedEncryption,
  Person,
  Phone,
  Settings,
  VerifiedUser,
  Block,
  LockOpen,
} from '@percept/mui/icons'

import { MultiFactorAuthentication } from './MultiFactorAuthentication'

import {
  useCanManageMFA,
  useDerivedMFADeliveryMedium,
  useMFAPreference,
  useMyUserDetail,
  useSignOut,
} from '@percept/hooks'

import timeago from 'timeago.js'


const { MFA_REQUIRED } = process.env


const IconAttribute = ({
  icon,
  children,
  ...props
}: {
  icon: React.ReactNode
} & BoxProps): JSX.Element => {
  return (
    <Box display='flex' alignItems='center' {...props}>
      { icon }

      <Box display='flex' alignItems='center' ml={2} fontSize={14} fontWeight={500}>
        { children }
      </Box>
    </Box>
  )
}


export type UserPopoverProps = {
  IconButtonPopoverProps?: Partial<IconButtonPopoverProps>
}


const useMFAStyles = makeAppStyles( theme => ({
  spacedLeft: {
    marginLeft: theme.spacing(2.5),
  },
  success: {
    color: theme.chart.healthColourScale(1),
  },
  smallButton: {
    height: 24,
    padding: theme.spacing(1),
  },
}))


const UserMFAManagement = ({
  onClose
}: {
  onClose: () => void
}): JSX.Element => {
  const [mfaMode, setMfaMode] = useState<'SETUP' | 'MANAGE' | null>(null)

  const activeDeliveryMedium = useDerivedMFADeliveryMedium()

  const mfaPreference = useMFAPreference()

  const isMfaSetup = !!mfaPreference

  const classes = useMFAStyles()

  return (
    <Fragment>
      <Box mt={4} mb={3}>
        { isMfaSetup ? (
          <IconAttribute
            fontWeight={700}
            icon={
              activeDeliveryMedium ?
                <VerifiedUser className={classes.success} /> :
                <Block color='disabled' />   
            }>
            <strong>
              Two Factor Authentication {activeDeliveryMedium ? 'Enabled' : 'Disabled'}
            </strong>

            { !MFA_REQUIRED && (
              <RoundedPlainTextButton
                className={
                  `${classes.spacedLeft} ${classes.smallButton}`
                }
                variant='contained'
                size='small'
                startIcon={<Settings />}
                onClick={(): void => {
                  setMfaMode('MANAGE')
                }}>
                Manage
              </RoundedPlainTextButton>
            )}
          </IconAttribute>
        ) : !MFA_REQUIRED ? (
          <RoundedPlainTextButton
            variant='contained'
            color='secondary'
            fullWidth
            startIcon={
              <EnhancedEncryption />
            }
            onClick={(): void => {
              setMfaMode('SETUP')
            }}>
            Setup Two Factor Authentication
          </RoundedPlainTextButton>
        ) : (
          null
        )}
      </Box>

      { mfaMode && (
        <MultiFactorAuthentication
          mode={mfaMode}
          onClose={(): void => {
            setMfaMode(null)
            onClose()
          }} />
      )}
    </Fragment>
  )
}

const useStyles = makeAppStyles( theme => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(1, 0, 3, 0),
  },
}))


export const UserPopover = ({ IconButtonPopoverProps = {} }: UserPopoverProps): JSX.Element => {

  const [user] = useMyUserDetail()

  const canManageMFA = useCanManageMFA()

  const signOut = useSignOut()

  const classes = useStyles()

  return (
    <Fragment>

      <IconButtonPopover
        IconComponent={Person}
        {...IconButtonPopoverProps}>
        { ({ onClose }): JSX.Element => (
          <Box minWidth='24rem' p={2}>
            { user.loading && (
              <Loader size='2rem' minHeight='8rem' />
            )}

            { user.data && (
              <Box textAlign='right'>
                <Typography variant='h5' className={classes.dialogTitle}>
                  <Person className={classes.icon} />
                  { user.data.name }
                </Typography>

                <Divider className={classes.divider} />

                <IconAttribute
                  icon={<Email />}>
                  { user.data.email }
                </IconAttribute>

                { user.data.phone_number && (
                  <IconAttribute
                    mt={2}
                    icon={<Phone />}>
                    { user.data.phone_number }
                  </IconAttribute>
                )}

                { user.data.previous_login && (
                  <IconAttribute
                    mt={2}
                    icon={<LockOpen />}>
                    Last logged in {timeago().format(new Date(user.data.previous_login))}
                  </IconAttribute>
                )}

                { canManageMFA && <UserMFAManagement onClose={onClose} /> }
              </Box>
            )}

            <Divider className={classes.divider} />

            <RoundedPlainTextButton
              variant='contained'
              fullWidth
              startIcon={
                <ExitToApp />
              }
              onClick={(): void => {
                signOut()
              }}>
              Sign Out
            </RoundedPlainTextButton>
          </Box>
        )}
      </IconButtonPopover>

    </Fragment>
  )
}
