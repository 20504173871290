import React, { Fragment, useMemo } from 'react'

import { AnimatedPath } from '../Paths'

import {
  area,
  stack,
  curveLinear,
  curveMonotoneX,
  curveMonotoneY,
  curveStepBefore,
  curveStepAfter,
  CurveFactory,
  stackOffsetNone,
  stackOffsetSilhouette,
  stackOrderNone,
  stackOrderInsideOut,
} from 'd3-shape'

import { find, get } from 'lodash-es'

import { filterZeroDatasets } from '../lib'

import { AnimatableSVGProps, MultiDataset, SVGDatumType } from '../typings'

import { AreaOnlyProps, QuantitativeCurve, QuantitativeRenderProps, StackOffset } from './typings'


export type AreaComponentProps<T extends SVGDatumType> = (
  QuantitativeRenderProps<T, AreaOnlyProps<T>> &
  Partial<AnimatableSVGProps>
)


const curveFactoryMap: Record<QuantitativeCurve, CurveFactory> = {
  linear: curveLinear,
  monotoneX: curveMonotoneX,
  monotoneY: curveMonotoneY,
  stepAfter: curveStepAfter,
  stepBefore: curveStepBefore,
}


function isDefined<T extends SVGDatumType>({ value }: T): boolean {
  return value !== null && !isNaN(value)
}


export function AreaComponent<T extends SVGDatumType>({
  data,
  xAccessor,
  yAccessor,
  width,
  height,
  fill,
  fillOpacity = 1,
  stroke,
  strokeOpacity = 1,
  svgPathProps,
  animate,
  animateInitial,
  onAreaClick,
  curve = 'linear',
  fillOverride,
  strokeOverride,
}: AreaComponentProps<T>): JSX.Element {

  const { path, initialPath } = useMemo(() => {
    const curveFactory = curveFactoryMap[curve] || curveLinear
    const generator = (
      area<T>()
        .x0(xAccessor)
        .x1(xAccessor)
        .y0(height)
        .y1(yAccessor)
        .curve(curveFactory)
        .defined(isDefined)
    )
    const initialPathGenerator = (
      area<T>()
        .x0(xAccessor)
        .x1(xAccessor)
        .y0(height)
        .y1(yAccessor)
        .curve(curveFactory)
        .defined(isDefined)
    )
    return {
      path: generator(data || []),
      initialPath: initialPathGenerator(data || []),
    }
  }, [data, height, xAccessor, yAccessor, curve])

  return (
    <AnimatedPath
      key='area'
      animate={animate}
      animateInitial={animateInitial}
      initialPath={initialPath || ''}
      onClick={onAreaClick}
      excludeSegment={(a, b): boolean => a.x === b.x && a.x === width}
      {...svgPathProps}
      width={width}
      fillOpacity={fillOpacity}
      strokeOpacity={strokeOpacity}
      height={height}
      d={path || ''}
      fill={fillOverride || fill}
      stroke={strokeOverride || stroke || fill} />
  )
}


export type LineComponentProps<T extends SVGDatumType> = (
  AreaComponentProps<T> & {possibleNegativeValues?: boolean}
)


export function LineComponent<T extends SVGDatumType>({
  data,
  datasets,
  xAccessor,
  yAccessor,
  width,
  height,
  fillOpacity = 0,
  stroke,
  strokeOpacity = 1,
  svgPathProps,
  animate,
  animateInitial,
  onAreaClick,
  curve = 'linear',
  fillOverride,
  strokeOverride,
  possibleNegativeValues = false
}: LineComponentProps<T>): JSX.Element {

  const pathConfigs = useMemo(() => {
    const curveFactory = curveFactoryMap[curve] || curveLinear
    const generator = (
      area<T>()
        .x0(xAccessor)
        .x1(xAccessor)
        .y0(height)
        .y1(yAccessor)
        .curve(curveFactory)
        .defined(isDefined)
    )
    const initialPathGenerator = (
      area<T>()
        .x0(xAccessor)
        .x1(xAccessor)
        .y0(height)
        .y1(height)
        .curve(curveFactory)
        .defined(isDefined)
    )

    const hoistedDatasets = (
      possibleNegativeValues && datasets || datasets && filterZeroDatasets(datasets) ||
      data && [{
        key: data.length ? 'line' : 'empty-line',
        label: 'line',
        data,
        color: data[0] && data[0].color,
        fillOpacity,
        stroke,
        strokeOpacity,
      }]
      || []
    ) as MultiDataset<T>[]

    return hoistedDatasets.map( ({ key, data, color, fill = 'none', fillOpacity = 0, stroke, strokeOpacity = 1 }) => {

      const dataColor = stroke || (fill !== 'none' && fill) || color || 'currentColor'

      return {
        key,
        areaPath: generator(data),
        initialAreaPath: initialPathGenerator(data),
        linePath: generator.lineY1()(data),
        initialLinePath: initialPathGenerator.lineY1()(data),
        stroke: dataColor,
        fill: fill !== 'none' ? fill : dataColor,
        fillOpacity,
        strokeOpacity,
      }
    })
  },
  [
    data, datasets, xAccessor, yAccessor, curve, height,
    fillOpacity, stroke, strokeOpacity,
  ])

  return (
    <Fragment>
      { pathConfigs.map( ({ key, areaPath, initialAreaPath, linePath, initialLinePath, stroke, fill, fillOpacity, strokeOpacity }) => (
        <Fragment key={key}>
          { fillOpacity !== 0 && (fillOverride || fill && fill !== 'none') && (
            <AnimatedPath
              key={`fill-${key}`}
              animate={animate}
              animateInitial={animateInitial}
              initialPath={initialAreaPath || ''}
              onClick={onAreaClick}
              fill={fillOverride || fill}
              stroke='none'
              fillOpacity={fillOpacity}
              width={width}
              height={height}
              d={areaPath || ''} />
          )}
          <AnimatedPath
            key={`stroke-${key}`}
            animate={animate}
            animateInitial={animateInitial}
            initialPath={initialLinePath || ''}
            onClick={onAreaClick}
            fill='none'
            stroke={strokeOverride || stroke}
            strokeWidth={2}
            {...svgPathProps}
            strokeOpacity={strokeOpacity}
            width={width}
            height={height}
            d={linePath || ''} />
        </Fragment>
      ))}
    </Fragment>
  )
}


const stackOffsetMap: Record<StackOffset, typeof stackOffsetNone> = {
  none: stackOffsetNone,
  silhouette: stackOffsetSilhouette,
}


export type StackedAreaComponentProps<T extends SVGDatumType> = (
  AreaComponentProps<T> & {
    stackOffset?: StackOffset
  }
)


export function StackedAreaComponent<T extends SVGDatumType>({
  datasets,
  labels,
  xScale,
  yScale,
  width,
  height,
  stroke,
  svgPathProps,
  animate,
  animateInitial,
  onAreaClick,
  curve = 'linear',
  stackOffset = 'none',
}: StackedAreaComponentProps<T>): JSX.Element {

  const stackConfigs = useMemo(() => {

    const hoistedDatasets = filterZeroDatasets(datasets || [])

    const keys = hoistedDatasets.map( ({ key }) => key )

    const generator = stack()
      .keys(keys)
      .offset(stackOffsetMap[stackOffset])
      .order(stackOffset === 'none' ? stackOrderNone : stackOrderInsideOut)

    const mergedDataset = labels.map( (label) => {
      return hoistedDatasets.reduce( (acc, { key, data }) => {
        acc[key] = Number(
          get(
            find(data, d => d.label === label ),
            'value',
            0
          )
        )
        return acc
      }, {} as Record<string, number>)
    }, [] as Record<string, number>[])

    const stacks = generator(mergedDataset)

    const curveFactory = curveFactoryMap[curve] || curveLinear

    const yOffset = stackOffset !== 'none' ? height / 2 : 0

    const x = (d: [number, number], i: number): number => (
      Number(
        xScale(Number(labels[i]))
      )
    )

    const y0 = (d: [number, number]): number => (
      Number(
        yScale(d[0] || 0)
      ) - yOffset
    )

    const y1 = (d: [number, number]): number => (
      Number(
        yScale(d[1] || 0)
      ) - yOffset
    )

    const defined = (d: [number, number]): boolean => (
      d[0] !== null && d[1] !== null &&
      !isNaN(d[0]) && !isNaN(d[1])
    )

    const areaGenerator = (
      area()
        .x(x)
        .y0(y0)
        .y1(y1)
        .curve(curveFactory)
        .defined(defined)
    )

    const initialPathGenerator = (
      area()
        .x(x)
        .y0(y0)
        .y1(y0)
        .curve(curveFactory)
        .defined(defined)
    )

    return stacks.map( (stack, i) => ({
      ...hoistedDatasets[i],
      stack,
      key: keys[i],
      path: areaGenerator(stack as [number, number][]) || '',
      initialAreaPath: initialPathGenerator(stack as [number, number][]) || '',
      linePath: areaGenerator.lineY1()(stack as [number, number][]) || '',
      initialLinePath: initialPathGenerator.lineY1()(stack as [number, number][]) || '',
    }))

  }, [datasets, xScale, yScale, curve, labels, stackOffset, height])

  return (
    <Fragment>
      
      { stackConfigs.map( ({ key, path, color, fill, fillOpacity, initialAreaPath }) => {
        return (
          <AnimatedPath
            key={`fill-${key}`}
            animate={animate}
            animateInitial={animateInitial}
            initialPath={initialAreaPath}
            onClick={onAreaClick}
            {...svgPathProps}
            excludeSegment={(a, b): boolean => a.x === b.x && a.x === width}
            width={width}
            height={height}
            d={path || ''}
            fill={fill || color}
            fillOpacity={fillOpacity}
            stroke='none' />
        )
      })}

      { stroke !== 'none' && (
        stackConfigs.map( ({ key, linePath, color, strokeOpacity, initialLinePath }) => {
          return (
            <AnimatedPath
              key={`stroke-${key}`}
              animate={animate}
              animateInitial={animateInitial}
              initialPath={initialLinePath}
              onClick={onAreaClick}
              {...svgPathProps}
              excludeSegment={(a, b): boolean => a.x === b.x && a.x === width}
              width={width}
              height={height}
              d={linePath || ''}
              fill='none'
              stroke={stroke || color}
              strokeOpacity={strokeOpacity} />
          )
        })
      )}

    </Fragment>
  )
}
