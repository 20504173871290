
import React, { Fragment } from 'react'

import { Tooltip } from '@material-ui/core'

import { makeAppStyles } from '../../themes'

import { Money } from '../Money'

import { isArray, displayNumber, separateThousands, deslugify, isNumber } from '@percept/utils'

import { format as formatDate } from 'date-fns'

import { round } from 'lodash-es'

import { MetricProps } from './typings'


const useStyles = makeAppStyles( theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1, 2, 0, 2),
    marginBottom: theme.spacing(2),
  },
  value: {
    lineHeight: '100%',
    justifySelf: 'center',
  },
  ratio: {
    lineHeight: '100%',
    justifySelf: 'center',
    fontSize: '0.75em',
  },
  meta: {
    whiteSpace: 'nowrap',
    color: theme.palette.secondary.main,
    transition: theme.transitions.create('color'),
    fontSize: '0.5em',
    fontWeight: 700,
    textAlign: 'center',
    marginTop: theme.spacing(3),
  },
  clickable: {
    cursor: 'pointer',
  },
  MICRO: {
    fontSize: '1.25rem',
    marginBottom: 0,
    padding: theme.spacing(0.5),
    '& $meta': {
      fontSize: '0.65em',
      marginTop: theme.spacing(0.5),
    },
  },
  MINI: {
    fontSize: '1.75rem',
    marginBottom: 0,
    padding: theme.spacing(0.5),
    '& $meta': {
      fontSize: '0.65em',
      marginTop: theme.spacing(0.5),
    },
  },
  MIDI: {
    fontSize: '1.85rem',
    marginBottom: 0,
    padding: theme.spacing(0.5),
    '& $meta': {
      fontSize: '0.5em',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(-1.5),
    },
  },
  SUMMARY: {
    fontSize: '2.25rem',
  },
  DETAIL: {
    fontSize: '2.75rem',
  },
}) )


type RatioProps = React.HTMLAttributes<HTMLDivElement> & {
  value: string | number | JSX.Element
  total: string | number | JSX.Element
}

const Ratio = ({ value, total, ...props }: RatioProps): JSX.Element => (
  <div {...props}>
    {value} / {total}
  </div>
)


type PercentageProps = React.HTMLAttributes<HTMLDivElement> & {
  value: number
  total: number
}

const Percentage = ({
  value,
  total,
  ...props
}: PercentageProps): JSX.Element => (
  <div {...props}>
    { (value > 0 && total > 0) ?
      `${round(value / total * 100, 2)}%` :
      '0%'
    }
  </div>
)


type TextMetricVisualisationPropsBase<T> = Pick<
  MetricProps, 'currency' | 'dimension' | 'displayType'
> & T & {
  total?: number
  rounded?: boolean
  prettyPrint?: boolean
  metric_type: 'attribute' | 'value' | 'proportion' | 'ratio'
  onClick?: () => void
}



export type NumberValueProps = {
  value: number | null
  format: 'percentage' | 'currency' | 'fraction' | 'number' | 'ratio'
}

export type TextValueProps = {
  value: string | null
  format: 'text' | 'date'
}

export type ListValueProps = {
  value: Array<string | null> | null
  format: 'list'
}

export type TextMetricVisualisationProps<P extends (NumberValueProps | TextValueProps | ListValueProps)> = (
  TextMetricVisualisationPropsBase<P>
)


export function TextMetricVisualisation<P extends (NumberValueProps | TextValueProps | ListValueProps)>({
  displayType = 'DETAIL',
  value,
  dimension,
  format,
  total = 0,
  rounded = true,
  prettyPrint = false,
  currency,
  metric_type,
  onClick
}: TextMetricVisualisationProps<P>): JSX.Element {

  const renderValue = rounded ? displayNumber : separateThousands

  const classes = useStyles()

  let displayValue

  if( format === 'percentage' ){
    displayValue = (
      <Fragment>
        <Percentage
          value={value as number}
          total={metric_type === 'attribute' ? 100 : total} />
        { metric_type !== 'attribute' && (
          <Ratio
            className={classes.meta}
            value={
              dimension === 'cost' ?
                <Money amount={value as number} currency={currency} /> :
                renderValue(value as number)
            }
            total={
              dimension === 'cost' ?
                <Money amount={total as number} currency={currency} /> :
                renderValue(total as number)
            } />
        )}
      </Fragment>
    )
  }else if( dimension === 'cost' || format === 'currency' ){
    if( format === 'ratio' || format === 'fraction' ){
      displayValue = (
        <Ratio
          className={classes.ratio}
          value={
            <Money amount={(value || 0) as number} currency={currency} />
          }
          total={
            <Money amount={total} currency={currency} />
          } />
      )
    }else{
      displayValue = (
        <div className={classes.value}>
          <Money amount={(value || 0) as number} currency={currency} />
        </div>
      )
    }
  }else if( format === 'text' ){
    value
    displayValue = (
      <span className={classes.value}>
        { value && prettyPrint ? deslugify(value as string) : value }
      </span>
    )
  }else if( format === 'date' ){
    displayValue = (
      <span className={classes.value}>
        { value && formatDate(new Date(value as string), 'DD/MM/YY') }
      </span>
    )
  }else if( format === 'list' ){
    displayValue = (
      <span className={classes.value}>
        { isArray(value) && value.map( v => prettyPrint ? deslugify(v as string) : v ).join(', ')}
      </span>
    )
  }else if( format === 'ratio' || format === 'fraction' ){
    displayValue = (
      <Ratio
        className={classes.ratio}
        value={renderValue(value as number)}
        total={renderValue(total)} />
    )
  }else{
    displayValue = (
      <span className={classes.value}>
        { renderValue(value as number) }
      </span>
    )
  }

  const className = [
    classes.root,
    classes[displayType],
    onClick && classes.clickable
  ].filter(Boolean).join(' ')

  return (
    <div className={className} onClick={onClick}>
      { isNumber(value) && format !== 'text' && value > 1000 ? (
        <Tooltip
          title={separateThousands(value)}>
          { displayValue }
        </Tooltip>
      ) : (
        displayValue
      ) }
    </div>
  )
}

export default TextMetricVisualisation
