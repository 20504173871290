import { Box, SelectProps } from '@material-ui/core'
import { makeAppStyles } from '@percept/mui'
import React from 'react'
import { Select } from './Select'
import { ToggleButtonProps, ToggleButtons } from './ToggleButtons'

const useAggregationClasses = makeAppStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2.5),
  },
  leftTogglegroup: {
    display: 'flex',
    gap: theme.spacing(2.5),
    flexWrap: 'wrap',
    justifyContent: 'start',
  },
  rightToggleGroup: {
    display: 'flex',
    gap: theme.spacing(2.5),
    flexWrap: 'wrap',
    justifyContent: 'end',
  },
}))

type OnChangeFunction = (e: ToggleButtonProps['id']) => void

export enum SelectType {
  Column = 'selectColumn',
  ScopeOfData = 'selectScopeOfData',
}

type TogglesProps = {
  view: string
  dataFormat?: string
  periodFormat: string
  selectType?: SelectType
  selectOptions?: { label: string; value: string }[]
  selectedValueSelect?: string
  changeHandlerSelect?: SelectProps['onChange']
  setView?: OnChangeFunction
  setDataFormat?: OnChangeFunction
  setPeriodFormat?: OnChangeFunction
}
const VIEW_OPTIONS = [
  { id: 'chart', label: 'Chart' },
  { id: 'table', label: 'Table' },
]

const DATA_OPTIONS = [
  { id: 'currency', label: 'Currency' },
  { id: 'percentage', label: 'Percentage' },
]

const PERIOD_OPTIONS = [
  { id: 'month', label: 'Month' },
  { id: 'quarter', label: 'Quarter' },
  { id: 'year', label: 'Year' },
]
export const AggregationToggles = ({
  view,
  dataFormat,
  periodFormat,
  selectOptions,
  selectedValueSelect,
  selectType = SelectType.Column,
  changeHandlerSelect,
  setView,
  setDataFormat,
  setPeriodFormat,
}: TogglesProps): JSX.Element => {
  const classes = useAggregationClasses()

  return (
    <Box className={classes.root}>
      <Box className={classes.leftTogglegroup}>
        {selectOptions && selectOptions.length > 1 && (
          <Select
            value={selectedValueSelect || selectOptions[0].value}
            renderValue={(value): string => {
              const item = selectOptions.find(({ value: v }) => v === value)
              return item
                ? selectType === 'selectColumn'
                  ? 'Data by ' + item.label
                  : item.label
                : ''
            }}
            changeHandle={changeHandlerSelect}
            optionList={selectOptions}
          />
        )}
        {(!selectOptions ||
          selectType === 'selectScopeOfData' ||
          (selectType === 'selectColumn' &&
            selectedValueSelect === 'period')) && (
          <ToggleButtons
            buttons={PERIOD_OPTIONS}
            activeButtonId={periodFormat}
            onChange={setPeriodFormat}
          />
        )}
      </Box>
      <Box className={classes.rightToggleGroup}>
        {dataFormat && (
          <ToggleButtons
            buttons={DATA_OPTIONS}
            activeButtonId={dataFormat}
            onChange={setDataFormat}
          />
        )}
        <ToggleButtons
          buttons={VIEW_OPTIONS}
          activeButtonId={view}
          onChange={setView}
        />
      </Box>
    </Box>
  )
}
