import React, { Fragment } from 'react'

import { Alert, Box, Button, Typography } from '@percept/mui'

import { PersonAdd } from '@percept/mui/icons'

import { SingleSignOn } from './SingleSignOn'

import * as Buttons from './Buttons'
import * as Inputs from './Inputs'

import getMessage from './getMessage'

import { AuthComponentProps } from './lib'


const SignIn = ({
  loading,
  dispatch,
  inputs,
  updateInputs,
  enableSingleSignOn = false,
  onSignUp,
  error,
  attemptSignIn,
  classes,
  AuthInputProps = {},
  hideSignInHeader = false
}: AuthComponentProps): JSX.Element => {

  const { username, password } = inputs

  return (
    <Fragment>

      { enableSingleSignOn && (
        <Box display='flex' flexDirection='column' justifyContent='center'>
          <SingleSignOn />
          <Box
            display='flex'
            alignItems='center'
            fontSize={20}
            fontWeight={500}
            mt={3}
            mb={1}>
            <div style={{borderBottom: '1px solid', width: '100%'}} />
            <span style={{margin: '0 12px'}}>Or</span>
            <div style={{borderBottom: '1px solid', width: '100%'}} />
          </Box>
        </Box>
      )}

      { !hideSignInHeader && (
        <Typography
          className={classes.heading}
          variant='h3'>
          Sign In

          { onSignUp && (
            <Box ml='auto'>
              <Button
                size='small'
                startIcon={
                  <PersonAdd />
                }
                onClick={onSignUp}>
                Sign Up
              </Button>
            </Box>
          )}
        </Typography>
      )}

      <form onSubmit={(e): void => e.preventDefault()}>

        { !!error && (
          <Alert
            my={3}
            variant='error'
            message={getMessage(error)} />
        )}

        <Inputs.Username
          autoFocus
          {...AuthInputProps}
          value={username || ''}
          onChange={(e): void => updateInputs({ username: e.target.value })} />

        <Inputs.Password
          BoxProps={{
            mb: 5,
          }}
          {...AuthInputProps}
          value={password || ''}
          // disabled={!username}
          onChange={(e): void => updateInputs({ password: e.target.value })} />

        <Buttons.Confirm
          disabled={loading || !username || !password}
          loading={loading}
          IconComponent={null}
          size='large'
          type='submit'
          onClick={attemptSignIn}>
          Sign In
        </Buttons.Confirm>
        
        <Box
          mt={3}>
          <Button
            size='small'
            onClick={(): void => dispatch({ type: 'FORGOT_PASSWORD' })}>
            Forgot Password
          </Button>
        </Box>

      </form>

    </Fragment>
  )

}

export default SignIn
