/* eslint-disable no-console */

import { USER_LOGIN } from '@percept/redux/bundles/user/actions'

import defaultActionHandlers from './actionHandlers'

import { getPath, isArray } from '@percept/utils'


export default ({ GA_TRACKING_ID, actionHandlers = defaultActionHandlers, debug = false }) => {

  // Set up analytics
  if( !debug ){
    
    window.ga= window.ga || function(){(window.ga.q=window.ga.q||[]).push(arguments)}
    window.ga.l = +new Date

    // Load script asynchronously
    const gaScript = document.createElement('script')
    gaScript.async = true
    gaScript.src = 'https://www.google-analytics.com/analytics.js'

    // Create tracker object on script load
    gaScript.onload = () => {
      window.ga('create', GA_TRACKING_ID, 'auto')
    }
    // Good old IE
    gaScript.onreadystatechange = () => {
      if( gaScript.readyState == 'complete' ){
        window.ga('create', GA_TRACKING_ID, 'auto')
      }
    }

    document.body.appendChild(gaScript)
  
  }else{
    console.log('Running analytics middleware in debug mode')
  }


  return store => next => action => {

    const { ga } = window

    // Store the previous state
    const previousState = store.getState()

    // Dispatch the action and store the resulting state
    const result = next(action)

    const nextState = store.getState()

    if( !debug && action.type === USER_LOGIN ){
      ga('set', 'userId', getPath(action, ['payload', 'user_id'], 'EMPTY'))
    }

    if( typeof actionHandlers[action.type] === 'function' ){

      const params = actionHandlers[action.type]({
        previous: previousState,
        action,
        next: nextState
      })

      if( params ){
        if( !debug ){
          isArray(params) ? params.map( p => ga('send', p) ) : ga('send', params)
        }else{
          if( !isArray(params) || params.length ){
            console.log('Analytics debug -> Params to send:', params)
          }
        }

      }
    
    }
    
    return result
  
  }

}
