import React, { Fragment, useState } from 'react'

import {
  Box,
  ButtonPopover,
  Collapse,
  DatePicker,
  DatePickerProps,
  FormControlLabel,
  makeAppStyles,
  Radio,
  RoundedPlainTextButton,
  Typography,
} from '@percept/mui'

import { ArrowDropDown, Today } from '@percept/mui/icons'

import {
  useReferenceDateBehaviour,
} from 'hooks'

import { format, subDays } from 'date-fns'


export type ReferenceDateSelectProps = {
  value: Date
  onChange: (referenceDate: Date) => void
} & Omit<DatePickerProps, 'value' | 'onChange'>


const useStyles = makeAppStyles( theme => ({
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  formControlLabel: {
    marginBottom: theme.spacing(1),
  },
  caption: {
    display: 'block',
    fontSize: 11,
    color: theme.palette.text.hint
  }
}))


export const ReferenceDateSelect = ({
  value,
  onChange,
  maxDate = subDays(new Date(), 1),
  ...props
}: ReferenceDateSelectProps): JSX.Element => {

  const [referenceDateBehaviour, setReferenceDateBehaviour] = useReferenceDateBehaviour()

  const [isSelectingCustom, setIsSelectingCustom] = useState(false)

  const classes = useStyles()

  return (
    <ButtonPopover
      ButtonComponent={RoundedPlainTextButton}
      buttonContent={
        format(value, 'dd/MM/yy')
      }
      variant='contained'
      color='secondary'
      startIcon={<Today />}
      endIcon={<ArrowDropDown />}
      PopoverProps={{
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        elevation: 12,
      }}>

      { ({ onClose }): JSX.Element => (
        <Box p={2} pb={0}>

          <Typography
            className={classes.subtitle}
            variant='subtitle1'>
            Reference Date
          </Typography>

          <Box
            display='flex'
            flexDirection='column'>

            <FormControlLabel
              className={classes.formControlLabel}
              label={
                <Fragment>
                  Show latest combined data from all providers
                  <Typography
                    className={classes.caption}
                    variant='caption'>
                    Data for some providers may be available more recently
                  </Typography>
                </Fragment>
              }
              checked={referenceDateBehaviour === 'LATEST_ALL' && !isSelectingCustom}
              onChange={(e, checked): void => {
                if( checked ){
                  setIsSelectingCustom(false)
                  setReferenceDateBehaviour('LATEST_ALL')
                  onClose()
                }
              }}
              control={<Radio />} />

            <FormControlLabel
              className={classes.formControlLabel}
              label={
                <Fragment>
                  Show latest data from any provider
                  <Typography
                    className={classes.caption}
                    variant='caption'>
                    Some provider data may be incomplete
                  </Typography>
                </Fragment>
              }
              checked={referenceDateBehaviour === 'LATEST_ANY' && !isSelectingCustom}
              onChange={(e, checked): void => {
                if( checked ){
                  setIsSelectingCustom(false)
                  setReferenceDateBehaviour('LATEST_ANY')
                  onClose()
                }
              }}
              control={<Radio />} />

            <FormControlLabel
              className={classes.formControlLabel}
              label={
                <Fragment>
                  Custom
                  <Typography
                    className={classes.caption}
                    variant='caption'>
                    View performance data for a specific date
                  </Typography>
                </Fragment>
              }
              checked={referenceDateBehaviour === 'CUSTOM' || isSelectingCustom}
              onChange={(e, checked): void => {
                if( checked ){
                  setIsSelectingCustom(true)
                }
              }}
              control={<Radio />} />
          </Box>

          <Collapse in={referenceDateBehaviour === 'CUSTOM' || isSelectingCustom}>
            <Box mt={2}>
              <DatePicker
                variant='static'
                value={value}
                onChange={(newDate): void => {
                  if( newDate ){
                    onChange(newDate)
                    onClose()
                  }
                }}
                maxDate={maxDate}
                {...props} />
            </Box>
          </Collapse>

        </Box>
      )}

    </ButtonPopover>
  )
}
