import { Box } from '@material-ui/core'
import { Money } from '@percept/mui'
import { spacedPercentageFormatter } from '@percept/mui/charts'
import React from 'react'
import { Summary } from '../DashboardLayout'
import { ListItem } from '../types'
import { useStyles } from './TableCells'

export const TableTotalColumn = ({
  row,
  depth = 0,
  opend,
  dimensions,
  dataFormat,
}: {
  row: ListItem
  depth?: number
  opend: string[]
  dimensions: string[]
  dataFormat: string
}): JSX.Element => {
  const classes = useStyles()
  const isAllMarkets = row.row_group === Summary.AllTotal
  return (
    <Box>
      <Box
        display='flex'
        flexWrap='nowrap'
        pl='10px'
        className={
          isAllMarkets
            ? classes.totalItem
            : row.data && depth === 0
            ? classes.groupItem
            : classes.item
        }
        height='32px'
        alignItems='center'>
        <Box
          fontSize={13}
          fontWeight={row.data ? 700 : 500}
          whiteSpace='nowrap'
          textAlign='right'
          mr='15px'
          width='6rem'>
          {dataFormat === 'currency' ? (
            <Money
              amount={Number(row.total)}
              currency={row.total_currency}
            />
          ) : (
            spacedPercentageFormatter(row.total_percentage)
          )}
        </Box>
      </Box>

      {opend.includes(row.row_group) && (
        <Box display='flex' flexDirection='column'>
          {(row.data ?? []).map((member) => (
            <TableTotalColumn
              key={member.data_type}
              dataFormat={dataFormat}
              row={{
                ...member,
                row_group: isAllMarkets ? Summary.AllTotal : member.data_type,
                costs: member.costs,
                total_currency: row.total_currency,
                total_percentage: member.average ? member.average : member.total_percentage
              }}
              depth={depth + 1}
              opend={[]}
              dimensions={dimensions}
            />
          ))}
        </Box>
      )}
    </Box>
  )
}
