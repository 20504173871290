import { isNumber, mapKeys, mapValues } from 'lodash-es'

import {
  coerceDoubleVerifyProvider,
  coerceReportProvider,
  isAveragingDimension,
  isConvertingDimension,
} from '@percept/utils'

import {
  AdformCampaignType,
  AdformSubNetwork,
  AdNetworkPerformanceReporting,
  AdNetworkPerformanceTimeseries,
  ChannelPerformanceReporting,
  ChannelPerformanceTimeseries,
  DoubleVerifyPerformanceReporting,
  DoubleVerifyPerformanceReportingDimension,
  DoubleVerifyPerformanceTimeseries,
  DoubleVerifyProviderEnum,
  DoubleVerifyProviderPerformanceReporting,
  DoubleVerifyProviderPerformanceTimeseries,
  DV360Exchange,
  DV360MediaType,
  FacebookCampaignObjective,
  FacebookPublisherPlatform,
  FacebookSubNetwork,
  GoogleAdsNetwork,
  GoogleAdsSubNetwork,
  PerformanceComparisons,
  PerformanceDataset,
  PerformanceDimensionType,
  PerformanceReporting,
  PerformanceReportingByProvider,
  PerformanceReportingDatum,
  PerformanceReportingDimension,
  PerformanceTimeseries,
  PerformanceTimeseriesByProvider,
  PerformanceTimeseriesDatum,
  PerformanceTotals,
  PerformanceTotalsResponse,
  PerformanceTotalsWithCampaignObjective,
  PerformanceTotalsWithNetwork,
  PerformanceTotalsWithSubNetwork,
  PerformanceValue,
  PlatformUnitProviderInfo,
  ProviderPerformanceReporting,
  ProviderPerformanceTimeseries,
  RawPerformanceReportingDimension,
  RawPerformanceValues,
  ReportProvider,
  ReportProviderEnum,
  SegmentedPerformanceTotals,
  SegmentedPerformanceTotalsResponse,
} from '@percept/types'

import {
  doubleVerifyAdformReportingDimensionOrder,
  doubleVerifyAdwordsReportingDimensionOrder,
  doubleVerifyFacebookReportingDimensionOrder,
  doubleVerifyProviderMap,
  performanceReportingDimensionOrder,
  providerEnumMap,
} from '@percept/constants'


const googleAdsNetworkEnumMapping: Record<number, GoogleAdsNetwork> = {
  1: 'CONTENT',
  2: 'MIXED',
  3: 'SEARCH',
  4: 'SEARCH_PARTNERS',
  5: 'UNKNOWN',
  6: 'UNSPECIFIED',
  7: 'YOUTUBE_SEARCH',
  8: 'YOUTUBE_WATCH',
}

const googleAdsSubNetworkEnumMapping: Record<number, GoogleAdsSubNetwork> = {
  1: 'DISPLAY',
  2: 'HOTEL',
  3: 'LOCAL',
  4: 'MULTI_CHANNEL',
  5: 'SEARCH',
  6: 'SHOPPING',
  7: 'SMART',
  8: 'UNKNOWN',
  9: 'UNSPECIFIED',
  10: 'VIDEO',
  11: 'PERFORMANCE_MAX',
  12: 'DISCOVERY',
  13: 'LOCAL_SERVICES',
}

const facebookPublisherPlatformEnumMapping: Record<number, FacebookPublisherPlatform> = {
  1: 'FACEBOOK',
  2: 'INSTAGRAM',
  3: 'AUDIENCE_NETWORK',
  4: 'MESSENGER',
  5: 'UNKNOWN',
  6: 'ALL_AUTOMATED_APP_ADS',
}

const facebookSubNetworkEnumMapping: Record<number, FacebookSubNetwork> = {
  1: 'FEED',
  2: 'INSTAGRAM_STORIES',
  3: 'FACEBOOK_STORIES',
  4: 'AN_CLASSIC',
  5: 'INSTANT_ARTICLE',
  6: 'MARKETPLACE',
  7: 'RIGHT_HAND_COLUMN',
  8: 'MESSENGER_INBOX',
  9: 'INSTREAM_VIDEO',
  10: 'INSTAGRAM_EXPLORE',
  11: 'REWARDED_VIDEO',
  12: 'VIDEO_FEEDS',
  13: 'MESSENGER_STORIES',
  14: 'SEARCH',
  15: 'FACEBOOK_GROUPS_FEED',
  16: 'UNKNOWN',
  17: 'ALL_AUTOMATED_APP_ADS',
  18: 'INSTAGRAM_IGTV',
  19: 'INSTAGRAM_REELS',
  20: 'INSTAGRAM_SHOP',
  21: 'FACEBOOK_REELS_OVERLAY',
  22: 'FACEBOOK_REELS',
  23: 'BIZ_DISCO_FEED',
  24: 'ADS_ON_FACEBOOK_REELS',
  25: 'INSTAGRAM_EXPLORE_GRID_HOME',
  26: 'INSTAGRAM_PROFILE_FEED',
  27: 'INSTAGRAM_SEARCH',
  28: 'INSTAGRAM_REELS_OVERLAY',
}

const facebookCampaignObjectiveEnumMapping: Record<number, FacebookCampaignObjective> = {
  1: 'APP_INSTALLS',
  2: 'BRAND_AWARENESS',
  3: 'CONVERSIONS',
  4: 'EVENT_RESPONSES',
  5: 'LEAD_GENERATION',
  6: 'LINK_CLICKS',
  7: 'LOCAL_AWARENESS',
  8: 'MESSAGES',
  9: 'OFFER_CLAIMS',
  10: 'PAGE_LIKES',
  11: 'POST_ENGAGEMENT',
  12: 'PRODUCT_CATALOG_SALES',
  13: 'REACH',
  14: 'STORE_VISITS',
  15: 'VIDEO_VIEWS',
  18: 'CANVAS_APP_ENGAGEMENT',
  19: 'CANVAS_APP_INSTALLS',
  20: 'CATALOGUE_SALES',
  21: 'MOBILE_APP_ENGAGEMENT',
  22: 'MOBILE_APP_INSTALLS',
  23: 'STORE_TRAFFIC',
  24: 'TRAFFIC',
  25: 'OUTCOME_APP_PROMOTION',
  26: 'OUTCOME_AWARENESS',
  16: 'OUTCOME_ENGAGEMENT',
  27: 'OUTCOME_LEADS',
  17: 'OUTCOME_SALES',
  28: 'OUTCOME_TRAFFIC',
}

const adformCampaignTypeEnumMapping: Record<number, AdformCampaignType> = {
  1: 'DISPLAY',
  2: 'RTB',
  3: 'AFFILIATE',
  4: 'MIXED',
  5: 'MICROSOFT',
  6: 'NONCAMPAIGN',
  7: 'GOOGLE',
  8: 'SEARCH_NON_API',
  9: 'EMAIL',
  10: 'SOCIAL_MEDIA',
  11: 'SEARCH_NONAPI',
  12: 'MOBILE',
}

const adformSubNetworkEnumMapping: Record<number, AdformSubNetwork> = {
  1: 'IMAGE',
  2: 'HTML',
  3: 'THIRD_PARTY',
  4: 'MOBILE_STANDARD',
  5: 'DISPLAY_ROLL_FOR_VIDEO',
  6: 'MOBILE_CLICK_TO_EXPAND',
  7: 'NATIVE',
  8: 'MOBILE_INTERSTITIAL',
  9: 'NA',
  10: 'SYNCHRONIZED',
  11: 'DISPLAY_STANDARD',
  12: 'EXPANDABLE_EXPANDABLE_TWO_FILES',
  13: 'INSTREAM',
  14: 'DISPLAY_3D_BOX_MPU',
  15: 'KEYWORD',
  16: 'FLOATING_PRESTITIAL',
  17: 'LINK',
  18: 'FLOATING_FLOATING_AD',
  19: 'MOBILE_CUBE_SWIPE',
  20: 'ADHESION_FLOOR_AD_SINGLE_FILE',
  21: 'EXPANDABLE_EXPANDABLE_SINGLE_FILE',
  22: 'DISPLAY_INVIEW',
  23: 'IAB_BRANDING_IAB_PUSHDOWN_SINGLE_FILE',
  24: 'IAB_BRANDING_IAB_BILBOARD',
}


const dv360MediaTypeEnumMapping: Record<number, DV360MediaType> = {
  1: 'DISPLAY',
  2: 'VIDEO',
  3: 'AUDIO',
}

const dv360ExchangeEnumMapping: Record<number, DV360Exchange> = {
  1: 'GOOGLE_AD_MANAGER',
  2: 'YAHOO_EXCHANGE',
  6: 'PUBMATIC',
  8: 'MAGNITE_DV_PLUS',
  9: 'OPENX',
  10: 'APPNEXUS_XANDR',
  11: 'IMPROVE_DIGITAL',
  13: 'INDEX_EXCHANGE',
  23: 'STRÖER_SSP',
  30: 'SOVRN',
  50: 'TEADSTV',
  60: 'SMART_RTB_PLUS_SMARTADSERVER',
  96: 'TRIPLELIFT',
  59: 'YOUTUBE_AND_PARTNERS',
  63: 'ADFORM',
  38: 'BIDSWITCH',
  42: 'FREEWHEEL_SSP',
  27: 'ONE_BY_AOL_MOBILE',
  21: 'SPOTX',
  17: 'VERIZON_MEDIA_VIDEO_EXCHANGE',
  67: 'YIELDMO',
  90: 'GUMGUM',
  52: 'DAILYMOTION',
  12: 'PULSEPOINT',
  93: 'UNRULYX',
  37: 'SMARTCLIP',
  20: 'MOPUB',
  34: 'YIELDLAB',
  51: 'FLUCT',
  85: 'ADSWIZZ',
  95: 'TRITON',
  98: 'SMAATO',
  77: 'PERMODO',
  91: 'KARGO',
  31: 'MICROAD',
  76: 'TABOOLA',
  97: 'INMOBI',
}


export const performanceComparisons = (
  response: Omit<PerformanceComparisons, 'reference_date'> & {
    reference_date: string
  }
): PerformanceComparisons => {
  return {
    ...response,
    reference_date: new Date(response.reference_date)
  }
}


const resolvePerformanceValue = (
  rawPerformance: RawPerformanceValues,
  dimension: PerformanceReportingDimension
): PerformanceValue => {
  const isAveraged = isAveragingDimension(dimension)

  const coercedDimension = (
    isAveraged ? `avg_${dimension}` : dimension
  ) as PerformanceDimensionType

  const valueKeys = (
    isConvertingDimension(coercedDimension) ?
      [`converted_${coercedDimension}`, coercedDimension] :
      [coercedDimension]
  ) as RawPerformanceReportingDimension[]

  let value: PerformanceValue = null

  for( const valueKey of valueKeys ){

    if( isNumber(rawPerformance[valueKey]) ){
      value = rawPerformance[valueKey]
      break
    }
  }

  return value
}


export const performanceTimeseries = (
  response: PerformanceReporting
): PerformanceTimeseries => {

  const datasets: Record<
    PerformanceReportingDimension, PerformanceTimeseriesDatum[]
  > = performanceReportingDimensionOrder.reduce((acc, dimension) => {

    const mapped: PerformanceTimeseriesDatum[] = (
      response.performance
    ).map( obj => {
      const date = obj.period_start

      const value = resolvePerformanceValue(obj, dimension)

      return {
        label: new Date(date).getTime(),
        value,
        start: new Date(obj.period_start).getTime(),
        end: new Date(obj.period_end).getTime(),
      }
    })

    acc[dimension] = mapped

    return acc
  }, {} as Record<PerformanceReportingDimension, PerformanceTimeseriesDatum[]>)

  return {
    start: new Date(response.date_from).getTime(),
    end: new Date(response.date_to).getTime(),
    datasets,
  }
}


const doubleVerifyDimensionsByProvider: {
  [P in ReportProvider]: DoubleVerifyPerformanceReportingDimension<P>[]
} = {
  adform: doubleVerifyAdformReportingDimensionOrder,
  adwords: doubleVerifyAdwordsReportingDimensionOrder,
  facebook: doubleVerifyFacebookReportingDimensionOrder,
  dv360: [],
}

export const doubleVerifyPerformanceTimeseries = <P extends ReportProvider>(
  response: DoubleVerifyPerformanceReporting,
  provider: P
): DoubleVerifyPerformanceTimeseries<P> => {

  const applicableDimensions = (
    doubleVerifyDimensionsByProvider[provider] as unknown as DoubleVerifyPerformanceReportingDimension<P>[]
  )
 
  const datasets: Record<
    DoubleVerifyPerformanceReportingDimension<P>, PerformanceTimeseriesDatum[]
  > = applicableDimensions.reduce((acc, dimension) => {

    const mapped: PerformanceTimeseriesDatum[] = (
      response.performance
    ).map( obj => {
      const date = obj.period_start

      let value: PerformanceValue = null

      if( isNumber(obj[dimension]) ){
        value = obj[dimension]
      }

      return {
        label: new Date(date).getTime(),
        value,
        start: new Date(obj.period_start).getTime(),
        end: new Date(obj.period_end).getTime(),
      }
    })

    acc[dimension] = mapped

    return acc
  }, {} as Record<DoubleVerifyPerformanceReportingDimension<P>, PerformanceTimeseriesDatum[]>)

  return {
    start: new Date(response.date_from).getTime(),
    end: new Date(response.date_to).getTime(),
    datasets,
  }
  
}



const extractPerformanceSegmentation = <Key extends string, Value extends string | number>(
  response: PerformanceReporting<Record<Key, Value>>,
  key: Key,
): Record<Value, PerformanceTimeseries & Record<Key, Value>> => {

  const responsesBySegment: Record<string, (PerformanceReportingDatum & Record<Key, Value>)[]> = {}

  response.performance.forEach( datum => {
    const segment = datum[key]
    responsesBySegment[String(segment)] = responsesBySegment[String(segment)] || []
    responsesBySegment[String(segment)].push(datum)
  })

  return mapValues(responsesBySegment, (value, segment) => {
    return {
      ...performanceTimeseries({
        ...response,
        performance: value,
      }),
      [key]: segment,
    } as PerformanceTimeseries & Record<Key, Value>
  }) as Record<Value, PerformanceTimeseries & Record<Key, Value>>
  
}

export const performanceTimeseriesByProvider = (
  response: PerformanceReportingByProvider
): PerformanceTimeseriesByProvider => {
  const extracted = extractPerformanceSegmentation<'provider', number>(
    response,
    'provider'
  )

  const withMappedKeys = mapKeys(extracted, (v, k) => {
    return providerEnumMap[k as unknown as ReportProviderEnum]
  })

  const withMappedValues = mapValues(withMappedKeys, (v, k) => {
    return {
      ...v,
      provider: k
    }
  }) as PerformanceTimeseriesByProvider

  return withMappedValues
}

export const channelPerformanceTimeseries = (
  response: ChannelPerformanceReporting
): ChannelPerformanceTimeseries => {
  const { channel } = response
  return {
    channel,
    ...performanceTimeseries(response)
  }
}


export const providerPerformanceTimeseries = (
  response: ProviderPerformanceReporting
): ProviderPerformanceTimeseries => {
  const { provider } = response
  return {
    provider: coerceReportProvider(provider),
    ...performanceTimeseries(response)
  }
}


export const performanceTotals = (
  response: PerformanceTotalsResponse
): PerformanceTotals => {
  return {
    start: new Date(response.date_from).getTime(),
    end: new Date(response.date_to).getTime(),
    dataset: performanceReportingDimensionOrder.reduce((acc, dimension) => {
      acc[dimension] = resolvePerformanceValue(response.performance, dimension)
      return acc
    }, {} as PerformanceDataset),
  }
}


export const getPerformanceTotalsWithSegmentation = <
  Segment extends string,
  SegmentId extends string,
  SegmentValue
>(
    response: SegmentedPerformanceTotalsResponse<SegmentId>,
    segment_id: SegmentId,
    segment: Segment,
    enumMapping: Record<number, SegmentValue>
  ): SegmentedPerformanceTotals<Segment, SegmentValue> => {
  return {
    start: new Date(response.date_from).getTime(),
    end: new Date(response.date_to).getTime(),
    datasets: response.performance.map( perf => {
      const dataset = performanceReportingDimensionOrder.reduce((acc, dimension) => {
        acc[dimension] = resolvePerformanceValue(perf, dimension)
        return acc
      }, {} as PerformanceDataset)
      return {
        ...dataset,
        [segment]: enumMapping[perf[segment_id]],
      }
    }) as SegmentedPerformanceTotals<Segment, SegmentValue>['datasets'],
  }
}

export const performanceTotalsWithNetwork = (
  response: SegmentedPerformanceTotalsResponse<'network_id'>,
  provider: ReportProvider = 'adwords',
): PerformanceTotalsWithNetwork => {

  const enumMapping = (
    provider === 'adform' ?
      adformCampaignTypeEnumMapping :
      provider === 'facebook' ?
        facebookPublisherPlatformEnumMapping :
        provider == 'dv360' ?
          dv360MediaTypeEnumMapping :
          googleAdsNetworkEnumMapping
  )

  return getPerformanceTotalsWithSegmentation(
    response,
    'network_id',
    'network',
    enumMapping,
  )
}

export const performanceTotalsWithSubNetwork = (
  response: SegmentedPerformanceTotalsResponse<'sub_network_id'>,
  provider: ReportProvider = 'adwords',
): PerformanceTotalsWithSubNetwork => {

  const enumMapping = (
    provider === 'adform' ?
      adformSubNetworkEnumMapping :
      provider === 'facebook' ?
        facebookSubNetworkEnumMapping :
        provider === 'dv360' ?
          dv360ExchangeEnumMapping :
          googleAdsSubNetworkEnumMapping
  )

  return getPerformanceTotalsWithSegmentation(
    response,
    'sub_network_id',
    'sub_network',
    enumMapping
  )
}


export const performanceTotalsWithCampaignObjective = (
  response: SegmentedPerformanceTotalsResponse<'extra_network_attr_1_id'>,
  provider: ReportProvider = 'adwords',
): PerformanceTotalsWithCampaignObjective => {

  if( provider !== 'facebook' ){
    throw new Error(`Campaign objective not supported for ${provider}`)
  }

  const enumMapping = facebookCampaignObjectiveEnumMapping

  return getPerformanceTotalsWithSegmentation(
    response,
    'extra_network_attr_1_id',
    'campaign_objective',
    enumMapping
  )
}


export const doubleVerifyProviderPerformanceTimeseries = (
  response: DoubleVerifyProviderPerformanceReporting
): DoubleVerifyProviderPerformanceTimeseries => {
  const doubleVerifyProvider = coerceDoubleVerifyProvider(response.provider)
  const provider = doubleVerifyProviderMap[doubleVerifyProvider]
  return {
    provider,
    ...doubleVerifyPerformanceTimeseries(response, provider),
  }
}

export const adNetworkPerformanceTimeseries = (
  response: AdNetworkPerformanceReporting
): AdNetworkPerformanceTimeseries => {
  const { network } = response
  return {
    network,
    ...performanceTimeseries(response)
  }
}


export const providerReferenceDates = (
  response: {
    provider_id: ReportProviderEnum | DoubleVerifyProviderEnum
    reference_date: string
  }[]
): PlatformUnitProviderInfo => {
  return response.reduce((acc, { provider_id, reference_date }) => {
    acc[providerEnumMap[provider_id]] = {
      reference_date: new Date(reference_date),
    }
    return acc
  }, {} as PlatformUnitProviderInfo)
}
