import { Histogram } from './Histogram'

import { makeChartWithTooltip } from '../makeChartWithTooltip'

import { makeResponsiveChart } from '../makeResponsiveChart'

import { HistogramProps } from './typings'

import { DatumType } from '@percept/types'


export * from './Histogram'

export * from './typings'


export const ResponsiveHistogram = makeResponsiveChart(
  Histogram
)


export const HistogramWithTooltip = makeChartWithTooltip<DatumType, HistogramProps<DatumType>>(
  Histogram
)


export const ResponsiveHistogramWithTooltip = makeResponsiveChart(
  HistogramWithTooltip
)
