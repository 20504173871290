import React, { useState } from 'react'

import { Slider, SliderProps, Tooltip, ValueLabelProps } from '@material-ui/core'

import { withAppStyles } from '../../themes'

import { identity } from 'lodash-es'


export type RangeSliderValue = [number, number]

export type SliderThemeColorProps = {
  color?: SliderProps['color']
}

export type RangeSliderProps = (
  Omit<
    SliderProps, 'value' | 'defaultValue' | 'onChange' | 'onChangeCommitted'
  > & {
    value: RangeSliderValue
    domain: RangeSliderValue
    /**
     * NOTE - `SliderProps['onChange']` is annoyingly typed as optionally a raw HTML span attribute,
     *        so the type overload breaks consumer expectations unless we restrict `onChange` to the
     *        type of the _actual_ change handler.
     */
    onChange: (e: React.ChangeEvent<{}>, value: RangeSliderValue) => void
  }
)

/*
 * NOTE - we need to create these separate HOCs to attain satisfactory
 * render performance with a referentially transparent custom `ValueLabelComponent`
 * for both the MUI Slider theme color options
 */

const PrimaryTooltip = withAppStyles( theme => ({
  tooltip: {
    fontSize: 12,
    fontWeight: 700,
    padding: theme.spacing(0.5, 1),
    borderRadius: '1em',
    minWidth: theme.spacing(3),
    textAlign: 'center',
    marginTop: theme.spacing(1.5),
    background: theme.palette.primary.dark,
  },
}))(Tooltip)


const SecondaryTooltip = withAppStyles( theme => ({
  tooltip: {
    fontSize: 12,
    fontWeight: 700,
    padding: theme.spacing(0.5, 1),
    borderRadius: '1em',
    minWidth: theme.spacing(3),
    textAlign: 'center',
    marginTop: theme.spacing(1.5),
    background: theme.palette.secondary.dark,
  },
}))(Tooltip)


const ValueLabelComponent = ({ children, open, value, color }: ValueLabelProps & SliderThemeColorProps): JSX.Element => {
  const TooltipComponent = color === 'secondary' ? SecondaryTooltip : PrimaryTooltip
  return (
    <TooltipComponent
      open={open}
      enterTouchDelay={0}
      placement='top'
      title={value}>
      { children }
    </TooltipComponent>
  )
}

const PrimaryValueLabelComponent = (props: ValueLabelProps): JSX.Element => (
  <ValueLabelComponent {...props} color='primary' />
)

const SecondaryValueLabelComponent = (props: ValueLabelProps): JSX.Element => (
  <ValueLabelComponent {...props} color='secondary' />
)


export const RangeSlider = ({
  value,
  domain,
  onChange,
  marks,
  color,
  valueLabelFormat = identity,
  ...props
}: RangeSliderProps): JSX.Element => {

  const [localValue, setLocalValue] = useState(value)

  marks = marks || value.map( (d, i) => ({
    value: d,
    label: typeof valueLabelFormat === 'function' ? valueLabelFormat(d, i) : d
  }))

  return (
    <Slider
      min={domain && domain[0]}
      max={domain && domain[1]}
      color={color}
      ValueLabelComponent={
        color === 'secondary' ? SecondaryValueLabelComponent : PrimaryValueLabelComponent
      }
      marks={marks}
      {...props}
      valueLabelFormat={valueLabelFormat}
      value={localValue}
      onChange={(e, update): void => setLocalValue(update as RangeSliderValue)}
      onChangeCommitted={onChange as SliderProps['onChangeCommitted']} />
  )
}
