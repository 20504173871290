import {
  AdminListingUser, ApiResponse, ApiStatusResponse, CognitoConfiguration, CognitoConfigurationParams, UserAttributes,
} from '@percept/types'

import { createSelector } from 'reselect'

import { Selector } from 'react-redux'

import { TokenPaginatedReducerState } from '../../reducers'

import { tokenPaginationResponseResolver } from '../../selectors'

import { getCognitoConfigurationKey, getCognitoIdKey } from '../../utils'

import { apiInitialState } from '../../constants'

import { get } from 'lodash-es'

import { AdminUsersState } from './typings'


type StoreWithAdminUsers = {
  adminUsers: AdminUsersState
}

type AdminUsersSelector<TProps, TOwnProps = null> = Selector<StoreWithAdminUsers, TProps, TOwnProps>


const defaultAdminUserPaginationResponse: TokenPaginatedReducerState<AdminListingUser[]> = {
  initial: apiInitialState,
  byToken: {},
}


export const getUsersPaginationState: AdminUsersSelector<
  TokenPaginatedReducerState<AdminListingUser[]>,
  CognitoConfigurationParams
> = (state, params) => (
  state.adminUsers.paginatedListing[getCognitoConfigurationKey(params) as CognitoConfiguration]
  || defaultAdminUserPaginationResponse
)

export const getPaginatedUsers = createSelector(
  getUsersPaginationState,
  tokenPaginationResponseResolver,
)


export const getUser: AdminUsersSelector<
  ApiResponse<AdminListingUser>,
  Pick<UserAttributes, 'cognito_id'>
> = (state, params) => (
  get(state.adminUsers.byId, getCognitoIdKey(params) || '', apiInitialState)
)


export const getCreateUser: AdminUsersSelector<
  ApiStatusResponse<AdminListingUser>
> = state => (
  state.adminUsers.create
)

export const getEditUser: AdminUsersSelector<
  ApiStatusResponse<AdminListingUser>
> = state => (
  state.adminUsers.edit
)

export const getResendUserInvite: AdminUsersSelector<
  ApiStatusResponse<AdminListingUser>
> = state => (
  state.adminUsers.resendUserInvite
)
