import { useEffect, useMemo } from 'react'

import { useCurrentUser } from '@percept/hooks'

import { useAppThemeState } from 'hooks'

import { getPlatformUnitHierarchy, useActiveOrgEnvironment } from 'components/Organisation'

import { get } from 'lodash-es'

import {
  AppTheme,
  brandtechTheme,
  perceptTheme,
  vodafoneTheme,
} from '@percept/mui/themes'

import { PlatformUnit } from '@percept/types'


const platformUnitIdThemeMap: Record<string, AppTheme> = {
  /* Vodafone */
  '10143060198421': vodafoneTheme,
  /* Brandtech */
  '5696345096639093586': brandtechTheme,
}


const adminTheme = perceptTheme


export const ThemeSwitcher = (): null => {

  const user = useCurrentUser()

  const {
    defaultPlatformUnitId,
    rootDashboardPlatformUnits,
  } = useActiveOrgEnvironment()

  const defaultHierarchy: PlatformUnit[] = useMemo(() => {
    return getPlatformUnitHierarchy(
      rootDashboardPlatformUnits,
      defaultPlatformUnitId,
    )
  }, [defaultPlatformUnitId, rootDashboardPlatformUnits])

  const rootUnitId = get(defaultHierarchy, [0, 'id'], null)

  const [themeState, setAppTheme] = useAppThemeState()

  useEffect(() => {
    if( user ){
      // Ensure Percept branding only for admins
      if( user.groups && user.groups.includes('Admins') ){
        if( themeState.active !== adminTheme ){
          setAppTheme(adminTheme)
        }
        return
      }
      const unitTheme = rootUnitId ? platformUnitIdThemeMap[rootUnitId] : null
      // Set root unit theme if not active
      if( unitTheme && themeState.active !== unitTheme ){
        setAppTheme(unitTheme)
        return
      }
      // Switch back to default if we've changed from a themed unit to a non-themed unit
      if( !unitTheme && Object.values(platformUnitIdThemeMap).includes(themeState.active) ){
        setAppTheme(themeState.options[0])
        return
      }
    }
  }, [user, rootUnitId, themeState, setAppTheme])

  return null
}
