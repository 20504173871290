import React from 'react'

import { cyan, lightBlue, purple, pink } from '@material-ui/core/colors'

import { desaturate, lighten } from 'polished'

import { TPAAuditLogo } from '../components/Logos'

import { makeBrandedAppTheme } from './brandedAppThemes'


const palette = {
  primaryBackground: '#FFF',
  secondaryBackground: 'rgb(250, 250, 250)',
  blue: '#0047AB',
  purple: '#7B217F',
  orange: '#FF8001',
  red: 'rgb(200,50,1)',
  yellow: 'rgb(255,191,10)',
  green: '#238c0c',
}


export const tpaTheme = makeBrandedAppTheme({
  branding: {
    label: 'TPA Audit',
    // eslint-disable-next-line
    LogoComponent: (props) => (
      <TPAAuditLogo
        {...props}
        size={28}
        units='px'
        height={16}
        overflow='visible' />
    ),
  },
  palette: {
    type: 'light',
    primary: {
      main: palette.purple,
      contrastText: '#FFF',
    },
    secondary: {
      main: palette.orange,
    },
    action: {
      active: '#000',
      disabled: 'rgb(150, 150, 150)',
      disabledBackground: 'rgb(230, 230, 230)',
    },
    background: {
      default: palette.primaryBackground,
      paper: palette.secondaryBackground,
    },
    text: {
      primary: '#000',
      secondary: 'rgba(0, 0, 0, 0.65)',
    },
    error: {
      main: palette.red,
    },
    warning: {
      main: palette.yellow,
    },
    success: {
      main: palette.green,
    },
    info: {
      main: lighten(0.2, palette.purple),
    },
    channel: {
      search: {
        light: lightBlue['400'],
        main: desaturate(0.1, lightBlue['700']),
        dark: desaturate(0.1, lightBlue['800']),
        contrastText: '#FFF',
      },
      social: {
        light: purple['400'],
        main: desaturate(0.2, purple['500']),
        dark: desaturate(0.25, purple['800']),
      },
      programmatic: {
        light: pink['400'],
        main: desaturate(0.3, pink['500']),
        dark: desaturate(0.3, pink['800']),
      },
    },
  },
  chart: {
    healthColourStops: [
      palette.red,
      palette.yellow,
      palette.green,
    ],
    informationalColourStops: [
      cyan['500'],
      purple['500'],
    ],
    grid: {
      stroke: 'rgb(220, 220, 220)',
    },
    tooltip: {
      valueColor: '#000',
      valueBackgroundColor: 'rgb(220, 220, 220)',
    },
  },
  typography: {
    fontFamily: 'Montserrat, Helvetica Neue, Arial, Helvetica, sans-serif',
  },
  appComponents: {
    authenticator: {
      root: {},
      heading: {},
      input: {
        color: '#000',
        backgroundColor: 'transparent',
      }
    },
    contrastPanel: {
      root: {
        color: '#FFF',
        backgroundColor: palette.purple,
        borderRadius: 0,
      },
    },
    table: {
      footer: {
        color: '#FFF',
        backgroundColor: '#32373d',
        borderColor: '#4a4949',
      },
    },
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        color: '#FFF',
      },
      colorSecondary: {
        color: '#FFF',
      },
    },
    MuiButton: {
      containedPrimary: {
        color: '#FFF',
      },
      containedSecondary: {
        color: '#FFF',
      },
    },
    MuiCard: {
      root: {
        background: palette.secondaryBackground,
      },
    },
    MuiChip: {
      root: {
        color: '#FFF',
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
      },
    },
    /* @material-ui/pickers */
    
    MuiPickersToolbar: {
      toolbar: {
        color: '#FFF',
        backgroundColor: palette.purple,
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: 'rgba(0,0,0,0.85)',
        fontWeight: 700,
      },
      switchHeader: {
        color: '#000',
      },
      iconButton: {
        color: 'rgba(0,0,0,0.85)',
      },
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: palette.purple,
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: palette.purple,
      },
    },
    MuiPickersClockPointer:{
      pointer: {
        backgroundColor: palette.purple,
      },
      noPoint: {
        backgroundColor: palette.purple,
      },
      thumb: {
        backgroundColor: palette.purple,
        border: `14px solid ${palette.purple}`,
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: palette.purple,
        '&:hover': {
          backgroundColor: palette.purple,
        },
        '@media (hover: none)': {
          '&:hover': {
            backgroundColor: `${palette.purple} !important`,
          },
        },
      },
      dayDisabled: {
        color: 'rgba(0,0,0,0.35)',
      },
    },
    MuiPickersYear: {
      yearDisabled: {
        color: 'rgba(0,0,0,0.35)',
      },
    },

    // MuiTableCell: {
    //   root: {
    //     borderBottom: 'none',
    //   },
    // },

    // MuiToolbar: {
    //   root: {
    //     minHeight: 0,
    //   },
    //   regular: {
    //     minHeight: 0,
    //   },
    //   gutters: {
    //     paddingLeft: '0.5rem',
    //     paddingRight: '0.5rem',
    //   }
    // },
  },
})
