import React, { Fragment } from 'react'

import { useAppTheme } from '../../themes'

import { GenericAxisChart } from '../GenericAxisChart/GenericAxisChart'

import { TooltipIndicator, TooltipProps } from '../Tooltip'

import { useQuantitativeAxes } from './useQuantitativeAxes'

import { useQuantitativeTooltip } from './useQuantitativeTooltip'

import { displayNumber } from '@percept/utils'

import { dayFormatter, getMoneyFormatter, getLabelFormatter, } from '../formatters'

import { identity } from 'lodash-es'

import { SVGDatumType } from '../typings'

import { QuantitativeChartProps, QuantitativeRenderProps } from './typings'
import { Annotations } from './Annotations'


const divStyle: React.CSSProperties = {
  position: 'relative',
}


export function makeQuantitativeChart<
  T extends SVGDatumType = SVGDatumType,
  P extends object = {},
>(
  RenderComponent: (props: QuantitativeRenderProps<T>) => JSX.Element,
  displayName: string,
  defaultProps: Partial<QuantitativeChartProps<T>> = {}
): ((props: QuantitativeChartProps<T> & P) => JSX.Element | null) {

  function QuantitativeChart(chartProps: QuantitativeChartProps<T> & P): JSX.Element | null {

    const mergedProps = {
      ...defaultProps,
      ...chartProps,
    }

    const {
      data,
      datasets,
      domain,
      width,
      height,
      verticalMargin = 20,
      xScaleType,
      yScaleType,
      currency,
      animate = true,
      animateInitial = false,
      curve = 'linear',
      defs,
      backgroundElement,
      foregroundElement,
      fillOverride,
      strokeOverride,
      tooltipLabelDatumFormatter,
      annotations,
      enableTooltip = true,
      svgClassName,
      ...props
    } = mergedProps

    let {
      xTickFormatter,
      yTickFormatter,
    } = mergedProps

    xTickFormatter = xTickFormatter || (
      xScaleType === 'time' ?
        dayFormatter :
        identity
    )

    yTickFormatter = yTickFormatter || (
      yScaleType === 'time' ?
        dayFormatter :
        currency ?
          getMoneyFormatter(currency) :
          displayNumber
    )

    const { xScale, yScale, xAccessor, yAccessor, xOffset, labels } = useQuantitativeAxes({
      xScaleType,
      yScaleType,
      data,
      datasets,
      domain,
      width,
      height,
      verticalMargin,
      ...props
    })

    const labelFormatter = getLabelFormatter({
      ...mergedProps,
      xTickFormatter,
    })

    const yMax = height - verticalMargin
    const xMax = width - xOffset

    const {
      tooltipData,
      tooltipTop,
      tooltipLeft,
      handleTooltip,
      hideTooltip,
      containerRef,
      TooltipComponent,
    } = useQuantitativeTooltip<T>({
      data,
      datasets,
      xScale,
      yScale,
      labels,
      labelFormatter,
      datumLabelFormatter: tooltipLabelDatumFormatter,
    })

    const animationConfig = {
      animate,
      animateInitial,
    }

    const appTheme = useAppTheme()

    if( !(width > 0 && height > 0) ){
      return (
        <div style={divStyle}>
          <svg ref={containerRef} width={width || '100%'} height={height || '100%'} />
        </div>
      )
    }

    return (
      <div style={divStyle}>

        <svg
          ref={containerRef}
          className={svgClassName}
          width={width}
          height={height}
          overflow='visible'>

          { defs && <defs>{ defs }</defs> }

          { backgroundElement || null }

          <GenericAxisChart
            {...props}
            {...animationConfig}
            data={data}
            datasets={datasets}
            xTickFormatter={xTickFormatter}
            yTickFormatter={yTickFormatter}
            xScale={xScale}
            yScale={yScale}
            width={width}
            height={height}
            verticalMargin={verticalMargin}
            xOffset={xOffset}>

            <RenderComponent
              {...props}
              {...animationConfig}
              {...(defs && {
                fillOverride,
                strokeOverride,
              })}
              curve={curve}
              data={data}
              datasets={datasets}
              xScale={xScale}
              yScale={yScale}
              xAccessor={xAccessor}
              yAccessor={yAccessor}
              labels={labels}
              width={xMax}
              height={yMax} />

            { !!(annotations && annotations.length) && (
              <Annotations
                annotations={annotations}
                xScale={xScale}
                yScale={yScale}
                xOffset={xOffset}
                width={xMax}
                height={yMax} />
            )}

          </GenericAxisChart>

          { enableTooltip && (
            <Fragment>

              <rect
                x={xOffset}
                y={0}
                width={xMax}
                height={yMax}
                opacity={0}
                fill='transparent'
                onTouchStart={handleTooltip}
                onTouchMove={handleTooltip}
                onMouseMove={handleTooltip}
                onMouseLeave={hideTooltip} />

              { tooltipData && (
                <TooltipIndicator
                  data={tooltipData}
                  stacked={props.stacked}
                  xScale={xScale}
                  yScale={yScale}
                  x={tooltipLeft}
                  y={tooltipTop}
                  stroke={appTheme.palette.action.disabled}
                  height={yMax} />
              )}

            </Fragment>
          )}

          { foregroundElement || null }

        </svg>

        { enableTooltip && TooltipComponent && (
          <TooltipComponent
            valueFormatter={yTickFormatter as TooltipProps['valueFormatter']} />
        )}

      </div>
    )
  }

  QuantitativeChart.displayName = `${displayName}QuantitativeChart`

  return QuantitativeChart

}
