import { Tab, Tabs } from '@material-ui/core'
import { useNavigation } from '@percept/hooks'
import { makeAppStyles } from '@percept/mui'
import { useOrgNavigation } from 'components/Organisation'
import React from 'react'
import { useLocation } from 'react-router'

const useTabClasses = makeAppStyles((theme) => ({
  root: {
    fontSize: 13,
    textTransform: 'none',
    fontWeight: 700,
    minHeight: theme.spacing(6.5),
    color: 'white',
  },
  indicator: (
    theme.appComponents.appBarTabIndicator ?
      theme.appComponents.appBarTabIndicator.colorSecondary :
      {
        backgroundColor: theme.palette.secondary.main,
      }
  ),
}))

enum PlatformDashboards {
  Optimisation = '/dashboards',
  Reporting = '/reporting',
}

export const PlatformDashboardsTabs = (): JSX.Element => {
  const { activePlatformUnit, ready } = useOrgNavigation()

  const activeOrgId = activePlatformUnit && activePlatformUnit.id

  const tabClasses = useTabClasses()
  const navigate = useNavigation()
  const { pathname } = useLocation()

  const activeTab = pathname.startsWith(PlatformDashboards.Reporting)
    ? PlatformDashboards.Reporting
    : PlatformDashboards.Optimisation

  if (!ready) {
    return <></>
  }

  return (
    <Tabs
      key={activeOrgId || 'tabs-loading'}
      classes={tabClasses}
      value={activeTab}
      indicatorColor='secondary'
      onChange={(e, value: PlatformDashboards): void => {
        if (activeOrgId) {
          navigate(`${value}/${activeOrgId}`)
        }
      }}>
      <Tab
        value={PlatformDashboards.Optimisation}
        classes={tabClasses}
        label='Optimisation'
      />
      <Tab
        value={PlatformDashboards.Reporting}
        classes={tabClasses}
        label='Reporting'
      />
    </Tabs>
  )
}
