import React, { useState, useMemo, Fragment } from 'react'

import {
  Card,
  CardContent,
  CardStrip,
  Grid,
  Typography,
  ChipMenu,
  makeAppStyles,
  useChannelBackgroundStyles,
  deriveCardStripColor,
  useAppTheme,
  ProviderLogo,
  ReportRange,
  RoundedPlainTextButton,
  Box,
} from '@percept/mui'

import {
  ChartData,
  dmyFormatter,
  LineProps,
  longDayMonthYearFormatter,
  percentageFormatter,
  ResponsiveLine,
  SVGDatumType,
} from '@percept/mui/charts'

import { dimensionIcons } from '@percept/mui/icons'

import { PerformanceTable } from 'components/Tables'

import { ChannelHeader, ChannelHeaderProps } from 'components/ChannelHeader'

import { getDefaultPerformanceReportingDomain } from 'components/CrossChannelDashboard'

import { getPerformanceDimensionOptions } from 'components/PerformanceDimensionSelect'

import { isMonetaryDimension, isPercentageDimension, isRateBasedDimension } from '@percept/utils'

import { format, isBefore } from 'date-fns'

import { channelDisplayMap, dimensionMap } from '@percept/constants'

import { AnyPerformanceDimension, DoubleVerifyPerformanceReportingDimension, PerformanceComparisonRange } from '@percept/types'

import { ChannelSummaryType } from './typings'

import { get } from 'lodash-es'

import { TimelineDatum } from 'types'


export type ChannelSummaryItemProps = (
  ChannelSummaryType &
  Pick<
    ChannelHeaderProps, 'provider' | 'seriesListing' | 'onSeriesClick'
  > & {
    availableDimensions: AnyPerformanceDimension[]
    includeDoubleVerify?: boolean
    doubleVerifyDimensions?: DoubleVerifyPerformanceReportingDimension[] | null
    doubleVerifyReferenceDate?: Date | null
    setReferenceDate?: (referenceDate: Date) => void
    performanceComparisonRange?: PerformanceComparisonRange
  }
)


const useStyles = makeAppStyles( theme => ({
  card: {
    marginBottom: theme.spacing(0.5),
    '&:last-child': {
      marginBottom: theme.spacing(3),
    },
  },
  typography: {
    margin: theme.spacing(2, 1),
  },
  tableCard: {
    marginTop: theme.spacing(1),
    // flexGrow: 1,
  },
  tableCardContent: {
    padding: theme.spacing(0, 1),
  },
  gridContainer: {
    minHeight: '100%',
    alignItems: 'baseline',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexGrow: {
    flexGrow: 1
  },
  chartGridItem: {
    alignSelf: 'flex-end',
    marginTop: theme.spacing(3),
  },
  chartCardHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  chartCardMenu: {
    marginLeft: 'auto',
  },
  chartCardTypography: {
    display: 'inline-flex',
    alignItems: 'center',
    flex: '0 0 auto',
  },
  providerLogo: {
    marginRight: theme.spacing(1),
  },
  chart: {
    margin: theme.spacing(4, 1, 0, 0.5),
  },
  doubleVerifyHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 18,
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  doubleVerifyLogo: {
    marginLeft: theme.spacing(0.75),
    position: 'relative',
    top: 1.5,
  },
  doubleVerifyTable: {
    marginTop: theme.spacing(0),
  },
  doubleVerifyTrigger: {
    marginTop: theme.spacing(2),
    height: 22,
  },
}) )


const lineProps: Partial<LineProps<TimelineDatum>> = {
  arbitraryTimeline: true,
  grid: 'rows',
  xTickFormatter: longDayMonthYearFormatter,
  // eslint-disable-next-line react/display-name
  tooltipLabelDatumFormatter: (d) => {
    return (
      <ReportRange
        start={d.start}
        end={d.end} />
    )
  }
}

const rateBasedLineProps: Partial<LineProps> = {
  yTickFormatter: percentageFormatter,
  domain: [0, 100],
}


export const ChannelSummaryItem = ({
  channel,
  referenceDate,
  doubleVerifyReferenceDate,
  performanceComparisonRange,
  setReferenceDate,
  currency,
  provider,
  datasets,
  availableDimensions,
  doubleVerifyDimensions,
  includeDoubleVerify,
  seriesListing,
  onSeriesClick,
}: ChannelSummaryItemProps): JSX.Element => {

  const dimensionOptions = getPerformanceDimensionOptions([
    ...availableDimensions,
    ...(doubleVerifyDimensions || []),
  ])

  const [activeDimension, setActiveDimension] = useState<AnyPerformanceDimension>(availableDimensions[0])

  const [defaultXDomain] = useState(getDefaultPerformanceReportingDomain)

  const appTheme = useAppTheme()

  const isRateBased = isRateBasedDimension(activeDimension)

  const isPercentageBased = isPercentageDimension(activeDimension)

  const lineDataset: ChartData = useMemo(() => {
    const color = deriveCardStripColor({ color: channel, appTheme })

    return get(datasets, [activeDimension, 'timeseries'], []).map( d => ({
      ...d,
      value: (
        isRateBased ?
          d.value && (Number(d.value) * 100) :
          d.value
      ),
      color,
    }))
  }, [datasets, activeDimension, channel, appTheme, isRateBased])

  const requiresDoubleVerifyTrigger = !!(
    doubleVerifyReferenceDate
    && referenceDate
    && isBefore(doubleVerifyReferenceDate, new Date(referenceDate))
  )

  const classes = useStyles()

  const label = provider ? (
    <ProviderLogo
      className={classes.providerLogo}
      size={1.35}
      provider={provider} />
  ) : channelDisplayMap[channel].label

  const channelStyles = useChannelBackgroundStyles()

  const TriggerIconComponent = dimensionIcons[activeDimension]

  return (
    // <Grid className={classes.gridContainer} container spacing={3}>
    <Fragment>

      {/* <Grid item container spacing={1}> */}

      <Grid className={classes.flexColumn} item xs={12}>

        <ChannelHeader
          channel={channel}
          provider={provider}
          color={channel}
          seriesListing={seriesListing}
          onSeriesClick={onSeriesClick} />

        {/* </Grid>

      <Grid item xs={12}> */}
        <Card className={classes.tableCard}>
          <CardContent className={classes.tableCardContent}>

            <PerformanceTable
              referenceDate={referenceDate}
              performanceComparisonRange={performanceComparisonRange}
              rows={
                availableDimensions.filter(
                  d => !!datasets[d]
                ).map(
                  k => ({
                    ...datasets[k].relative,
                    dimension: k,
                    currency,
                  })
                )
              } />

            { includeDoubleVerify && (
              <Fragment>

                <Box
                  mt={1}
                  ml={1}
                  display='flex'
                  alignItems='center'
                  flexWrap='wrap'>

                  <Typography
                    className={classes.doubleVerifyHeader}
                    variant='h5'>
                    Brand Safety by

                    <ProviderLogo
                      className={classes.doubleVerifyLogo}
                      provider='doubleverify'
                      size={1}
                      units='em' />

                  </Typography>

                  { requiresDoubleVerifyTrigger && doubleVerifyReferenceDate && setReferenceDate && (
                    <RoundedPlainTextButton
                      className={classes.doubleVerifyTrigger}
                      color='primary'
                      variant='contained'
                      size='small'
                      onClick={(): void => {
                        setReferenceDate(doubleVerifyReferenceDate)
                      }}>
                      Available up to {format(doubleVerifyReferenceDate, 'dd/MM/yy')}
                    </RoundedPlainTextButton>
                  )}

                </Box>

                { doubleVerifyDimensions && (
                  <PerformanceTable
                    className={classes.doubleVerifyTable}
                    referenceDate={referenceDate}
                    performanceComparisonRange={performanceComparisonRange}
                    rows={
                      doubleVerifyDimensions.filter(
                        d => !!datasets[d]
                      ).map(
                        k => ({
                          ...datasets[k].relative,
                          dimension: k,
                          currency,
                        })
                      )
                    } />
                )}

              </Fragment>
            )}

          </CardContent>
        </Card>
      </Grid>

      {/* </Grid> */}

      <Grid item xs={12} className={classes.chartGridItem}>

        <Card>

          <CardStrip
            color={channel} />

          <CardContent>

            <div className={classes.chartCardHeader}>
              <Typography
                className={classes.chartCardTypography}
                variant='h5'>
                { label } 12 months
              </Typography>

              <ChipMenu
                value={activeDimension}
                label={dimensionMap[activeDimension].text}
                TriggerProps={{
                  className: (
                    `${classes.chartCardMenu} ${channelStyles[channel]}`
                  ),
                  icon: <TriggerIconComponent />,
                  size: 'small',
                  color: 'default',
                }}
                options={dimensionOptions}
                onChange={(e, value): void => {
                  setActiveDimension(value as AnyPerformanceDimension)
                }} />
            </div>

            <div className={classes.chart}>
              <ResponsiveLine
                data={lineDataset || []}
                defaultXDomain={defaultXDomain}
                {...lineProps as Partial<LineProps<SVGDatumType>>}
                axisLine
                axisText
                numYTicks={5}
                height={160}
                xScaleType='time'
                fillOpacity={0.2}
                currency={isMonetaryDimension(activeDimension) ? currency : null}
                {...(isRateBased && rateBasedLineProps || {})}
                yTickFormatter={isPercentageBased ? percentageFormatter : undefined}
                tooltipLabelFormatter={dmyFormatter} />
            </div>

          </CardContent>

        </Card>

      </Grid>
    
      {/* </Grid> */}
    </Fragment>
  )
}
