
import React, { useMemo } from 'react'

import { capitalize, Divider, MenuItem, Typography } from '@material-ui/core'

import { makeAppStyles, useChannelColorStyles, useChannelBackgroundStyles } from '../../themes'

import { PlainTextButtonMenu, MenuAbstractionProps } from '../Menus'

import { ProviderLogo } from '../Logos'

import { ChannelKey, Dictionary, ReportSeries } from '@percept/types'

import { every, flatten } from 'lodash-es'

import { providerChannelMap } from '@percept/constants'


const useStyles = makeAppStyles( theme => ({
  subheader: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(4),
    margin: theme.spacing(1, 0),
    '&:focus': {
      outline: 0
    },
    '&:not(:first-child)': {
      marginTop: theme.spacing(2),
    },
  },
  listItem: {
    paddingLeft: theme.spacing(4),
    fontSize: 13,
    fontWeight: 700,
  },
}) )


const channelOrder: ChannelKey[] = [
  'search', 
  'social',
  'programmatic'
]


const areSeriesEqual = (a: ReportSeries | undefined, b: ReportSeries | undefined): boolean => (
  Boolean(a && b && a.id === b.id)
)


type ExpectedTriggerProps = {
  disabled?: boolean
  className?: string
} & Dictionary  // It's more helpful to be permissive and let consumers type their own extraneous props 


export type SeriesSelectProps = {
  series: ReportSeries[]
  MenuComponent?: React.ComponentType<MenuAbstractionProps<ExpectedTriggerProps, ReportSeries>>
  channelFilter?: ChannelKey
  placeholder?: React.ReactNode
} & Omit<MenuAbstractionProps<ExpectedTriggerProps, ReportSeries>, 'options'>


export function SeriesSelect({
  value,
  series,
  onChange,
  MenuComponent = PlainTextButtonMenu,
  channelFilter,
  TriggerProps = {},
  placeholder = 'Select Series',
  ...props
}: SeriesSelectProps): JSX.Element {

  const seriesByChannel = useMemo(() => {
    const byChannel = series.reduce( (acc, s) => {
      const channel = providerChannelMap[s.provider.slug]
      acc[channel] = (
        acc[channel] || []
      ).concat(s) 
      return acc
    }, {} as Partial<Record<ChannelKey, ReportSeries[]>>)

    if( channelFilter ){
      return [
        {
          channel: channelFilter,
          series: byChannel[channelFilter] || []
        }
      ]
    }

    return channelOrder.map( channel => ({
      channel,
      series: byChannel[channel] || [] as ReportSeries[],
    })).filter( c => !!c.series.length )

  }, [series, channelFilter])

  const classes = useStyles()

  const colorClasses = useChannelColorStyles()

  const backgroundClasses = useChannelBackgroundStyles()

  return (
    <MenuComponent
      value={value}
      isEqual={areSeriesEqual}
      label={value && <ProviderLogo size={1.5} units='rem' provider={value.provider.slug} /> || placeholder}
      TriggerProps={{
        ...TriggerProps,
        disabled: (
          every(seriesByChannel, s => s.series.length === 0) || !!TriggerProps.disabled
        ),
      }}
      {...props}>

      { ({ onClose }): JSX.Element[] => {

        return flatten(seriesByChannel.map( ({ channel, series }, i) => {

          return (
            [

              <Typography
                key={channel}
                variant='subtitle1'
                className={`${classes.subheader} ${colorClasses[channel]}`}>
                { capitalize(channel) }
              </Typography>,

              ...series.map( s => {
                const selected = (value && s.id === value.id)
                return (
                  <MenuItem
                    button
                    className={
                      `${classes.listItem} ${selected ? backgroundClasses[channel] : ''}`
                    }
                    key={s.id}
                    onClick={
                      onChange ?
                        ((e): void => {
                          onChange(e, s)
                          onClose()
                        }) :
                        undefined
                    }>
                    <ProviderLogo size={1.5} units='rem' provider={s.provider.slug} />
                  </MenuItem>
                )
              }),

              false && i < seriesByChannel.length - 1 && (
                <Divider />
              ),
            ].filter( (e): e is JSX.Element => !!e )
          )
        }))

      }}

    </MenuComponent>
  )
}
