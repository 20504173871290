
import qs from 'query-string'

import {
  useEffect,
  useCallback,
  useMemo,
} from 'react'

import {
  push as pushAction,
  replace as replaceAction,
  goBack as popAction,
} from 'connected-react-router'

import {
  useDispatch,
  useSelector,
} from 'react-redux'

import {
  makeEntityLoadHook,
  makeUpdateHook,
  makeUpdateHookWithArguments,
  makeUpdateAndResetHook,
  makeEntityMutationHook,
  makeSelectorHook,
  normalizeAdwordsAccountHierarchy,
  makeEntityDeletionHook,
} from './lib'

import {
  makeLoaderHook,
  makePropLoaderHook,
  makePropSelectorHook,
  makeSynchronousHook,
  PropsValidator,
} from './libv2'

import { metricMetadata, series, seriesGroups, layouts, structuralReporting } from '@percept/redux/bundles'

// import seriesGroups from '@percept/redux/bundles/seriesGroups'

import {
  getSeriesGroups,
  getSeriesGroup,
  getSeriesGroupDetail,
  getActiveSeriesGroupSeries,
  getCreateSeries,
  getAdwordsSeriesSetup,
  
} from '@percept/redux/bundles/seriesGroups/selectors'

import seriesSelectors from '@percept/redux/bundles/series/selectors'

const {
  getSeries,
  getSeriesAudits,
  getCreateReport,
  getSeriesMetrics,
  getSeriesMetric,
  getActiveSeriesMetric,
  getSeriesChartType,
  getSeriesHealthDimension,
  getSeriesPerformanceTail,
  getSeriesMutation,
  getSeriesAccounts,
  getSeriesOAuthUrl,
  getSeriesRefreshToken,
} = seriesSelectors

import auditSelectors from '@percept/redux/bundles/audits/selectors'

const {
  getAudit,
  getAuditEntity,
  getAuditEntityFilter,
  getAuditEntitySort,
  getAuditWrappersById,
  filterPerformance,
  filterHealth,
  filterCurrencyCode,
  getAuditPayload,
  getFilteredHealth,
  getAuditMetric,
  getActiveMetric,
  getActiveSegment,
  getExportMetric,
  getExportType,
  getExportOptions,
  getDisplayType,
  getMetricOrder,
} = auditSelectors

import {
  getMetricMetadata
} from '@percept/redux/bundles/metricMetadata/selectors'

import {
  getLayouts,
} from '@percept/redux/bundles/layouts/selectors'

import {
  getAnnotations, getCreateAnnotation, getAttachedAnnotations, getMutateAnnotation, getDeleteAnnotation, getAnnotationsActive, getAnnotationContext, getEntityAnnotationObject,
} from '@percept/redux/bundles/annotations/selectors'

import {
  getJobPayload
} from '@percept/redux/bundles/jobs/selectors'

import {
  setActiveSeriesGroupSeries,
  createSeries,
  clearCreateSeries,
  completeSeriesSetup,
  clearSeriesSetup,
  createTrialSeries,
  clearCreateTrialSeries,
} from '@percept/redux/bundles/seriesGroups/actions'

import {
  loadSeries,
  loadSeriesAudits,
  createReport,
  clearCreateReport,
  loadSeriesMetric,
  mutateSeries,
  clearMutateSeries,
  loadSeriesAccounts,
  loadSeriesOAuthUrl,
  saveSeriesRefreshToken,
  setActiveSeriesMetric,
  clearActiveSeriesMetric,
  setSeriesChartType,
  setSeriesPerformanceTail,
  setSeriesHealthDimension,
  clearSaveSeriesRefreshToken,
  createInitialReport,
  clearCreateInitialReport,
} from '@percept/redux/bundles/series/actions'

import {
  loadAudit,
  loadAuditEntity,
  loadAuditPayload,
  loadAuditMetric,
  setActiveMetric,
  clearActiveMetric,
  setActiveSegment,
  clearActiveSegment,
  setExportMetric,
  clearExportMetric,
  setExportType,
  setExportOptions,
  setDisplayType,
  setMetricOrder,
  filterAuditEntities,
  clearAuditEntityFilter,
  sortAuditEntities,
} from '@percept/redux/bundles/audits/actions'

import {
  loadMetricMetadata,
} from '@percept/redux/bundles/metricMetadata/actions'

import {
  loadLayouts,
} from '@percept/redux/bundles/layouts/actions'

import {
  loadAnnotations,
  addAnnotation,
  clearAddAnnotation,
  editAnnotation,
  clearEditAnnotation,
  deleteAnnotation,
  clearDeleteAnnotation,
  toggleAnnotations,
  setAnnotationContext,
  clearAnnotationContext,
} from '@percept/redux/bundles/annotations/actions'

import {
  loadJobPayload,
} from '@percept/redux/bundles/jobs/actions'

import {
  getNotifications, getToasts
} from '@percept/redux/bundles/notifications/selectors'

import {
  addNotification,
  removeNotification,
  removeAllNotifications,
  AddNotificationParams,
  AddToastParams,
  addToast,
  removeToast,
  removeAllToasts,
} from '@percept/redux/bundles/notifications/actions'

import {
  getUserLoggedIn,
  getUserAttributes,
} from '@percept/redux/bundles/user/selectors'

import {
  getDeeplinkParameters
} from '@percept/redux/bundles/chromeExtension/selectors'

import {
  storeDeeplinkParameters
} from '@percept/redux/bundles/chromeExtension/actions'

import {
  shouldAttemptLoad,
  getPath,
  isObject,
  isNumber,
  all,
  collectMetricIdsFromLayout,
  trim,
  size,
  takeWhile,
  getHealthFrom,
  any,
  findNext,
} from '@percept/utils'

import { usePrevious } from './genericHooks'

import {
  ApiResponse,
  ReportPayload,
  Dictionary,
  ReduxAction,
  StoreState,
  SeriesGroup,
  Selector,
  SeriesGroupDetailItem,
  Series,
  SeriesAudit,
  Report,
  ReportStatus,
  HealthType,
  LocationParams,
  AnnotationPoints,
  SeriesMetric,
  ReportEntity,
  EntityType,
  DimensionType,
  MetricsPayload,
  ApiStatusResponse,
  AdwordsAccountHierarchy,
  ReduxDispatcher,
  Notification,
  MetricMetadataType,
  Annotation,
  AnnotationObject,
  ApiLoader,
  TrackingProps,
  AuditProvider,
  AppBranding,
  MetricOrder,
  HydratedSeries,
  Toast,
} from '@percept/types'

import { Location } from 'history'

import { apiInitialState, getEntityKey } from '@percept/redux'


// Memoization helper
const EMPTY_OBJECT: {} = {}


export type DocumentTitleHookProps = {
  paths?: (string | null | false | undefined)[],
  site?: string,
  test?: boolean,
  separator?: string
}


const brandingTitleMap: Record<AppBranding, string> = {
  PERCEPT: 'Percept',
  TPA: 'TPA Audit',
  BRANDTECH: 'Brandtech Media Group',
  VODAFONE: 'Vodafone Media Wizard',
}

export const useDocumentTitle = ({
  paths = [],
  site = brandingTitleMap[process.env.BRANDING],
  test = true,
  separator = ' | '
}: DocumentTitleHookProps = {}): void => {

  const title = (
    !test || all(paths) ?
      [site, ...paths].join(separator) :
      ''
  )

  return useEffect(() => {
    if( title ){
      document.title = title
    }
  }, [title])

}

export const useAdminDocumentTitle = (props: DocumentTitleHookProps): void => (
  useDocumentTitle({ site: 'Percept Admin', ...props })
)


type SearchParams = Dictionary<string | number | null | boolean | undefined>

type Navigate = (
  route: string,
  options?: {
    replace?: boolean,
    search?: string | SearchParams
  }
) => void

export const useNavigation = (): Navigate => {
  const dispatch = useDispatch()

  // const currentLocation = useLocation()

  // const currentRoute = currentLocation.pathname + currentLocation.search

  // const previousRoute = usePrevious(currentRoute)

  return useCallback<Navigate>((
    route: string,
    { replace, search }: { replace?: boolean, search?: string | SearchParams } = {}
  ) => {

    let suffix = ''

    if( search ){

      if( typeof search === 'string' ){

        suffix = search

      }else if( isObject(search) ){

        const searchObject = Object.keys(search).reduce( (acc: SearchParams, k) => {
          const param = search[k]
          if( (typeof param === 'string' && param.length) || isNumber(search[k]) ){
            acc[k] = search[k]
          }
          return acc
        }, {})

        suffix = '?' + qs.stringify(searchObject)

      }
    
    }

    route = route + suffix

    const handler = (
      // route === previousRoute ?
      //   popAction :
      replace ?
        replaceAction :
        pushAction
    )

    dispatch(handler(route))

  }, [dispatch,])
}

const locationSelector = (state: StoreState): Location => state.router.location

export const useLocation = (): Location => useSelector(locationSelector)

const regexesByParam: Record<string, RegExp[]> = {
  org_unit_id: [
    /dashboards\/([^/]+)/,
    /organisations\/([^/]+)/,
  ],
  series_group_id: [
    /series-groups\/([^/]+)/,
  ],
  series_id: [
    /series\/([^/]+)/
  ],
  insights_report_type: [
    /insights-reports\/([^/]+)/
  ],
  report_id: [
    /reports\/([^/]+)/
  ],
  metric_id: [
    /reports\/.*metric_id=([^&]+)/
  ],
  channel: [
    /.*(search|social|programmatic).*/
  ],
  provider: [
    /.*(google-ads|adwords|facebook|adform|dv360).*/
  ],
}

export const useLocationParams = (): LocationParams => {
  const location = useLocation()
  return useMemo(() => {
    const matcher = (location.pathname || '')

    const params = Object.entries(regexesByParam).reduce( (acc: Dictionary<any>, [param, regexes]) => {
      let matches: RegExpMatchArray | null = null
      for( const regex of regexes ){
        matches = matcher.match(regex)
        if( matches ){
          break
        }
      }
      acc[param] = (
        matches && typeof matches[1] === 'string' ?
          decodeURIComponent(matches[1]) :
          null
      )

      return acc
    }, {})

    return {
      ...params,
      ...(qs.parse(location.search))
    } as LocationParams
  }, [location.pathname, location.search])
}

export const useTracking = (): ((payload: TrackingProps) => void) => {
  const dispatch = useDispatch()

  return useCallback( payload => {
    dispatch({
      type: 'TRACK',
      payload,
    } as ReduxAction)
  },
  [dispatch])
}

export const useLoggedIn = makeSelectorHook<boolean>(getUserLoggedIn)

export const useCurrentUser = makeSelectorHook(getUserAttributes)

export const useSeriesGroups = makeLoaderHook(
  seriesGroups.selectors.getSeriesGroups,
  seriesGroups.actions.loadSeriesGroups,
  { autoload: true }
)

/**
 * NOTE: 
 * 
 * We expose an optional report limit, which can be configured via action creator params.
 * This _currently_ isn't changed during the lifetime of the app, and so we don't need to
 * store or query multiple variants of series group listing.
 * To opt into this variance explicitly in the future, and also to maintain TypeScript functionality, we expose
 * separate selectors / hooks etc for limited / non-limited series groups.
 * In practice, `useLimitedSeriesGroups` won't be required for the time being,
 * but should be configured to support all features of the API.
 */
export const useLimitedSeriesGroups = makePropLoaderHook(
  seriesGroups.selectors.getSeriesGroups,
  seriesGroups.actions.loadSeriesGroups,
  {
    autoload: true,
    validate: (
      (props): boolean => !!(props && props.reportLimit)
    ) as PropsValidator<{ reportLimit?: number }>
  }
)

export const useSeriesGroup = makePropLoaderHook(
  seriesGroups.selectors.getSeriesGroup,
  seriesGroups.actions.loadSeriesGroup,
  { autoload: true }
)

export const useSeriesGroupDetail = makePropLoaderHook(
  seriesGroups.selectors.getSeriesGroupDetail,
  seriesGroups.actions.loadSeriesGroupDetail,
  { autoload: true }
)


// TODO: Deprecate this hook? No usage determined in the codebase currently
// export const useActiveSeriesGroupSeries = makeUpdateHookWithArguments(
//   (state, { series_group_id }) => getActiveSeriesGroupSeries(state, series_group_id),
//   setActiveSeriesGroupSeries,
// )



export const useSeries = makeEntityLoadHook({
  selector: getSeries,
  actionCreator: loadSeries,
  id: 'series_id',
  deps: ['series_id'],
  test: true
})

export const useSeriesMutation = makeEntityMutationHook({
  selector: getSeriesMutation,
  actionCreator: mutateSeries,
  clearActionCreator: clearMutateSeries,
  id: 'series_id',
  deps: ['series_id'],
  test: true
})

export const useSeriesOAuthUrl = makeEntityLoadHook({
  selector: getSeriesOAuthUrl,
  actionCreator: loadSeriesOAuthUrl,
  id: 'series_id',
  deps: ['series_id'],
  test: true,
  autoload: false
})

export const useSaveSeriesRefreshToken = makeEntityMutationHook({
  selector: getSeriesRefreshToken,
  actionCreator: saveSeriesRefreshToken,
  clearActionCreator: clearSaveSeriesRefreshToken,
  id: 'series_id',
  deps: ['series_id'],
  test: true
})

export const useSeriesAudits_ = makeEntityLoadHook({
  selector: getSeriesAudits,
  actionCreator: loadSeriesAudits,
  id: 'series_id',
  deps: ['series_id'],
  test: true
})

// TODO: Temporary patch for using series groups endpoint to populate series audits listing
export const useSeriesAudits = (
  { series_id }: { series_id: string }
): [ApiResponse<SeriesAudit[]>, ApiLoader<{ series_id: string }>] => {
  const [seriesGroups, sync] = useSeriesGroups()

  const seriesAudits = useMemo<ApiResponse<SeriesAudit[]>>(() => {
    if( seriesGroups.data ){
      let matchedSeries: HydratedSeries | null = null
      for( const group of seriesGroups.data ){
        for( const series of (group.series || []) ){
          if( series.series_id === series_id ){
            matchedSeries = series
            break
          }
        }
      }

      return {
        ...seriesGroups,
        data: matchedSeries ? matchedSeries.reports : [],
      }
    }
    return {
      ...seriesGroups,
      data: null,
    }
  }, [seriesGroups, series_id])

  return [seriesAudits, sync]
}


const makeSeriesOverviewsHook = (statuses: ReportStatus[]) => (
  { series_id, chronological = true }:
  { series_id: string | null, chronological?: boolean }
): SeriesAudit[] => {

  const seriesAudits = useSelector<Dictionary<any>, ApiResponse<SeriesAudit[]>>(
    state => getSeriesAudits(state, series_id)
  )

  return useMemo(() => {
    const overviews = (seriesAudits.data || []).filter(
      audit => statuses.indexOf(audit.status) !== -1
    )
    return chronological ? overviews : overviews.reverse()
  }, [seriesAudits.data, chronological])

}

export const useSeriesOverviews = makeSeriesOverviewsHook(['SCHEDULED', 'COMPLETED', 'CANCELLED'])

export const useSeriesPerformanceOverviews = makeSeriesOverviewsHook(['COMPLETED'])


export const useAudit = makeEntityLoadHook({
  makeSelector: ({ report_id }: { report_id: string }) => (
    (state): ApiResponse<Report> => getAudit(state, report_id)
  ),
  actionCreator: loadAudit,
  deps: ['report_id'],
  test: true
})


export const useCreateReport = makeEntityMutationHook({
  makeSelector: ({ series_id }: { series_id: string }) => (
    (state): ApiStatusResponse<Report> => getCreateReport(state, series_id)
  ),
  actionCreator: createReport,
  clearActionCreator: clearCreateReport,
  deps: ['series_id'],
  test: true,
})

export const useCreateInitialReport = makeEntityMutationHook({
  makeSelector: ({ series_id }: { series_id: string }) => (
    (state): ApiStatusResponse<Report> => getCreateReport(state, series_id)
  ),
  actionCreator: createInitialReport,
  clearActionCreator: clearCreateInitialReport,
  deps: ['series_id'],
  test: true,
})


export const useAuditPayload = makeEntityLoadHook({
  makeSelector: ({ report_id }) => (state): ApiResponse<ReportPayload> => getAuditPayload(state, report_id),
  actionCreator: loadAuditPayload,
  deps: ['report_id'],
  test: true,
})

export const useAuditEntity = makeEntityLoadHook({
  makeSelector: (params) => (state): ApiResponse<ReportEntity> => getAuditEntity(state, params),
  actionCreator: loadAuditEntity,
  deps: ['report_id', 'entity_type', 'entity_id'],
  test: true,
})

export const useAuditMetric = makeEntityLoadHook({
  makeSelector: (params) => (state): ApiResponse => getAuditMetric(state, params),
  actionCreator: loadAuditMetric,
  deps: ['report_id', 'entity_type', 'entity_id', 'metric_id', 'dimension'],
  test: true,
})

/* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */
export const useActiveAuditMetric = (
  { report_id }:
  { report_id: string | null }
) => { 
  const activeSegment = useSelector(getActiveSegment)

  return useAuditMetric({ report_id, ...(activeSegment || {}) })
}


export const useAuditHealth = (
  { report_id, entity_type, entity_id }:
  { report_id: string | null, entity_type: string | null, entity_id: string | null }
): number | null => (
  useSelector<Dictionary<any>, HealthType>(
    useCallback((state) => {
      return getFilteredHealth(state, report_id, { type: entity_type, id: entity_id })
    }, [report_id, entity_type, entity_id])
  )
)


export const useAuditData = ({ report_id, entity_type, entity_id }) => {

  const [payload] = useAuditPayload({ report_id })

  const [entity] = useAuditEntity({ report_id, entity_type, entity_id })

  const health = useAuditHealth({ report_id, entity_type, entity_id })

  return useMemo(() => {

    const { performance = null, currency_code = null } = getPath(
      payload.data,
      ['entities', entity_type, entity_id],
      EMPTY_OBJECT
    )
    
    // The entity reference is used as a loading indicator for the given entity.
    // Metrics are currently the entity's payload but may not be at some point,
    // so mapping that here exposes a better component API
    return {
      health,
      performance,
      currency_code,
      entity,
      metrics: entity.data ? (entity.data.metrics || EMPTY_OBJECT) : EMPTY_OBJECT
    }

  }, [entity, health, payload.data, entity_type, entity_id])

}


export const useDisplayType = makeUpdateHook(
  getDisplayType,
  setDisplayType
)


export const useSeriesChartType = makeUpdateHook(
  getSeriesChartType,
  setSeriesChartType
)


export const useSeriesPerformanceTail = makeUpdateHook(
  getSeriesPerformanceTail,
  setSeriesPerformanceTail,
)


export const useSeriesHealthDimension = makeUpdateHook(
  getSeriesHealthDimension,
  setSeriesHealthDimension,
)


export const useMetricOrder = makeUpdateHook<MetricOrder>(
  getMetricOrder,
  setMetricOrder
)


export const useMetricSeries = makeEntityLoadHook({
  makeSelector: (props) =>
    (state): ApiResponse<SeriesMetric[]> => getSeriesMetric(
      state, props
    ),
  actionCreator: loadSeriesMetric,
  deps: ['series_id', 'entity_type', 'entity_id', 'metric_id', 'dimension', 'cursor', 'limit'],
  test: true,
})


export const useReportSeries__LEGACY = ({
  enabled = true,
  report_id,
  series_id,
  entity_type,
  entity_id,
  limit,
  countHealthOnly = false,
}: {
  enabled?: boolean,
  report_id: string | null,
  series_id: string | null,
  entity_type: EntityType | null,
  entity_id: string | null,
  limit?: boolean,
  countHealthOnly?: boolean,
}): Dictionary<Dictionary<{ data: SeriesMetric[] }>> => {

  const dispatch = useDispatch()

  const seriesAudits = useSelector( (state: StoreState) => getSeriesAudits(state, series_id) )

  const seriesAuditsById = (seriesAudits.data || []).reduce( (acc: Dictionary<SeriesAudit>, a: SeriesAudit) => {
    acc[a.report_id] = a
    return acc
  }, {})

  const reportEntity = useSelector( (state: StoreState) => (
    getAuditEntity(state, {
      report_id,
      entity_type,
      entity_id,
    })
  ))

  const entityKey = getEntityKey({ entity_type, entity_id })

  const requiredReportIds = useMemo(() => {
    if( !report_id || !seriesAudits.data ){
      return []
    }
    
    const reportIds = takeWhile(
      (seriesAudits.data || []).filter( (a: SeriesAudit) => a.status === 'COMPLETED' ),
      (a: SeriesAudit) => a.report_id !== report_id
    ).map( a => a.report_id ).concat([report_id])

    if( isNumber(limit) && reportIds.length > limit ){
      return reportIds.slice(reportIds.length - limit)
    }

    return reportIds
  }, [seriesAudits.data, report_id, limit])

  const reportWrappersById = useSelector(getAuditWrappersById)
  
  useEffect(() => {
    if( enabled && entity_type && entity_id ){
      requiredReportIds.forEach( id => {
        const entityResponse = getPath(
          reportWrappersById,
          [id, 'entities', entityKey],
          apiInitialState
        )
        if( shouldAttemptLoad(entityResponse) ){
          dispatch(loadAuditEntity({ report_id: id, entity_type, entity_id }))
        }
      })
    }
  }, [enabled, dispatch, reportWrappersById, entityKey, requiredReportIds, series_id, entity_type, entity_id])


  return useMemo(() => {
    if(
      !(
        enabled && entity_type && entity_id && report_id &&
        Object.keys(seriesAuditsById).length && reportEntity.data
      ) || (
        any(
          Object.values(reportWrappersById),
          wrapper => !!getPath(wrapper.entities[entityKey], 'loading') !== false
        )
      )
    ){
      return EMPTY_OBJECT
    }

    const currentMetricIds = Object.keys(reportEntity.data.metrics || {})

    return currentMetricIds.reduce( (acc: Dictionary<Dictionary<{ data: SeriesMetric[] }>>, metric_id) => {

      acc[metric_id] = {}

      requiredReportIds.forEach( requiredReportId => {
        const report = seriesAuditsById[requiredReportId]

        const reportEntity = getPath(
          reportWrappersById,
          [
            requiredReportId,
            'entities',
            entityKey,
            'data',
          ]
        ) as ReportEntity | null

        if( reportEntity ){

          const metricEntry = getPath(reportEntity, ['metrics', metric_id]) as ReportEntity['metrics']

          const countHealth = getHealthFrom(getPath(metricEntry, 'count'))

          const dimensions = Object.keys(metricEntry || {}) as DimensionType[]

          dimensions.forEach( dimension => {

            acc[metric_id][dimension] = (
              acc[metric_id][dimension] || { data: [] }
            ) as { data: SeriesMetric[] }

            acc[metric_id][dimension].data.push({
              ...report,
              dimension,
              health:
                countHealthOnly ?
                  countHealth :
                  getHealthFrom(getPath(metricEntry, dimension))
              ,
              id: requiredReportId,
              metric: getPath(metricEntry, dimension) || {
                id: metric_id,
                data: null,
                dimension,
              }
            })

          })
        }

      })    

      return acc
    }, {})

  },
  [
    enabled, report_id, countHealthOnly, entity_type, entity_id,
    reportWrappersById, seriesAuditsById, requiredReportIds,
    reportEntity.data, entityKey,
  ])

}


export const useActiveMetricSeriesFilter = (
  props: Dictionary<any>
): [Dictionary | null, (params?: Dictionary | null) => void, () => void] => {
  const dispatch = useDispatch()

  const activeSeriesMetric = useSelector(getActiveSeriesMetric)

  const updater = useCallback( params => {
    dispatch(
      setActiveSeriesMetric({
        ...props,
        ...(activeSeriesMetric || {}),
        ...(params || {})
      })
    )
  }, [dispatch, activeSeriesMetric, props])

  const resetter = useCallback(() => dispatch(clearActiveSeriesMetric()), [dispatch])

  return [activeSeriesMetric, updater, resetter]
}

export const useActiveMetricSeries = (props?: Dictionary<any>) => {
  const activeSeriesMetric = useSelector(getActiveSeriesMetric)

  return useMetricSeries({ ...(activeSeriesMetric || {}), ...(props || {}) })
}


export const useActiveSegment = ({ report_id, entity_type, entity_id } = {}) => {

  const dispatch = useDispatch()

  const activeSegment = useSelector(getActiveSegment)

  const setActive = useCallback( params => {
    if( params && params.segment && params.segment.value ){
      dispatch(setActiveSegment({
        report_id,
        entity_type,
        entity_id,
        ...(activeSegment || {}),
        ...params,
      }))
    }
  }, [dispatch, report_id, entity_type, entity_id, activeSegment])

  const clearActive = useCallback(() => dispatch(clearActiveSegment()), [dispatch])

  return [activeSegment, setActive, clearActive]
  
}


export const useAuditEntitySort = makeUpdateHook(
  getAuditEntitySort,
  sortAuditEntities,
)

export const useAuditEntityFilter = ({ series_id, provider = 'adwords' }) => {
  const dispatch = useDispatch()

  const entityFilter = useSelector(state => getAuditEntityFilter(state, series_id, provider))

  const updateFilter = useCallback( params => {
    params && dispatch(filterAuditEntities({ series_id, ...params }))
  }, [dispatch, series_id])

  const clearFilter = useCallback( () => {
    dispatch(clearAuditEntityFilter({ series_id, provider }))
  }, [dispatch, series_id, provider])

  return [entityFilter, updateFilter, clearFilter]
}


export const useExportMetric = makeUpdateAndResetHook(
  getExportMetric,
  setExportMetric,
  clearExportMetric
)

export const useExportType = makeUpdateHook(getExportType, setExportType)

export const useExportOptions = makeUpdateHook(getExportOptions, setExportOptions)


export const useJobPayload = makeEntityLoadHook({
  makeSelector: ({ task_id }) => {
    return state => getJobPayload(state, task_id)
  },
  actionCreator: loadJobPayload,
  deps: ['task_id'],
  test: true,
})


export const useLayouts = makeEntityLoadHook({
  selector: getLayouts,
  actionCreator: loadLayouts,
})

// export const useLayout = makePropLoaderHook(
//   layouts.selectors.getLayout,
//   layouts.actions.loadLayout,
//   { autoload: true }
// )


export const useNotifications = (): [
  Notification[],
  (n: AddNotificationParams) => void,
  (p: Pick<Notification, 'id'>) => void,
  () => void,
] => {
  const dispatch = useDispatch()

  const notifications = useSelector(getNotifications)

  const add = useCallback((params: AddNotificationParams) => {
    dispatch(addNotification(params))
  }, [dispatch])

  const remove = useCallback((params: Pick<Notification, 'id'>) => {
    dispatch(removeNotification(params))
  }, [dispatch])

  const removeAll = useCallback(() => {
    dispatch(removeAllNotifications())
  }, [dispatch]) as ReduxDispatcher

  return [notifications, add, remove, removeAll]
}


export const useToasts = (): [
  Toast[],
  (n: AddToastParams) => void,
  (p: Pick<Toast, 'id'>) => void,
  () => void,
] => {
  const dispatch = useDispatch()

  const toasts = useSelector(getToasts)

  const add = useCallback((params: AddToastParams) => {
    dispatch(addToast(params))
  }, [dispatch])

  const remove = useCallback((params: Pick<Toast, 'id'>) => {
    dispatch(removeToast(params))
  }, [dispatch])

  const removeAll = useCallback(() => {
    dispatch(removeAllToasts())
  }, [dispatch]) as ReduxDispatcher

  return [toasts, add, remove, removeAll]
}


/* Signup hooks */


export const useCreateSeries = makeEntityMutationHook({
  makeSelector: ({ series_group_id, provider }) => {
    return (state: StoreState): ApiStatusResponse<Series> => getCreateSeries(state, { series_group_id, provider })
  },
  actionCreator: createSeries,
  clearActionCreator: clearCreateSeries,
  deps: ['series_group_id', 'provider'],
  test: true,
})


export const useCreateTrialSeries = makeEntityMutationHook({
  makeSelector: ({ series_group_id, provider }) => {
    return (state: StoreState): ApiStatusResponse<Series> => getCreateSeries(state, { series_group_id, provider })
  },
  actionCreator: createTrialSeries,
  clearActionCreator: clearCreateTrialSeries,
  deps: ['series_group_id', 'provider', 'config', 'name'],
  test: true,
})


export const useSeriesAccounts = makeEntityLoadHook({
  makeSelector: ({ series_id }) => {
    return (state: StoreState): ApiResponse<AdwordsAccountHierarchy[]> => getSeriesAccounts(state, series_id)
  },
  actionCreator: loadSeriesAccounts,
  deps: ['series_id'],
  test: true,
  autoload: false,
})


export const useAdwordsAccountsById = (
  { series_id }: { series_id: string }
): Dictionary<AdwordsAccountHierarchy> => {
  const [{ data }] = useSeriesAccounts({ series_id })
  return useMemo(() => normalizeAdwordsAccountHierarchy(data), [data])
}

export const useAdwordsSeriesSetup = makeEntityMutationHook({
  makeSelector: ({ series_group_id }) => {
    return state => getAdwordsSeriesSetup(state, series_group_id)
  },
  actionCreator: completeSeriesSetup,
  clearActionCreator: clearSeriesSetup,
  deps: ['series_group_id', 'series_id', 'config'],
  test: true,
})


/* Annotations */

export const useAnnotations = makeEntityLoadHook({
  makeSelector: ({ series_id }) => state => getAnnotations(state, { series_id }),
  actionCreator: loadAnnotations,
  deps: ['series_id'],
  test: true,
})

export const useAttachedAnnotations = (annotationPoints: AnnotationPoints): Annotation[] => {
  
  useAnnotations({ series_id: annotationPoints.series_id })

  return useSelector((state: StoreState) => (
    getAttachedAnnotations(state, annotationPoints)
  ))
}

export const useEntityAnnotations = (annotationPoints: AnnotationPoints): AnnotationObject | null => (
  useSelector((state: StoreState) => (
    getEntityAnnotationObject(state, annotationPoints)
  ))
)


export const useSetAnnotationContext = makeUpdateHook(
  getAnnotationContext,
  setAnnotationContext
)

export const useClearAnnotationContext = (): () => ReduxAction => {
  const dispatch = useDispatch()

  return useCallback(() => dispatch(clearAnnotationContext()), [dispatch])
}

export const useToggleAnnotations = makeUpdateHook(
  getAnnotationsActive,
  toggleAnnotations
)


export const useAddAnnotation = makeEntityMutationHook({
  selector: getCreateAnnotation,
  actionCreator: addAnnotation,
  clearActionCreator: clearAddAnnotation,
  deps: ['series_id'],
  test: true,
})

export const useEditAnnotation = makeEntityMutationHook({
  selector: getMutateAnnotation,
  actionCreator: editAnnotation,
  clearActionCreator: clearEditAnnotation,
  deps: ['annotation_id', 'series_id', 'payload'],
  test: true,
})

export const useDeleteAnnotation = makeEntityDeletionHook({
  selector: getDeleteAnnotation,
  actionCreator: deleteAnnotation,
  clearActionCreator: clearDeleteAnnotation,
  deps: ['annotation_id'],
  test: true,
})


/* Deeplinks */

export const useDeeplinkParameters = makeUpdateHook(
  getDeeplinkParameters,
  storeDeeplinkParameters
)
