
import { useEffect, useCallback, useRef, useState, EffectCallback } from 'react'

import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import { isLoading, shouldAttemptLoad } from '@percept/utils'

import { isEqual, every } from 'lodash-es'

import { ClientReportState as ReportState } from '@percept/redux/bundles/clientReports/typings'
import { NotificationState } from '@percept/redux/bundles/notifications/typings'
import { SeriesGroupState } from '@percept/redux/bundles/seriesGroups/typings'
import { SeriesState } from '@percept/redux/bundles/clientSeries/typings'
import { UserState } from '@percept/redux/bundles/user/typings'
import { PlatformUnitState } from '@percept/redux/bundles/platformUnits/typings'

import {
  Dictionary,
  BaseReduxAction,
  AsyncReduxAction,
  AnyApiResponse,
  ApiLoader,
  ApiMutator,
  ApiStatusResponse,
  Nullable,
} from '@percept/types'


type ApiStatusResetter<T> = (...args: any[]) => void

type StoreState = (
  {
    seriesGroups: SeriesGroupState
  } & {
    series: SeriesState
  } & {
    reports: ReportState
  } & {
    user: UserState
  } & {
    notifications: NotificationState
  } & {
    platformUnits: PlatformUnitState
  }
) & Dictionary


export const usePrevious = <T>(value: T): T => {
  const ref = useRef<T>(value)

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}


const useDeepCompareMemoize = <T>(value: T): T => {
  const ref = useRef<T>()

  if( !ref.current || !isEqual(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}

export const useDeepCompareEffect = (callback: EffectCallback, deps: any[]): void => {
  useEffect(callback, useDeepCompareMemoize(deps))
}


export type PropsValidator<T> = (props: T | Nullable<T> | null | undefined) => props is T

export type PropsValidatorGeneric<T> = (props: T | Nullable<T> | null | undefined) => boolean //props is T

const defaultPropsValidator = <T>(
  props: Dictionary | null | undefined
): props is T => (
    !!props && every(Object.values(props))
  )


export type LoaderHookValue<Value> = [AnyApiResponse<Value>, ApiLoader<undefined>]

export type LoaderHook<Value> = () => LoaderHookValue<Value>

export type PropLoaderHookValue<Value, Props> = (
  [AnyApiResponse<Value>, ApiLoader<Nullable<Props> | undefined>]
)

export type PropLoaderHook<Value, Props> = (params: Nullable<Props>) => PropLoaderHookValue<Value, Props>

export type MutatorHookValue<Value, Props, ResetProps = undefined> = (
  [ApiStatusResponse<Value>, ApiMutator<Props>, ApiStatusResetter<ResetProps>]
)

export type MutatorHook<Value, Props, ResetProps = undefined> = (
  () => MutatorHookValue<Value, Props, ResetProps>
)

export type PropMutatorHook<Value, Props, ResetProps = undefined> = (
  (params: Nullable<Props>) => MutatorHookValue<Value, Props, ResetProps>
)


type LoaderHookCreatorOptions<T> = {
  autoload?: boolean
  validate?: PropsValidator<T>
}

export const makePropLoaderHook = <T, P, S = StoreState>(
  selector: (state: S, props: P) => AnyApiResponse<T>,
  actionCreator: (props: P) => AsyncReduxAction,
  options: LoaderHookCreatorOptions<P> = {
    autoload: false,
    validate: defaultPropsValidator,
  }
) => (
    (props?: Nullable<P>): PropLoaderHookValue<T, P> => {

      const dispatch = useDispatch()

      const entity = useSelector( (state: S) => selector(state, props as P))

      const previousProps = usePrevious(props)

      const stableProps = (
        props === previousProps || shallowEqual(props, previousProps) ?
          previousProps : props
      )

      const loadEntity = useCallback((params): void => {
        const actionCreatorParams = {
          ...stableProps,
          ...(params || {})
        }
        if( !entity.loading ){
          const validator = options.validate || defaultPropsValidator
          if( validator(actionCreatorParams) ){
            dispatch(actionCreator(actionCreatorParams))
          }
        }
      }, [dispatch, stableProps, entity])

      useEffect(() => {
        if( options.autoload && shouldAttemptLoad(entity) ){
          const validator = (
            options.validate || defaultPropsValidator
          ) as PropsValidator<P>

          if( validator(stableProps) ){
            dispatch(actionCreator(stableProps))
          }
        }
      }, [dispatch, entity, stableProps])

      return [entity, loadEntity]
    }
  )


export const makeLoaderHook = <T, S = StoreState>(
  selector: (state: S) => AnyApiResponse<T>,
  actionCreator: () => AsyncReduxAction,
  options: LoaderHookCreatorOptions<T> = {
    autoload: false,
  }
): LoaderHook<T> => (
    (): LoaderHookValue<T> => {

      const dispatch = useDispatch()

      const entity = useSelector(selector)

      const loadEntity = useCallback((): void => {
        if( !isLoading(entity) ){
          dispatch(actionCreator())
        }
      }, [entity, dispatch])

      useEffect(() => {
        if( options.autoload && shouldAttemptLoad(entity) ){
          dispatch(actionCreator())
        }
      }, [dispatch, entity])

      return [entity, loadEntity]
    }
  )


export const makeMutationHook = <T, P, R, S = StoreState>(
  selector: (state: S) => ApiStatusResponse<T>,
  actionCreator: (props: P) => AsyncReduxAction,
  resetActionCreator: (props?: R) => BaseReduxAction,
): MutatorHook<T, P, T> => (
    (): MutatorHookValue<T, P, R> => {

      const dispatch = useDispatch()

      const mutation = useSelector(selector)

      const mutateEntity = useCallback((params): void => {
        if( !mutation.loading ){
          dispatch(actionCreator(params as P))
        }
      }, [dispatch, mutation])

      const clearMutation = useCallback((params?: R): void => {
        dispatch(resetActionCreator(params))
      }, [dispatch])

      return [mutation, mutateEntity, clearMutation]
    }
  )


export const makePropMutationHook = <T, P, R, S = StoreState>(
  selector: (state: S, props: P) => ApiStatusResponse<T>,
  actionCreator: (props: P) => AsyncReduxAction,
  resetActionCreator: (props?: R) => BaseReduxAction,
  options: LoaderHookCreatorOptions<P> = {
    autoload: false,
    validate: defaultPropsValidator,
  },
): PropMutatorHook<T, P, R> => (
    (props: Nullable<P>): MutatorHookValue<T, P, R> => {

      const dispatch = useDispatch()

      const mutation = useSelector( (state: S) => selector(state, props as P))

      const previousProps = usePrevious(props)

      const stableProps = (
        props === previousProps || shallowEqual(props, previousProps) ?
          previousProps : props
      )

      const mutateEntity = useCallback((params: Partial<P>): void => {
        const actionCreatorParams = {
          ...stableProps,
          ...(params || {})
        }
        if( !mutation.loading ){
          const validator = options.validate || defaultPropsValidator
          if( validator(actionCreatorParams) ){
            dispatch(actionCreator(actionCreatorParams as P))
          }
        }
      }, [dispatch, mutation, stableProps])

      const clearMutation = useCallback((params?: R): void => {
        dispatch(resetActionCreator(params))
      }, [dispatch])

      return [mutation, mutateEntity, clearMutation]
    }
  )


export function makeMockMutationHook<T extends Dictionary>(
  value: T,
  delay = 1000
): MutatorHook<T, Dictionary> {

  return (): MutatorHookValue<T, Dictionary> => {

    const [status, setStatus] = useState<ApiStatusResponse<T>>({
      loading: false,
      data: null,
      error: null,
      lastFetched: null,
      processing: false,
      processed: false,
    })

    const mutator = useCallback((params: Dictionary) => {
      if( !status.loading ){
        setStatus( prev => ({ ...prev, loading: true, processing: true, }) )
        setTimeout(() => {
          setStatus( prev => ({
            ...prev,
            loading: false,
            processed: true,
            processing: false,
            data: {
              ...value,
              ...params,
            },
            lastFetched: Date.now(),
          }) as ApiStatusResponse<T>)
        }, delay)
      }
    }, [status.loading, setStatus])

    const resetter = useCallback(() => {
      setStatus( prev => ({ ...prev, response: null, processing: false, processed: false }))
    }, [setStatus])

    return [status, mutator, resetter]
  }
}


export const makeSynchronousHook = <T, P, S = StoreState>(
  selector: (state: S, ...args: any[]) => T,
  actionCreator: (props: P) => BaseReduxAction,
) => (
    (): [T, (params: P) => void] => {

      const dispatch = useDispatch()

      const selected = useSelector(selector)

      const updater = useCallback((params: P) => {
        dispatch(actionCreator(params))
      }, [dispatch])

      return [selected, updater]

    }
  )


export const makeDispatchHook = <P>(
  actionCreator: () => BaseReduxAction
): (() => () => void) => (
    (): (() => void) => {
      const dispatch = useDispatch()

      return useCallback((): void => {
        dispatch(actionCreator())
      }, [dispatch])
    }
  )


export const makePropDispatchHook = <P>(
  actionCreator: (props: P) => BaseReduxAction
): (() => (params: P) => void) => (
    (): ((params: P) => void) => {
      const dispatch = useDispatch()

      return useCallback((params: P): void => {
        dispatch(actionCreator(params))
      }, [dispatch])
    }
  )


export const makeSelectorHook = <T, S = StoreState>(
  selector: (state: S) => T
) => (
    (): T => (
      useSelector(selector)
    )
  )


export const makePropSelectorHook = <T, P, S = StoreState>(
  selector: (state: S, props: P) => T
) => (
    (params: P): T => (
      useSelector( (state: S) => selector(state, params) )
    )
  )
