import { UserPrivileges } from '@percept/types'

const vodafoneMarketIds = [
  '12830040392735',
  '31774511989840',
  '24907052221496',
  '14851904635942',
  '32230978094163',
  '31268880254029',
  '13480333673507',
  '23753861891122',
  '27303811744836',
  '21641224520748',
  '30509216302154',
  '10734373176347',
  '10336979649558',
  '29962908206151',
  '26608547136577',
  '26067532252222',
  '25530275464251',
  '24364435113013',
  '22432882623535',
  '10722570404889',
  '15963663631401',
]

const vodafoneGlobalId = '10143060198421'


const allVodafoneIds = [vodafoneGlobalId, ...vodafoneMarketIds]


export const vodafoneRedirectChecker = (privileges: UserPrivileges): boolean => {
  if( privileges && privileges.user && privileges.user.default_org_unit_id ){
    return allVodafoneIds.includes(privileges.user.default_org_unit_id)
  }
  return false
}
