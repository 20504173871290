import { combineReducers } from 'redux'

import { createApiReducer, createKeyedReducer, createTokenPaginatedReducer } from '../../reducers'

import {
  CREATE_USER,
  EDIT_USER,
  LOAD_USER,
  LOAD_USERS,
  RESEND_USER_INVITE,
  RESET_CREATE_USER,
  RESET_EDIT_USER,
  RESET_RESEND_USER_INVITE,
} from './actions'

import { getCognitoConfigurationKeyFromAction, getCognitoIdKeyFromAction } from '../../utils'

import { Reducer } from 'redux'

import { ReduxAction } from '@percept/types'

import { AdminUsersState } from './typings'


const paginatedListing: Reducer<AdminUsersState['paginatedListing'], ReduxAction> = createKeyedReducer(
  createTokenPaginatedReducer(
    LOAD_USERS
  ),
  getCognitoConfigurationKeyFromAction
)

const byId: Reducer<AdminUsersState['byId'], ReduxAction> = createKeyedReducer(
  createApiReducer(LOAD_USER),
  getCognitoIdKeyFromAction
)

const create: Reducer<AdminUsersState['create'], ReduxAction> = createApiReducer(
  CREATE_USER, {
    process: true,
    resetActionTypes: [RESET_CREATE_USER],
  }
)

const edit: Reducer<AdminUsersState['edit'], ReduxAction> = createApiReducer(
  EDIT_USER, {
    process: true,
    resetActionTypes: [RESET_EDIT_USER],
  }
)

const resendUserInvite: Reducer<AdminUsersState['resendUserInvite'], ReduxAction> = createApiReducer(
  RESEND_USER_INVITE, {
    resetActionTypes: [RESET_RESEND_USER_INVITE],
  }
)

const reducer: Reducer<AdminUsersState, ReduxAction> = combineReducers({
  paginatedListing,
  byId,
  create,
  edit,
  resendUserInvite,
})

export default reducer
