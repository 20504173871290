import React, { Fragment, useEffect, useMemo } from 'react'

import {
  Alert,
  BackdropLoader,
  BackdropProps,
  Box,
  Divider,
  GridSize,
  Typography,
  useAppTheme,
} from '@percept/mui'

import { PageHeader } from 'components/PageHeader'

import { PerformanceControls } from 'components/PerformanceControls'

import { PerformanceComparisonRangeSelect } from 'components/PerformanceComparisonRangeSelect'

import { ChannelSummary, ChannelSummaryProps } from 'components/ChannelSummary'

import {
  useLayoutGridSpacing,
  usePerformanceReportingDimension,
  useReferenceDate,
  usePerformanceComparisonRange,
  useComparisonsByProvider,
  useDoubleVerifyComparisonsByProvider,
  useDoubleVerifyTimeseriesByProvider,
} from 'hooks'

import { useChannelSummaries } from './lib'

import { platformUnitHasDoubleVerify, resolvePerformanceReportingDimensions } from 'components/Organisation/lib'

import { useProviderDatasets } from './perfUtils'

import { usePerformanceTimeseriesByProvider, usePlatformUnit, usePlatformUnitProviderInfo } from '@percept/hooks'

import { isLoading } from '@percept/utils'

import { format } from 'date-fns'

import { some } from 'lodash-es'

import {
  DoubleVerifyProvider,
  NormalizedError,
  PerformanceComparisonRange,
  PlatformUnit,
  PlatformUnitProviderInfo,
  ReportProvider,
} from '@percept/types'



export type CrossChannelPerformanceProps = {
  platformUnit: PlatformUnit | null
  providers: ReportProvider[]
  doubleVerifyProviders: DoubleVerifyProvider[] | null
  providerInfo: PlatformUnitProviderInfo | null
  referenceDate: Date | null
  latestAvailableReferenceDate: Date | null
  activeComparison?: PerformanceComparisonRange
  mainHeader?: React.ReactNode
  secondaryHeaderContent?: React.ReactNode
  spendLabel?: string
  currency: string
  qualityAnalysisHeader?: React.ReactNode
  error?: NormalizedError
  displayLoader?: boolean
  showContent?: boolean
} & Pick<ChannelSummaryProps, 'seriesListing' | 'onSeriesClick'>


function getGridSizing<T>(items: T[]): GridSize {
  switch(items.length){
    case 4: return 3
    case 3: return 4
    case 2: return 6
    default: return 12
  }
}


export const CrossChannelPerformance = ({
  mainHeader,
  secondaryHeaderContent,
  error,
  displayLoader = false,
  showContent = true,
  currency,
  platformUnit,
  referenceDate,
  latestAvailableReferenceDate,
  doubleVerifyProviders,
  seriesListing,
  onSeriesClick,
}: CrossChannelPerformanceProps): JSX.Element => {

  const appTheme = useAppTheme()

  const org_unit_id = platformUnit && platformUnit.id

  const [platformUnitDetail] = usePlatformUnit({
    org_unit_id,
  })

  const hasDoubleVerify = !!(platformUnit && platformUnitHasDoubleVerify(platformUnit))

  const [platformUnitProviderInfo] = usePlatformUnitProviderInfo({
    org_unit_id,
  })

  const referenceDateParam = referenceDate && format(referenceDate, 'yyyy-MM-dd')

  const comparisonsByProvider = useComparisonsByProvider({
    org_unit_id,
    target_currency: currency,
    ...(referenceDateParam && {
      reference_date: referenceDateParam,
    })
  })

  const doubleVerifyComparisonsByProvider = useDoubleVerifyComparisonsByProvider({
    org_unit_id,
    enabledDoubleVerifyProviders: doubleVerifyProviders,
    ...(referenceDateParam && {
      reference_date: referenceDateParam,
    })
  })

  const [timeseriesByProvider] = usePerformanceTimeseriesByProvider({
    org_unit_id,
    period: 'DAYS_365',
    chunking: 'MONTH',
    target_currency: currency,
    ...(referenceDateParam && {
      reference_date: referenceDateParam,
    })
  })

  const doubleVerifyTimeseriesByProvider = useDoubleVerifyTimeseriesByProvider({
    org_unit_id,
    period: 'DAYS_365',
    chunking: 'MONTH',
    enabledDoubleVerifyProviders: doubleVerifyProviders,
    ...(referenceDateParam && {
      reference_date: referenceDateParam,
    })
  })

  const datasets = useProviderDatasets({
    timeseriesByProvider: timeseriesByProvider.data,
    doubleVerifyTimeseriesByProvider: doubleVerifyTimeseriesByProvider.data,
    comparisonsByProvider: comparisonsByProvider.data,
    doubleVerifyComparisonsByProvider: doubleVerifyComparisonsByProvider.data,
    platformUnit: platformUnitDetail.data,
    currency,
  })

  const channelSummaries = useChannelSummaries({
    appTheme,
    datasets,
  })

  const stillLoading = some([
    platformUnitDetail,
    timeseriesByProvider,
    comparisonsByProvider,
    ...(hasDoubleVerify && [
      doubleVerifyComparisonsByProvider,
      doubleVerifyTimeseriesByProvider,
    ] || []),
  ], isLoading)
  
  const backdropProps: Partial<BackdropProps> = {
    open: displayLoader || stillLoading
  }

  const availableDimensions = useMemo(() => {
    if( platformUnitDetail.data ){
      return resolvePerformanceReportingDimensions({
        platformUnit: platformUnitDetail.data,
      })
    }
    return undefined
  }, [platformUnitDetail.data])

  const [activeDimension, setActiveDimension] = usePerformanceReportingDimension()

  useEffect(() => {
    if( availableDimensions && !availableDimensions.includes(activeDimension) ){
      setActiveDimension(availableDimensions[0])
    }
  }, [availableDimensions, activeDimension, setActiveDimension])

  const [ , setActiveReferenceDate] = useReferenceDate()

  const [comparisonRange, setComparisonRange] = usePerformanceComparisonRange()

  const gridSpacing = useLayoutGridSpacing()

  const gridSize = getGridSizing(channelSummaries)

  const shouldRender = showContent && !stillLoading

  return (
    <Fragment>

      <Box
        my={4}
        mx={3}>

        { error && (
          <Alert
            variant='error'
            maxWidth='30em'
            mx='auto'
            {...error} />
        )}

        <PageHeader
          header={mainHeader}
          secondaryContent={secondaryHeaderContent} />

        { !!(
          shouldRender && platformUnit && platformUnitDetail.data
            && availableDimensions && availableDimensions.includes(activeDimension)
        ) && (
          <Fragment>
            
            { showContent && (
              <Fragment>
                <Box mt={5} mb={3}>
                  <Typography variant='h5'>
                    <Box display='flex' alignItems='center'>
                      Performance Analysis

                      <Box ml={2}>
                        <PerformanceComparisonRangeSelect
                          size='small'
                          value={comparisonRange}
                          onChange={setComparisonRange}
                          referenceDate={referenceDate} />
                      </Box>
                    </Box>
                  </Typography>
                </Box>

                <Box my={3}>
                  <Divider />
                </Box>

                <PerformanceControls
                  platformUnit={platformUnit}
                  availableDimensions={availableDimensions}
                  potentialEfficiencyEnabled={false}
                  showDimensionSelector={true}
                  referenceDate={referenceDate}
                  maxDate={latestAvailableReferenceDate}
                  doubleVerifyReferenceDate={null}
                  requiresDoubleVerifyTrigger={false} />

                <Box mt={5}>
                  <ChannelSummary
                    GridItemProps={{
                      md: gridSize,
                    }}
                    GridContainerProps={{
                      spacing: gridSpacing,
                    }}
                    platformUnit={platformUnitDetail.data}
                    includeDoubleVerify={hasDoubleVerify}
                    providerReferenceDates={platformUnitProviderInfo.data}
                    referenceDate={referenceDate}
                    setReferenceDate={setActiveReferenceDate}
                    performanceComparisonRange={comparisonRange}
                    currency={currency}
                    channelSummaries={channelSummaries}
                    seriesListing={seriesListing}
                    onSeriesClick={onSeriesClick} />
                </Box>
              </Fragment>
            )}

          </Fragment>
        )}

      </Box>

      { backdropProps.open && (
        <BackdropLoader
          BackdropProps={backdropProps} />
      )}

    </Fragment>
  )

}
