import { AppThemeOptions } from './themeAugmentation'

export { ThemeProvider, CssBaseline } from '@material-ui/core'

import { cyan, lightBlue, purple, pink } from '@material-ui/core/colors'

import { darken, desaturate } from 'polished'

const borderRadius = 2


const defaultHeaderStyle = {
  fontWeight: 700,
}

const palette = {
  lightBlue: '#6EA8B7',
  blue: '#5A728A',
  darkBlue: '#576379',
  gunmetal: '#3B4352',
  darkestBlue: '#2A303A',
  turquoise: '#4395AB',
  red: '#BD6069',
  grey: '#E5E9EF',
  black: '#434343',
  yellow: '#D9C385',
  darkGreen: '#77A3A1',
  purple: '#846680',
  orange: '#D6A17B',
  green: '#A8BC91',
}

const contrastPanelColor = '#303D4B'

export const basePerceptThemeOptions: Omit<AppThemeOptions, 'branding'> = {
  chart: {
    healthColourStops: [
      palette.red,
      palette.yellow,
      palette.green,
    ],
    informationalColourStops: [
      palette.turquoise,
      palette.purple,
    ],
    grid: {
      stroke: darken(0.15, palette.blue),
    },
    tooltip: {
      valueColor: '#FFF',
      valueBackgroundColor: palette.blue,
    },
  },
  palette: {
    /* MUI theme variables */
    type: 'dark',
    primary: {
      main: palette.blue,
    },
    secondary: {
      main: palette.turquoise,
    },
    action: {
      active: '#fff',
      disabled: '#7e8d9a', // '#707884', //palette.grey,
      disabledBackground: palette.gunmetal,
    },
    background: {
      default: palette.gunmetal,
      paper: palette.darkestBlue,
    },
    text: {
      primary: '#fff',
      secondary: 'rgba(255, 255, 255, 0.54)',
    },
    error: {
      main: palette.red,
    },
    warning: {
      main: palette.yellow,
    },
    success: {
      main: palette.green,
    },
    info: {
      main: palette.lightBlue,
    },
    /* App theme variables */
    channel: {
      search: {
        light: lightBlue['400'],
        main: desaturate(0.3, lightBlue['700']),
        dark: desaturate(0.3, lightBlue['800']),
      },
      social: {
        light: purple['400'],
        main: desaturate(0.4, purple['500']),
        dark: desaturate(0.45, purple['800']),
      },
      programmatic: {
        light: pink['400'],
        main: desaturate(0.4, pink['500']),
        dark: desaturate(0.4, pink['800']),
      },
    }
  },
  appComponents: {
    authenticator: {
      root: {},
      heading: {},
      input: {
        color: '#FFF',
        backgroundColor: 'transparent',
      }
    },
    contrastPanel: {
      root: {
        color: '#FFF',
        backgroundColor: contrastPanelColor,
        borderRadius: 0,
      },
    },
    performanceTable: {
      cellCompact: {
        fontWeight: 700,
        fontSize: 12,
        padding: '12px 8px',
        '@media (min-width: 1500px)': {
          fontSize: 13,
        },
        '@media (min-width: 1920px)': {
          fontSize: 14,
        },
      },
    },
    table: {
      footer: {
        color: '#FFF',
        backgroundColor: '#32373d',
        borderColor: '#4a4949',
      },
    },
  },
  shape: {
    borderRadius,
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    // MuiInputLabel: {
    //   shrink: true,
    // },
    // MuiInput: {
    //   disableUnderline: true,
    // },
    // MuiInputAdornment: {
    //   disableTypography: true,
    // },
    // MuiSelect: {
    //   disableUnderline: true,
    // }
  },
  typography: {
    fontFamily: 'Inter, Helvetica Neue, Arial, Helvetica, sans-serif',
    fontWeightRegular: 500,
    fontWeightBold: 700,
    fontSize: 14,
    button: {
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 700,
    },
    body1: {
      fontSize: 14,
      lineHeight: '22px',
    },
    body2: {
      fontSize: 12,
      lineHeight: '20px',
    },
    caption: {
      fontSize: 10,
      lineHeight: '16px',
    },
    h1: {
      ...defaultHeaderStyle,
      fontSize: 40,
      lineHeight: '48px',
    },
    h2: {
      ...defaultHeaderStyle,
      fontSize: 32,
      lineHeight: '40px',
    },
    h3: {
      ...defaultHeaderStyle,
      fontSize: 26,
      lineHeight: '34px',
    },
    h4: {
      ...defaultHeaderStyle,
      fontSize: 24,
      lineHeight: '28px',
    },
    h5: {
      ...defaultHeaderStyle,
      fontSize: 16,
      lineHeight: '24px',
    },
    h6: {
      ...defaultHeaderStyle,
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  overrides: {

    MuiAppBar: {
      root: {
        minHeight: '0 !important',
        height: '56px !important',
        display: 'flex',
        padding: '0 0.5rem',
      },
      colorDefault: {
        backgroundColor: palette.darkBlue,
      },
      colorSecondary: {
        color: 'white',
      },
    },

    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      },
    },

    // MuiBreadcrumbs: {
    //   ol: {
    //     lineHeight: 2.25,
    //   },
    // },

    MuiButton: {
      // root: {
      //   padding: '8px 12px',
      //   minWidth: 'auto',
      //   '&$disabled': {
      //     opacity: 0.65,
      //     color: 'inherit',
      //   },
      // },
      // contained: {
      //   backgroundColor: palette.gunmetal,
      //   color: 'white',
      //   '& .MuiSvgIcon-root': {
      //     opacity: 0.7,
      //   },
      // },
      // containedPrimary: {
      //   '&$disabled': {
      //     opacity: 0.65,
      //   },
      //   '& .MuiSvgIcon-root': {
      //     opacity: 1,
      //   },
      // },
      // containedSecondary: {
      //   '& .MuiSvgIcon-root': {
      //     opacity: 1,
      //   },
      // },
      // startIcon: {
      //   marginLeft: 0,
      //   marginRight: '4px'
      // },
      // endIcon: {
      //   marginLeft: '4px',
      //   marginRight: 0
      // },
    },

    MuiCard: {
      root: {
        background: palette.darkestBlue,
      },
    },

    MuiChip: {
      root: {
        fontWeight: 700,
      },
      sizeSmall: {
        padding: '0 4px',
      },
    },

    MuiDivider: {
      root: {
        backgroundColor: 'rgba(255, 255, 255, 0.25)',
      },
    },

    MuiIconButton: {
      root: {
        padding: '4px',
      },
    },
    // MuiIconButton: {
    //   root: {
    //     color: 'rgba(255, 255, 255, 0.54)',
    //     '&:hover': {
    //       backgroundColor: 'rgba(255, 255, 255, 0.15)',
    //     },
    //     '@media (hover: none)': {
    //       '&:hover': {
    //         backgroundColor: 'rgba(255, 255, 255, 0.15) !important',
    //       },
    //     },
    //   },
    //   colorPrimary: {
    //     '&:hover': {
    //       backgroundColor: 'rgba(233, 32, 83, 0.25)',
    //     },
    //     '@media (hover: none)': {
    //       '&:hover': {
    //         backgroundColor: 'rgba(233, 32, 83, 0.25) !important',
    //       },
    //     },
    //   },
    // },

    MuiInputBase: {
      root: {
        fontWeight: 700,
      },
      input: {
        padding: '6px 4px 7px',
      },
    },

    /* @material-ui/pickers */
  
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: palette.blue,
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: 'rgba(255,255,255,0.85)',
        fontWeight: 700,
      },
      switchHeader: {
        color: 'white',
      },
      iconButton: {
        color: 'rgba(255,255,255,0.85)',
      },
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: palette.blue,
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: palette.blue,
      },
    },
    MuiPickersClockPointer:{
      pointer: {
        backgroundColor: palette.blue,
      },
      noPoint: {
        backgroundColor: palette.blue,
      },
      thumb: {
        backgroundColor: palette.blue,
        border: `14px solid ${palette.blue}`,
      },
    },
    MuiPickersDay: {
      day: {
        fontWeight: 700,
      },
      daySelected: {
        fontWeight: 700,
        backgroundColor: palette.blue,
        '&:hover': {
          backgroundColor: palette.blue,
        },
        '@media (hover: none)': {
          '&:hover': {
            backgroundColor: `${palette.blue} !important`,
          },
        },
      },
      dayDisabled: {
        color: 'rgba(255,255,255,0.35)',
      },
    },
    MuiPickersYear: {
      yearDisabled: {
        color: 'rgba(255,255,255,0.35)',
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        paddingBottom: '12px',
      },
    },

    MuiTableCell: {
      root: {
        borderBottom: 'none',
      },
    },

    MuiToolbar: {
      root: {
        minHeight: 0,
      },
      regular: {
        minHeight: 0,
      },
      gutters: {
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
      }
    },
  },
}
