import React from 'react'
import { Route, Switch } from 'react-router-dom'
import DashboardRoutes from './DashboardRoutes'
import ReportingRoutes from './ReportingRoutes'

const Routes = (): JSX.Element => {
  return (
    <Switch>
      <Route path='/reporting'>
        <ReportingRoutes />
      </Route>
      <Route path='/'>
        <DashboardRoutes />
      </Route>
    </Switch>
  )
}

export default Routes
