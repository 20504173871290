import { useMemo } from 'react'

import {
  dimensionMap,
  providerChannelMap,
  doubleVerifyDimensionsByProvider,
  reportProviders,
} from '@percept/constants'

import {
  AnyPerformanceDimension,
  DoubleVerifyPerformanceComparisons,
  DoubleVerifyPerformanceReportingDimension,
  DoubleVerifyPerformanceTimeseries,
  PerformanceComparisons,
  PerformanceRangeKey,
  PerformanceReportingDimension,
  PerformanceTimeseriesByProvider,
  PlatformUnit,
  ReportProvider,
} from '@percept/types'

import { intersection, mapValues } from 'lodash-es'

import {
  ChannelDataset,
  PerformanceDatasets,
  PerformanceDimensionDataset,
  PerformanceReportingDatum,
  TimelineDatum,
} from 'types'

import {
  deriveRelativePerformanceComparisons,
  resolvePerformanceReportingDimensions,
} from 'components/Organisation/lib'

import { ChartData } from '@percept/mui/charts'


type UseChannelDatasetsProps = {
  timeseriesByProvider: PerformanceTimeseriesByProvider | null
  doubleVerifyTimeseriesByProvider: Record<ReportProvider, DoubleVerifyPerformanceTimeseries | null> | null
  comparisonsByProvider: Record<ReportProvider, PerformanceComparisons | null> | null
  doubleVerifyComparisonsByProvider: Record<ReportProvider, DoubleVerifyPerformanceComparisons | null> | null
  platformUnit: PlatformUnit | null
  currency: string
  absoluteValues?: boolean
}


export const useProviderDatasets = ({
  timeseriesByProvider,
  doubleVerifyTimeseriesByProvider,
  comparisonsByProvider,
  doubleVerifyComparisonsByProvider,
  platformUnit,
  currency,
  absoluteValues = false,
}: UseChannelDatasetsProps): ChannelDataset[] => {

  return useMemo(() => {

    const availableProviders = (
      timeseriesByProvider && Object.keys(timeseriesByProvider) || []
    ) as ReportProvider[]

    if( !timeseriesByProvider || !comparisonsByProvider || !availableProviders.length || !platformUnit ){
      return []
    }

    const mappableProviders = intersection(reportProviders, availableProviders)
  
    return mappableProviders.map( provider => {

      const channel = providerChannelMap[provider]

      const timeseriesResponse = timeseriesByProvider[provider]

      const doubleVerifyTimeseriesResponse = doubleVerifyTimeseriesByProvider && doubleVerifyTimeseriesByProvider[provider]

      const availableDimensions = resolvePerformanceReportingDimensions({
        platformUnit,
        provider,
        includeDoubleVerify: true,
      })

      const mappableDatasets = availableDimensions.reduce( (acc, dimension) => {
        let timeseries: ChartData<TimelineDatum> | null = null
        if( timeseriesResponse && dimension in timeseriesResponse.datasets ){
          timeseries = timeseriesResponse.datasets[dimension as PerformanceReportingDimension]
        }else if( doubleVerifyTimeseriesResponse && dimension in doubleVerifyTimeseriesResponse.datasets ){
          timeseries = doubleVerifyTimeseriesResponse.datasets[dimension as DoubleVerifyPerformanceReportingDimension]
        }
        if( timeseries ){
          acc[dimension] = timeseries
        }
        return acc
      }, {} as Record<AnyPerformanceDimension, ChartData<TimelineDatum>>)

      const datasets: PerformanceDatasets = mapValues(
        mappableDatasets,
        (dataset, dimension: AnyPerformanceDimension) => {

          const comparisons: PerformanceComparisons | null = (
            comparisonsByProvider && comparisonsByProvider[provider]
          )

          const doubleVerifyComparisons: DoubleVerifyPerformanceComparisons | null = (
            doubleVerifyComparisonsByProvider && doubleVerifyComparisonsByProvider[provider]
          )

          const isDoubleVerifyDimension = (
            doubleVerifyDimensionsByProvider[provider].includes(dimension as DoubleVerifyPerformanceReportingDimension)
          )

          const targetComparisons = isDoubleVerifyDimension ? doubleVerifyComparisons : comparisons

          const relative = targetComparisons ? (
            deriveRelativePerformanceComparisons({
              comparisons: targetComparisons,
              dimension,
              absoluteValues,
              /**
               * NOTE - temporarily nullify adform 365d comparisons
               * and all CPV comparisons
               */
              nullifyKeys: provider === 'adform' ? [
                'year',
                ...(dimension === 'cpv' && [
                  'now',
                  'month',
                  'week',
                ] || []),
              ].filter( (d): d is PerformanceRangeKey => !!d ) : [],
            })
          ) : {}

          return {
            key: dimension,
            label: dimensionMap[dimension].abbreviatedText,
            timeseries: dataset,
            relative: relative as PerformanceReportingDatum,
            currency,
          } as PerformanceDimensionDataset
        }
      ) as unknown as PerformanceDatasets

      return {
        channel,
        provider,
        datasets
      }
    })
  }, [
    timeseriesByProvider, comparisonsByProvider, currency, absoluteValues, platformUnit,
    doubleVerifyTimeseriesByProvider, doubleVerifyComparisonsByProvider,
  ])
  
}
