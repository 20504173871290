
import React from 'react'

import { Grid, GridProps } from '@percept/mui'

import { ChannelSummaryItem, ChannelSummaryItemProps } from './ChannelSummaryItem'

import { resolveDoubleVerifyPerformanceReportingDimensions, resolvePerformanceReportingDimensions } from 'components/Organisation/lib'

import { get } from 'lodash-es'

import { isBefore } from 'date-fns'

import { providerDoubleVerifyMap } from '@percept/constants'

import { ChannelSummaryType } from './typings'

import { PerformanceComparisonRange, PlatformUnit, PlatformUnitProviderInfo } from '@percept/types'


export type ChannelSummaryProps = (
  Pick<
    ChannelSummaryItemProps,
    'currency' | 'referenceDate' | 'setReferenceDate' | 'seriesListing' | 'onSeriesClick'
  > & {
    channelSummaries: ChannelSummaryType[]
    platformUnit: PlatformUnit
    includeDoubleVerify?: boolean
    providerReferenceDates?: PlatformUnitProviderInfo | null
    performanceComparisonRange?: PerformanceComparisonRange
    GridItemProps?: Partial<Omit<GridProps, 'item' | 'container'>>
    GridContainerProps?: Partial<Omit<GridProps, 'item' | 'container'>>
  }
)


export const ChannelSummary = ({
  channelSummaries,
  GridContainerProps = {},
  GridItemProps = {},
  platformUnit,
  includeDoubleVerify,
  providerReferenceDates,
  referenceDate,
  ...props
}: ChannelSummaryProps): JSX.Element => {

  return (
    <Grid container spacing={3} {...GridContainerProps}>
      
      { channelSummaries.map( summary => {

        const { provider } = summary

        const doubleVerifyProvider = provider && providerDoubleVerifyMap[provider]

        const doubleVerifyReferenceDate = (
          includeDoubleVerify && doubleVerifyProvider ?
            get(providerReferenceDates, [doubleVerifyProvider, 'reference_date'], null) :
            null
        )

        const showDoubleVerify = !!(
          includeDoubleVerify &&
          referenceDate &&
          doubleVerifyReferenceDate &&
          !isBefore(doubleVerifyReferenceDate, referenceDate)
        )

        const availableDimensions = resolvePerformanceReportingDimensions({
          platformUnit,
          provider,
        })

        const doubleVerifyDimensions = (
          showDoubleVerify ?
            resolveDoubleVerifyPerformanceReportingDimensions({
              platformUnit,
              provider
            }) :
            null
        )

        return (
          <Grid key={summary.provider} item container xs={12} {...GridItemProps}>
            <ChannelSummaryItem
              availableDimensions={availableDimensions}
              doubleVerifyDimensions={doubleVerifyDimensions}
              doubleVerifyReferenceDate={doubleVerifyReferenceDate}
              referenceDate={referenceDate}
              includeDoubleVerify={
                !!(includeDoubleVerify && doubleVerifyDimensions && doubleVerifyDimensions.length)
              }
              {...props}
              {...summary} />
          </Grid>
        )
      })}
      
    </Grid>
  )
}
