import { Box, Card, LinearProgress } from '@material-ui/core'
import { makeAppStyles } from '@percept/mui'
import { SortConfig } from '@percept/types'
import React, { useEffect, useState } from 'react'
import { TableCells } from './TableCells'
import { TableLeftSide } from './TableLeftSide'
import { TableTotalColumn } from './TableTotalColumn'
import { SortControl } from './SortControl'
import { sortTable } from './sortTable'
import { ListItem, NestedItem } from '../types'
import { reorderColumns } from '../reorderColumns'

export type SortConfiguration = SortConfig<keyof ListItem | keyof NestedItem>

export type TableProps = {
  data: ListItem[]
  dataFormat: string
  showFetchIndicator: boolean
  differenceVariant?: boolean
}

const useStyles = makeAppStyles((theme) => ({
  card: {
    position: 'relative',
  },
  tableRowNames: {
    boxShadow: '3px 0px 12px rgba(0, 0, 0, 0.3)',
    zIndex: 100,
  },
  tableTotalColumn: {
    boxShadow: '-3px 0px 12px rgba(0, 0, 0, 0.3)',
  },
  tableHeader: {
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
  },
  tableData: {
    overflow: 'auto',
    flex: 1,
    flexWrap: 'nowrap',
    flexDirection: 'column',
    '&::-webkit-scrollbar': {
      height: '0.5em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#C6CBD0',
      borderRadius: '20px',
    },
  },
  fetchIndicator: {
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
  },
}))

export const ReportTable = ({
  data,
  dataFormat,
  showFetchIndicator,
  differenceVariant,
}: TableProps): JSX.Element => {
  const [isOpened, setIsOpened] = useState<string[]>([])
  const [sortBy, setSortBy] = useState<SortConfiguration>()
  const classes = useStyles()
  const dimensions = reorderColumns(
    data.reduce((arr: string[], el: ListItem) => {
      const newArr = el.costs
        .filter(
          (item: { type_value: string }) =>
            !arr.find((el) => el === item.type_value)
        )
        .map((el) => el.type_value)
      return arr.concat(newArr)
    }, [])
  )

  useEffect(() => {
    if (isOpened.length > 0) setSortBy({ key: 'data_type', order: 'ASC' })
  }, [isOpened])

  const sortedData = sortBy
    ? sortTable(data, sortBy.key, sortBy.order, dimensions)
    : data

  const handleClick = (key: string): void => {
    isOpened.includes(key)
      ? setIsOpened(isOpened.filter((e) => e != key))
      : setIsOpened([...isOpened, key])
  }

  const handleSortChange = ({
    order,
    key,
  }: Omit<SortConfiguration, 'order'> & { order?: 'ASC' | 'DESC' }): void => {
    if (differenceVariant) {
      return
    }
    if (key != sortBy?.key) {
      return setSortBy({
        key,
        order: 'ASC',
      })
    }
    setSortBy(
      order
        ? {
          order,
          key,
        }
        : undefined
    )
  }

  const isGrouped = !!(sortedData[0] && sortedData[0].data)

  return (
    <Card className={classes.card}>
      {showFetchIndicator && <LinearProgress className={classes.fetchIndicator} />}
      <Box display='flex' flexDirection='row'>
        <Box className={classes.tableRowNames}>
          <Box
            display='flex'
            justifyContent='space-around'
            className={classes.tableHeader}>
            <SortControl
              label={differenceVariant ? '' : 'Market'}
              orderBy={sortBy && sortBy.order}
              isActiveKey={!!sortBy && sortBy.key === 'row_group'}
              onSort={(order): void => {
                handleSortChange({
                  order,
                  key: 'row_group',
                })
              }}
              justifyContent='flex-start'
            />
            {isOpened.length > 0 && sortedData[0].data && (
              <SortControl
                label='Data'
                orderBy={sortBy && sortBy.order}
                isActiveKey={!!sortBy && sortBy.key === 'data_type'}
                onSort={(order): void => {
                  handleSortChange({
                    order,
                    key: 'data_type',
                  })
                }}
                justifyContent='flex-start'
              />
            )}
          </Box>
          <Box>
            {sortedData.map((row) => (
              <TableLeftSide
                key={row.row_group}
                row={row}
                onClick={handleClick}
                isOpen={isOpened}
              />
            ))}
          </Box>
        </Box>
        <Box
          display='flex'
          className={classes.tableData}
          style={{
            overflow: 'auto',
            flex: 1,
            flexWrap: 'nowrap',
            flexDirection: 'column',
          }}>
          <Box flex='1' display='flex'>
            {dimensions.map((dimension) => (
              <Box
                className={classes.tableHeader}
                style={{ padding: '0 15px', flex: 1, minWidth: '140px' }}
                key={dimension}>
                <SortControl
                  orderBy={sortBy && sortBy.order}
                  isActiveKey={!!sortBy && sortBy.key === dimension}
                  label={dimension}
                  justifyContent='flex-end'
                  onSort={(order): void => {
                    handleSortChange({
                      order,
                      key: dimension as SortConfiguration['key'],
                    })
                  }}
                />
              </Box>
            ))}
          </Box>
          {sortedData.map((row) => (
            <TableCells
              key={row.row_group}
              row={row}
              dataFormat={dataFormat}
              isOpen={isOpened}
              dimensions={dimensions}
            />
          ))}
        </Box>
        {!differenceVariant && (
          <Box className={classes.tableTotalColumn}>
            <Box className={classes.tableHeader}>
              <SortControl
                isActiveKey={!!sortBy && sortBy.key === 'total'}
                label={
                  sortedData[0].data && sortedData[0].data[0].average
                    ? 'AVERAGE YOY'
                    : dataFormat === 'percentage' && isGrouped
                      ? 'AVG. PERCENT'
                      : 'TOTAL'
                }
                orderBy={sortBy && sortBy.order}
                onSort={(order): void => {
                  handleSortChange({
                    order,
                    key: 'total',
                  })
                }}
                justifyContent='flex-end'
                mx={2}
              />
            </Box>
            {sortedData.map((row) => (
              <TableTotalColumn
                dataFormat={dataFormat}
                key={row.row_group}
                row={row}
                opend={isOpened}
                dimensions={dimensions}
              />
            ))}
          </Box>
        )}
      </Box>
    </Card>
  )
}
