import React, { Fragment, useMemo, useRef, useState } from 'react'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableProps,
  TableRow
} from '@material-ui/core'

import { ClassNameMap } from '@material-ui/styles'

import { ArrowDownward, ArrowUpward } from '@material-ui/icons'

import { makeAppStyles } from '../../themes'

import { SecondaryIconTooltip } from '../Tooltips'

import { identity, sortBy } from 'lodash-es'

import {
  CellRenderer,
  CellRenderers,
  Column,
  DefaultCellRenderer,
  TableSortConfig,
} from './typings'


export type SimpleTableProps<T extends object> = (
  Partial<
    TableProps
  > & {
    columns: Column<T>[]
    rows: T[]
    renderers?: CellRenderers<T>
    defaultRenderer?: DefaultCellRenderer<T>
    classes?: Partial<ClassNameMap<'tableRow' | 'tableCell' | 'headerCell'>>
    sortable?: boolean
    initialSortConfig?: TableSortConfig<T>
  }
)


const useStyles = makeAppStyles( theme => ({
  tableRow: {
    '&:nth-child(odd)': {
      background: theme.palette.action.disabledBackground,
    },
  },
  columnHeaderContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  columnHeaderContentCenter: {
    justifyContent: 'center',
  },
  columnHeaderContentLeft: {
    justifyContent: 'flex-start',
  },
  columnHeaderContentRight: {
    justifyContent: 'flex-end',
  },
  clickable: {
    cursor: 'pointer',
    userSelect: 'none',
  },
  tableCell: {
    fontWeight: 700,
    fontSize: 14,
  },
  headerCell: {
    fontWeight: 700,
    fontSize: 14,
  },
  headerTooltip: {
    marginLeft: theme.spacing(0.5),
  },
  sortIcon: {
    fontSize: 16,
    marginLeft: 4,
    color: theme.palette.text.hint,
  },
}) )

const useTableCellClasses = makeAppStyles( theme => ({
  stickyHeader: {
    backgroundColor: theme.palette.secondary.dark,
    color: '#fff',
    zIndex: 'unset',
  },
}) )


function defaultCellRenderer<T extends object>(item: T, key: keyof T): React.ReactNode {
  return item[key] as React.ReactNode
}


const flipSortOrder = (order: 'ASC' | 'DESC'): 'ASC' | 'DESC' => (
  order === 'ASC' ? 'DESC' : 'ASC'
)


export function SimpleTable<T extends object>({
  columns,
  rows,
  renderers,
  defaultRenderer = defaultCellRenderer,
  classes = {},
  initialSortConfig,
  sortable,
  ...props
}: SimpleTableProps<T>): JSX.Element {

  const mergedClasses = {
    ...useStyles(),
    ...classes,
  }

  const tableCellClasses = useTableCellClasses()

  const [sortConfig, setSortConfig] = useState(initialSortConfig || null)

  const columnsRef = useRef(columns)
  columnsRef.current = columns

  const sortedRows: T[] = useMemo(() => {
    if( !sortConfig ){
      return rows
    }
    const { key } = sortConfig
    const column = columnsRef.current.find( c => c.key === key )
    const getSortValue = column && column.getSortValue || identity
    const sorted = sortBy(
      rows,
      row => {
        const value = getSortValue(row[sortConfig.key])
        if( !value ) return Number(value)
        return value
      }
    )
    if( sortConfig.order === 'ASC' ){
      return sorted
    }else{
      return sorted.reverse()
    }
  }, [rows, sortConfig])

  return (
    <TableContainer>
      <Table
        {...props}>

        <TableHead>

          { columns.map( ({ key, label, tooltip, ...props }) => (
            <TableCell
              classes={tableCellClasses}
              className={mergedClasses.headerCell}
              key={key}
              {...props}>
              <span
                className={
                  [
                    mergedClasses.columnHeaderContent,
                    props.align === 'center' && mergedClasses.columnHeaderContentCenter,
                    props.align === 'right' && mergedClasses.columnHeaderContentRight,
                    props.align === 'left' && mergedClasses.columnHeaderContentLeft,
                    sortable && mergedClasses.clickable
                  ].filter(Boolean).join(' ')
                }
                onClick={(): void => {
                  if( sortable ){
                    if( !sortConfig ){
                      setSortConfig({
                        key,
                        order: 'DESC',
                      })
                    }else{
                      if( sortConfig.key === key ){
                        setSortConfig({
                          key,
                          order: flipSortOrder(sortConfig.order)
                        })
                      }else{
                        setSortConfig({
                          key,
                          order: sortConfig.order,
                        })
                      }
                    }
                  }
                }}>
                <Fragment>
                  { label }
                  { tooltip && (
                    <SecondaryIconTooltip
                      className={mergedClasses.headerTooltip}
                      title={tooltip} />
                  )}
                  { !!(sortable && sortConfig && sortConfig.key === key) && (
                    sortConfig.order === 'ASC' ?
                      <ArrowUpward className={mergedClasses.sortIcon} /> :
                      <ArrowDownward className={mergedClasses.sortIcon} />
                  )}
                </Fragment>
              </span>
            </TableCell>
          ))}

        </TableHead>

        <TableBody>

          { sortedRows.map( (row, i) => (
            <TableRow
              className={mergedClasses.tableRow}
              key={i}>

              { columns.map( ({ key, label, ...props }) => {

                let cellContent = null

                if( renderers && renderers[key] ){
                  const Renderer = renderers[key] as CellRenderer<T>
                  cellContent = <Renderer {...row} />
                }else{
                  cellContent = defaultRenderer(row, key)
                }

                return (
                  <TableCell
                    className={mergedClasses.tableCell}
                    key={`${i}-${key}`}
                    {...props}>
                    { cellContent }
                  </TableCell>
                )
              })}

            </TableRow>

          ))}

        </TableBody>

      </Table>
    </TableContainer>
  )
}
