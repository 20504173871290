
import React, { Fragment, useState } from 'react'

import { BackdropProps, ButtonProps, Fade, Modal } from '@material-ui/core'

import { makeAppStyles } from '../../themes'

import { PlainTextButton } from '../Buttons'

import { EntityList, EntityListProps } from './EntityList'

import { FilterList, Error as ErrorIcon } from '../../icons'

import { getPath } from '@percept/utils'


export type EntityListPopoverProps = EntityListProps & {
  ButtonProps?: Partial<Omit<ButtonProps, 'onClick'>>
  ButtonComponent?: React.FC<ButtonProps>
}


const useStyles = makeAppStyles( theme => ({
  popoverRoot: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    top: theme.spacing(7),
    right: theme.spacing(0.5),
    zIndex: theme.zIndex.appBar + 1,
    maxHeight: `calc(100vh - ${theme.spacing(8)}px)`,
    overflowY: 'auto',
    boxShadow: theme.shadows['3'],
    padding: theme.spacing(0, 1, 1, 1),
    '&:focus': {
      outline: 0,
    },
  },
}))


const backdropProps: Partial<BackdropProps> = {
  invisible: true
}


export const EntityListPopover = ({
  ButtonComponent = PlainTextButton,
  ButtonProps = {},
  onEntityClick,
  ...props }: EntityListPopoverProps): JSX.Element => {

  const [open, setOpen] = useState(false)

  const availableTypes = Object.keys(props.entities) as (keyof typeof props.entities)[]

  const isSingleEntity = availableTypes.length === 1 && (
    Object.keys(props.entities[availableTypes[0]] || {}).length < 2
  )

  const handleClick = (): void => {
    if( !isSingleEntity ){
      setOpen( prev => !prev )
    }
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  const label = getPath(
    props.entities,
    [props.activeEntity.type, props.activeEntity.id, 'name']
  )

  const hasMatch = label !== null

  const classes = useStyles()

  return (
    <Fragment>

      <ButtonComponent
        startIcon={
          isSingleEntity ?
            undefined :
            hasMatch ?
              <FilterList /> :
              <ErrorIcon />
        }
        {...ButtonProps}
        onClick={handleClick}>
        { hasMatch ? label : 'No Match' }
      </ButtonComponent>

      <Modal
        disableScrollLock
        BackdropProps={backdropProps}
        keepMounted
        open={open}
        onClose={handleClose}>

        <Fade in={open}>
          <EntityList
            {...props}
            onEntityClick={(filter): void => {
              onEntityClick(filter)
              handleClose()
            }}
            className={classes.popoverRoot} />
        </Fade>
      </Modal>

    </Fragment>
  )
}
