
import AppComponent, { AppProps, NavigationOptions } from './App'

import { Dispatch } from 'redux'

import { connect } from 'react-redux'

import { getLocation, replace, push } from 'connected-react-router'

import qs from 'query-string'

import {
  reportError,
  enableAllTips,
  disableAllTips,
  dismissTipToast,
} from 'redux/actions'

import { user, notifications } from '@percept/redux/bundles'

import {
  getTips,
  getActiveAppTheme,
} from 'redux/selectors'

import { all } from '@percept/utils'

import { TipSettings } from '@percept/types'


const stateToProps = (state: any): Pick<
  AppProps,
  'initialLocation' | 'appTheme' | 'isSigningUp' | 'allTipsDisabled' | 'allTipsEnabled' |
  'requiresTipToast' | 'location' | 'loggedIn' | 'refreshResponse' | 'userPrivileges'
> => {
  const tips = getTips(state)
  const tipValues = Object.values(tips.byLabel as TipSettings['byLabel'])
  const allTipsEnabled = all(tipValues)
  const allTipsDisabled = all(tipValues, v => !v)

  const location = getLocation(state)

  return {
    initialLocation: state.appState.initialLocation,
    location,
    loggedIn: user.selectors.getUserLoggedIn(state),
    appTheme: getActiveAppTheme(state),
    isSigningUp: location.pathname.indexOf('signup') !== -1,
    allTipsEnabled,
    allTipsDisabled,
    requiresTipToast: !tips.dismissed,
    refreshResponse: user.selectors.getUserTokenRefreshResponse(state),
    userPrivileges: user.selectors.getMyUserPrivileges(state),
  }
}

const dispatchToProps = (dispatch: Dispatch): Pick<
  AppProps,
  'addNotification' | 'navigate' | 'checkRefreshToken' | 'reportError' | 'enableAllTips' |
  'disableAllTips' | 'dismissTipToast'
> => ({
  checkRefreshToken: (): void => {
    dispatch(user.actions.checkRefreshToken())
  },
  addNotification: (notification): void => {
    dispatch(notifications.actions.addNotification(notification))
  },
  navigate: (route: string, options: NavigationOptions = {}): void => {
    const actionCreator = options.replace ? replace : push
    const routeSearch = options.search ? qs.stringify(options.search) : ''
    const parsedRoute = routeSearch ? route + '?' + routeSearch : route
    dispatch(actionCreator(parsedRoute))
  },
  reportError: ({ error, errorInfo }: { error: Error, errorInfo: React.ErrorInfo }): void => {
    dispatch(reportError({ error, errorInfo }))
  },
  enableAllTips: (): void => {
    dispatch(enableAllTips())
  },
  disableAllTips: (): void => {
    dispatch(disableAllTips())
  },
  dismissTipToast: (): void => {
    dispatch(dismissTipToast())
  },
})


export default connect( stateToProps, dispatchToProps )( AppComponent )
