
import React, { ComponentType, Fragment, useState } from 'react'

import { makeAppStyles } from '../../themes'

import {
  MenuProps,
  Menu,
  MenuItem,
} from '@material-ui/core'

import { identity } from 'lodash-es'


const useStyles = makeAppStyles( theme => ({
  menuItem: {
    fontWeight: 700,
    padding: theme.spacing(1, 1.5),
    '&:focus': {
      outline: 0,
    },
  },
  defaultSelected: {
    backgroundColor: theme.palette.action.selected,
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },
  },
}) )


export type BaseMenuProps<Value> = {
  value: Value | undefined
  label?: React.ReactNode
  valueClassName?: string
  selectedClassName?: string
  isEqual?: (a: Value | undefined, b: Value | undefined) => boolean
  MenuProps?: Partial<Omit<MenuProps, 'onChange' | 'children'>>
}


export type MenuOption<Value> = {
  value: Value
  label?: React.ReactNode
  className?: string
  disabled?: boolean
}

export type MenuOptionsProps<Value> = BaseMenuProps<Value> & {
  options?: MenuOption<Value>[]
  onChange?: (e: React.MouseEvent, value: Value) => void
  children?: never
}

export type MenuChildrenProps<Value> =  BaseMenuProps<Value> & {
  options?: never
  onChange?: never
  children?: JSX.Element | JSX.Element[] | ((props: { onClose: () => void }) => JSX.Element | JSX.Element[])
}

export type MenuComponentProps<Value = string> = (MenuOptionsProps<Value> | MenuChildrenProps<Value>)

export type MenuComponent<Value = string> = React.ComponentType<MenuComponentProps<Value>>

export type MenuAbstractionProps<Props extends object, Value = string> = (
  MenuComponentProps<Value> & {
    disabled?: boolean
    OverrideTriggerComponent?: ComponentType<Props>
    TriggerProps?: Partial<CleanTriggerProps<Props>>
  }
)


function defaultIsEqual<T = unknown>(a: T | undefined, b: T | undefined): boolean {
  return Boolean(a && b && a === b)
}


type CleanTriggerProps<Props> = Omit<Props, 'value' | 'onChange'>


export function makeMenuComponent<Props extends object, V = any>({
  TriggerComponent,
  getTriggerProps = identity,
}: {
  TriggerComponent: ComponentType<Props>
  getTriggerProps?: (params: { value: any, label?: React.ReactNode }) => Partial<CleanTriggerProps<Props>>
}): ComponentType<MenuAbstractionProps<Props, V>> {

  function MenuComponent<O = V>({
    value,
    label,
    options,
    onChange,
    isEqual,
    disabled = false,
    MenuProps = {},
    TriggerProps = {},
    OverrideTriggerComponent,
    children,
    selectedClassName,
    valueClassName,
  }: MenuAbstractionProps<Props, O>): JSX.Element {

    const equalityCheck = isEqual || defaultIsEqual

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const classes = useStyles()

    const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
      setAnchorEl(event.currentTarget)
    }

    const handleClose = (): void => {
      setAnchorEl(null)
    }

    const CanonicalTriggerComponent = OverrideTriggerComponent || TriggerComponent

    return (
      <Fragment>

        <CanonicalTriggerComponent
          disabled={disabled}
          {...getTriggerProps({ label, value })}
          {...TriggerProps as Props}
          onClick={handleClick} />

        <Menu
          keepMounted
          {...MenuProps}
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleClose}>
          { options && onChange ? (
            options.map( (option, i) => {
              const itemClassName = [
                valueClassName || classes.menuItem,
                option.className,
                equalityCheck(option.value, value) && (selectedClassName || classes.defaultSelected),
              ].filter(Boolean).join(' ') as string
              return (
                <MenuItem
                  key={i}
                  className={itemClassName}
                  disabled={option.disabled}
                  onClick={(e): void => {
                    onChange(e, option.value)
                    handleClose()
                  }}>
                  { option.label || option.value }
                </MenuItem>
              )
            })
          ) : typeof children === 'function' ? (
            children({ onClose: handleClose })
          ) : (
            children
          )}
        </Menu>

      </Fragment>
    )
  }

  return MenuComponent

}
