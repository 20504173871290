import { Middleware } from 'redux'

import { user } from '../bundles'

import { getFetchTypes } from '../utils'

import { get, noop } from 'lodash-es'

import { makeStorageDeletor, makeStorageGetter, makeStorageSetter } from './storage'

import { Action, TokenRefreshParams } from '@percept/types'


const { PERCEPT_ENV } = process.env


let intervalRef: null | NodeJS.Timeout = null


const signInFetchTypes = getFetchTypes(user.actions.SIGN_IN)
const codeChallengeFetchTypes = getFetchTypes(user.actions.RESPOND_TO_CODE_CHALLENGE)
const oAuthRefreshFetchTypes = getFetchTypes(user.actions.OAUTH_REFRESH)
const ssoExchangeFetchTypes = getFetchTypes(user.actions.EXCHANGE_SINGLE_SIGN_ON_CODE)
const ssoRefreshFetchTypes = getFetchTypes(user.actions.OAUTH_REFRESH_SSO)

const idTokenSuccessTypes = [
  signInFetchTypes.success,
  codeChallengeFetchTypes.success,
  oAuthRefreshFetchTypes.success,
  ssoExchangeFetchTypes.success,
  ssoRefreshFetchTypes.success,
]

const refreshTokenSuccessTypes = [
  signInFetchTypes.success,
  codeChallengeFetchTypes.success,
  ssoExchangeFetchTypes.success,
]


const AUTH = 'AUTH'

const fetchUserAuthParams = makeStorageGetter<TokenRefreshParams>(AUTH)

const saveUserAuthParams = makeStorageSetter<TokenRefreshParams>(AUTH)

const deleteUserAuthParms = makeStorageDeletor(AUTH)


export const tokenMiddleware: Middleware = (store) => (next) => (action: Action): Action => {

  if( action.type === user.actions.CHECK_REFRESH_TOKEN ){
    fetchUserAuthParams().then( ({ user_id, refresh_token, source = 'LOGIN' })  => {
      store.dispatch(user.actions.tokenAutologin({ user_id, refresh_token, source }))
      store.dispatch(
        source === 'SINGLE_SIGN_ON' ?
          user.actions.oAuthRefreshSso({ refresh_token }) :
          user.actions.oAuthRefresh({ user_id, refresh_token })
      )
    }).catch(noop)
  }

  const result = next(action)

  if( action.type === user.actions.SIGN_OUT ){
    intervalRef && clearInterval(intervalRef)
    deleteUserAuthParms().then(noop).catch(console.warn)
    sessionStorage.clear()
  }

  if( refreshTokenSuccessTypes.includes(action.type) ){
    const tokenParams: TokenRefreshParams = {
      user_id: action.payload.user_id,
      refresh_token: action.payload.refresh_token,
      source: (
        action.type === ssoExchangeFetchTypes.success ?
          'SINGLE_SIGN_ON' :
          'LOGIN'
      )
    }
    saveUserAuthParams(tokenParams).then(noop).catch(console.warn)
  }

  if( idTokenSuccessTypes.includes(action.type) ){

    const idToken = get(action.payload, 'id_token')
    if( idToken ){
      try{
        sessionStorage.setItem('PERCEPT_TOKEN', idToken)
      }catch(e){
        if( PERCEPT_ENV !== 'prod' ){
          console.warn('Token could not be set in session storage')
        }
      }
    }else if( PERCEPT_ENV !== 'prod' ){
      console.error('Unexpected auth payload - id token not present')
    }

    const user_id = action.payload.user_id

    const expires_at = (action.payload && action.payload.expires_at) as string | null | undefined

    let delta: number | null = null

    if( !expires_at ){
      console.warn('Token has no expiry', action.payload)
      delta = 1000 * 60 * 59
    }else{
      const expiryDate = new Date(expires_at)
      delta = expiryDate.getTime() - Date.now() - (1000 * 60)
    }

    if( delta > 0 ){ 
    
      console.log('Token middleware: refreshing in', `${delta / 1000 / 60} minutes`)

      intervalRef && clearInterval(intervalRef)

      intervalRef = setInterval(() => {
        try{
          console.log('Token middleware: Attempting token refresh')
          const state = store.getState()

          const refreshToken = user.selectors.getUserRefreshToken(state)

          if( refreshToken ){
            console.log('Token middleware: Dispatching oAuthRefresh')
            store.dispatch(user.actions.oAuthRefresh({
              user_id,
              refresh_token: refreshToken,
            }))
          }else{
            console.log('Token middleware: invalid refreshToken')
          }

        }catch(e){
          console.warn(e)
          intervalRef && clearInterval(intervalRef)
        }
      }, delta)

    }
  }

  return result
}
