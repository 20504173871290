import React, { useEffect, useMemo, useRef, useState } from 'react'

import MUIDataTable, { debounceSearchRender, MUIDataTableOptions } from 'mui-datatables'

import { Typography } from '@percept/mui'

import { DataTableThemeProvider } from './DataTableThemeProvider'

import { components } from './DataTableComponents'

import { useDataTableColumns, UseDataTableColumnsProps } from './useDataTableColumns'

import { useDataFrame } from './useDataFrame'

import { structuralEntityTypeLabelMap } from '@percept/constants'

import { useDataTableClasses } from './styles'

import { DataFrame, dimensionLabelMappings } from './lib'

import { DimensionType, EntityType, MetricExample } from '@percept/types'


export type ExamplesDataTableProps = (
  Omit<
    UseDataTableColumnsProps, 'dataFrame' | 'classes'
  > & {
    examples: MetricExample[]
    dimension: DimensionType | null
    activePerformanceTail?: string | null
    title?: string
  }
)


const defaultTableOptions: MUIDataTableOptions = {
  print: false,
  fixedHeader: true,
  tableBodyMaxHeight: '75vh',
  responsive: 'standard',
  selectableRows: 'none',
  jumpToPage: true,
  rowsPerPageOptions: [15, 30, 50, 100],
  filterType: 'multiselect',
  rowHover: false,
  customSearchRender: debounceSearchRender(50),
}


type SortOptions = {
  name: string
  direction: 'asc' | 'desc'
}

const resolveDimensionLabel = (dimension: DimensionType | null): string => (
  (!dimension || dimension === 'count') ?
    dimensionLabelMappings.cost :
    dimensionLabelMappings[dimension]
)


const RenderExamplesDataTable = ({
  title = 'Examples',
  dimension,
  dataFrame,
  examples,
  activeSegments,
  provider,
  ...props
}: ExamplesDataTableProps & {
  dataFrame: DataFrame
}): JSX.Element => {

  const classes = useDataTableClasses()

  const columns = useDataTableColumns({
    dataFrame,
    classes,
    provider,
    activeSegments,
    ...props,
  })

  const [rowsPerPage, setRowsPerPage] = useState(15)

  const [sortOrder, setSortOrder] = useState<SortOptions>({
    name: resolveDimensionLabel(dimension),
    direction: 'desc',
  })

  /**
   * NOTE - we use a mutable ref here so we don't constantly trigger
   * the following `useEffect` call every time a user sorts the table. This way,
   * only changes in the metric dimension itself via URL params
   * trigger this hook to reset the sort column if not set to the corresponding
   * dimension label.
   */
  const sortOrderRef = useRef(sortOrder)
  sortOrderRef.current = sortOrder

  const displayType = useMemo(() => {
    const defaultDisplayType = examples[0] && examples[0].type
    const displayTypes = examples.reduce( (acc, e) => {
      if(
        e.attributes
        && e.attributes.display_type
        && acc.indexOf(String(e.attributes.display_type)) === -1
      ){
        acc.push(e.attributes.display_type as string)
      }
      return acc
    }, [] as string[])
    if( displayTypes.length === 1 ){
      return displayTypes[0]
    }else{
      // Older payload or multiple options, show mapped variant
      return structuralEntityTypeLabelMap[defaultDisplayType as EntityType] || defaultDisplayType
    }
  }, [examples])

  useEffect(() => {
    const name = resolveDimensionLabel(dimension)
    if( sortOrderRef.current.name !== name ){
      setSortOrder({
        name,
        direction: 'desc',
      })
    }
  }, [dimension])

  const options: MUIDataTableOptions = {
    ...defaultTableOptions,
    rowsPerPage,
    onChangeRowsPerPage: setRowsPerPage,
    sortOrder,
    onColumnSortChange: (name, direction) => {
      setSortOrder({ name, direction })
    },
    setRowProps: () => ({
      className: classes.row,
    }),
  }

  return (
    <MUIDataTable
      data={dataFrame.rows}
      columns={columns}
      options={options}
      title={
        <Typography variant='h5'>
          { displayType }
          {' '}
          { title }
        </Typography>
      }
      components={components} />
  )
}


export const ExamplesDataTable = (props: ExamplesDataTableProps): JSX.Element => {

  const dataFrame = useDataFrame(props)

  return (
    <DataTableThemeProvider>
      <RenderExamplesDataTable
        {...props}
        dataFrame={dataFrame} />
    </DataTableThemeProvider>
  )
}
