import { useMemo } from 'react'

import { capitalize, get } from 'lodash-es'

import {
  DataFrame,
  dataToDataFrame,
  exampleToRow,
  ExampleColumn,
  Item,
  exampleColumnMappings,
  providerColumnMap,
} from './lib'

import { MetricExample, MetricMetadataType, ReportProvider } from '@percept/types'


export type UseDataFrameProps = {
  provider?: ReportProvider
  metadata?: MetricMetadataType
  activePerformanceTail?: string | null
  examples: MetricExample[]
  currency: string | null | undefined
}


export const useDataFrame = ({
  provider,
  activePerformanceTail,
  metadata,
  examples,
  currency,
}: UseDataFrameProps): DataFrame => {

  return useMemo(() => {
    const rows = examples.map( example => (
      exampleToRow(
        example as Item,
        {
          activePerformanceTail,
          extraAttributes: { currency },
        })
    ))

    const baseColumns = provider && providerColumnMap[provider] || []

    const additionalColumns = [
      ...Object.keys(get(examples[0], 'attributes') || {}),
    ].filter( attr => !!(metadata && metadata[attr]) )

    const columns = [
      ...baseColumns,
      ...additionalColumns
    ].map( key => {
      return {
        key,
        text: (
          metadata && metadata[key] && metadata[key].title ||
          exampleColumnMappings[key as ExampleColumn] ||
          capitalize(key)
        ), 
      }
    })

    return dataToDataFrame({ rows, columns })
  }, [provider, metadata, examples, currency, activePerformanceTail])

}
