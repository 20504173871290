import { Box } from '@material-ui/core'
import React from 'react'
import { FilterItem, FiltersPanel } from '../Filters'
import { InvestmentReport, Report } from './InvestmentReport'
import { filtersReduxState } from 'redux/ReportingDashboard/reducers/filtersReducer'

type Props = {
  filters: FilterItem[]
  dashboardType: keyof filtersReduxState
  reports: Report[]
}

export const DashboardLayout = ({
  filters,
  dashboardType,
  reports,
}: Props): JSX.Element => {
  return (
    <Box>
      <FiltersPanel possibleFilters={filters} dashboardType={dashboardType} />
      {reports.map((el, idx) => (
        <InvestmentReport key={idx} {...el} />
      ))}
    </Box>
  )
}
