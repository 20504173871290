import { Box } from '@material-ui/core'
import { makeAppStyles, Money } from '@percept/mui'
import { spacedPercentageFormatter } from '@percept/mui/charts'
import React from 'react'
import { Summary } from '../DashboardLayout'
import { ListItem } from '../types'

export const useStyles = makeAppStyles((theme) => ({
  groupItem: {
    backgroundColor: theme.palette.action.disabledBackground,
    borderBottom: `1px solid ${theme.appComponents.table.footer.borderColor}`,
  },
  totalItem: {
    ...theme.appComponents.table.footer,
    borderBottom: `1px solid ${theme.appComponents.table.footer.borderColor}`,
  },
  item: {
    borderBottom: `1px solid ${theme.appComponents.table.footer.borderColor}`,
  },
}))

export const TableCells = ({
  row,
  depth = 0,
  isOpen,
  dataFormat,
  dimensions,
}: {
  row: ListItem
  depth?: number
  isOpen: string[]
  dimensions: string[]
  dataFormat: string
}): JSX.Element => {
  const classes = useStyles()

  return (
    <>
      <Box flex='1' display='flex'>
        {dimensions.map((dimension) => {
          const cell = row.costs.find((el) => el.type_value === dimension)

          return (
            <Box
              key={dimension}
              className={
                row.row_group === Summary.AllTotal
                  ? classes.totalItem
                  : row.data
                  ? classes.groupItem
                  : classes.item
              }
              style={{
                flex: 1,
                minWidth: '140px',
                height: '32px',
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '0 15px',
              }}
              alignItems='center'>
              <Box
                fontSize={13}
                fontWeight={row.data ? 700 : 500}
                textAlign='right'
                overflow='hidden'
                style={
                  dataFormat === 'percentage'
                    ? { paddingRight: '10px', whiteSpace: 'nowrap' }
                    : { whiteSpace: 'nowrap' }
                }
                alignSelf='center'>
                {!cell ? (
                  '-'
                ) : row.row_group === 'Difference' ? (
                  spacedPercentageFormatter(cell.percent)
                ) : dataFormat === 'currency' ? (
                  <Money
                    amount={Number(Number(cell.spend).toFixed(2))}
                    currency={row.total_currency}
                  />
                ) : (
                  spacedPercentageFormatter(cell.percent)
                )}
              </Box>
            </Box>
          )
        })}
      </Box>
      {isOpen.includes(row.row_group) &&
        (row.data ?? []).map((member) => (
          <TableCells
            dataFormat={dataFormat}
            key={member.data_type}
            row={{
              ...member,
              total_currency: row.total_currency,
              row_group:
                row.row_group === Summary.AllTotal
                  ? Summary.AllTotal
                  : member.data_type,
            }}
            depth={depth + 1}
            isOpen={[]}
            dimensions={dimensions}
          />
        ))}
    </>
  )
}
