
import React, { Fragment } from 'react'

import {
  Card,
  CardProps,
  CardHeader,
  CardContent,
  Grid,
  Typography,
  ButtonProps,
  GridProps,
} from '@material-ui/core'

import { makeAppStyles } from '../../themes'

import { Health } from '../Health'

import { PlainTextButton } from '../Buttons'

import { SecondaryIconTooltip } from '../Tooltips'

import { MetricActionPopover, MetricProps } from '../Metric'

import { CardStrip, CardStripColor } from './CardStrip'

import { HealthType } from '@percept/types'


const useStyles = makeAppStyles( theme => ({
  card: {
    position: 'relative',
    flexDirection: 'column',
    alignContent: 'space-evenly',
    justifyContent: 'space-between',
    display: 'flex',
  },
  fullHeight: {
    height: '100%',
  },
  cardHeader: {
    padding: theme.spacing(1, 2, 2, 2),
  },
  cardHeader__content: {
    width: 'min-content',
  },
  cardContent: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    position: 'relative',
    paddingTop: theme.spacing(2),
  },
  cardContentHeader: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  cardContent__Grid: {
    alignSelf: 'flex-end',
    minHeight: '100%',
  },
  cardActions: {
    padding: theme.spacing(0.2, 1, 0.2, 1),
    backgroundColor: 'rgba(193, 226, 255, 0.04)',
  },
  title: {
    marginTop: theme.spacing(1),
    flex: '0 0 auto',
    minWidth: '18rem',
    alignItems: 'center',
    fontSize: 15,
    position: 'relative',
  },
  descriptionTooltip: {
    position: 'relative',
    top: 3,
    marginRight: theme.spacing(0.75),
  },
  metricActionPopover: {
    marginRight: theme.spacing(0.5),
  },
  actions: {
    marginLeft: theme.spacing(2),
    display: 'inline-flex',
    '&> *': {
      marginRight: theme.spacing(0.5)
    },
  },
  fixedActions: {
    position: 'absolute',
    marginLeft: theme.spacing(1.5),
    zIndex: 100,
  },
  fixedActionsContainer: {
    position: 'relative',
  },
  fixedRightHeaderContent: {
    float: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    top: -4,
    right: -8,
  },
  health: {
    padding: theme.spacing(0, 0.75),
    minWidth: 'unset',
  },
  metricVisualisationWrap: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    zIndex: 0,
  },
  spacedTop: {
    marginTop: theme.spacing(2),
  },
  muted: {
    color: `${theme.palette.action.disabled} !important`,
  },
  debugContent: {
    marginTop: theme.spacing(1),
  }
}) )


export type MetricVisualisationWrapProps = React.PropsWithChildren<
  {
    label?: React.ReactNode | JSX.Element
    button?: boolean
    ButtonProps?: Partial<Omit<ButtonProps, 'disabled'>>
    GridProps?: Partial<GridProps>
    disabled?: boolean
    onClick?: () => void
  }
>


export const MetricVisualisationWrap = ({
  label,
  button = true,
  disabled,
  onClick,
  ButtonProps = {},
  GridProps = {},
  children
}: MetricVisualisationWrapProps): JSX.Element => {

  const classes = useStyles()

  return (
    <Grid
      classes={{
        root: classes.metricVisualisationWrap
      }}
      item
      {...GridProps}>

      { children }

      { label && (
        !button ? (
          <span
            className={classes.spacedTop}
            onClick={onClick}>
            { label }
          </span>
        ) : (
          <PlainTextButton
            className={`${classes.spacedTop} ${disabled ? classes.muted : ''}`}
            size='small'
            disabled={disabled || !onClick}
            {...ButtonProps}
            onClick={onClick}>
            { label }
          </PlainTextButton>
        )
      )}

    </Grid>
  )

}


export type MetricCardProps = React.PropsWithChildren<
  Pick<
    MetricProps,
    'containerRef'
  > & {
    color?: CardStripColor
    health?: HealthType
    title?: React.ReactNode
    debugContent?: React.ReactNode
    actionPopoverContent?: React.ReactNode | ((props: { onClose: () => void }) => JSX.Element)
    actionPopoverDisabled?: boolean
    description?: React.ReactNode
    showHeader?: boolean
    showHealthPercentage?: boolean
    fullHeight?: boolean
    CardProps?: Partial<CardProps>
    GridProps?: Partial<GridProps>
  }
>


export const MetricCard = ({
  color = 'health',
  health,
  title,
  description,
  showHeader = true,
  showHealthPercentage = true,
  fullHeight = true,
  debugContent = null,
  actionPopoverContent = null,
  actionPopoverDisabled = false,
  containerRef,
  children,
  CardProps = {},
  GridProps = {},
}: MetricCardProps): JSX.Element => {

  const classes = useStyles()

  const cardClassName = [
    classes.card,
    fullHeight && classes.fullHeight,
    CardProps.className,
  ].filter(Boolean).join(' ')

  const hasHealth = Boolean(
    showHealthPercentage
    && health !== null
    && health !== undefined
  )

  const hasfixedRightHeaderContent = Boolean(
    hasHealth || actionPopoverContent
  )

  return (
    <Card
      {...CardProps}
      ref={containerRef}
      className={cardClassName}>

      { showHeader && (
        <CardStrip color={color} health={health} />
      )}

      { showHeader && (
        <CardHeader
          classes={{
            root: classes.cardHeader,
            content: classes.cardHeader__content,
          }}
          disableTypography
          title={
            <Fragment>

              { title && (
                <Typography
                  className={classes.title}
                  variant='h5'>

                  { hasfixedRightHeaderContent && (
                    <div className={classes.fixedRightHeaderContent}>
                      { hasHealth && (
                        <Health
                          className={classes.health}
                          fontSize='20px'
                          value={Number(health)} />
                      )}

                      { actionPopoverContent && (
                        <MetricActionPopover
                          IconButtonProps={{
                            className: classes.metricActionPopover,
                            disabled: actionPopoverDisabled,
                          }}
                          PopoverProps={{
                            elevation: 12,
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'right',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'right',
                            }
                          }}>
                          { actionPopoverContent }
                        </MetricActionPopover>
                      )}
                    </div>
                  )}

                  { description && (
                    <SecondaryIconTooltip
                      className={classes.descriptionTooltip}
                      title={description}
                      placement='bottom-start' />
                  )}

                  { title }

                </Typography>
              )}

              { debugContent && (
                <div className={classes.debugContent}>
                  { debugContent }
                </div>
              ) }

            </Fragment>
          } />
      )}

      <CardContent
        className={
          `${classes.cardContent} ${showHeader ? classes.cardContentHeader : ''}`
        }>

        <Grid
          className={classes.cardContent__Grid}
          container
          spacing={3}
          justify='center'
          {...GridProps}>

          { children }

        </Grid>

      </CardContent>

    </Card>
  )
}
