import React, { Fragment, useEffect, useReducer, useRef } from 'react'

import {
  Box,
  ButtonSelect,
  Typography,
  makeAppStyles,
  RoundedPlainTextButton,
  BackdropLoader,
  CopyToClipboard,
  ButtonSelectProps,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
} from '@percept/mui'

import QRCode from 'react-qr-code'

import {
  ArrowRightAlt,
  CheckCircleOutline,
  InsertLink,
  PasswordIcon,
  QrCodeIcon,
  Send,
  Snooze,
  VerifiedUser,
} from '@percept/mui/icons'

import { useBeginMFASetup, useCompleteMFASetup, useToggleMFAState } from '@percept/hooks'

import { MFAComponentProps } from './lib'

import mfaReducer, { MFAStep, initialState } from './mfaReducer'

import { some } from 'lodash-es'


const { MFA_REQUIRED } = process.env


const useStyles = makeAppStyles( theme => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  inlineIcon: {
    marginRight: theme.spacing(1),
  },
  success: {
    color: theme.chart.healthColourScale(1),
  },
  closeButton: {
    marginRight: theme.spacing(2),
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
  closeIcon: {
    fontSize: 32,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2.5, 0),
    fontSize: 20,
  },
  spacedLeft: {
    marginLeft: theme.spacing(2),
  },
  nextButton: {
    margin: theme.spacing(4, 0, 2, 0),
  },
  divider: {
    margin: theme.spacing(1, 0, 3, 0),
  },
  formControl: {
    display: 'block',
  },
  formHelperText: {
    marginTop: theme.spacing(1),
    maxWidth: '14rem',
  },
  code: {
    backgroundColor: theme.palette.secondary.contrastText,
    color: theme.palette.secondary.dark,
    fontWeight: 700,
    fontFamily: 'monospace',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    overflow: 'hidden',
  },
}))


const linkOptions: ButtonSelectProps<MFAStep>['options'] = [
  {
    value: 'getQR',
    label: 'QR Code',
    icon: <QrCodeIcon />,
  },
  {
    value: 'showLink',
    label: 'Link',
    icon: <InsertLink />,
  },
]

export type SetupMultiFactorAuthenticationDependencies = {
  accessToken: string
  username: string
}

export type SetupMultiFactorAuthenticationProps = {
  onClose: () => void
}

export const SetupMultiFactorAuthentication = ({
  accessToken,
  username,
  onClose,
}: MFAComponentProps): JSX.Element => {

  const [state, dispatch] = useReducer(mfaReducer, { ...initialState, accessToken, username }) 

  const {
    currentStep,
    inputs: { code, deviceName },
    totpURI,
  } = state

  const [beginStatus, beginMFASetup] = useBeginMFASetup({
    username,
    access_token: accessToken,
  })

  useEffect(() => {
    if( !beginStatus.data && !beginStatus.loading && !beginStatus.error ){
      beginMFASetup({
        username,
        access_token: accessToken,
      })
    }else if( beginStatus.data && currentStep === 'beginSetup' ){
      dispatch({
        type: 'BEGIN_SETUP_COMPLETE',
        payload: beginStatus.data,
      })
    }
  }, [beginStatus, currentStep, beginMFASetup, username, accessToken, dispatch])

  const [completeStatus, completeMFASetup] = useCompleteMFASetup({
    access_token: accessToken,
    user_code: null,
    device_name: deviceName || null,
  })

  const [toggleStatus, toggleMFAState] = useToggleMFAState({
    access_token: accessToken,
    enabled: true
  })

  const onCloseRef = useRef(onClose)
  onCloseRef.current = onClose

  useEffect(() => {
    if( completeStatus.data ){
      if( MFA_REQUIRED ){
        toggleMFAState({
          access_token: accessToken,
          enabled: true,
        })
      }else{
        dispatch({
          type: 'SET_CURRENT_STEP',
          payload: 'enableMFA',
        })
      }
    }
  }, [completeStatus.data, dispatch, toggleMFAState, accessToken])

  useEffect(() => {
    if( toggleStatus.data ){
      onCloseRef.current()
    }
  }, [toggleStatus.data])

  const classes = useStyles()

  const loading = some([
    beginStatus.loading,
    toggleStatus.loading,
    completeStatus.loading,
  ])

  return (
    <Fragment>
      { currentStep === 'beginSetup' && (
        <Box display='flex' alignItems='center' justifyContent='center'  height={256}>
          <Typography variant='h5' color='textPrimary'>
            Generating QR Code...
          </Typography>
        </Box>
      )}

      { (currentStep === 'showLink' || currentStep === 'getQR') && (
        <Box textAlign='center'>              
          <ButtonSelect
            value={currentStep}
            options={linkOptions}
            onChange={(e, payload): void => {
              dispatch({
                type: 'SET_CURRENT_STEP',
                payload,
              })
            }} />

          <Box
            mt={3}
            minHeight={192}
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'>
            { currentStep === 'showLink' ? (
              <div className={classes.code}>
                <CopyToClipboard
                  value={totpURI} />
              </div>
            ) : (
              <Fragment>
                <Box
                  fontSize={14}
                  mb={3}>
                  <strong>1.</strong> Ensure you have a software authentication app installed on your smartphone, e.g Google Authenticator
                  <br />
                  <strong>2.</strong> Scan this QR code to enable multi-factor authentication
                  <br />
                  <strong>3.</strong> Click Next to continue
                </Box>
                <QRCode
                  value={totpURI}
                  size={192} />
              </Fragment>
            )}
          </Box>

          <RoundedPlainTextButton
            variant='contained'
            size='large'
            className={classes.nextButton}
            startIcon={
              <ArrowRightAlt />
            }
            onClick={(): void => {
              dispatch({
                type: 'SET_CURRENT_STEP',
                payload: 'chooseDeviceName',
              })
            }}>
            Next
          </RoundedPlainTextButton>
        </Box>
      )}

      { currentStep === 'chooseDeviceName' && (
        <Box textAlign='center'>
          <Typography
            variant='h5'
            className={classes.header}>
            Choose A Device Name
          </Typography>

          <form
            onSubmit={(e): void => {
              e.preventDefault()
              dispatch({ type: 'SET_CURRENT_STEP', payload: 'completeSetup' })
            }}>
            <div>
              <FormControl>
                <InputLabel>Device Name</InputLabel>
                <Input
                  name='device_name'
                  autoFocus
                  color='secondary'
                  value={deviceName}
                  onChange={(e): void => {
                    dispatch({ type: 'UPDATE_DEVICE_NAME', payload: e.target.value })
                  }} />
                <FormHelperText className={classes.formHelperText}>
                  Please choose a short name to help you remember this device, such as the name of the
                  application or password manager you&apos;re using
                </FormHelperText>
              </FormControl>
            </div>

            <RoundedPlainTextButton
              type='submit'
              size='large'
              className={classes.nextButton}
              variant='contained'
              disabled={!deviceName}
              startIcon={
                <PasswordIcon />
              }>
              Verify Device
            </RoundedPlainTextButton>
          </form>
        </Box>
      )}

      { currentStep === 'completeSetup' && (
        <Box fontSize={14} textAlign='center'>
          <Typography
            variant='h5'
            className={classes.header}>
            Verify Device
          </Typography>

          <form
            onSubmit={(e): void => {
              e.preventDefault()
              if( code && deviceName ){
                completeMFASetup({
                  access_token: accessToken,
                  user_code: code,
                  device_name: deviceName,
                })
              }
            }}>
            <div>
              <FormControl>
                <InputLabel>Enter Code</InputLabel>
                <Input
                  name='code'
                  color='secondary'
                  autoFocus
                  value={code}
                  onChange={(e): void => {
                    dispatch({ type: 'UPDATE_CODE', payload: e.target.value })
                  }} />
                <FormHelperText className={classes.formHelperText}>
                  Please enter the one-time password currently shown on your device or application
                </FormHelperText>
              </FormControl>
            </div>

            <RoundedPlainTextButton
              size='large'
              type='submit'
              className={classes.nextButton}
              variant='contained'
              startIcon={
                <Send />
              }
              disabled={completeStatus.loading || !code || code.length < 6 || !deviceName}>
              Send Code
            </RoundedPlainTextButton>
          </form>
        </Box>
      )}

      { currentStep === 'enableMFA' && (
        <Box
          fontSize={14}
          display='flex'
          alignItems='center'
          flexDirection='column'>
          <Typography
            variant='h5'
            className={classes.header}>
            <CheckCircleOutline
              className={`${classes.inlineIcon} ${classes.success}`} />
            Setup Complete
          </Typography>

          <Box width='22rem' mt={4}>
            <RoundedPlainTextButton
              fullWidth
              size='large'
              variant='contained'
              color='primary'
              startIcon={
                <VerifiedUser />
              }
              disabled={loading}
              onClick={(): void => {
                toggleMFAState({
                  access_token: accessToken,
                  enabled: true,
                })
                onClose()
              }}>
              Enable Two Factor Authentication
            </RoundedPlainTextButton>
          </Box>
          { !MFA_REQUIRED && (
            <Box width='22rem' mt={3} mb={5}>
              <RoundedPlainTextButton
                fullWidth
                size='large'
                startIcon={
                  <Snooze />
                }
                onClick={onClose}>
                Later
              </RoundedPlainTextButton>
            </Box>
          )}
          
        </Box>
      )}

      <BackdropLoader
        BackdropProps={{
          open: loading,
        }} />
    </Fragment>
  )
}
