
import React from 'react'

import { Tabs, TabsProps, Tab, Box } from '@material-ui/core'

import { makeAppStyles } from '../../themes'

import { Health } from '../Health'

import { isNumber } from '@percept/utils'

import { Dictionary, HealthType, LayoutNodeType } from '@percept/types'


const useTabsStyles = makeAppStyles( theme => ({
  root: {
    minHeight: 'unset',
  },
  indicator: (
    theme.appComponents.appBarTabIndicator ?
      theme.appComponents.appBarTabIndicator.colorSecondary :
      {
        backgroundColor: theme.palette.secondary.main,
      }
  ),
}))

const useTabStyles = makeAppStyles( theme => ({
  root: {
    minHeight: theme.spacing(5.5),
    minWidth: theme.spacing(12),
    maxWidth: 'unset',
    fontSize: 11,
  }
}) )


export type LayoutTab = LayoutNodeType & {
  key: string
  name: string
  selected?: boolean
}

export type TabSwitcherProps = Omit<TabsProps, 'onChange'> & {
  tabs: LayoutTab[]
  value?: string
  onChange?: (event: React.ChangeEvent<{}>, tab: string) => void
  sectionScores?: Dictionary<HealthType>
}


export const TabSwitcher = ({
  tabs,
  sectionScores,
  ...props
}: TabSwitcherProps): JSX.Element => {

  const tabsClasses = useTabsStyles()

  const tabClasses = useTabStyles()

  return (
    <Tabs
      classes={tabsClasses}
      scrollButtons='auto'
      variant='scrollable'
      {...props}>

      { tabs.map( tab => {
        return (
          <Tab
            classes={tabClasses}
            key={tab.key}
            value={tab.key}
            label={
              <Box
                display='flex'
                alignItems='center'>
                { tab.name }
                { sectionScores && isNumber(sectionScores[tab.key]) && (
                  <Box width='3.75em'>
                    <Health
                      value={sectionScores[tab.key]} />
                  </Box>
                )}
              </Box>
            } />
        )
      }) }

    </Tabs>
  )
}

export default TabSwitcher
