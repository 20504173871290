import React, { Fragment, useEffect, useMemo, useState } from 'react'

import {
  Alert,
  BackdropLoader,
  BackdropProps,
  Box,
  Divider,
  Typography,
} from '@percept/mui'

import { PageHeader } from 'components/PageHeader'

import { QualityAnalysis } from 'components/QualityAnalysis'

import { PerformanceControls } from 'components/PerformanceControls'

import { PotentialEfficiencies } from './PotentialEfficiencies/PotentialEfficiencies'

import { ChannelSummaryProps } from 'components/ChannelSummary'

import { usePerformanceReportingDimension } from 'hooks'

import {
  DoubleVerifyProvider,
  NormalizedError,
  PerformanceComparisonRange,
  PlatformUnit,
  PlatformUnitProviderInfo,
  ReportProvider,
} from '@percept/types'

import { useChannelPillarScores, usePlatformUnit } from '@percept/hooks'

import { resolvePerformanceReportingDimensions } from 'components/Organisation/lib'

import { isLoading } from '@percept/utils'

import { CrossChannelPerformanceSummary } from './CrossChannelPerformanceSummary'


export type CrossChannelDashboardProps = {
  platformUnit: PlatformUnit | null
  providers: ReportProvider[]
  doubleVerifyProviders?: DoubleVerifyProvider[] | null
  providerInfo: PlatformUnitProviderInfo | null
  referenceDate: Date | null
  latestAvailableReferenceDate: Date | null
  activeComparison?: PerformanceComparisonRange
  mainHeader?: React.ReactNode
  secondaryHeaderContent?: React.ReactNode
  spendLabel?: string
  currency: string
  qualityAnalysisHeader?: React.ReactNode
  error?: NormalizedError
  displayLoader?: boolean
  showContent?: boolean
} & Pick<ChannelSummaryProps, 'seriesListing' | 'onSeriesClick'>



export const CrossChannelDashboard = ({
  mainHeader,
  secondaryHeaderContent,
  error,
  displayLoader = false,
  showContent = true,
  currency,
  platformUnit,
  providers,
  referenceDate,
  latestAvailableReferenceDate,
}: CrossChannelDashboardProps): JSX.Element => {

  const org_unit_id = platformUnit && platformUnit.id

  const [platformUnitDetail] = usePlatformUnit({
    org_unit_id,
  })

  const [pillarScores] = useChannelPillarScores({
    org_unit_id,
  })

  const [efficiencyActive, setEfficiencyActive] = useState(true)

  const stillLoading = isLoading(pillarScores)

  const availableDimensions = useMemo(() => {
    if( platformUnitDetail.data ){
      return resolvePerformanceReportingDimensions({
        platformUnit: platformUnitDetail.data,
      })
    }
    return undefined
  }, [platformUnitDetail.data])

  const [activeDimension, setActiveDimension] = usePerformanceReportingDimension()

  useEffect(() => {
    if( availableDimensions && !availableDimensions.includes(activeDimension) ){
      setActiveDimension(availableDimensions[0])
    }
  }, [availableDimensions, activeDimension, setActiveDimension])

  const shouldRender = Boolean(
    showContent
    && !stillLoading
    && platformUnit
    && availableDimensions
    && availableDimensions.includes(activeDimension)
  )

  const backdropProps: Partial<BackdropProps> = {
    open: displayLoader || !shouldRender
  }

  return (
    <Fragment>

      <Box
        my={4}
        mx={3}>

        { error && (
          <Alert
            variant='error'
            maxWidth='30em'
            mx='auto'
            {...error} />
        )}

        <PageHeader
          header={mainHeader}
          secondaryContent={secondaryHeaderContent} />

        { !!(
          shouldRender && platformUnit
        ) && (
          <Fragment>

            <Box mt={5} mb={3}>
              <Typography variant='h5'>
                Quality Analysis
              </Typography>
            </Box>

            <QualityAnalysis
              provider={null}
              platformUnit={platformUnit} />
            
            <Box my={3}>
              <Divider />
            </Box>
            
            <PerformanceControls
              platformUnit={platformUnit}
              availableDimensions={availableDimensions || []}
              referenceDate={referenceDate}
              maxDate={latestAvailableReferenceDate}
              doubleVerifyReferenceDate={null}
              requiresDoubleVerifyTrigger={false}
              potentialEfficiencyActive={efficiencyActive}
              onDimensionChange={(dimension): void => {
                setEfficiencyActive(dimension === 'potential_efficiency')
              }} />

            <Box mt={4}>
              { efficiencyActive? (
                <PotentialEfficiencies
                  suppressLoader={!showContent}
                  org_unit_id={platformUnit.id} />
              ) : (
                <CrossChannelPerformanceSummary
                  platformUnit={platformUnit}
                  providers={providers}
                  currency={currency}
                  referenceDate={referenceDate} />
              )}
            </Box>

          </Fragment>
        )}

      </Box>

      { backdropProps.open && (
        <BackdropLoader
          BackdropProps={backdropProps} />
      )}

    </Fragment>
  )

}

export default CrossChannelDashboard
