import { useReducer, Reducer, Dispatch } from 'react'

import { PlatformUnit, PlatformUnitProviderInfo, ReportProvider } from '@percept/types'

import { flatten, maxBy, minBy, uniq, xor } from 'lodash-es'

import { isAfter, isBefore } from 'date-fns'

import { discoverPerformanceProviders, isChildPlatformUnit } from './lib'

import { DerivedAggregationPeriod, PerformanceReportSegmentation } from './typings'


export type PerformanceReportReducerState = {
  platformUnit: PlatformUnit | null
  selectedPlatformUnits: PlatformUnit[]
  providerInfo: PlatformUnitProviderInfo | null
  providerOptions: ReportProvider[]
  providers: ReportProvider[]
  segmentation: PerformanceReportSegmentation | null
  aggregationPeriod: DerivedAggregationPeriod | null
  startDate: Date
  endDate: Date
  minDate: Date | null
  maxDate: Date
}

type PerformanceReportReducerAction = (
  { type: 'SET_PLATFORM_UNIT', payload: PlatformUnit } |
  { type: 'TOGGLE_PLATFORM_UNIT', payload: PlatformUnit } |
  { type: 'SET_PROVIDER_INFO', payload: PlatformUnitProviderInfo } |
  { type: 'SET_DATES', payload: Partial<Pick<PerformanceReportReducerState, 'startDate' | 'endDate'>> } |
  { type: 'TOGGLE_PROVIDER', payload: ReportProvider } |
  { type: 'TOGGLE_NETWORK_SEGMENTATION', payload: PerformanceReportSegmentation | null } | 
  { type: 'TOGGLE_AGGREGATION_PERIOD', payload: DerivedAggregationPeriod | null }
)



const minDatesByProvider: Record<ReportProvider, Date> = {
  adwords: new Date('2019-01-01'),
  facebook: new Date('2019-01-01'),
  adform: new Date('2020-03-01'),
  dv360: new Date('2021-10-06'),
}


const initialState: PerformanceReportReducerState = {
  platformUnit: null,
  selectedPlatformUnits: [],
  providerInfo: null,
  providerOptions: [],
  providers: [],
  segmentation: null,
  aggregationPeriod: null,
  startDate: new Date(),
  endDate: new Date(),
  maxDate: new Date(),
  minDate: null,
}


const getDateCompareValue = (d: Date): number => d.getTime()


const deriveDates = ({
  endDate,
  startDate,
  providerInfo,
  providers,
}: (
  Pick<PerformanceReportReducerState, 'endDate' | 'startDate' | 'providers'> & {
    providerInfo: PlatformUnitProviderInfo
  }
)): Pick<PerformanceReportReducerState, 'maxDate' | 'minDate' | 'endDate' | 'startDate'> => {
  const providerMaxDates = providers.map( provider => providerInfo[provider].reference_date )
  const providerMinDates = providers.map( provider => minDatesByProvider[provider] )

  let maxDate = minBy(providerMaxDates, getDateCompareValue) as Date
  const minDate = maxBy(providerMinDates, getDateCompareValue) as Date
  maxDate = maxBy([maxDate, minDate]) as Date

  const end = (
    isBefore(endDate, minDate) ?
      minDate :
      isAfter(endDate, maxDate) ?
        maxDate :
        endDate
  )

  const start = (
    isAfter(startDate, end) ?
      end :
      isBefore(startDate, minDate) ?
        minDate :
        startDate
  )

  return {
    endDate: end,
    startDate: start,
    maxDate,
    minDate,
  }
}


const performanceReportReducer: Reducer<
  PerformanceReportReducerState, PerformanceReportReducerAction
> = (state, action) => {
  switch(action.type){
    case 'SET_PLATFORM_UNIT': {
      const availableProviders = discoverPerformanceProviders(action.payload)

      let update: Partial<PerformanceReportReducerState> = {}

      if( state.providerInfo ){
        update = deriveDates({
          ...state,
          providers: availableProviders,
          providerInfo: state.providerInfo,
        })
        // Don't allow campaign_objective segmentation for
        // provider selections greater than Facebook only
        if(
          state.segmentation === 'campaign_objective' &&
          ( !availableProviders.includes('facebook') || availableProviders.length !== 1 )
        ){
          update.segmentation = null
        }
      }

      return {
        ...state,
        selectedPlatformUnits: [action.payload],
        platformUnit: action.payload,
        providerOptions: availableProviders,
        providers: availableProviders,
        ...update,
      }
    }

    case 'TOGGLE_PLATFORM_UNIT': {
      const currentPlatformUnits = state.selectedPlatformUnits

      const unitsToRemove: PlatformUnit[] = []

      currentPlatformUnits.forEach( unit => {
        if( isChildPlatformUnit(action.payload, unit) || isChildPlatformUnit(unit, action.payload) ){
          unitsToRemove.push(unit)
        }
      })

      const selectedPlatformUnits = xor(currentPlatformUnits, [...unitsToRemove, action.payload])

      const providerOptions = uniq(
        flatten(
          selectedPlatformUnits.map(discoverPerformanceProviders)
        )
      ).sort()

      let segmentation = state.segmentation

      // Don't allow campaign_objective segmentation for
      // provider selections greater than Facebook only
      if(
        segmentation === 'campaign_objective' &&
        ( !providerOptions.includes('facebook') || providerOptions.length !== 1 )
      ){
        segmentation = null
      }

      const dateParams = (
        state.providerInfo ?
          deriveDates({
            providerInfo: state.providerInfo,
            providers: providerOptions,
            startDate: state.startDate,
            endDate: state.endDate,
          }) :
          {}
      )

      let aggregationPeriod = state.aggregationPeriod
      if( aggregationPeriod && selectedPlatformUnits.length > 1 ){
        aggregationPeriod = null
      }

      return {
        ...state,
        aggregationPeriod,
        selectedPlatformUnits,
        providerOptions,
        providers: providerOptions,
        segmentation,
        ...dateParams,
      }
    }

    case 'SET_PROVIDER_INFO': {
      const providerInfo = action.payload
      const dateParams = deriveDates({
        providerInfo,
        providers: state.providers,
        startDate: state.startDate,
        endDate: state.endDate,
      })
      return {
        ...state,
        providerInfo,
        ...dateParams,
      }
    }

    case 'SET_DATES': {
      const update = state.providerInfo ? deriveDates({
        ...state,
        ...action.payload,
        providers: state.providers,
        providerInfo: state.providerInfo,
      }) : {}
      return {
        ...state,
        ...action.payload,
        ...update,
      }
    }

    case 'TOGGLE_PROVIDER': {
      const providers = xor(state.providers, [action.payload])
      let update: Partial<PerformanceReportReducerState> = {}
      if( state.providerInfo ){
        update = deriveDates({
          ...state,
          providers,
          providerInfo: state.providerInfo,
        })
        // Don't allow campaign_objective segmentation for
        // provider selections greater than Facebook only
        if(
          state.segmentation === 'campaign_objective' &&
          ( !providers.includes('facebook') || providers.length !== 1 )
        ){
          update.segmentation = null
        }
      }
      return {
        ...state,
        providers,
        ...update,
      }
    }

    case 'TOGGLE_NETWORK_SEGMENTATION':
      return {
        ...state,
        segmentation: action.payload || null,
      }


    case 'TOGGLE_AGGREGATION_PERIOD':
      return {
        ...state,
        aggregationPeriod: action.payload || null,
      }
  }
}


export const usePerformanceReportReducer = (): [
  PerformanceReportReducerState, Dispatch<PerformanceReportReducerAction>
] => (
  useReducer(performanceReportReducer, initialState)
)
