import { Box, BackdropLoader } from '@percept/mui'
import { useActivePlatformUnit } from 'components/Organisation'
import {
  DashboardLayout,
  Report,
} from 'components/ReportingDashboard/DashboardLayout'
import { LayoutWithHeader } from 'components/ReportingDashboard/LayoutWithHeader'
import { Sidebar } from 'components/ReportingDashboard/Sidebar'
import { TableForm } from 'components/ReportingDashboard/TableForm/TableForm'
import { SubmissionTracker } from 'components/ReportingDashboard/SubmissionTracker'
import {
  COMPETITIVE_INVESTMENT_ROUTES,
  FORM_ROUTES,
  MEDIA_INVESTMENT_ROUTES,
  POSSIBLE_FILTERS_COMPETITIVE_INVESTMENT,
  POSSIBLE_FILTERS_MEDIA_INVESTMENT,
  SIDEBAR_TABS_COMPETITIVE_INVESTMENT,
  SIDEBAR_TABS_MEDIA_INVESTMENT,
} from 'constans'
import React, { ReactElement } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { DefaultLayout } from 'screens/DefaultLayout'
import PageNotFound from 'screens/PageNotFound'


export enum Reporting {
  MediaInvestment = 'media-investment',
  CompetitiveInvestment = 'competitive-investment',
  SubmissionTracker = 'submission-tracker',
  Form = 'investment-form',
}

const Organisation = (): ReactElement => {
  const activePlatformUnit = useActivePlatformUnit()
  if (activePlatformUnit) {
    return (
      <Redirect
        to={`${activePlatformUnit.id}/${Reporting.MediaInvestment}${MEDIA_INVESTMENT_ROUTES[0].path}`}
      />
    )
  }
  return <BackdropLoader />
}

const ReportingContainer = ({ children }: React.PropsWithChildren<{}>): JSX.Element => {
  return (
    <Box
      flexGrow={1}
      marginLeft='240px'
      padding='40px 36px'>
      { children }
    </Box>
  )
}

const ReportingRoutes = (): JSX.Element => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${path}/:org_unit_id`} component={Organisation} />
      <Route path={`${path}/:org_unit_id/${Reporting.Form}`}>
        <Switch>
          {FORM_ROUTES.map((route, index) => {
            return (
              <Route
                key={index}
                path={`${path}/:org_unit_id/${Reporting.Form}` + route.path}>
                <TableForm {...route.props} />
              </Route>
            )
          })}
          <Redirect
            to={`${path}/:org_unit_id/${Reporting.Form}/media-investment`}
          />
        </Switch>
      </Route>
      <Route path={`${path}/:org_unit_id/${Reporting.SubmissionTracker}`}>
        <SubmissionTracker />
      </Route>

      <DefaultLayout dashboardType='media-investment'>
        <Route path={`${path}/:org_unit_id/${Reporting.MediaInvestment}`}>
          <ReportingContainer>
            <Sidebar tabs={SIDEBAR_TABS_MEDIA_INVESTMENT} />
            <Switch>
              {MEDIA_INVESTMENT_ROUTES.map((route, index) => {
                return (
                  <Route
                    key={index}
                    path={
                      `${path}/:org_unit_id/${Reporting.MediaInvestment}` +
                      route.path
                    }>
                    <DashboardLayout
                      reports={route.props}
                      dashboardType='filtersMedia'
                      filters={POSSIBLE_FILTERS_MEDIA_INVESTMENT}
                    />
                  </Route>
                )
              })}
              <Redirect
                to={`${path}/:org_unit_id/${Reporting.MediaInvestment}${MEDIA_INVESTMENT_ROUTES[0].path}`}
              />
            </Switch>
          </ReportingContainer>
        </Route>
        <Route path={`${path}/:org_unit_id/${Reporting.CompetitiveInvestment}`}>
          <ReportingContainer>
            <Sidebar tabs={SIDEBAR_TABS_COMPETITIVE_INVESTMENT} />
            <Switch>
              {COMPETITIVE_INVESTMENT_ROUTES.map((route, index) => {
                return (
                  <Route
                    key={index}
                    path={
                      `${path}/:org_unit_id/${Reporting.CompetitiveInvestment}` +
                      route.path
                    }>
                    <DashboardLayout
                      reports={route.props as Report[]}
                      dashboardType='filtersCompetitve'
                      filters={POSSIBLE_FILTERS_COMPETITIVE_INVESTMENT}
                    />
                  </Route>
                )
              })}
              <Redirect
                to={`${path}/:org_unit_id/${Reporting.CompetitiveInvestment}${COMPETITIVE_INVESTMENT_ROUTES[0].path}`}
              />
            </Switch>
          </ReportingContainer>
        </Route>
      </DefaultLayout>
      <Route component={PageNotFound} />
    </Switch>
  )
}

export default ReportingRoutes
