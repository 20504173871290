
import {
  Dictionary,
  ChannelKey,
  EntityType,
  ReportProvider,
  PerformanceReportingDimension,
  DoubleVerifyAdwordsPerformanceReportingDimension,
  DoubleVerifyFacebookPerformanceReportingDimension,
  DoubleVerifyAdformPerformanceReportingDimension,
  MetricPillar,
  URLReportProvider,
  RawPerformanceReportingDimension,
  DoubleVerifyPerformanceReportingDimension,
  DoubleVerifyProvider,
  ReportProviderEnum,
  DoubleVerifyProviderEnum,
  DoubleVerifyProviderConstant,
  ReportProviderV2,
  GoogleAdsNetwork,
  FacebookPublisherPlatform,
  AdformCampaignType,
  GoogleAdsSubNetwork,
  FacebookSubNetwork,
  AdformSubNetwork,
  FacebookCampaignObjective,
  StructuralReportFrequency,
  DV360MediaType,
  DV360Exchange,
  ChannelSlug,
} from '@percept/types'


const { PERCEPT_ENV } = process.env


type ConstantSetting = {
  text: string
  abbreviatedText?: string
  icon: string
}

interface ProviderSetting extends ConstantSetting {
  profiles?: Dictionary
}

export const reportProviders: ReportProvider[] = [
  'adwords', 'facebook', 'adform', 'dv360',
]

export const reportProviderMap: Record<ReportProvider, ProviderSetting> = {
  
  'adwords': {
    text: 'Google Ads',
    icon: 'google',
    profiles: {
      default: {
        text: 'Default'
      },
      enabled_only: {
        text: 'Enabled Only'
      },
      in_scope: {
        text: 'In Scope'
      },
      vf_50: {
        text: 'Vodafone 50'
      },
    }
  },
  
  'facebook': {
    text: 'Facebook Ads',
    icon: 'facebook f',
    profiles: {
      default: {
        text: 'Default'
      }
    }
  },

  'adform': {
    text: 'Adform',
    icon: 'adform',
    profiles: {
      default: {
        text: 'Default'
      }
    }
  },

}


export const providerEnumMap: Record<
  ReportProviderEnum | DoubleVerifyProviderEnum,
  ReportProvider | DoubleVerifyProvider
> = {
  1: 'facebook',
  2: 'adwords',
  3: 'adform',
  4: 'doubleverify_facebook',
  5: 'doubleverify_adwords',
  6: 'doubleverify_adform',
  7: 'dv360',
}


export const reportProviderV2Map: Record<
  ReportProvider, ReportProviderV2
> = {
  facebook: 'FACEBOOK',
  adwords: 'GOOGLE_ADS',
  adform: 'ADFORM',
  dv360: 'DV360',
}

export const providerStringEnumMap: Record<
  ReportProvider | DoubleVerifyProvider,
  ReportProviderV2 | DoubleVerifyProviderConstant
> = {
  ...reportProviderV2Map,
  doubleverify_facebook: 'DOUBLEVERIFY_FACEBOOK',
  doubleverify_adwords: 'DOUBLEVERIFY_YOUTUBE',
  doubleverify_adform: 'DOUBLEVERIFY_STANDARD',
}


export const providerChannelMap: Record<ReportProvider | ReportProviderV2 | DoubleVerifyProvider, ChannelKey> = {
  adform: 'programmatic',
  ADFORM: 'programmatic',
  doubleverify_adform: 'programmatic',
  adwords: 'search',
  GOOGLE_ADS: 'search',
  doubleverify_adwords: 'search',
  facebook: 'social',
  FACEBOOK: 'social',
  doubleverify_facebook: 'social',
  dv360: 'programmatic',
  DV360: 'programmatic',
}


export const providerLabelMap: Record<ReportProvider | ReportProviderV2 | DoubleVerifyProvider, string> = {
  adform: 'Adform',
  ADFORM: 'Adform',
  doubleverify_adform: 'DoubleVerify Adform',
  adwords: 'Google Ads',
  GOOGLE_ADS: 'Google Ads',
  doubleverify_adwords: 'DoubleVerify Google Ads',
  facebook: 'Facebook',
  FACEBOOK: 'Facebook',
  doubleverify_facebook: 'DoubleVerify Facebook',
  dv360: 'DV360',
  DV360: 'DV360',
}


export const providerURLMap: Record<ReportProvider, URLReportProvider> = {
  adwords: 'google-ads',
  facebook: 'facebook',
  adform: 'adform',
  dv360: 'dv360',
}


export const doubleVerifyProviderMap: Record<DoubleVerifyProvider, ReportProvider> = {
  doubleverify_adform: 'adform',
  doubleverify_adwords: 'adwords',
  doubleverify_facebook: 'facebook',
}

export const providerDoubleVerifyMap: Record<ReportProvider | ReportProviderV2, DoubleVerifyProvider> = {
  ADFORM: 'doubleverify_adform',
  adform: 'doubleverify_adform',
  GOOGLE_ADS: 'doubleverify_adwords',
  adwords: 'doubleverify_adwords',
  FACEBOOK: 'doubleverify_facebook',
  facebook: 'doubleverify_facebook',
  dv360: '',
  DV360: '',
}


type Option<T> = {
  value: T
  label: string
}

export const channelDisplayMap: Record<ChannelKey, Option<ChannelKey>>  = {
  programmatic: {
    value: 'programmatic',
    label: 'Programmatic',
  },
  search: {
    value: 'search',
    label: 'Search',
  },
  social: {
    value: 'social',
    label: 'Social',
  },
}

export const channelSlugKeyMap: Record<ChannelSlug, ChannelKey> = {
  PROGRAMMATIC: 'programmatic',
  SEARCH: 'search',
  SOCIAL: 'social',
}


export const structuralReportFrequencyTypes: Record<number, StructuralReportFrequency> = {
  1: {
    type: 'DAILY',
  },
  2: {
    type: 'DAY_OF_WEEK',
    day: 'MON',
  },
  3: {
    type: 'DAY_OF_MONTH',
    day: 'FIRST',
  },
  4: {
    type: 'DISABLED',
  },
}

export const structuralReportFrequencyTypeLabelMap: Record<StructuralReportFrequency['type'], string> = {
  DAILY: 'Daily',
  DAY_OF_MONTH: 'Monthly',
  DAY_OF_WEEK: 'Weekly',
  DISABLED: 'Disabled',
}


export const pillarLabelMap: Record<MetricPillar, string> = {
  structure: 'Structure',
  creative: 'Creative',
  audience: 'Audience',
  brand_safety: 'Brand Safety',
}


export const performanceReportingDimensionOrder: PerformanceReportingDimension[] = [
  'cost',
  'impressions',
  'clicks',
  'conversions',
  'cpc',
  'ctr',
  'cpa',
  'cpm',
  'cpv',
  ...(PERCEPT_ENV === 'dev' && [
    'unique_daily_campaigns_mean',
    'unique_daily_campaigns_max',
    'unique_daily_campaigns_min',
  ] as PerformanceReportingDimension[] || []),
]


export const rawPerformanceReportingDimensionMap: Record<
  RawPerformanceReportingDimension, PerformanceReportingDimension
> = {
  cost: 'cost',
  converted_cost: 'cost',
  impressions: 'impressions',
  clicks: 'clicks',
  conversions: 'conversions',
  views: 'views',
  conv_rate: 'conversions',
  ctr: 'ctr',
  avg_ctr: 'ctr',
  cpa: 'cpa',
  avg_cpa: 'cpa',
  converted_avg_cpa: 'cpa',
  cpc: 'cpc',
  avg_cpc: 'cpc',
  converted_avg_cpc: 'cpc',
  cpm: 'cpm',
  avg_cpm: 'cpm',
  converted_avg_cpm: 'cpm',
  cpv: 'cpv',
  avg_cpv: 'cpv',
  converted_avg_cpv: 'cpv',
  unique_daily_campaigns_max: 'unique_daily_campaigns_max',
  unique_daily_campaigns_mean: 'unique_daily_campaigns_mean',
  unique_daily_campaigns_min: 'unique_daily_campaigns_min',
}


export const doubleVerifyAdwordsReportingDimensionOrder: DoubleVerifyAdwordsPerformanceReportingDimension[] = [
  'authentic_impressions',
  'authentic_rate',
  'authentic_video_viewable_impressions',
  'authentic_video_viewable_rate',
  'brand_safe_rate',
  'fraud_free_rate',
  'fraudsivt_free_impressions',
  'monitored_impressions',
  'suitable_rate',
  'video_viewable_impressions',
  'yt_brand_safe_impressions',
  'yt_ss_measured_impressions',
  'yt_suitable_impressions'
]

export const doubleVerifyAdformReportingDimensionOrder: DoubleVerifyAdformPerformanceReportingDimension[] = [
  'authentic_ads',
  'authentic_rate',
  'block_rate',
  'blocks',
  'brand_safe_ads',
  'brand_safety_rate',
  'display_viewable_rate',
  'fraud_free_rate',
  'fraudsivt_free_ads',
  'in_geo_ads',
  'in_geo_rate',
  'monitored_ads',
  'viewable_impressions',
  'video_viewable_impressions',
  'viewablity_video_rate'
]

export const doubleVerifyFacebookReportingDimensionOrder: DoubleVerifyFacebookPerformanceReportingDimension[] = [
  'authentic_impressions',
  'authentic_rate',
  'display_viewable_impressions',
  'display_viewable_rate',
  'fraud_free_rate',
  'fraudsivt_free_impressions',
  'monitored_impressions',
  'video_authentic_impressions',
  'video_authentic_rate'
]


export const doubleVerifyDimensionsByProvider: Record<ReportProvider, DoubleVerifyPerformanceReportingDimension[]> = {
  adform: doubleVerifyAdformReportingDimensionOrder,
  adwords: doubleVerifyAdwordsReportingDimensionOrder,
  facebook: doubleVerifyFacebookReportingDimensionOrder,
  dv360: [],
}


type DimensionSettings = Record<PerformanceReportingDimension | DoubleVerifyPerformanceReportingDimension, ConstantSetting>

export const dimensionMap = {
  all: {
    text: 'All',
    icon: 'globe',
  },
  count: {
    text: 'Total',
    icon: 'add circle',
  },
  cost: {
    text: 'Spend',
    icon: 'money'
  },
  impressions: {
    text: 'Impressions',
    abbreviatedText: 'Impr.',
    icon: 'eye',
  },
  clicks: {
    text: 'Clicks',
    icon: 'hand pointer outline',
  },
  conversions: {
    text: 'Conversions',
    abbreviatedText: 'Conv.',
    icon: 'exchange',
  },
  cpc: {
    text: 'CPC',
    icon: 'money',
  },
  ctr: {
    text: 'CTR',
    icon: 'percentage',
  },
  cpa: {
    text: 'CPA',
    icon: 'money',
  },
  cpm: {
    text: 'CPM',
    icon: 'money',
  },
  cpv: {
    text: 'CPV',
    icon: 'money',
  },
  // DoubleVerify
  authentic_ads: {
    text: 'Authentic Ads',
    abbreviatedText: 'Auth. Ads',
    icon: '',
  },
  authentic_impressions: {
    text: 'Authentic Impressions',
    abbreviatedText: 'Auth. Impr.',
    icon: '',
  },
  authentic_rate: {
    text: 'Authentic Rate',
    abbreviatedText: 'Auth. Rate',
    icon: '',
  },
  authentic_video_viewable_impressions: {
    text: 'Authentic Video Viewable Impressions',
    abbreviatedText: 'Auth. Video Viewable Impr.',
    icon: '',
  },
  authentic_video_viewable_rate: {
    text: 'Authentic Video Viewable Rate',
    abbreviatedText: 'Auth. Video Viewable Rate',
    icon: '',
  },
  block_rate: {
    text: 'Block Rate',
    icon: '',
  },
  blocks: {
    text: 'Blocks',
    icon: '',
  },
  brand_safe_ads: {
    text: 'Brand Safe Ads',
    icon: '',
  },
  brand_safe_rate: {
    text: 'Brand Safe Rate',
    icon: '',
  },
  brand_safety_rate: {
    text: 'Brand Safety Rate',
    icon: '',
  },
  display_viewable_impressions: {
    text: 'Display Viewable Impressions',
    abbreviatedText: 'Display Viewable Impr.',
    icon: '',
  },
  display_viewable_rate: {
    text: 'Display Viewable Rate',
    abbreviatedText: 'Display Viewable Rate',
    icon: '',
  },
  fraud_free_rate: {
    text: 'Fraud Free Rate',
    icon: '',
  },
  fraudsivt_free_ads: {
    text: 'Fraud SIVT Free Ads',
    icon: '',
  },
  fraudsivt_free_impressions: {
    text: 'Fraud SIVT Free Impressions',
    abbreviatedText: 'Fraud SIVT Free Impr.',
    icon: '',
  },
  in_geo_ads: {
    text: 'In Geo Ads',
    icon: '',
  },
  in_geo_rate: {
    text: 'In Geo Rate',
    icon: '',
  },
  monitored_ads: {
    text: 'Monitored Ads',
    icon: '',
  },
  monitored_impressions: {
    text: 'Monitored Impressions',
    abbreviatedText: 'Monitored Impr.',
    icon: '',
  },
  suitable_rate: {
    text: 'Suitable Rate',
    icon: '',
  },
  video_authentic_impressions: {
    text: 'Video Authentic Impressions',
    abbreviatedText: 'Video Auth. Impr.',
    icon: '',
  },
  video_authentic_rate: {
    text: 'Video Authentic Rate',
    abbreviatedText: 'Video Auth. Rate',
    icon: '',
  },
  video_viewable_impressions: {
    text: 'Video Viewable Impressions',
    abbreviatedText: 'Video Viewable Impr.',
    icon: '',
  },
  viewable_impressions: {
    text: 'Viewable Impressions',
    abbreviatedText: 'Viewable Impr.',
    icon: '',
  },
  viewablity_video_rate: {
    text: 'Video Viewable Rate',
    icon: '',
  },
  yt_brand_safe_impressions: {
    text: 'Brand Safe Impressions',
    abbreviatedText: 'Brand Safe Impr.',
    icon: '',
  },
  yt_ss_measured_impressions: {
    text: 'Suitable Measured Impressions',
    abbreviatedText: 'Suitable Measured Impr.',
    icon: '',
  },
  yt_suitable_impressions: {
    text: 'Suitable Impressions',
    abbreviatedText: 'Suitable Impr.',
    icon: '',
  },
  // Structural dimensions
  unique_daily_campaigns_min: {
    text: 'Minimum Daily Campaigns',
    abbreviatedText: 'Min. Daily Campaigns',
    icon: '',
  },
  unique_daily_campaigns_mean: {
    text: 'Average Daily Campaigns',
    abbreviatedText: 'Avg. Daily Campaigns',
    icon: '',
  },
  unique_daily_campaigns_max: {
    text: 'Maximum Daily Campaigns',
    abbreviatedText: 'Max. Daily Campaigns',
    icon: '',
  },
  views: {
    text: 'Views',
    abbreviatedText: 'Views',
    icon: '',
  }
} as DimensionSettings


export const networkLabelMap: Record<
  GoogleAdsNetwork | FacebookPublisherPlatform | AdformCampaignType | DV360MediaType,
  string
> = {
  AFFILIATE: 'Affiliate',
  ALL_AUTOMATED_APP_ADS: 'All Automated App Ads',
  AUDIENCE_NETWORK: 'Audience Network',
  AUDIO: 'Audio',
  CONTENT: 'Content',
  DISPLAY: 'Display',
  EMAIL: 'Email',
  FACEBOOK: 'Facebook',
  GOOGLE: 'Google',
  INSTAGRAM: 'Instagram',
  MESSENGER: 'Messenger',
  MICROSOFT: 'Microsoft',
  MIXED: 'Mixed',
  MOBILE: 'Mobile',
  NONCAMPAIGN: 'Non-Campaign',
  RTB: 'Real-Time Bidding',
  SEARCH: 'Search',
  SEARCH_NONAPI: 'Search Non-API',
  SEARCH_NON_API: 'Search Non-API',
  SEARCH_PARTNERS: 'Search Partners',
  SOCIAL_MEDIA: 'Social Media',
  UNKNOWN: 'Unknown',
  UNSPECIFIED: 'Unspecified',
  YOUTUBE_SEARCH: 'YouTube Search',
  YOUTUBE_WATCH: 'YouTube Watch',
  VIDEO: 'Video',
}

export const subNetworkLabelMap: Record<
  GoogleAdsSubNetwork | FacebookSubNetwork | AdformSubNetwork | DV360Exchange,
  string
> = {
  ADS_ON_FACEBOOK_REELS: 'Ads on Facebook Reels',
  ADHESION_FLOOR_AD_SINGLE_FILE: 'Adhesion Floor Ad Single File',
  ALL_AUTOMATED_APP_ADS: 'All Automated App Ads',
  AN_CLASSIC: 'Audience Network Classic',
  BIZ_DISCO_FEED: 'Business Discovery Feed',
  DISCOVERY: 'Discovery',
  DISPLAY: 'Display',
  DISPLAY_3D_BOX_MPU: 'Display 3D Box MPU',
  DISPLAY_INVIEW: 'Display Inview',
  DISPLAY_ROLL_FOR_VIDEO: 'Display Roll for Video',
  DISPLAY_STANDARD: 'Display Standard',
  EXPANDABLE_EXPANDABLE_SINGLE_FILE: 'Expandable Single File',
  EXPANDABLE_EXPANDABLE_TWO_FILES: 'Expandable Two Files',
  FACEBOOK_GROUPS_FEED: 'Facebook Groups Feed',
  FACEBOOK_REELS: 'Facebook Reels',
  FACEBOOK_REELS_OVERLAY: 'Facebook Reels Overlay',
  FACEBOOK_STORIES: 'Facebook Stories',
  FEED: 'Feed',
  FLOATING_FLOATING_AD: 'Floating Ad',
  FLOATING_PRESTITIAL: 'Floating Prestitial',
  HOTEL: 'Hotel',
  HTML: 'HTML',
  IAB_BRANDING_IAB_BILBOARD: 'IAB Branding Billboard',
  IAB_BRANDING_IAB_PUSHDOWN_SINGLE_FILE: 'IAB Branding Pushdown Single File',
  IMAGE: 'Image',
  INSTAGRAM_EXPLORE: 'Instagram Explore',
  INSTAGRAM_EXPLORE_GRID_HOME: 'Instagram Explore Grid Home',
  INSTAGRAM_IGTV: 'Instagram IGTV',
  INSTAGRAM_PROFILE_FEED: 'Instagram Profile Feed',
  INSTAGRAM_REELS: 'Instagram Reels',
  INSTAGRAM_REELS_OVERLAY: 'Instagram Reels Overlay',
  INSTAGRAM_SEARCH: 'Instagram Search',
  INSTAGRAM_SHOP: 'Instagram Shop', 
  INSTAGRAM_STORIES: 'Instagram Stories',
  INSTANT_ARTICLE: 'Instant Article',
  INSTREAM: 'Instream',
  INSTREAM_VIDEO: 'Instream Video',
  KEYWORD: 'Keyword',
  LINK: 'Link',
  LOCAL: 'Local',
  LOCAL_SERVICES: 'Local Services',
  MARKETPLACE: 'Marketplace',
  MESSENGER_INBOX: 'Messenger Inbox',
  MESSENGER_STORIES: 'Messenger Stories',
  MOBILE_CLICK_TO_EXPAND: 'Mobile Click To Expand',
  MOBILE_CUBE_SWIPE: 'Mobile Cube Swipe',
  MOBILE_INTERSTITIAL: 'Mobile Interstitial',
  MOBILE_STANDARD: 'Mobile Standard',
  MULTI_CHANNEL: 'Multi Channel',
  NA: 'Not Applicable',
  NATIVE: 'Native',
  PERFORMANCE_MAX: 'Performance Max',
  REWARDED_VIDEO: 'Rewarded Video',
  RIGHT_HAND_COLUMN: 'Right Hand Column',
  SEARCH: 'Search',
  SHOPPING: 'Shopping',
  SMART: 'Smart',
  SYNCHRONIZED: 'Synchronized',
  THIRD_PARTY: 'Third Party',
  UNKNOWN: 'Unknown',
  UNSPECIFIED: 'Unspecified',
  VIDEO: 'Video',
  VIDEO_FEEDS: 'Video Feeds',
  // DV360 Exchanges
  GOOGLE_AD_MANAGER: 'Google Ad Manager',
  YAHOO_EXCHANGE: 'Yahoo Exchange',
  PUBMATIC: 'PubMatic',
  MAGNITE_DV_PLUS: 'Magnite DV+',
  OPENX: 'OpenX',
  APPNEXUS_XANDR: 'AppNexus (Xandr)',
  IMPROVE_DIGITAL: 'Improve Digital',
  INDEX_EXCHANGE: 'Index Exchange',
  STRÖER_SSP: 'Ströer SSP',
  SOVRN: 'Sovrn',
  TEADSTV: 'Teads.tv',
  SMART_RTB_PLUS_SMARTADSERVER: 'Smart RTB+ (Smartadserver)',
  TRIPLELIFT: 'TripleLift',
  YOUTUBE_AND_PARTNERS: 'YouTube & Partners',
  ADFORM: 'Adform',
  BIDSWITCH: 'BidSwitch',
  FREEWHEEL_SSP: 'FreeWheel SSP',
  ONE_BY_AOL_MOBILE: 'ONE by AOL: Mobile',
  SPOTX: 'SpotX',
  VERIZON_MEDIA_VIDEO_EXCHANGE: 'Verizon Media Video Exchange',
  YIELDMO: 'Yieldmo',
  GUMGUM: 'GumGum',
  DAILYMOTION: 'Dailymotion',
  PULSEPOINT: 'PulsePoint',
  UNRULYX: 'UnrulyX',
  SMARTCLIP: 'SmartClip',
  MOPUB: 'MoPub',
  YIELDLAB: 'Yieldlab',
  FLUCT: 'Fluct',
  ADSWIZZ: 'AdsWizz',
  TRITON: 'Triton',
  SMAATO: 'Smaato',
  PERMODO: 'Permodo',
  KARGO: 'Kargo',
  MICROAD: 'MicroAd',
  TABOOLA: 'Taboola',
  INMOBI: 'InMobi',
}


export const campaignObjectiveLabelMap: Record<FacebookCampaignObjective, string> = {
  APP_INSTALLS: 'App Installs',
  BRAND_AWARENESS: 'Brand Awareness',
  CONVERSIONS: 'Conversions',
  EVENT_RESPONSES: 'Event Responses',
  LEAD_GENERATION: 'Lead Generation',
  LINK_CLICKS: 'Link Clicks',
  LOCAL_AWARENESS: 'Local Awareness',
  MESSAGES: 'Messages',
  OFFER_CLAIMS: 'Offer Claims',
  PAGE_LIKES: 'Page Likes',
  POST_ENGAGEMENT: 'Post Engagement',
  PRODUCT_CATALOG_SALES: 'Product Catalog Sales',
  REACH: 'Reach',
  STORE_VISITS: 'Store Visits',
  VIDEO_VIEWS: 'Video Views',
  CANVAS_APP_ENGAGEMENT: 'Canvas App Engagement',
  CANVAS_APP_INSTALLS: 'Canvas App Installs',
  CATALOGUE_SALES: 'Catalogue Sales',
  MOBILE_APP_ENGAGEMENT: 'Mobile App Engagement',
  MOBILE_APP_INSTALLS: 'Mobile App Installs',
  STORE_TRAFFIC: 'Store Traffic',
  TRAFFIC: 'Traffic',
  OUTCOME_APP_PROMOTION: 'Outcome App Promotion',
  OUTCOME_AWARENESS: 'Outcome Awareness',
  OUTCOME_ENGAGEMENT: 'Outcome Engagement',
  OUTCOME_LEADS: 'Outcome Leads',
  OUTCOME_SALES: 'Outcome Sales',
  OUTCOME_TRAFFIC: 'Outcome Traffic',
}


export const structuralEntityTypeLabelMap: Record<EntityType, string> = {
  Account: 'Account',
  AccountExtension: 'Account Extension',
  AccountGroup: 'Account Group',
  Ad: 'Ad',
  AdGroup: 'Ad Group',
  AdGroupAd: 'Ad',
  AdGroupCriterion: 'Keyword',
  AdGroupExtension: 'Ad Group Extension',
  AdSet: 'Adset',
  AdVideo: 'Video',
  Campaign: 'Campaign',
  CampaignCriterion: 'Negative Keyword',
  CampaignExtension: 'Campaign Extension',
  CampaignGroup: 'CampaignGroup',
  Creative: 'Creative',
  DirectLineItem: 'Direct Line Item',
  ExpandedTextAd: 'Expanded Text Ad',
  Extension: 'Extension',
  InsertionOrder: 'Insertion Order',
  Keyword: 'Keyword',
  LandingPage: 'Landing Page',
  LineItem: 'Line Item',
  RangedSearchTerm: 'Search Term',
  RangedSearchTermMatch: 'Search Term Match',
  ResponsiveSearchAd: 'Responsive Search Ad',
  RtbLineItem: 'RTB Line Item',
  SearchTermMatch: 'Search Term Match',
}


export const iabCategories = [
  { id: 'IAB1', name: 'Arts & Entertainment' },
  { id: 'IAB2', name: 'Automotive' },
  { id: 'IAB3', name: 'Business' },
  { id: 'IAB4', name: 'Careers' },
  { id: 'IAB5', name: 'Education' },
  { id: 'IAB6', name: 'Family & Parenting' },
  { id: 'IAB7', name: 'Health & Fitness' },
  { id: 'IAB8', name: 'Food & Drink' },
  { id: 'IAB9', name: 'Hobbies & Interests' },
  { id: 'IAB10', name: 'Home & Garden' },
  { id: 'IAB11', name: 'Law, Government & Politics' },
  { id: 'IAB12', name: 'News' },
  { id: 'IAB13', name: 'Personal Finance' },
  { id: 'IAB14', name: 'Society' },
  { id: 'IAB15', name: 'Science' },
  { id: 'IAB16', name: 'Pets' },
  { id: 'IAB17', name: 'Sports' },
  { id: 'IAB18', name: 'Style & Fashion' },
  { id: 'IAB19', name: 'Technology & Computing' },
  { id: 'IAB20', name: 'Travel' },
  { id: 'IAB21', name: 'Real Estate' },
  { id: 'IAB22', name: 'Shopping' },
  { id: 'IAB23', name: 'Religion & Spirituality' },
  { id: 'IAB24', name: 'Uncategorised' },
] as { id: string, name: string }[]


export type EmptyPaginationCursor = 'NO_CURSOR'

export const emptyPaginationCursor: EmptyPaginationCursor = 'NO_CURSOR'


export type EmptyPaginationLimit = 'NO_LIMIT'

export const emptyPaginationLimit: EmptyPaginationLimit = 'NO_LIMIT'


export default {
  reportProviderMap,
  dimensionMap,
}
