import { createSelector } from 'reselect'

import { getLocation } from 'connected-react-router'

import { user } from '@percept/redux/bundles'

import { get } from 'lodash-es'

import { isLoading, isPlatformUnitContainer, isVodafonePlatformUnit } from '@percept/utils'

import {
  discoverDoubleVerifyProviders,
  discoverProviders,
  getOrgBySeriesId,
  getPlatformUnitHierarchy,
  orgLabelDisplayMap,
  platformUnitHasInsightReports,
} from './utils'

import { ActiveOrgEnvironment, OrgNavigation } from './typings'

import { DashboardSelector } from 'redux/selectors'

import { MenuOption } from '@percept/mui'

import { PlatformUnit } from '@percept/types'


/* Active platform unit derived state memoized selectors */

export const getActivePlatformUnitId: DashboardSelector<string | null> = createSelector(
  [
    getLocation,
    user.selectors.getUserPlatformUnits,
    user.selectors.getDefaultPlatformUnitId,
  ],
  ({ pathname }, units, defaultPlatformUnitId) => {
    let match: RegExpMatchArray | null = null
    if ((match = /dashboards\/(\d+)\/?/.exec(pathname)) ){
      return match && match[1]
    }
    if ((match = /reporting\/(\d+)\/?/.exec(pathname)) ){
      return match && match[1]
    }
    if ((match = /series\/([a-z0-9-_]+)/.exec(pathname)) ){
      const org = getOrgBySeriesId(units.data, match && match[1] || '')
      return get(org, 'id', null)
    }
    return defaultPlatformUnitId
  }
)


export const getActiveOrgUnitHierarchy: DashboardSelector<OrgNavigation['hierarchy']> = createSelector(
  [
    user.selectors.getUserPlatformUnits,
    getActivePlatformUnitId
  ],
  (units, activePlatformUnitId) =>(
    getPlatformUnitHierarchy(units.data, activePlatformUnitId)
  )
)


export const getActiveOrgUnit: DashboardSelector<OrgNavigation['activePlatformUnit']> = createSelector(
  [getActiveOrgUnitHierarchy],
  h => h.length ? h[h.length - 1] : null
)


const getPathname: DashboardSelector<string> = state => state.router.location.pathname

const getSeriesIdRouteParam: DashboardSelector<string | null> = createSelector(
  [getPathname],
  path => {
    const match = /\/series\/([^/]+)/.exec(path)
    return match && match[1]
  }
)


export const getActiveOrgEnvironment: DashboardSelector<ActiveOrgEnvironment> = createSelector(
  [
    user.selectors.getUserPlatformUnits,
    user.selectors.getDefaultPlatformUnitId,
    getActivePlatformUnitId,
    getSeriesIdRouteParam,
  ],
  (rootPlatformUnits, defaultPlatformUnitId, activePlatformUnitId, report_series_id) => {

    let activePlatformUnit: PlatformUnit | null = null

    let hierarchy: PlatformUnit[] = []

    if( report_series_id ){
      const match = getOrgBySeriesId(rootPlatformUnits.data, report_series_id)
      activePlatformUnit = match
      hierarchy = getPlatformUnitHierarchy(rootPlatformUnits.data, activePlatformUnit && activePlatformUnit.id)
    }else{
      hierarchy = getPlatformUnitHierarchy(rootPlatformUnits.data, activePlatformUnitId)
      activePlatformUnit = (
        hierarchy.length ?  
          hierarchy[hierarchy.length - 1] :
          null
      )
    }

    const platformUnitOptions: MenuOption<PlatformUnit>[] = (
      isPlatformUnitContainer(activePlatformUnit) ?
        activePlatformUnit.children.map( value => {
          return {
            value,
            label: value.name,
          }
        }) :
        []
    )

    const parentPlatformUnit = hierarchy[hierarchy.length - 2]

    const siblingPlatformUnitOptions: MenuOption<PlatformUnit>[] = (
      isPlatformUnitContainer(parentPlatformUnit) ?
        parentPlatformUnit.children.map( value => {
          return {
            value,
            label: value.name,
          }
        }) :
        []
    )

    const providers = discoverProviders(activePlatformUnit)

    const doubleVerifyProviders = discoverDoubleVerifyProviders(activePlatformUnit)

    const hasPerformanceReporting = Boolean(
      activePlatformUnit
      && activePlatformUnit.performance_reporting
      && activePlatformUnit.performance_reporting.enabled
    )

    const hasStructuralReporting = !!(
      activePlatformUnit && activePlatformUnit.report_series && activePlatformUnit.report_series.length
    )

    const hasOverview = (
      hasPerformanceReporting ||
      platformUnitOptions.length > 0
    )

    const hasInsights = false // platformUnitHasInsights(activePlatformUnit)

    const hasInsightReports = platformUnitHasInsightReports(activePlatformUnit)

    const loading = !hierarchy || isLoading(rootPlatformUnits)

    return {
      ready: !!activePlatformUnit && !loading,
      loading,
      hierarchy,
      rootDashboardPlatformUnits: rootPlatformUnits.data,
      rootPlatformUnit: hierarchy[0] || null,
      defaultPlatformUnitId,
      hasInsights,
      hasInsightReports,
      hasPerformanceReporting,
      hasStructuralReporting,
      hasExtendedMediaReporting: isVodafonePlatformUnit(activePlatformUnit),
      hasOverview,
      providers,
      doubleVerifyProviders,
      activePlatformUnit,
      platformUnitOptions,
      siblingPlatformUnitOptions,
      childPlatformUnitLabel: (
        isPlatformUnitContainer(activePlatformUnit) ?
          (activePlatformUnit.unit_type && orgLabelDisplayMap[activePlatformUnit.unit_type] || 'Dashboard') :
          null
      ),
    } as ActiveOrgEnvironment
  }
)
