import { ReduxAction } from '@percept/types'
import { FiltersList, filtersReduxState } from './reducers/filtersReducer'

export const APPLY_FILTERS = 'APPLY_FILTERS'

export const applyFilters = (payload: {
  list: FiltersList
  dashboardType: keyof filtersReduxState
}): ReduxAction => ({
  type: APPLY_FILTERS,
  payload,
})