
import React, { Fragment, memo, useMemo, useRef, useEffect } from 'react'

import { Grid, Typography, TypographyProps } from '@material-ui/core'

import { makeAppStyles } from '../../themes'

import { lighten, darken } from 'polished'

import Title from './Title'

import { EntitySummary } from './EntitySummary'

import { getPath, partition, all, any } from '@percept/utils'

import { renderers, resolveAttributeMetric } from './lib'

import { isBefore, isAfter } from 'date-fns'

import { get } from 'lodash-es'

import {
  HealthType,
  Dictionary,
  DimensionType, 
  MetricMetadataType,
  LayoutNodeType,
  TipSettings,
  TipLabel,
  DateType,
  LayoutMetricType,
  LayoutOptionalMetrics,
  ReportEntity,
  ReportMetricsPayload,
} from '@percept/types'

import { ReportDashboardProps } from './typings'


const { APP, PREVIEW, PERCEPT_ENV } = process.env

const DEBUG = APP === 'admin' || PREVIEW || PERCEPT_ENV === 'beta'


const orderByHealth = (
  { data, ids, asc = false, dimension = 'count' }:
  { data: Dictionary | null, asc: boolean, ids: string[], dimension?: DimensionType }
): string[] => {
  
  const idHealthTuples: [string, HealthType][] = ids.map( id => [
    id,
    getPath(data, [id, dimension, 'health'], null)
  ])
  
  const [withHealth, withoutHealth]: [
    [string, number][], [string, null][]
  ] = partition(idHealthTuples, t => t[1] !== null)
  
  if( withHealth.length === 0 ){
    return withoutHealth.map( t => t[0] )
  }

  return withHealth.sort( (a, b) => {
    if( a[1] > b[1] ) return asc ? 1 : -1
    if( a[1] < b[1] ) return asc ? -1 : 1
    return 0
  }).map( t => t[0] ).concat(withoutHealth.map( t => t[0] ))

}


const isMetricTypeList = (
  members: LayoutNodeType['members']
): members is (LayoutMetricType | LayoutOptionalMetrics)[] => (
  any<LayoutNodeType | LayoutMetricType | LayoutOptionalMetrics>(members, m => !!m.id || m.type === 'optional' )
)

const isLayoutNodeTypeList = (
  members: LayoutNodeType['members']
): members is LayoutNodeType[] => (
  all<LayoutNodeType | LayoutMetricType | LayoutOptionalMetrics>(members, m => m.type === 'section' )
)

const isLayoutOptionalMetrics = (
  node: LayoutMetricType | LayoutOptionalMetrics
): node is LayoutOptionalMetrics => (
  node.type === 'optional'
)


const tabTitleTypographyProps: Partial<TypographyProps> = {
  variant: 'h3',
}

const sectionPrimaryTitleTypographyProps: Partial<TypographyProps> = {
  variant: 'h4',
}

const sectionSecondaryTitleTypographyProps: Partial<TypographyProps> = {
  variant: 'h5',
}


const useStyles = makeAppStyles( theme => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(1.5, 0, 3.5, 0),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '&:first-child': {
      marginTop: 0,
    },
  },
  sectionPaper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
    display: 'inline-flex',
  },
  titleContainer: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  tabDescription: {
    margin: theme.spacing(3, 0),
    maxWidth: '60rem',
    fontSize: 15,
    fontWeight: 700,
    lineHeight: '1.6rem',
    color: darken(0.065, theme.palette.text.primary),
    background: lighten(0.035, theme.palette.background.paper),
    padding: theme.spacing(2),
  },
  description: {
    margin: theme.spacing(3, 0, 2, 0),
    maxWidth: '60rem',
    fontSize: 14,
    fontWeight: 500,
    color: darken(0.065, theme.palette.text.primary),
    background: lighten(0.035, theme.palette.background.paper),
    padding: theme.spacing(2),
  },
}) )


type SectionProps = {
  type: 'section' | 'tab'
  name: string
  members: LayoutNodeType[] | LayoutMetricType[]
  path: string
  entity: ReportEntity | null
  dimensionOptions: DimensionType[]
  health?: HealthType
  metadata: MetricMetadataType
  metricOrder?: 'default' | 'health_asc' | 'health_desc'
  metrics: ReportMetricsPayload
  features?: Dictionary
  tipSettings?: TipSettings
  onToggleTip?: (label: TipLabel) => void
  tipPriority?: TipLabel[]
  reportStart: DateType | null
  reportEnd: DateType | null
  activeLayoutItemPath?: string
  isPrimarySection?: boolean
} & ReportDashboardProps

const Section = ({
  name,
  type,
  health,
  members,
  metadata,
  metricOrder = 'default',
  metrics,
  features,
  tipSettings,
  onToggleTip,
  tipPriority,
  reportStart,
  reportEnd,
  activeLayoutItemPath = '',
  path = '',
  isPrimarySection = true,
  ...props
}: SectionProps): JSX.Element | null => {

  const sectionRef = useRef<HTMLDivElement | null>(null)

  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const depthIndicator = (
    path.length &&
    (path.length - (path.length / 2) + 1)
  )

  useEffect(() => {
    if( path && path === activeLayoutItemPath && sectionRef.current ){
      timeoutRef.current = setTimeout(() => {
        if( sectionRef.current ){
          const { top } = sectionRef.current.getBoundingClientRect()
          window.scrollTo(0, top - 120)
        }
      }, 100)
      return (): void => {
        timeoutRef.current !== null && clearTimeout(timeoutRef.current)
      }
    }
  }, [activeLayoutItemPath, path])

  const renderOrder = useMemo(() => {

    let metricsToRender: LayoutNodeType[] | (LayoutMetricType | LayoutOptionalMetrics)[] = members || []

    if( isMetricTypeList(metricsToRender) ){

      const membersById = metricsToRender.reduce( (obj, m) => {
        if( isLayoutOptionalMetrics(m) ){
          const { operator } = m
          if( operator === 'any' ){
            for( const nested of m.metrics ){
              if( metrics[nested.id] ){
                obj[nested.id] = nested
                break
              }
            }
          }
        }else{
          obj[m.id] = m
        }
        return obj
      }, {} as Dictionary<LayoutMetricType>)

      if( metricOrder !== 'default' ){
        metricsToRender = (
          orderByHealth({
            data: metrics,
            ids: Object.keys(membersById),
            asc: metricOrder === 'health_asc',
          })
        ).map( id => membersById[id] )
      }

      if( !DEBUG ){
        const attributes = get(props.entity.derived, 'attributes', null)
        const performanceAttributes = get(props.entity.derived, 'performance', null)
        metricsToRender = metricsToRender.filter( m => {
          return Boolean(
            isLayoutOptionalMetrics(m)
            || metrics[(m as LayoutMetricType).id]
            || resolveAttributeMetric({
              id: (m as LayoutMetricType).id,
              attributes,
              performanceAttributes,
              performanceTail: props.activePerformanceTail,
            })
          )
        })
      }

      if( reportStart && reportEnd ){
        metricsToRender = metricsToRender.filter( m => {
          if( !isLayoutOptionalMetrics(m) ){
            if( m.start && isAfter(m.start, reportStart) ){
              return false
            }
            if( m.end && isBefore(m.end, reportEnd) ){
              return false
            }
          }
          return true
        })
      }
    }

    return metricsToRender

  }, [members, metrics, metricOrder, reportStart, reportEnd, props.activePerformanceTail, props.entity.derived])

  const classes = useStyles()

  if( renderOrder.length === 0 ){
    return null
  }

  const { description = '', show_performance_summary = false } = features || {}

  if( isLayoutNodeTypeList(renderOrder) ){
    return (
      <Fragment>

        { !!(type === 'tab' && props.entity && show_performance_summary) && (
          <EntitySummary
            dimensionOptions={props.dimensionOptions}
            {...props.entity} />
        )}

        { !!( name || description ) && (
          <div
            className={classes.titleContainer}
            ref={sectionRef}>

            { name && (
              <Title
                type={type}
                title={name}
                health={health}
                TypographyProps={
                  type === 'tab' ?
                    tabTitleTypographyProps :
                    isPrimarySection ?
                      sectionPrimaryTitleTypographyProps :
                      sectionSecondaryTitleTypographyProps
                } />
            )}

            { description && (
              <Typography
                className={
                  type === 'tab' ?
                    classes.tabDescription
                    : classes.description
                }
                variant='body1'>
                { description }
              </Typography>
            ) }
            
          </div>
        ) }

        <div
          ref={( name || description ) ? undefined : sectionRef}
          className={classes.section}>
          { renderOrder.map( (m, i, sectionMembers) => (
            <Fragment key={m.name}>
              <Section
                key={m.name || m.key || i}
                {...m}
                isPrimarySection={type === 'tab'}
                path={`${path}-${i}`}
                activeLayoutItemPath={activeLayoutItemPath}
                reportStart={reportStart}
                reportEnd={reportEnd}
                metadata={metadata}
                metricOrder={metricOrder}
                metrics={metrics}
                {...(i === 0 && {
                  tipSettings,
                  onToggleTip,
                  tipPriority,
                } || {})}
                {...props} />
              {/* { i !== (sectionMembers.length - 1) && (
                <Divider />
              ) } */}
            </Fragment>
          )) }
        </div>

      </Fragment>
    )
  }

  const title = type !== 'tab' && name

  return (
    <div
      ref={sectionRef}
      className={classes.section}>

      { !!( title || description ) && (
        <div
          className={classes.titleContainer}>
          { title && (
            <Title
              type={type}
              title={name}
              health={health}
              TypographyProps={
                type === 'tab' ?
                  tabTitleTypographyProps :
                  isPrimarySection ?
                    sectionPrimaryTitleTypographyProps :
                    sectionSecondaryTitleTypographyProps
              } />
          ) }
          { description && (
            <Typography
              className={
                type === 'tab' ?
                  classes.tabDescription
                  : classes.description
              }
              variant='h5'>
              { description }
            </Typography>
          ) }
        </div>
      ) }

      <Grid container spacing={4}>
        { renderOrder.map( (item, i) => {

          if( isLayoutOptionalMetrics(item) ){
            let valid = null
            const { operator } = item
            if( operator === 'any' ){
              for( const metric of item.metrics ){
                if( metrics[metric.id] ){
                  valid = metric
                  break
                }
              }
            }
            if( !valid ){
              return null
            }else{
              item = valid
            }
          }

          const metricType = item.type || getPath(metadata, [item.id, 'display_options', 'chart_type'])

          const Renderer = metricType ? renderers[metricType] : null

          if( !Renderer ){
            return null
          }

          if( item.id && !metadata[item.id] ){
            console.warn(`Missing metatada for metric "${item.id}"`)
            return null
          }

          return (
            <Grid
              item
              key={`${item.id || metricType}-${i}`}>
              <Renderer
                key={`${item.id || metricType}-${i}`}
                metrics={metrics}
                metadata={metadata}
                {...(metadata[item.id] || {})}
                {...item}
                {...props}
                {...(i === 0 && {
                  tipSettings,
                  onToggleTip,
                  tipPriority,
                } || {})} />
            </Grid>
          )
        }) }
      </Grid>
      
    </div>
  )
}

export default memo(Section)
