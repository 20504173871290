import React, { Fragment } from 'react'

import { useAppTheme } from '../../themes'

import { Axes, SimpleAxes } from '../Axes'

import { Grids } from '../Grids'

import { Text } from '@visx/text'

import { numberFormatter } from '../formatters'

import { makeGetXTickLabelProps, makeGetYTickLabelProps } from '../styles'

import { get, sortBy } from 'lodash-es'

import { TickLabelProps } from '@visx/axis'

import {
  CombinedChartProps,
  CustomAxisChartTypeProps,
  ScaleRequirements,
  SizeRequirements,
  SVGDatumType,
  TickConfiguration,
} from '../typings'

import { AxisScale, AxisScaleOutput } from '@visx/axis'


export type GenericAxisChartProps<T extends SVGDatumType> = React.PropsWithChildren<
  Pick<
    CombinedChartProps<T>,
    'animate' | 'animateInitial' | 'data' | 'datasets'
  > &
  SizeRequirements &
  TickConfiguration & 
  CustomAxisChartTypeProps & {
    columnOffset?: number
    xAxisOffset?: number
    xScale: AxisScale<AxisScaleOutput>
    yScale: AxisScale<AxisScaleOutput>
    arbitraryTimeline?: boolean
  }
>


export function GenericAxisChart<T extends SVGDatumType>({
  xScale,
  yScale,
  width,
  height,
  animate = true,
  verticalMargin = 0,
  xOffset,
  columnOffset,
  grid = false,
  axisLine = true,
  axisText = false,
  numXTicks,
  numYTicks,
  rotateXTicks,
  hideXTickLines,
  hideYTickLines,
  xTickFormatter,
  yTickFormatter = numberFormatter,
  xAxisLabel,
  yAxisLabel,
  axisLabelStroke = 'currentColor',
  arbitraryTimeline = false,
  data,
  datasets,
  children,
}: GenericAxisChartProps<T>): JSX.Element {

  const canonicalXOffset = (
    xOffset !== undefined ?
      xOffset :
      (axisLine && axisText) ?
        36 :
        0
  )

  const xMax = width - canonicalXOffset
  const yMax = height - verticalMargin

  const appTheme = useAppTheme()

  const defaultGetXTickLabelProps = makeGetXTickLabelProps(
    appTheme, data && rotateXTicks ? {
      angle: -45,
      dx: 0,
      dy: -4,
      textAnchor: 'end',
      width: Math.floor( (width - canonicalXOffset) / (data.length + 2)),
    } : {}
  )

  let getXTickLabelProps: TickLabelProps<string | number> = defaultGetXTickLabelProps

  let xTickValues: number[] | undefined

  if( arbitraryTimeline ){

    const xDomain = xScale.domain() as [number, number]

    xTickValues = xDomain
    
    const referenceData = data || datasets && (
      get(sortBy(datasets, ds => ds.data.length ).reverse(), [0, 'data'])
    )

    if( referenceData ){

      const first = referenceData[0]
      const last = referenceData[referenceData.length - 1]

      if( first && last ){
        xTickValues = [
          (first as unknown as { start: number }).start,
          (last as unknown as { end: number }).end,
        ]
      }
      const typedValues = xTickValues as [number, number]
      getXTickLabelProps = (value, i, values) => {
        return {
          ...defaultGetXTickLabelProps(value, i, values),
          textAnchor: i === 0 ? 'start' : 'end',
          dx: i === 0 ? (
            Number(xScale(xDomain[0])) - Number(xScale(typedValues[0]))
          ) : (
            Number(xScale(xDomain[1])) - Number(xScale(typedValues[1]))
          ),
        }
      }
    }
  }

  const getYTickLabelProps = makeGetYTickLabelProps(appTheme)

  const axisStroke = appTheme.palette.action.disabled

  const axisTickStroke = appTheme.palette.primary.dark

  const gridStroke = appTheme.chart.grid.stroke

  const gridItemsProps: ScaleRequirements & TickConfiguration & {
    animate: boolean
  } = {
    animate,
    xScale,
    yScale,
    numXTicks,
    numYTicks,
    hideYTickLines,
    hideXTickLines,
    xTickFormatter,
    yTickFormatter,
  }

  return (
    <Fragment>

      { grid && (
        <Grids
          {...gridItemsProps}
          grids={grid}
          width={xMax}
          height={yMax}
          columnOffset={columnOffset}
          rowOffset={xOffset}
          stroke={gridStroke} />
      )}

      { children }

      { axisLine && (
        axisText ? (
          <Axes
            {...gridItemsProps}
            animate={gridItemsProps.animate && !arbitraryTimeline}
            arbitraryTimeline={arbitraryTimeline}
            left={xOffset}
            getXTickLabelProps={getXTickLabelProps}
            getYTickLabelProps={getYTickLabelProps}
            xTickValues={xTickValues}
            tickStroke={axisTickStroke}
            stroke={axisStroke} />
        ) : (
          <SimpleAxes
            left={xOffset}
            width={xMax}
            height={yMax}
            top={verticalMargin}
            stroke={axisStroke} />
        )
      )}

      { xAxisLabel && (
        <Text
          x={width / 2}
          y={height}
          textAnchor='middle'
          fill={axisLabelStroke}
          fontSize={10}
          dy={30}>
          { xAxisLabel }
        </Text>
      )}

      { yAxisLabel && (
        <Text
          x={0}
          y={height / 2}
          textAnchor='middle'
          fill={axisLabelStroke}
          fontSize={10}
          angle={-90}>
          { yAxisLabel }
        </Text>
      )}

    </Fragment>
  )
}

GenericAxisChart.displayName = 'GenericAxisChart'
