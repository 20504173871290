import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'

import { REPORT_ERROR } from 'redux/actions'

import { USER_LOGIN } from '@percept/redux/bundles/user/actions'

import { getUserAttributes, getUserLoggedIn } from '@percept/redux/bundles/user/selectors'

import { capitalize, get, mapValues, pick } from 'lodash-es'

import { Middleware } from 'redux'

import { StoreState } from 'types'

import { ApiResponse, Dictionary } from '@percept/types'


const entitySummary = (
  entities: Record<string, ApiResponse>
): Record<string, Pick<ApiResponse, 'loading' | 'lastFetched'> & { error: string | null }> => {
  return mapValues(entities, (v) => ({
    loading: v.loading,
    lastFetched: v.lastFetched,
    error: v.error && v.error.message
  }))
}


const serializeState = (state: StoreState): Dictionary => {
  return {
    location: pick(state.router.location, ['pathname', 'search']),
    orgUnits: (
      (
        state.user
        && state.user.detail
        && state.user.detail.data
         && state.user.detail.data.org_units
      ) || []
    ).map( u => pick(u, ['id', 'name'])),
    settings: state.settings,
    isUserAuthenticated: state.user.loggedIn,
  }
}


export type SentryMiddlewareConfig = {
  dsn: string
  env?: string
}

export const sentryMiddlewareCreator = ({
  dsn,
  env = '<Not Provided>'
}: SentryMiddlewareConfig): Middleware<{}, StoreState> => {

  Sentry.init({
    dsn,
    environment: capitalize(env),
    integrations: [new BrowserTracing()],
    ignoreErrors: [
      // Ignore harmless ResizeObserver error - see https://stackoverflow.com/a/50387233
      'ResizeObserver loop',
      // Ignore transient ServiceWorker update failures. We expect these to appear every so often,
      // as the application eagerly attempts to check for updates on a timer - this can easily happen
      // while the user's network connection is interrupted etc.
      'Failed to update a ServiceWorker',
    ],
  })

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return store => next => action => {

    if( action.type === USER_LOGIN ){

      const result = next(action)

      const state = store.getState()

      const loggedIn = getUserLoggedIn(state)
      const attributes = getUserAttributes(state)

      if( loggedIn && attributes ){
        Sentry.setUser({
          id: attributes.user_id,
          email: attributes.email,
        })
      }

      return result

    }else if( action.type === REPORT_ERROR ){
     
      const { error, errorInfo } = action.payload
      
      try{
        Sentry.withScope(scope => {
          Object.keys(errorInfo).forEach(key => {
            scope.setExtra(key, errorInfo[key])
          })
          if( !errorInfo.reduxState ){
            scope.setExtra('reduxState', serializeState(store.getState()))
          }
          Sentry.captureException(error)
        })
      }catch(e){
        console.log('Caught exception', e)
      }
    
    }else{

      try{
      
        return next(action)
      
      }catch(error){
        
        try{
          store.dispatch({
            type: REPORT_ERROR,
            payload: {
              error,
              errorInfo: {
                action,
                reduxState: serializeState(store.getState())
              }
            }
          })
        }catch(e){
          console.log('Caught exception', e)
        }
      
      }

    }

  }

}
