
import { getFetchTypes } from '@percept/redux/utils'

import { LOCATION_CHANGE } from 'connected-react-router'

import {
  TRACK,
} from 'redux/actions'

import {
  USER_LOGOUT,
} from '@percept/redux/bundles/user/actions'

import {
  SET_ANNOTATION_CONTEXT,
  CLEAR_ANNOTATION_CONTEXT,
  ADD_ANNOTATION,
  EDIT_ANNOTATION,
  DELETE_ANNOTATION,
} from '@percept/redux/bundles/annotations/actions'

import { getPath, capitalize, findNext, getAnnotationPath } from '@percept/utils'

import { StoreState } from 'types'


const getCanonicalUrl = (state: StoreState): string | null => {
  const location = getPath(state, ['router', 'location'])
  if( !location ){
    return null
  }
  return `${location.pathname}${location.search}`
}


type ViewPredicate = (path: string) => boolean

const isHomeView: ViewPredicate = path => path === '/' || path === ''

const isSignUpView: ViewPredicate = path => /signup\/?$/.test(path)

const isSeriesGroupView: ViewPredicate = path => /\/series-groups\/[0-9\-A-z]+\/?$/.test(path)

const isSeriesView: ViewPredicate = path => /\/series\/[0-9\-A-z]+\/?$/.test(path)

const isCreateSeriesView: ViewPredicate = path => /\/series-groups\/[0-9\-A-z]+\/create-series\/?/.test(path)

const isMetricDetailView: ViewPredicate = path => /\/reports\/[0-9\-A-z]+\/?\?.+metric_id=.+/.test(path)

const isReportView: ViewPredicate = path => /\/reports\/[0-9\-A-z]+\/?/.test(path)

const isPerformanceView: ViewPredicate = path => path.indexOf('performance-report') > -1


const pageTitleValidators: [string, ViewPredicate][] = [
  ['Home',
    isHomeView],

  ['Sign Up',
    isSignUpView],

  ['Metric Detail',
    isMetricDetailView],

  ['Report',
    isReportView],

  ['Series',
    isSeriesView],

  ['Series Group',
    isSeriesGroupView],

  ['Create Series',
    isCreateSeriesView],

  ['Performance',
    isPerformanceView],
]


const getPageTitle = (path?: string | null): string => {
  if( !path ){
    return ''
  }

  const [title] = findNext(
    pageTitleValidators,
    ([ , validator ]) => validator(path)
  ) || [path, null]

  return title
}


type GAEvent = {
  hitType: 'pageview'
  location: string
  page: string
  title: string
} | {
  hitType: 'event'
  eventCategory: string
  eventAction: string
  eventLabel?: string
}


type GAEventParams = (
  {
    type: 'pageview'
    page: string
    location?: string
    category?: never
    action?: never
    label?: never
  } | {
    type: 'event'
    location?: never
    page?: never
    category: string
    action: string
    label?: string
  }
)


const gaEvent = ({ type, location, page, category, action, label }: GAEventParams): GAEvent => {
  if( type === 'pageview' ){
    return {
      hitType: 'pageview',
      location,
      page,
      title: getPageTitle(page as string)
    } as GAEvent
  }

  return {
    eventCategory: category,
    eventAction: action,
    eventLabel: label
  } as GAEvent
}


const getNavigationEvent = (previousPage?: string | null, nextPage?: string | null): GAEventParams | null => {

  const label = getPageTitle(nextPage || ''),
        previousLabel = getPageTitle(previousPage || '')

  const action = (
    previousLabel ?
      `Leaving ${previousLabel} View` :
      label ?
        `Entering ${label} View` :
        ''
  )

  return (
    label !== previousLabel && (label && action) ?
      { type: 'event', category: 'Navigation', label, action } :
      null
  )
}



const addAnnotationFetchTypes = getFetchTypes(ADD_ANNOTATION)

const editAnnotationFetchTypes = getFetchTypes(EDIT_ANNOTATION)

const deleteAnnotationFetchTypes = getFetchTypes(DELETE_ANNOTATION)


// Store current page
let currentPage: string | null = null

/*
 * Action handlers are called with one object as argument:
 *   { action: <reduxAction>, previous: <previousState>, next: <nextState> }
 */

const actionHandlers: Record<string, (...args: any[]) => GAEvent | GAEvent[] | null> = {

  [TRACK]: ({ action: { payload } }) => (
    gaEvent({ ...payload })
  ),

  [USER_LOGOUT]: () => (
    gaEvent({
      type: 'event',
      category: 'Navigation',
      action: 'Sign Out',
    })
  ),

  // Handle all pageviews here
  [LOCATION_CHANGE]: ({ previous, next }) => {
    
    const events = []
    
    const previousPage = getCanonicalUrl(previous),
          nextPage = getCanonicalUrl(next)

    if( previousPage !== nextPage || !currentPage ){
      
      if( nextPage ){
        events.push(
          gaEvent({ type: 'pageview', page: nextPage })
        )
      }

      if( !currentPage ){
        events.push(
          gaEvent({ type: 'event', category: 'Navigation', action: 'Landing', label: getPageTitle(nextPage) })
        )
      }else{
        const navigationEvent = getNavigationEvent(previousPage, nextPage)

        if( navigationEvent ){
          events.push(
            gaEvent(navigationEvent)
          )
        }
      }

      currentPage = nextPage

    }

    return events
  },

  // [FILTER_AUDIT_ENTITIES]: () => (
  //   gaEvent({
  //     type: 'event',
  //     category: 'Refining',
  //     action: 'Filter By Account'
  //   })
  // ),

  // [SORT_AUDIT_ENTITIES]: ({ action: { payload } }) => {
  //   if( !payload.key ){
  //     return null
  //   }
  //   return gaEvent({
  //     type: 'event',
  //     category: 'Refining',
  //     action: 'Change Audit Entity Filter Order',
  //     label: capitalize(payload.key)
  //   })
  // },

  // [SET_ACTIVE_SEGMENT]: ({ next }) => {
  //   const params = getPath(next, ['audits', 'activeSegment'], {})
  //   return gaEvent({
  //     type: 'event',
  //     category: 'Details',
  //     action: 'View Examples',
  //     label: (
  //       `${params.title || ''} > ${getPath(params, ['segment', 'label'], '')}`
  //     )
  //   })
  // },

  // [SET_EXPORT_METRIC]: () => {
  //   return gaEvent({
  //     type: 'event',
  //     category: 'Export',
  //     action: 'View Metric Export Options'
  //   })
  // },

  [SET_ANNOTATION_CONTEXT]: ({ action: { payload } }) => {
    const annotationPath = getAnnotationPath(payload)
    return gaEvent({
      type: 'event',
      category: 'Editing',
      action: 'View Annotations',
      label: annotationPath
    })
  },

  [CLEAR_ANNOTATION_CONTEXT]: () => {
    return gaEvent({
      type: 'event',
      category: 'Editing',
      action: 'Close Annotations'
    })
  },

  [addAnnotationFetchTypes.success]: ({ action: { payload } }) => {
    const annotationPath = getAnnotationPath(payload)
    return gaEvent({
      type: 'event',
      category: 'Editing',
      action: 'Add Annotation',
      label: annotationPath
    })
  },

  [editAnnotationFetchTypes.success]: ({ action: { payload } }) => {
    const annotationPath = getAnnotationPath(payload)
    return gaEvent({
      type: 'event',
      category: 'Editing',
      action: 'Edit Annotation',
      label: annotationPath
    })
  },

  [deleteAnnotationFetchTypes.success]: ({ action: { payload } }) => {
    const annotationPath = getAnnotationPath(payload)
    return gaEvent({
      type: 'event',
      category: 'Editing',
      action: 'Delete Annotation',
      label: annotationPath
    })
  },

}


export default actionHandlers
