import React, { Fragment } from 'react'

import { Link } from 'react-router-dom'

import { AppBarProps, Box, PerceptAppBar, RoundedPlainTextButton } from '@percept/mui'

import { LockOpen } from '@percept/mui/icons'

import { HomeButton } from 'components/HomeButton'


export type PublicLayoutProps = React.PropsWithChildren<{
  AppBarProps?: Partial<AppBarProps>
}>

export const PublicLayout = ({ children, AppBarProps = {} }: PublicLayoutProps): JSX.Element => {

  return (
    <Fragment>

      <PerceptAppBar
        position='fixed'
        startIcon={
          <Box display='flex' alignItems='center'>
            <HomeButton />
          </Box>
        }
        {...AppBarProps}>

        { AppBarProps.children }

        <Box
          display='flex'
          alignItems='center'
          marginLeft='auto'>
          <Link to='/sign-in'>
            <RoundedPlainTextButton
              startIcon={
                <LockOpen />
              }
              size='small'
              variant='contained'
              color='secondary'>
              Sign In
            </RoundedPlainTextButton>
          </Link>
        </Box>

      </PerceptAppBar>

      <Box
        pt={8}>
        { children }
      </Box>

    </Fragment>
  )
}
