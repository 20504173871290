import React from 'react'

import { BoxProps } from '@percept/mui'

import { AppBranding } from '@percept/types'


export const mainBodyTextBoxProps: BoxProps = {
  fontSize: 18,
  fontWeight: 500,
  lineHeight: 1.5,
}

export const secondaryBodyTextBoxProps: BoxProps = {
  fontSize: 16,
  fontWeight: 500,
  lineHeight: 1.65,
}

export const underlineStyles: React.CSSProperties = {
  textDecoration: 'underline',
  textUnderlineOffset: 5,
}

export const linkStyles: React.CSSProperties = {
  fontWeight: 700,
  ...underlineStyles,
}


export const documentTitleProps: Record<AppBranding, { site: string, paths: string[] }> = {
  PERCEPT: {
    site: 'Percept',
    paths: ['Welcome to Percept'],
  },
  TPA: {
    site: 'TPA Audit',
    paths: ['Welcome'],
  },
  BRANDTECH: {
    site: 'Brandtech Media Group',
    paths: ['Welcome'],
  },
}
