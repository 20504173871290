
import React, { Fragment } from 'react'

import {
  Alert,
  AlertProps,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  TypographyProps,
} from '@percept/mui'

import { CheckCircle, Cancel } from '@percept/mui/icons'

import { hasNoTrailingWhitespace, hasMixedCase, hasNumbers, hasSymbols } from './utils'


const { PASSWORD_REQUIREMENTS } = process.env


export const MessageContent = (): JSX.Element => (
  <Fragment>
    <Typography>Your password must:</Typography>
    <ul>
      <li>Contain at least { PASSWORD_REQUIREMENTS.LENGTH } characters</li>
      <li>Have no whitespace characters at the beginning or end</li>
      { PASSWORD_REQUIREMENTS.MIXED_CASE && <li>Contain a mix of uppercase and lowercase characters</li> }
      { PASSWORD_REQUIREMENTS.SYMBOLS && <li>Contain at least one special character</li> }
      { PASSWORD_REQUIREMENTS.NUMBERS && <li>Contain at least one number</li> }
    </ul>
  </Fragment>
)


const listItemTypographyProps: TypographyProps = {
  variant: 'subtitle1'
}


const LivePasswordRequirements = ({ 
  password,
}: { password: string }): JSX.Element => {

  const passIcon = <CheckCircle color='inherit' />
  const failIcon = <Cancel color='disabled' />

  return (
    <Fragment>
      <Typography>Your password must:</Typography>
      <List dense>
        {/* Password length */}
        <ListItem>
          <ListItemIcon>
            { password.length >= PASSWORD_REQUIREMENTS.LENGTH ? passIcon : failIcon }
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={listItemTypographyProps}
            primary={`Contain at least ${PASSWORD_REQUIREMENTS.LENGTH} characters`} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            { hasNoTrailingWhitespace(password) ? passIcon : failIcon }
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={listItemTypographyProps}
            primary='Have no whitespace characters at the beginning or end' />
        </ListItem>
        {/* Mixed case */}
        { PASSWORD_REQUIREMENTS.MIXED_CASE && (
          <ListItem>
            <ListItemIcon>
              { hasMixedCase(password) ? passIcon : failIcon }
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={listItemTypographyProps}
              primary='Contain a mix of uppercase and lowercase characters' />
          </ListItem>
        )}
        { PASSWORD_REQUIREMENTS.SYMBOLS && (
          <ListItem>
            <ListItemIcon>
              { hasSymbols(password) ? passIcon : failIcon }
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={listItemTypographyProps}
              primary='Contain at least one special character' />
          </ListItem>
        )}
        { PASSWORD_REQUIREMENTS.NUMBERS && (
          <ListItem>
            <ListItemIcon>
              { hasNumbers(password) ? passIcon : failIcon }
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={listItemTypographyProps}
              primary='Contain at least one number' />
          </ListItem>
        )}
      </List>
    </Fragment>
  )
}


export const PasswordRequirements = ({
  password,
  ...props
}: { password: string } & Partial<AlertProps>): JSX.Element => (
  <Alert
    variant='info'
    header='Password Requirements'
    message={
      <Box mt={2} px={1}>
        <LivePasswordRequirements password={password} />
      </Box>
    }
    {...props} />
)