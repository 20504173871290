import { format as formatDate } from 'date-fns'

import { displayNumber } from '@percept/utils'

import { formatMoney } from '../components'

import { round } from 'lodash-es'

import { DatumFormatters, TickConfiguration } from './typings'


export const numberFormatter = (n: number | null | string): string => (
  displayNumber(Number(n))
)


export const percentageFormatter = (n: number | null | string): string => (
  `${round(Number(n), 2)}%`
)

export const spacedPercentageFormatter = (n: number | null | string): string => (
  `${round(Number(n), 2)} %`
)

export const detailedPercentageFormatter = (n: number | null | string): string => (
  `${round(Number(n), 4)}%`
)

export const automaticPrecisionPercentageFormatter = (n: number | null | string): string => {
  if( !n || n >= 0.01 ){
    return percentageFormatter(n)
  }
  return detailedPercentageFormatter(n)
}

export const percentageDeltaFormatter = (n: number | null | string): string => {
  n = round(Number(n), 2)
  const nWithSign = n >= 0 ? `+${n}` : n
  return `${nWithSign}%`
}

export const roundedPercentageDeltaFormatter = (n: number | null | string): string => (
  percentageDeltaFormatter(Math.round(Number(n)))
)

export const rateFormatter = (n: number | null | string): string => (
  automaticPrecisionPercentageFormatter(Number(n) * 100)
)


/**
 * NOTE
 *
 * `date-fns` uses Unicode tokens as of v2 for string format definitions.
 *
 * See https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
 *
 */
export const getDateFormatter = (format: string): ((d: Date | number | string) => string) => (
  (date): string => (
    formatDate(new Date(date), format)
  )
)

export const dayFormatter = getDateFormatter('dd/MM')  // 01/04, 02/04, 03/04, ...

export const dmyFormatter = getDateFormatter('dd/MM/yy')  // 01/04/20, 02/04/20, 03/04/20, ...

export const longDayMonthYearFormatter = getDateFormatter('do MMMM yyyy')

export const longMonthFormatter = getDateFormatter('MMMM')  // 'January', 'February', 'March', ...

export const longMonthYearFormatter = getDateFormatter('MMMM yyyy')  // 'January 2020', 'February 2020', 'March 2020', ...

export const shortMonthFormatter = getDateFormatter('MMMMM')  // 'J', 'F', 'M', ...



export const getMoneyFormatter = (currency: string | null | undefined) => (
  // eslint-disable-next-line react/display-name
  (amount: number | string | null): string => {
    const numberValue = Number(amount)
    return formatMoney({
      amount: numberValue,
      currency,
      precision: numberValue ? 2 : 0
    })
  }
)



type FormattingProps = (
  Pick<
    TickConfiguration, 'xTickFormatter' | 'yTickFormatter'
  > & Partial<{
    dimension: string
    currency: string | null
    tooltipLabelFormatter: TickConfiguration['xTickFormatter']
  }>
)


export const getValueFormatter = (props: FormattingProps): DatumFormatters['valueFormatter'] => (
  props.yTickFormatter as DatumFormatters['valueFormatter'] || (
    props.dimension === 'cost' ?
      getMoneyFormatter(props.currency) :
      numberFormatter
  )
)


export const getLabelFormatter = (props: FormattingProps): DatumFormatters['labelFormatter'] => (
  props.tooltipLabelFormatter as DatumFormatters['labelFormatter'] ||
    props.xTickFormatter ||
    String
)
