import React, { Component, Fragment } from 'react'

import { ConnectedRouter } from 'connected-react-router'

import {
  AppThemeProvider,
  CssBaseline,
  ScrollToTop,
  GlobalCss,
  AppTheme,
  BackdropLoader,
  MuiPickersUtilsProvider,
} from '@percept/mui'

import { Authenticator, NotificationHub, ToastHub, UserWelcomeToast } from '@percept/app-components'

import DateFnsUtils from '@date-io/date-fns'

import qs from 'query-string'

import { Sync } from '@percept/mui/icons'

import Routes from '../../routes'

import ErrorScreen from 'screens/ErrorScreen'
import { DefaultLayout } from 'screens/DefaultLayout'
import { PlainLayout } from 'screens/PlainLayout'
import { Welcome } from 'screens/Welcome'

import { VodafoneRedirect } from './VodafoneRedirect'
import { vodafoneRedirectChecker } from './vodafoneRedirectChecker'

import { UserEnvironmentLoader } from './UserEnvironmentLoader'

import { ThemeSwitcher } from './ThemeSwitcher'

import { UserPrivilegeContext } from 'contexts'

// import TipToast from './TipToast'

// import ErrorPage from '../../screens/ErrorPage'

import { omit } from 'lodash-es'

import { registerServiceWorker } from '@percept/app-utils'

import {
  User as UserType,
  Notification,
  ApiResponse,
  RefreshResponse,
  UserPrivileges,
} from '@percept/types'

import {
  Location,
  History,
} from 'history'


const reportPathnameRegex = /^\/reports\/.*/

const detailViewRegex = /^\/reports\/.*(metric_id=)/

const shouldScroll = (location: Location, previousLocation: Location): boolean => {
  const curr = location.pathname
  const prev = previousLocation.pathname

  return (
    !(detailViewRegex.test(curr) && detailViewRegex.test(prev))
    && !(reportPathnameRegex.test(curr) && reportPathnameRegex.test(prev))
  )
}


const isLoginLocation = ({ pathname }: Location): boolean => (
  pathname.includes('sign-in')
)

const isAccountCreationLocation = ({ search }: Location): boolean => (
  search.includes('token=') && search.includes('username=')
)

const isAdwordsOAuthLocation = ({ search }: Location): boolean => (
  location.pathname === '/adwords-oauth-redirect' &&
  search.includes('state=') && search.includes('code=')
)


export type NavigationOptions = {
  replace?: boolean
  search?: Record<string, any>
}


type ReportErrorProps = {
  error: Error
  errorInfo: React.ErrorInfo
}

export type AppProps = {
  location: Location
  initialLocation: Location | null
  loggedIn: boolean
  isSigningUp: boolean
  appTheme: AppTheme
  history: History
  checkForUpdates: boolean
  updateInterval: number | null
  debug: boolean
  allTipsEnabled: boolean
  allTipsDisabled: boolean
  requiresTipToast: boolean
  addNotification: (notification: Notification) => void
  reportError: (reportErrorProps: ReportErrorProps) => void
  navigate: (route: string, options?: NavigationOptions) => void
  enableAllTips: () => void
  disableAllTips: () => void
  dismissTipToast: () => void
  refreshResponse: ApiResponse<RefreshResponse>
  userPrivileges: ApiResponse<UserPrivileges>
  checkRefreshToken: () => void
}

export type AppState = {
  loading: boolean
  user: UserType | null
  error: Error | null
  notification: Notification | null
}


class App extends Component<AppProps, AppState> {

  private hasInitializedServiceWorker: boolean

  private hasRedirected: boolean

  constructor(props: AppProps){
    super(props)
    this.state = {
      loading: true,
      user: null,
      error: null,
      notification: null,
    }
    this.hasInitializedServiceWorker = false
    this.hasRedirected = false
  }

  componentDidMount(): void {
    this.initializeServiceWorker()
    this.props.checkRefreshToken()
    setTimeout(() => {
      this.setState( prev => ({ ...prev, loading: false }))
    }, 50)
  }

  initializeServiceWorker(): void {

    if( this.hasInitializedServiceWorker ){
      return
    }

    const { checkForUpdates, updateInterval, debug } = this.props

    registerServiceWorker({
      updateInterval,
      debug,
      onUpdateError: null,
      onUpdateFound: !checkForUpdates ? null : (
        (onRefresh: () => void): void => {
          this.setState({
            notification: {
              id: 'refresh',
              type: 'info',
              alertIcon: <Sync />,
              name: 'Update Required',
              message: 'The application has been updated. Please click Update to install the latest version',
              buttonActions: [
                {
                  startIcon: <Sync />,
                  content: 'Update',
                  onClick: (): void => {
                    // Remove notification in case update is not found
                    this.setState({ notification: null })
                    // Call the provided refresher function
                    typeof onRefresh === 'function' && onRefresh()
                  },
                },
              ],
              onClose: (): void => this.setState({ notification: null })
            },
          })
        }
      ),
      onUpdateTrigger: !checkForUpdates ? null : (
        (): void => {
          this.setState({
            notification: {
              id: 'refresh',
              type: 'info',
              loading: true,
              name: 'Update In Progress',
              message: 'Application updating...',
              onClose: (): void => this.setState({ notification: null })
            }
          })
        }
      ),
      onUpdateSuccess: !checkForUpdates ? null : (
        (): void => {
          this.setState({
            notification: {
              id: 'refresh',
              type: 'success',
              name: 'Update Successful',
              message: 'Application updated.',
              ttl: 1500,
              onClose: (): void => this.setState({ notification: null })
            }
          })
        }
      )
    })

    this.hasInitializedServiceWorker = true

  }

  componentDidUpdate(): void {
    const { initialLocation, loggedIn } = this.props
    
    if( loggedIn && initialLocation && !this.hasRedirected ){
      if( initialLocation.pathname === '/sign-in' ){
        this.props.navigate('/')
        this.hasRedirected = true
        return
      }
      if( initialLocation.pathname !== '/' ){
        this.props.navigate(initialLocation.pathname, {
          search: omit(
            qs.parse(initialLocation.search),
            ['username', 'token']
          ),
        })
        this.hasRedirected = true
        return
      }
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void{
    this.setState({ error })
    this.props.reportError({ error, errorInfo })
  }


  render(): JSX.Element {
    const { error, notification } = this.state,
          { location, loggedIn, appTheme, refreshResponse, userPrivileges, history } = this.props

    const isLoginRoute = (
      !!(isLoginLocation(location) || isAccountCreationLocation(location))
       && !loggedIn
    )

    const loading = this.state.loading || (refreshResponse.loading && !loggedIn)

    return (
      <AppThemeProvider
        theme={appTheme}>

        <Fragment>

          <GlobalCss />

          <CssBaseline />

          <ConnectedRouter
            history={history}>

            <ScrollToTop
              location={location}
              shouldScroll={shouldScroll}>

              { error ? (
                <DefaultLayout>
                  <ErrorScreen />
                </DefaultLayout>
              ) : isLoginRoute ? (
                <Authenticator />
              ) : (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>

                  { loggedIn && (
                    <Fragment>
                      <UserEnvironmentLoader />
                      <ThemeSwitcher />
                    </Fragment>
                  )}

                  { !loading && !loggedIn && (
                    <Welcome />
                  )}

                  { !loading && !isLoginRoute && loggedIn && (
                    !userPrivileges.data ? (
                      <PlainLayout>
                        <BackdropLoader />
                      </PlainLayout>
                    ) : (
                      <UserPrivilegeContext.Provider value={userPrivileges.data}>
                        { vodafoneRedirectChecker(userPrivileges.data) ? (
                          <PlainLayout>
                            <VodafoneRedirect />
                          </PlainLayout>
                        ) : (
                          <Fragment>
                            <UserWelcomeToast />
                            <Routes />
                          </Fragment>
                        )}
                      </UserPrivilegeContext.Provider>
                    )
                  )}
                  
                  {/* <Sidebar /> */}

                  {/* { !!(this.props.requiresTipToast && !loading && loggedIn && user && !isSigningUp) && (
                    <TipToast
                      onDismiss={this.props.dismissTipToast} />
                  )} */}

                  { loading && (
                    <BackdropLoader
                      BackdropProps={{open: true}} />
                  )}

                </MuiPickersUtilsProvider>

              ) }
              
            </ScrollToTop>

            <NotificationHub
              prepend={notification} />

            <ToastHub />

          </ConnectedRouter>

        </Fragment>

      </AppThemeProvider>
    )
  }

}


export default App
