import { ListItem } from '../types'
import MediaInvestmentApi, {
  DataByPeriodProps,
} from 'api/services/MediaInvestment'
import { Filters } from 'enums/Filters'
import { PossibleFiltersOptions } from './InvestmentReport'
import { find, noop } from 'lodash-es'
import { UseQueryResult } from 'react-query'

import {
  CampaignPillarSectionTitle,
  MediaInvestmentPath,
  MediaMixSectionTitle,
  MediaSpendExportRequestParams,
  ScopeOfDataType,
} from './typings'


type DataHookExportParams = Pick<MediaSpendExportRequestParams, 'export_type' | 'export_sub_type'>

type DataHookConfig = {
  hook: (props: DataByPeriodProps) => UseQueryResult<ListItem[], Error>
  path: MediaInvestmentPath
  scope: ScopeOfDataType
  sectionTitle?: CampaignPillarSectionTitle | MediaMixSectionTitle
  exportParams: DataHookExportParams
}


const dataHookConfigs: DataHookConfig[] = [
  // Campaign Pillar By Period
  {
    path: MediaInvestmentPath.CampaignPillar,
    scope: ScopeOfDataType.Period,
    sectionTitle: CampaignPillarSectionTitle.CampaignPillar,
    hook: MediaInvestmentApi.useCampaignPillar,
    exportParams: {
      export_type: 'campaign_pillar',
      export_sub_type: 'period',
    },
  },
  // Campaign Pillar By Market
  {
    path: MediaInvestmentPath.CampaignPillar,
    scope: ScopeOfDataType.Market,
    sectionTitle: CampaignPillarSectionTitle.CampaignPillar,
    hook: MediaInvestmentApi.useCampaignPillarByMarket,
    exportParams: {
      export_type: 'campaign_pillar',
      export_sub_type: 'market',
    },
  },
  // Brand vs Performance By Period
  {
    path: MediaInvestmentPath.CampaignPillar,
    scope: ScopeOfDataType.Period,
    sectionTitle: CampaignPillarSectionTitle.BrandPerformance,
    hook: MediaInvestmentApi.useBrandPerformance,
    exportParams: {
      export_type: 'brand_vs_performance',
      export_sub_type: 'period',
    },
  },
  // Brand vs Performance By Market
  {
    path: MediaInvestmentPath.CampaignPillar,
    scope: ScopeOfDataType.Market,
    sectionTitle: CampaignPillarSectionTitle.BrandPerformance,
    hook: MediaInvestmentApi.useBrandPerformanceByMarket,
    exportParams: {
      export_type: 'brand_vs_performance',
      export_sub_type: 'market',
    },
  },
  // Campaign Sub Pillar
  {
    path: MediaInvestmentPath.CampaignPillar,
    scope: ScopeOfDataType.Period,
    sectionTitle: CampaignPillarSectionTitle.CampaignSubPillar,
    hook: MediaInvestmentApi.useCampaignSubPillar,
    exportParams: {
      export_type: 'campaign_sub_pillar',
      export_sub_type: 'period',
    },
  },
  // Consumer vs Business
  {
    path: MediaInvestmentPath.CampaignPillar,
    scope: ScopeOfDataType.Period,
    sectionTitle: CampaignPillarSectionTitle.ConsumerBusiness,
    hook: MediaInvestmentApi.useConsumerVsBusiness,
    exportParams: {
      export_type: 'consumer_vs_business',
      export_sub_type: 'period',
    },
  },
  // Media Mix By Period
  {
    path: MediaInvestmentPath.MediaMix,
    scope: ScopeOfDataType.Period,
    sectionTitle: MediaMixSectionTitle.MediaInvest,
    hook: MediaInvestmentApi.useMediaMix,
    exportParams: {
      export_type: 'media_mix',
      export_sub_type: 'period',
    }
  },
  // Media Mix By Market
  {
    path: MediaInvestmentPath.MediaMix,
    scope: ScopeOfDataType.Market,
    sectionTitle: MediaMixSectionTitle.MediaInvest,
    hook: MediaInvestmentApi.useMediaMixByMarket,
    exportParams: {
      export_type: 'media_mix',
      export_sub_type: 'market'
    },
  },
  // Media Mix By Campaign
  {
    path: MediaInvestmentPath.MediaMix,
    scope: ScopeOfDataType.CampaignPillar,
    sectionTitle: MediaMixSectionTitle.MediaInvest,
    hook: MediaInvestmentApi.useMediaMixByCampaign,
    exportParams: {
      export_type: 'media_mix',
      export_sub_type: 'campaign_pillar',
    },
  },
  // Media Mix By Sub Campaign
  {
    path: MediaInvestmentPath.MediaMix,
    scope: ScopeOfDataType.CampaignSubPillar,
    sectionTitle: MediaMixSectionTitle.MediaInvest,
    hook: MediaInvestmentApi.useMediaMixBySubCampaign,
    exportParams: {
      export_type: 'media_mix',
      export_sub_type: 'campaign_sub_pillar',
    },
  },
  // Media Mix YOY
  {
    path: MediaInvestmentPath.MediaMix,
    scope: ScopeOfDataType.Period,
    sectionTitle: MediaMixSectionTitle.MediaMixPercentage,
    hook: MediaInvestmentApi.useMediaMixYOY,
    exportParams: {
      export_type: 'media_mix',
      export_sub_type: 'year_over_year',
    },
  },
  // Media Mix TV vs Digital
  {
    path: MediaInvestmentPath.MediaMix,
    scope: ScopeOfDataType.Period,
    sectionTitle: MediaMixSectionTitle.TVDigital,
    hook: MediaInvestmentApi.useMediaMixTVVsDigital,
    exportParams: {
      export_type: 'media_mix',
      export_sub_type: 'tv_vs_digital',
    },
  },
  // Second Brand By Period
  {
    path: MediaInvestmentPath.SecondBrand,
    scope: ScopeOfDataType.Period,
    hook: MediaInvestmentApi.useSecondBrandByPeriod,
    exportParams: {
      export_type: 'second_brand',
      export_sub_type: 'period',
    },
  },
  // Second Brand By Campaign Pillar
  {
    path: MediaInvestmentPath.SecondBrand,
    scope: ScopeOfDataType.CampaignPillar,
    hook: MediaInvestmentApi.useSecondBrandByCampaignPillar,
    exportParams: {
      export_type: 'second_brand',
      export_sub_type: 'campaign_pillar',
    },
  },
  // Second Brand By Media Mix
  {
    path: MediaInvestmentPath.SecondBrand,
    scope: ScopeOfDataType.MediaMix,
    hook: MediaInvestmentApi.useSecondBrandByMediaMix,
    exportParams: {
      export_type: 'second_brand',
      export_sub_type: 'media_mix',
    },
  },
  // Second Brand By Digital Media Mix
  {
    path: MediaInvestmentPath.SecondBrand,
    scope: ScopeOfDataType.DigitalMediaMix,
    hook: MediaInvestmentApi.useSecondBrandByDigitalMediaMix,
    exportParams: {
      export_type: 'second_brand',
      export_sub_type: 'digital_media_mix',
    },
  },
  // Brand Messaging By Market
  {
    path: MediaInvestmentPath.BrandMessaging,
    scope: ScopeOfDataType.Market,
    hook: MediaInvestmentApi.useBrandMessagingByMarket,
    exportParams: {
      export_type: 'message',
      export_sub_type: 'market',
    },
  },
  // Brand Messaging By Campaign Pillar
  {
    path: MediaInvestmentPath.BrandMessaging,
    scope: ScopeOfDataType.CampaignPillar,
    hook: MediaInvestmentApi.useBrandMessagingByCampaignPillar,
    exportParams: {
      export_type: 'message',
      export_sub_type: 'campaign_pillar',
    },
  },
  // Brand Messaging By Media Mix
  {
    path: MediaInvestmentPath.BrandMessaging,
    scope: ScopeOfDataType.MediaMix,
    hook: MediaInvestmentApi.useBrandMessagingByMediaMix,
    exportParams: {
      export_type: 'message',
      export_sub_type: 'media_mix',
    },
  },
  // Funding Source By Market
  {
    path: MediaInvestmentPath.FundingSource,
    scope: ScopeOfDataType.Market,
    hook: MediaInvestmentApi.useFundingSourceByMarket,
    exportParams: {
      export_type: 'funding_source',
      export_sub_type: 'market',
    },
  },
  // Funding Source By Campaign Pillar
  {
    path: MediaInvestmentPath.FundingSource,
    scope: ScopeOfDataType.CampaignPillar,
    hook: MediaInvestmentApi.useFundingSourceByCampaign,
    exportParams: {
      export_type: 'funding_source',
      export_sub_type: 'campaign_pillar',
    },
  },
  // Funding Source By Sub Campaign Pillar
  {
    path: MediaInvestmentPath.FundingSource,
    scope: ScopeOfDataType.CampaignSubPillar,
    hook: MediaInvestmentApi.useFundingSourceBySubCampaign,
    exportParams: {
      export_type: 'funding_source',
      export_sub_type: 'campaign_sub_pillar',
    },
  },
  // Funding Source By Media Mix
  {
    path: MediaInvestmentPath.FundingSource,
    scope: ScopeOfDataType.MediaMix,
    hook: MediaInvestmentApi.useFundingSourceByMediaMix,
    exportParams: {
      export_type: 'funding_source',
      export_sub_type: 'media_mix',
    },
  },
  // Funding Source By Digital Media Mix
  {
    path: MediaInvestmentPath.FundingSource,
    scope: ScopeOfDataType.DigitalMediaMix,
    hook: MediaInvestmentApi.useFundingSourceByDigitalMediaMix,
    exportParams: {
      export_type: 'funding_source',
      export_sub_type: 'digital_media_mix',
    },
  },
  // Funding Source By Brand vs Performance
  {
    path: MediaInvestmentPath.FundingSource,
    scope: ScopeOfDataType.BrandVsPerformance,
    hook: MediaInvestmentApi.useFundingSourceByBrandVsPerformance,
    exportParams: {
      export_type: 'funding_source',
      export_sub_type: 'brand_vs_performance',
    },
  },
  // Product By Market
  {
    path: MediaInvestmentPath.Product,
    scope: ScopeOfDataType.Market,
    hook: MediaInvestmentApi.useProductByMarket,
    exportParams: {
      export_type: 'product',
      export_sub_type: 'market',
    },
  },
  // Product By Campaign Pillar
  {
    path: MediaInvestmentPath.Product,
    scope: ScopeOfDataType.CampaignPillar,
    hook: MediaInvestmentApi.useProductByCampaign,
    exportParams: {
      export_type: 'product',
      export_sub_type: 'campaign_pillar',
    },
  },
  // Product By Media Mix
  {
    path: MediaInvestmentPath.Product,
    scope: ScopeOfDataType.MediaMix,
    hook: MediaInvestmentApi.useProductByMediaMix,
    exportParams: {
      export_type: 'product',
      export_sub_type: 'media_mix',
    },
  },
]

const emptyQueryResult: UseQueryResult<ListItem[], Error> = {
  data: undefined,
  isError: false,
  isFetched: false,
  isFetchedAfterMount: false,
  isFetching: false,
  isIdle: true,
  isLoading: false,
  isLoadingError: false,
  isPlaceholderData: true,
  isPreviousData: false,
  isRefetchError: false,
  isRefetching: false,
  isStale: false,
  isSuccess: false,
  error: null,
  errorUpdateCount: 0,
  errorUpdatedAt: 0,
  refetch: () => new Promise(noop),
  remove: noop,
  status: 'idle',
  dataUpdatedAt: 0,
  failureCount: 0,
}

const useEmptyQueryResult = () => emptyQueryResult

type InvestmentReportHookProps = {
  url: string | undefined
  title: string
  scope?: string
  periodFormat: string
  filterOptions: PossibleFiltersOptions
}

type InvestmentReportHookValue = UseQueryResult<ListItem[], Error> & {
  exportParams: DataHookExportParams | null
}


export const useInvestmentReportData = ({
  url,
  title,
  scope = ScopeOfDataType.Period,
  periodFormat,
  filterOptions,
}: InvestmentReportHookProps): InvestmentReportHookValue => {
  const period = `${periodFormat}ly`

  const dataHookProps: DataByPeriodProps = {
    period,
    funding_sources: filterOptions[Filters.FundingSource],
    markets: filterOptions[Filters.Markets],
    media_sub_channels: filterOptions[Filters.MediaSubChannel],
    financial_years: filterOptions[Filters.FinancialYear],
    years: filterOptions[Filters.CalendarYears],
    media_channels: filterOptions[Filters.MediaChannel],
    campaigns: filterOptions[Filters.Campaign],
    sub_campaigns: filterOptions[Filters.SubCampaign],
    second_brands: filterOptions[Filters.SecondBrand],
    buy_types: filterOptions[Filters.BuyType],
    jbps: filterOptions[Filters.JBP],
  }

  const matchedDataHookConfig = find(
    dataHookConfigs,
    config => (
      url === config.path &&
      (config.sectionTitle ? title === config.sectionTitle : true) &&
      scope === config.scope
    )
  )

  if( !matchedDataHookConfig ){
    console.warn(`Could not match report ${title}:${scope} to data hook config`)
  }

  const useDataHook = matchedDataHookConfig ? matchedDataHookConfig.hook : useEmptyQueryResult

  const queryResult = useDataHook(dataHookProps)

  return {
    ...queryResult,
    exportParams: matchedDataHookConfig && matchedDataHookConfig.exportParams || null,
  }
}
