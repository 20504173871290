import { get, maxBy, minBy } from 'lodash-es'

import { reportProviders } from '@percept/constants'

import { DoubleVerifyProvider, PlatformUnitProviderInfo, ReportProvider } from '@percept/types'


type DeriveReferenceDateProps = {
  providerInfo: PlatformUnitProviderInfo | null
  enabledProviders: (ReportProvider | DoubleVerifyProvider)[]
  activeReferenceDate: Date | null
  referenceDateBehaviour: 'LATEST_ALL' | 'LATEST_ANY' | 'CUSTOM'
}

export const deriveCanonicalReferenceDate = ({
  providerInfo,
  enabledProviders,
  activeReferenceDate,
  referenceDateBehaviour,
}: DeriveReferenceDateProps): Date | null => {

  if( referenceDateBehaviour === 'CUSTOM' ){
    return activeReferenceDate
  }

  if( !providerInfo ){
    return null
  }

  const matchingDates = enabledProviders.reduce( (acc, provider) => {
    const referenceDate = get(providerInfo[provider], 'reference_date', null)
    if( referenceDate ){
      acc.push(referenceDate)
    }
    return acc
  }, [] as Date[])

  if( !matchingDates.length ){
    return null
  }

  if( referenceDateBehaviour === 'LATEST_ALL' ){
    return minBy(matchingDates, d => d.getTime()) || null
  }

  const matchingPrimaryProviders = enabledProviders.filter( p => (
    reportProviders.includes(p as ReportProvider)
  )) as ReportProvider[]

  const matchingPrimaryProviderDates = matchingPrimaryProviders.reduce( (acc, provider) => {
    const referenceDate = get(providerInfo[provider], 'reference_date', null)
    if( referenceDate ){
      acc.push(referenceDate)
    }
    return acc
  }, [] as Date[])

  return maxBy(matchingPrimaryProviderDates, d => d.getTime()) || null

}


export const deriveLatestAvailableReferenceDate = ({
  providerInfo,
  enabledProviders,
}: Pick<
  DeriveReferenceDateProps, 'providerInfo' | 'enabledProviders'
>): Date | null => {
  return deriveCanonicalReferenceDate({
    providerInfo,
    enabledProviders,
    activeReferenceDate: null,
    referenceDateBehaviour: 'LATEST_ANY',
  })
}
