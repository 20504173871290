import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Popover,
  Tab,
  Tabs,
} from '@material-ui/core'
import { makeAppStyles } from '@percept/mui'
import React, { useState } from 'react'
import { GroupItem, OptionType } from '../FiltersPanel'

interface Props {
  changeHandler: (value: OptionType | OptionType[], groupValue: string) => void
  optionsList: GroupItem[]
  checkedOptions: {
    list: (null | string | { value: string; name: string })[]
    group: string
  }
  anchorEl: Element | null
  closeHandler: () => void
}

const usePopoverClasses = makeAppStyles(() => ({
  tabs: {
    '& span': {
      textTransform: 'none',
    },
  },
  checkboxContainer: {
    padding: '6px 16px',
    fontSize: '12px',
    maxHeight: '200px',
    overflow: 'auto',
    width: '100%',
    '&::-webkit-scrollbar': {
      width: '0.5em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#C6CBD0',
      borderRadius: '20px',
    },
  },
}))

export const PopoverWithCheckbox = ({
  changeHandler,
  optionsList,
  checkedOptions,
  anchorEl,
  closeHandler,
}: Props): JSX.Element => {
  const isOpen = !!anchorEl
  const id = isOpen ? 'simple-popover' : undefined
  const withGroups = optionsList.length !== 1

  const [tabValue, setTabValue] = useState(0)
  const popoverClasses = usePopoverClasses()

  const changeHandlerCheckbox = (
    checkOption: { value: string; name: string },
    listOfOptions: GroupItem,
    checked: boolean
  ): void => {
    if (checkOption.value === 'all' && checked) {
      const listOfCheckedOptions = listOfOptions.options.filter(
        (option) =>
          !checkedOptions.list.find(
            (el) =>
              el &&
              typeof el === 'object' &&
              el.value === option.value &&
              el.name === option.name
          )
      )
      changeHandler(listOfCheckedOptions, listOfOptions.group_value)
    } else {
      if (
        checkedOptions.list.length === listOfOptions.options.length - 2 &&
        checked &&
        listOfOptions.options.find((el) => el.value === 'all')
      ) {
        const allOption = listOfOptions.options.find(
          (el) => el.value === 'all'
        ) as OptionType
        changeHandler(
          [{ value: allOption.value, name: allOption.name },{value: checkOption.value, name: checkOption.name}],
          listOfOptions.group_value
        )
      }
      else {
        changeHandler(
          { value: checkOption.value, name: checkOption.name },
          listOfOptions.group_value
        )
      }
    }
  }

  return (
    <Popover
      id={id}
      open={isOpen}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={(): void => {
        closeHandler()
        setTabValue(0)
      }}>
      {withGroups ? (
        <Tabs
          value={tabValue}
          className={popoverClasses.tabs}
          onChange={(_, newValue): void => setTabValue(newValue)}>
          {optionsList.map((option, index) => (
            <Tab
              key={option.group_value || `Tab ${index}`}
              value={index}
              label={option.group_name || `Tab ${index}`}
            />
          ))}
        </Tabs>
      ) : null}

      {optionsList.map((option, index) => {
        return (
          <div
            role='tabpanel'
            key={option.group_value}
            hidden={tabValue !== index}>
            {tabValue === index && (
              <FormControl className={popoverClasses.checkboxContainer}>
                <FormGroup>
                  {option.options.map((el) => (
                    <FormControlLabel
                      key={el.value}
                      control={
                        <Checkbox
                          checked={
                            (checkedOptions.list.filter(
                              (i) =>
                                i &&
                                typeof i === 'object' &&
                                i.value === el.value
                            ).length > 0 ||
                              checkedOptions.list.includes(el.value)) &&
                            checkedOptions.group === option.group_value
                          }
                          onChange={(_, checked): void =>
                            changeHandlerCheckbox(el, option, checked)
                          }
                          name={el.value}
                        />
                      }
                      label={el.name}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            )}
          </div>
        )
      })}
    </Popover>
  )
}
