import React from 'react'

import {
  Grid,
  GridProps,
  useAppTheme,
  ReportRange,
  Typography,
  makeAppStyles,
} from '@percept/mui'

import {
  LineProps,
  ResponsiveLine,
  dmyFormatter,
} from '@percept/mui/charts'

import { useReportSeriesReports } from '@percept/hooks'

import { SeriesPerformanceSegmentation, TimelineDatum, useSeriesDatasets } from './lib'

import { get, last } from 'lodash-es'
import { StructuralReportResultType } from '@percept/types'


const useStyles = makeAppStyles( theme => ({
  chartContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    flexBasis: '100%',
    margin: theme.spacing(-2),
    [theme.breakpoints.up('xs')]: {
      flexWrap: 'wrap',
    },
    [theme.breakpoints.up('md')]: {
      flexWrap: 'nowrap',
    },
  },
  chart: {
    [theme.breakpoints.up('xs')]: {
      width: '25%',
    },
    [theme.breakpoints.up('md')]: {
      width: '12.5%',
    },
  },
  chartElement: {
    boxShadow: theme.shadows[2],
  },
  chartLabel: {
    textAlign: 'center',
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(1),
  },
  chartForeground: {
    textShadow: '0 0 4px rgba(0, 0, 0, 0.85)',
    pointerEvents: 'none',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: 14,
    textAnchor: 'end',
    stroke: 'none',
  },
}))


const lineProps: Omit<LineProps<TimelineDatum>, 'data' | 'width'> = {
  height: 48,
  grid: false,
  axisLine: false,
  axisText: false,
  xScaleType: 'time',
  numYTicks: 3,
  verticalMargin: 0,
  fillOpacity: 0.35,
  arbitraryTimeline: true,
  xTickFormatter: dmyFormatter,
  // eslint-disable-next-line react/display-name
  tooltipLabelDatumFormatter: (d) => (
    <ReportRange
      start={d.start}
      end={d.end}
      format='dd/MM/yy' />
  )
}


export type ReportSeriesPerformanceProps = {
  series_id: string | null
  segmentation: SeriesPerformanceSegmentation | null
  resultType?: StructuralReportResultType
  GridContainerProps?: Partial<Omit<GridProps, 'container' | 'item'>>
  GridItemProps?: Partial<Omit<GridProps, 'container' | 'item'>>
}


export const ReportSeriesPerformance = ({
  series_id,
  segmentation,
  resultType = 'METRIC_REPORT',
  GridContainerProps = {},
  GridItemProps = {},
}: ReportSeriesPerformanceProps): JSX.Element => {

  const appTheme = useAppTheme()

  const classes = useStyles()

  const [reports] = useReportSeriesReports({ series_id })

  const seriesDatasets = useSeriesDatasets({
    reports: reports.data,
    segmentation,
    resultType,
    appTheme,
  })

  return (
    <Grid container spacing={4} {...GridContainerProps}>
      { seriesDatasets.map( ({ dimension, data, color, label, formatter, backgroundColor }) => {
        const latestValue = get(last(data), 'value', null)
        const textContent = latestValue === null ? 'N / A' : formatter(latestValue)
        const foregroundElement = (
          <text
            x='100%'
            y='100%'
            dx={-6}
            dy={-6}
            className={classes.chartForeground}
            fill={
              latestValue === null ?
                appTheme.palette.text.disabled :
                appTheme.palette.secondary.contrastText
            }>
            { textContent }
          </text>
        )
        const backgroundElement = (
          backgroundColor ? (
            <rect
              x='0%'
              y='0%'
              height='100%'
              width='100%'
              fill={backgroundColor} />
          ) : null
        )
        return (
          <Grid
            item
            key={label}
            className={classes.chart}
            {...GridItemProps}>
            <ResponsiveLine
              svgClassName={classes.chartElement}
              key={dimension}
              backgroundElement={backgroundElement}
              foregroundElement={foregroundElement}
              {...lineProps as LineProps}
              yTickFormatter={formatter}
              fill={color}
              data={data} />
            <Typography
              className={classes.chartLabel}
              variant='subtitle1'>
              { label }
            </Typography>
          </Grid>
        )
      })}
    </Grid>
  )
}
