import React from 'react'

import { AppTheme, AppThemeOptions, createAppTheme } from './themeAugmentation'

import { BrandtechLogo, Percept, VodafoneLogo } from '../components/Logos'

import { basePerceptThemeOptions } from './basePerceptThemeOptions'

import { merge } from 'lodash-es'


type BrandedAppThemeOptions = (
  Pick<
    AppThemeOptions, 'branding'
  > &
  Partial<
    RecursiveOptional<
      Omit<
        AppThemeOptions, 'branding'
      >
    >
  >
)


type RecursiveOptional<T extends object> = {
  [K in keyof T]: (T[K] extends object ? (Partial<T[K]> | undefined) : T[K] | undefined)
}


export const makeBrandedAppTheme = (
  options: BrandedAppThemeOptions
): AppTheme => (
  createAppTheme(merge({}, basePerceptThemeOptions, options))
)


export const perceptTheme = makeBrandedAppTheme({
  branding: {
    label: 'Percept',
    LogoComponent: Percept,
  },
})


export const vodafoneTheme = makeBrandedAppTheme({
  branding: {
    label: 'Vodafone',
    // eslint-disable-next-line
    LogoComponent: (props) => (
      <VodafoneLogo
        {...props}
        size={28}
        units='px'
        height={16}
        overflow='visible' />
    ),
  },
})


export const brandtechTheme = makeBrandedAppTheme({
  branding: {
    label: 'Brandtech Group',
    // eslint-disable-next-line
    LogoComponent: (props) => (
      <BrandtechLogo
        {...props}
        size={32}
        units='px'
        height={16}
        overflow='visible' />
    ),
  },
})
