
import React, { Fragment, useMemo, useState } from 'react'

import { Box, IconButton, TablePagination } from '@material-ui/core'

import { FirstPage, LastPage, KeyboardArrowLeft, KeyboardArrowRight } from '../../icons'

import { SimpleTable, SimpleTableProps } from './SimpleTable'


interface TablePaginationActionsProps {
  count: number
  page: number
  rowsPerPage: number
  onChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void
}

const TablePaginationActions = (props: TablePaginationActionsProps): JSX.Element => {

  const { count, page, rowsPerPage, onChangePage } = props

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    onChangePage(event, 0)
  }

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    onChangePage(event, page - 1)
  }

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    onChangePage(event, page + 1)
  }

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box ml={1} display='inline-flex'>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page">
        <FirstPage />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page">
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page">
        <LastPage />
      </IconButton>
    </Box>
  )
}



export type PaginatedTableProps<T extends object> = (
  SimpleTableProps<T> & {
    paginate: number
  }
)

export function PaginatedTable<T extends object>({
  paginate,
  rows,
  ...props
}: PaginatedTableProps<T>): JSX.Element {

  const [page, setPage] = useState(0)

  const items = useMemo(() => {
    const offset = page * paginate
    return paginate >= rows.length ?
      rows :
      rows.slice(offset, Math.min(offset + paginate, rows.length))
  }, [paginate, rows, page])

  return (
    <Fragment>

      <SimpleTable rows={items} {...props} />

      <TablePagination
        ActionsComponent={TablePaginationActions}
        count={rows.length}
        rowsPerPage={paginate}
        rowsPerPageOptions={[paginate]}
        page={page}
        onChangePage={(e, newPage): void => setPage(newPage)} />

    </Fragment>
  )
}  