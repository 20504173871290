
import React, { useState, Fragment } from 'react'

import api from '@percept/api'

import { Typography, Box, Alert, CircularProgress } from '@percept/mui'

import { Send } from '@percept/mui/icons'

import * as Buttons from './Buttons'
import * as Inputs from './Inputs'

import { PasswordRequirements } from './PasswordRequirements'

import getMessage from './getMessage'

import { isValidEmail } from '@percept/utils'

import { isValidCode, isValidPassword } from './utils'

import { AuthComponentProps, AuthInputs } from './lib'

import { StateOnlyResponse } from '@percept/types'


const { CORE_API_ROOT } = process.env

const VERSIONED_CORE_API_ROOT = `${CORE_API_ROOT}/v1`


const ForgotPassword = ({
  inputs,
  dispatch,
  updateInputs,
  attemptSignIn,
  loading,
  error,
  classes,
  AuthInputProps = {},
}: AuthComponentProps): JSX.Element => {

  const [sending, setSending] = useState(false)

  const [delivery, setDelivery] = useState(false)

  const updateHandler = (name: keyof AuthInputs) => (
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const value = e.target.value
      updateInputs({ [name]: value })
    }
  )

  const sendCode = (): void => {
    const { username } = inputs
    if( username ){
      setSending(true)
      api({
        baseURL: VERSIONED_CORE_API_ROOT,
        url: '/auth/forgotten-password',
        method: 'POST',
        data: { username },
      }).then( response => {
        console.log('Response', response)
        setDelivery(true)
      }).catch( e => {
        console.error(e)
      }).finally(() => {
        setSending(false)
      })
    }
  }

  const confirmPasswordReset = (): void => {
    const { username, code, password } = inputs
    api({
      baseURL: VERSIONED_CORE_API_ROOT,
      url: '/auth/forgotten-password-confirmation',
      method: 'POST',
      data: { username, token: code, password },
    }).then( response => {
      console.log('Response', response)
      attemptSignIn()
    }).catch( e => {
      console.error(e)
    }).finally(() => {
      setSending(false)
    })
  }

  const disabled = sending || loading

  return (
    <form
      onSubmit={(e): void => {
        e.preventDefault()
      }}>
        
      <Typography
        className={classes.heading}
        variant='h3'>
        Reset Password
      </Typography>

      { error && (
        <Alert
          my={3}
          variant='error'
          message={getMessage(error)} />
      )}

      { sending && (
        <Alert
          my={3}
          variant='info'
          icon={<CircularProgress size='1em' color='inherit' />}
          message='Sending code...' />
      )}

      { delivery && (
        <Alert
          my={3}
          variant='success'
          header='Verification Code Sent'
          message={`Please check your email ${inputs.username && `at ${inputs.username}`}`} />
      )}
      
      <Box mb={5}>
        { delivery ? (
          <Fragment>

            <PasswordRequirements
              password={inputs.password || ''}
              my={3} />

            <Inputs.Code
              autoFocus
              {...AuthInputProps}
              value={inputs.code || ''}
              onChange={updateHandler('code')} />

            <Inputs.Password
              {...AuthInputProps}
              placeholder='New Password'
              disabled={!isValidCode(inputs.code)}
              value={inputs.password || ''}
              onChange={updateHandler('password')} />

          </Fragment>
        ) : (
          <Fragment>

            <Inputs.Username
              autoFocus
              {...AuthInputProps}
              placeholder='Enter your username'
              value={inputs.username || ''}
              onChange={updateHandler('username')} />

          </Fragment>
        ) }
      </Box>
      
      { delivery ? (
        <Buttons.Confirm
          type='submit'
          disabled={loading || !isValidCode(inputs.code) || !isValidPassword(inputs.password)}
          loading={loading}
          onClick={confirmPasswordReset}>
          Change Password
        </Buttons.Confirm>
      ) : (
        <Buttons.Confirm
          type='submit'
          disabled={disabled || !inputs.username || !isValidEmail(inputs.username)}
          loading={sending}
          IconComponent={Send}
          onClick={sendCode}>
          Send Code
        </Buttons.Confirm>
      ) }
  
      <Box mt={3}>
        { delivery ? (
          <Buttons.Confirm
            fullWidth={false}
            color='default'
            size='small'
            type='submit'
            disabled={disabled}
            loading={sending}
            IconComponent={Send}
            onClick={(): void => {
              console.log('Resend code...')
            }}>
            Resend Code
          </Buttons.Confirm>
        ) : (
          <Buttons.BackToSignIn
            onClick={(): void => dispatch({ type: 'SIGN_IN' })} />
        )}
      </Box>
    
    </form>
  )
}

export default ForgotPassword
