import React, { useMemo } from 'react'

import { Redirect } from 'react-router'

import { platformUnitHasDoubleVerify } from './lib'

import CrossChannelDashboard, { CrossChannelPerformance } from 'components/CrossChannelDashboard'

import {
  useLocation,
  useNavigation,
  usePlatformUnitProviderInfo,
} from '@percept/hooks'

import {
  useReferenceDate,
  useReferenceDateBehaviour,
  useTargetCurrency,
} from 'hooks'

import { useActiveOrgEnvironment } from './hooks'

import { find, get } from 'lodash-es'

import { isBefore } from 'date-fns'

import { isPlatformUnitContainer } from '@percept/utils'

import { platformUnitHasInsights } from './utils'

import { deriveCanonicalReferenceDate, deriveLatestAvailableReferenceDate } from 'components/ReferenceDates'

import { providerURLMap } from '@percept/constants'

import { DoubleVerifyProvider, PlatformUnit, PlatformUnitLeaf, ReportProvider } from '@percept/types'


export const Organisation = (): JSX.Element => {

  const {
    activePlatformUnit,
    rootPlatformUnit,
    defaultPlatformUnitId,
    providers,
    doubleVerifyProviders,
    ...activeOrgEnvironment
  } = useActiveOrgEnvironment()

  const seriesListing = (
    activePlatformUnit && (activePlatformUnit as PlatformUnitLeaf).report_series || []
  )

  const location = useLocation()

  const org_unit_id = activePlatformUnit && activePlatformUnit.id

  const hasDoubleVerify = !!(activePlatformUnit && platformUnitHasDoubleVerify(activePlatformUnit))

  const [platformUnitProviderInfo] = usePlatformUnitProviderInfo({
    org_unit_id,
  })

  const [activeReferenceDate] = useReferenceDate()

  const [referenceDateBehaviour] = useReferenceDateBehaviour()

  const [currency] = useTargetCurrency()

  const referenceDate = useMemo(() => {
    return deriveCanonicalReferenceDate({
      providerInfo: platformUnitProviderInfo.data,
      referenceDateBehaviour,
      activeReferenceDate,
      enabledProviders: [
        ...providers,
        ...doubleVerifyProviders,
      ]
    })
  }, [
    platformUnitProviderInfo.data, referenceDateBehaviour, activeReferenceDate,
    providers, doubleVerifyProviders,
  ])

  const enabledDoubleVerifyProviders: DoubleVerifyProvider[] | undefined = useMemo(() => {
    if( !hasDoubleVerify ){
      return []
    }
    const providerInfoData = platformUnitProviderInfo.data
    if( !(providerInfoData && referenceDate) ){
      return undefined
    }
    return doubleVerifyProviders.reduce( (acc, provider) => {
      const doubleVerifyReferenceDate = get(providerInfoData[provider], 'reference_date', null)
      if( doubleVerifyReferenceDate && !isBefore(doubleVerifyReferenceDate, referenceDate) ){
        acc.push(provider)
      }
      return acc
    }, [] as DoubleVerifyProvider[])
  }, [platformUnitProviderInfo.data, referenceDate, doubleVerifyProviders, hasDoubleVerify])

  const latestAvailableReferenceDate: Date | null = useMemo(() => {
    return deriveLatestAvailableReferenceDate({
      providerInfo: platformUnitProviderInfo.data,
      enabledProviders: [
        ...providers,
        ...doubleVerifyProviders,
      ]
    })
  }, [platformUnitProviderInfo.data, providers, doubleVerifyProviders])

  const { loading } = activeOrgEnvironment

  const navigate = useNavigation()

  const singleProvider: ReportProvider | null = (
    providers.length === 1 ?
      providers[0] :
      null
  )

  const hasPerformanceReporting = !!get(
    activePlatformUnit, ['performance_reporting', 'enabled']
  )

  // Handle root redirect
  if( location.pathname === '/' && defaultPlatformUnitId ){
    return <Redirect to={`/dashboards/${defaultPlatformUnitId}`} />
  }
  
  // Handle redirect for empty parent orgs / orgs with no performance reporting
  if( activePlatformUnit && !hasPerformanceReporting ){

    if( isPlatformUnitContainer(activePlatformUnit) ){

      const nextValidChild = find(activePlatformUnit.children, unit => {
        return (
          unit.performance_reporting && unit.performance_reporting.enabled
        ) || (
          !isPlatformUnitContainer(unit) && get(unit.report_series, 'length')
        )
      }) as PlatformUnit | undefined

      if( nextValidChild && nextValidChild.id ){
        return (
          <Redirect to={`/dashboards/${nextValidChild.id}`} />
        )
      }

    }else{

      if( activePlatformUnit.report_series && activePlatformUnit.report_series.length ){
        // Redirect to either insights or first available series if no perf reporting
        let redirectPath: string | null | undefined

        if( platformUnitHasInsights(activePlatformUnit) ){
          redirectPath = `/dashboards/${activePlatformUnit.id}/insights`
        }else{
          const redirectSeries = activePlatformUnit.report_series[0]
          redirectPath = `/series/${redirectSeries.id}`
        }

        if( redirectPath ){
          return (
            <Redirect to={redirectPath} />
          )
        }
      }else{
        console.warn('No valid route detected for this unit', activePlatformUnit)
      }
    }

  }

  if( singleProvider && activePlatformUnit && rootPlatformUnit ){
    const providerURLFragment = providerURLMap[singleProvider]
    const redirectRoute = (
      `/dashboards/${activePlatformUnit.id}/${providerURLFragment}`
    )
    return (
      <Redirect to={redirectRoute} />
    )
  }

  const DashboardComponent = (
    location.pathname.endsWith('performance') ?
      CrossChannelPerformance :
      CrossChannelDashboard
  )

  return (
    <DashboardComponent
      displayLoader={loading}
      showContent={!loading}
      platformUnit={activePlatformUnit}
      currency={currency}
      providers={providers}
      doubleVerifyProviders={enabledDoubleVerifyProviders || null}
      providerInfo={platformUnitProviderInfo.data}
      referenceDate={referenceDate}
      latestAvailableReferenceDate={latestAvailableReferenceDate}
      seriesListing={seriesListing}
      onSeriesClick={
        seriesListing ?
          (({ id }): void => {
            navigate(`/series/${id}`)
          }) :
          undefined
      } />
  )

}

export default Organisation
